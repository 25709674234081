<template>
  <div class="flex">
    <div class="relative mr-0.5" data-cy="set-review-1">
      <img src="../../assets/images/topofferten/star_empty.png" class="relative">
      <div class="absolute top-0 left-0 w-full h-full">
        <img src="../../assets/images/topofferten/star_full.png"
             class="relative"
             style="position: absolute; overflow: hidden;"
             :style="`clip-path: polygon(0 0, ${width1}% 0, ${width1}% 100%, 0 100%);`"
        >
      </div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-2">
      <img src="../../assets/images/topofferten/star_empty.png" class="relative">
      <div class="absolute top-0 left-0 w-full h-full">
        <img src="../../assets/images/topofferten/star_full.png"
             class="relative"
             style="position: absolute; overflow: hidden;"
             :style="`clip-path: polygon(0 0, ${width2}% 0, ${width2}% 100%, 0 100%);`"
        >
      </div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-3">
      <img src="../../assets/images/topofferten/star_empty.png" class="relative">
      <div class="absolute top-0 left-0 w-full h-full">
        <img src="../../assets/images/topofferten/star_full.png"
             class="relative"
             style="position: absolute; overflow: hidden;"
             :style="`clip-path: polygon(0 0, ${width3}% 0, ${width3}% 100%, 0 100%);`"
        >
      </div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-4">
      <img src="../../assets/images/topofferten/star_empty.png" class="relative">
      <div class="absolute top-0 left-0 w-full h-full">
        <img src="../../assets/images/topofferten/star_full.png"
             class="relative"
             style="position: absolute; overflow: hidden;"
             :style="`clip-path: polygon(0 0, ${width4}% 0, ${width4}% 100%, 0 100%);`"
        >
      </div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-5">
      <img src="../../assets/images/topofferten/star_empty.png" class="relative">
      <div class="absolute top-0 left-0 w-full h-full">
        <img src="../../assets/images/topofferten/star_full.png"
             class="relative"
             style="position: absolute; overflow: hidden;"
             :style="`clip-path: polygon(0 0, ${width5}% 0, ${width5}% 100%, 0 100%);`"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'RatingTopofferten',
    props: {
        review: {
            type: Number,
            default: 5
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: '#4B5563',
        }
    },
    emits: ['update:review'],
    data () {
        return {
            width1: 100,
            width2: 100,
            width3: 100,
            width4: 100,
            width5: 100,
        };
    },
    computed: {},
    watch: {
        review () {
            this.getColor();
        }
    },
    created () {
        this.getColor();
    },
    methods: {
        onMouseOver (number) {
            this.$emit('update:review', number);
        },
        getColor () {
            if (this.review > 0.99) {
                this.width1 = 100;
            } else if (this.review > 0.74) {
                this.width1 = 75;
            } else if (this.review > 0.49) {
                this.width1 = 50;
            } else if (this.review > 0.24) {
                this.width1 = 25;
            } else {
                this.width1 = 0;
            }

            if (this.review > 1.99) {
                this.width2 = 100;
            } else if (this.review > 1.74) {
                this.width2 = 75;
            } else if (this.review > 1.49) {
                this.width2 = 50;
            } else if (this.review > 1.24) {
                this.width2 = 25;
            } else {
                this.width2 = 0;
            }

            if (this.review > 2.99) {
                this.width3 = 100;
            } else if (this.review > 2.74) {
                this.width3 = 75;
            } else if (this.review > 2.49) {
                this.width3 = 50;
            } else if (this.review > 2.24) {
                this.width3 = 25;
            } else {
                this.width3 = 0;
            }

            if (this.review > 3.99) {
                this.width4 = 100;
            } else if (this.review > 3.74) {
                this.width4 = 75;
            } else if (this.review > 3.49) {
                this.width4 = 50;
            } else if (this.review > 3.24) {
                this.width4 = 25;
            } else {
                this.width4 = 0;
            }

            if (this.review > 4.99) {
                this.width5 = 100;
            } else if (this.review > 4.74) {
                this.width5 = 75;
            } else if (this.review > 4.49) {
                this.width5 = 50;
            } else if (this.review > 4.24) {
                this.width5 = 25;
            } else {
                this.width5 = 0;
            }
        },
    }
};
</script>

<style scoped>

</style>
