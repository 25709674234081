<template>
  <div v-if="!noWebsite" class="relative">
    <input id="website"
           v-model="form.websiteTemp"
           class="border border-gray-400 w-full mt-2"
           :placeholder="$t('companyArea.components.contact.companyWebsite.website')"
           required
           type="text"
           @blur="urlChecker"
    >
    <font-awesome-icon v-if="websiteValidation || noWebsite"
                       :icon="['fal','check']"
                       class="absolute right-3 top-5 text-lg text-green-500 cursor-pointer"
                       @click="urlChecker"
    />
    <font-awesome-icon v-else
                       :icon="['fal','exclamation-circle']"
                       class="absolute right-3 top-5 text-base cursor-pointer"
                       @click="urlChecker"
    />
  </div>
  <input v-model="noWebsite" type="checkbox" class="mt-2 mr-2 mb-3">{{ $t('companyArea.components.contact.companyWebsite.noWebsiteYet') }}
</template>

<script>
  import companyApi from '../../../connections/company';

  export default {
    name: 'CompanyWebsite',
    props: {
      company: Object,
      register: Boolean,
    },
    data() {
      return {
        form: {
          websiteTemp: '',
        },
        websiteValidation: null,
        websiteLastValue: null,
        noWebsite: false
      };
    },
    watch: {
      noWebsite() {
        if(this.noWebsite) {
          this.company.website = 'in-progress'
        }
      }
    },
    mounted() {
      this.websiteLastValue = this.form.websiteTemp;
    },
    methods: {
      urlChecker: function () {
        if (this.form.websiteTemp && this.websiteLastValue !== this.form.websiteTemp) {
          companyApi.post('/url-checker', this.form, {
            notification: true,
            'progress': true,
            'progressMessage': 'Wir überprüfen Ihre Webseite.',
          }).then(response => [this.company.website = response.data.content['url'], this.websiteValidation = response.data.content['valid']])
              .catch(() => [this.company.website = '', this.websiteValidation = false ]);
        }

        this.websiteLastValue = this.form.websiteTemp;
      },
    }
  };
</script>

<style scoped>

</style>
