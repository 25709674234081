<template>
  <div class="panel">
    <div class="flex items-center mb-6">
      <info-circle v-if="user.email_verified_at"
                   size="md"
                   class="mr-3"
                   color="bg-green-500"
      >
        <font-awesome-icon :icon="['fal','check']" />
      </info-circle>
      <span class="text-base font-semibold">{{ $t('customerArea.finishLead.finishLeadTips.confirmEmail') }}</span>
    </div>

    <div v-if="!show.editEmail" class="flex items-center">
      <div class="text-xl">{{ editLead.email }}</div>
      <circle-button v-if="!user.email_verified_at"
                     :icon="['fal','pen']"
                     size="sm"
                     class="ml-4"
                     data-cy="btn-edit-email"
                     data-gtm="btn-edit-email"
                     @click="show.editEmail = true"
      />
      <circle-button v-else
                     :icon="['fal','check']"
                     size="sm"
                     class="ml-4"
      />
    </div>
    <input v-else
           v-model="editLead.email"
           type="text"
           class="w-full border border-gray-400"
           :placeholder="$t('customerArea.finishLead.finishLeadTips.email')"
           data-cy="edit-email"
           data-gtm="btn-edit-email"
    >

    <p v-if="!user.email_verified_at" class="mt-2" v-html="$t('customerArea.finishLead.finishLeadTips.confirmEmailInfo')" />
    <p v-else class="mt-2">{{ $t('customerArea.finishLead.finishLeadTips.emailConfirmed') }}</p>
    <div v-if="show.editEmail" class="flex justify-center mt-3">
      <button class="btn btn-lg btn-primary" data-cy="confirm-email-change" @click="saveEmail">{{ $t('general.save') }}</button>
    </div>
  </div>

  <div v-if="!editLead.phone" class="panel">
    <div class="flex items-center mb-4">
      <info-circle v-if="lead.phone"
                   size="md"
                   class="mr-3"
                   color="bg-green-500"
      >
        <font-awesome-icon :icon="['fal','check']" />
      </info-circle>
      <span class="text-base font-semibold">{{ $t('customerArea.finishLead.finishLeadTips.phoneSeriosity') }}</span>
    </div>
    <p class="mb-2">{{ $t('customerArea.finishLead.finishLeadTips.phoneInfo') }}</p>

    <div class="flex items-center">
      <input v-model="phone"
             type="text"
             class="w-full border border-gray-400"
             :placeholder="$t('customerArea.finishLead.finishLeadTips.phone')"
             data-cy="edit-phone"
      >
    </div>
    <p class="mt-2" v-html="$t('customerArea.finishLead.finishLeadTips.maxCompaniesInfo', {desired_quotes: lead.desired_quotes})" />
    <div class="flex justify-center mt-3">
      <button class="btn btn-lg btn-primary" data-cy="confirm-phone-change" @click="savePhone">{{ $t('general.save') }}</button>
    </div>
  </div>

  <div class="panel">
    <p class="text-base font-semibold">{{ $t('customerArea.finishLead.finishLeadTips.leadWillBeSent') }}</p>
    <p class="mt-1">{{ $t('customerArea.finishLead.finishLeadTips.leadWillBeSentInfo') }}</p>
    <div class="flex justify-center mt-4">
      <button class="btn btn-lg btn-primary"
              data-cy="btn-finished"
              data-gtm="btn-lead-finished"
              @click="saveFinishLead"
      >
        {{ $t('customerArea.finishLead.finishLeadTips.finished') }}
      </button>
    </div>
    <p v-if="lead.taken_by === 0" class="text-center text-gray-500 mt-10">{{ $t('customerArea.finishLead.finishLeadTips.mistake') }}</p>
    <div class="flex justify-center mt-3">
      <button class="btn btn-sm btn-secondary mx-1 mt-2"
              data-cy="cancel-lead"
              data-gtm="btn-cancel-lead"
              @click="show.confirm = 'cancelLead'"
      >
        {{ $t('customerArea.finishLead.finishLeadTips.cancelLead') }}
      </button>
    </div>
  </div>

  <confirmation-modal v-if="show.confirm.length > 0" @close="show.confirm = ''">
    <div>
      <div class="text-center text-xl font-extrabold px-2">
        <div>
          {{ $t('customerArea.finishLead.finishLeadTips.stopLead') }}<br>
          <div class="text-xs font-normal">{{ $t('customerArea.finishLead.finishLeadTips.stopLeadInfo') }}</div>
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                data-cy="confirm"
                @click="cancelLead"
        >
          <font-awesome-icon :icon="['fal','check']" />
          <span class="absolute -bottom-6 text-gray-900 text-xs uppercase">{{ $t('general.yes') }}</span>
        </button>
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                data-cy="reject"
                @click="show.confirm = ''"
        >
          <font-awesome-icon :icon="['fal','times']" />
          <span class="absolute -bottom-6 text-gray-900 text-xs uppercase">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </confirmation-modal>

  <div v-if="show.send" class="absolute inset-0 flex flex-col items-center justify-center bg-white/90">
    <p v-if="error.status" class="text-sm font-semibold text-center">{{ error.message }}</p>
    <p v-else class="text-sm font-semibold text-center">{{ $t('customerArea.finishLead.finishLeadTips.finishWaitSentence') }}</p>
    <div class="relative h-10 w-10 mt-5">
      <div class="absolute text-center left-0 right-0 top-2.5">
        {{ timeRemaining }}
      </div>
      <div class="sk-chase">
        <div class="sk-chase-dot sk-chase-dot-1" />
        <div class="sk-chase-dot sk-chase-dot-2" />
        <div class="sk-chase-dot sk-chase-dot-3" />
        <div class="sk-chase-dot sk-chase-dot-4" />
        <div class="sk-chase-dot sk-chase-dot-5" />
        <div class="sk-chase-dot sk-chase-dot-6" />
        <div class="sk-chase-dot sk-chase-dot-7" />
      </div>
    </div>
    <div v-if="error.status" class="flex justify-center mt-4">
      <button id="error" class="btn btn-secondary btn-sm" @click="closeError">{{ $t('general.close') }}</button>
    </div>
    <div v-else-if="timeRemaining === 0" class="flex justify-center mt-4">
      <button class="btn btn-secondary btn-sm" @click="closeError">{{ $t('general.next') }}</button>
    </div>
  </div>
</template>

<script>
import leadFormApi from '../../connections/leadForm';
import store from '../../store/mainStore';
import InfoCircle from '../../snippets/InfoCircle.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import Bugsnag from '@bugsnag/js';

export default {
  name: 'FinishLeadTips',
  components: { ConfirmationModal, CircleButton, InfoCircle },
  props: {
    lead: Object,
    // no use
    showDesiredQuotes: Boolean,
  },
  emits: ['success'],
  data() {
    return {
      show: {
        editEmail: false,
        confirm: '',
        send: false,
      },
      error: {
        status: false,
        message: 'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später nochmals.',
      },
      editLead: JSON.parse(JSON.stringify(this.lead)),
      phone: JSON.parse(JSON.stringify(this.lead)).phone,
      timeRemaining: 10,
      timer: null,
    };
  },
  computed: {
    user() {
      return store.state.main.user;
    },
  },
  methods: {
    saveEmail() {
      try {
        // GTM Event, used for Analytics Tracking
        window.dataLayer.push({
          'event': 'finish_lead_tips_save_email',
          'leadUuid': this.lead.uuid,
        });
      } catch (error) {
        Bugsnag.notify(error);
      }

      console.log('FinishLead.vue: saveEmail ' + this.lead.uuid + ' ' + this.lead.email);
      leadFormApi.post('leads/' + this.lead.uuid + '/company-finder/change-email', { email: this.editLead.email }, { notification: true })
          .then(response => response.data.notificationType === 'success' ? store.commit('customer/setCurrentLead', response.data.content) : this.show.editEmail = true);
      this.show.editEmail = false;
      console.log('FinishLead.vue: saveEmail successful');
    },
    savePhone() {
      try {

      console.log('FinishLead.vue: savePhone ' + this.lead.uuid + ' ' + this.phone);
      leadFormApi.post('leads/' + this.lead.uuid + '/company-finder/update-phone', { phone: this.phone }, { notification: true })
          .then(response => [store.commit('customer/setCurrentLead', response.data.content),
            console.log('FinishLead.vue: savePhone successful'),
            // GTM Event, used for Analytics Tracking
            window.dataLayer.push({
              'event': 'finish_lead_tips_save_phone',
              'leadUuid': this.lead.uuid,
            }),
          ]);
      } catch (error) {
        Bugsnag.notify(error);
      }
    },
    saveFinishLead() {
      console.log('FinishLead.vue: savePhone ' + this.lead.uuid + ' ' + this.phone);
      this.startTimer();

      try{
        // GTM Event, used for Analytics Tracking
        window.dataLayer.push({
          'event': 'finish_lead_finished',
          'leadUuid': this.lead.uuid,
        });
      } catch (error) {
        Bugsnag.notify(error);
      }

      leadFormApi.post('leads/' + this.lead.uuid + '/company-finder/finish-lead', { phone: this.phone }, { notification: true })
        .then(response => response.data.content.companyLeads.length > 0
          ? [store.commit('customer/setCompanyLeads', response.data.content.companyLeads),
              store.commit('customer/setCurrentLead', response.data.content.companyLeads[0].lead),
              console.log('FinishLead.vue: saveFinishLead successful')
            ]
          : (
            response.data.content.leads.length > 0 ?
              [store.commit('customer/setCurrentLead',
                response.data.content.leads[0]),
                console.log('FinishLead.vue: saveFinishLead successful')
              ]
                :
              [console.log('FinishLead.vue: saveFinishLead no data')]
          )
        );
    },
    cancelLead() {
      leadFormApi.patch('leads/' + this.lead.id + '/cancel-lead', {
        'progress': true,
        notification: true,
      }).then(response => [store.commit('customer/setCurrentLead', response.data.content['leads']), this.$router.push({ name: 'QuoteOverview' })]);
      this.show.confirm = '';
    },
    closeError() {
      this.show.send = false;
      this.error.message = '';
      this.error.status = false;
    },
    startTimer() {
      this.show.send = true;
      this.timer = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.stopTimer();
          this.redirectToNewLocation();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    redirectToNewLocation() {
      // Hier erfolgt der Router-Umleitung nach 20 Sekunden - new param ist für GTM Conversion Tracking
      this.$router.push({ name: 'QuoteOverview', query: { new_lead: true }});
    },
  },
};
</script>

<style scoped>

</style>
