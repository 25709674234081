<template>
  <p class="text-sm mt-4">{{ $t('customerArea.quoteOverview.companyTags.markCompany') }}</p>
  <div class="mt-1">
    <button class="btn btn-sm m-1" :class="formdata.professional === 1 ? 'btn-green' : 'btn-secondary'" @click="storeProfessional(1)">{{ $t('customerArea.quoteOverview.companyTags.professial') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.professional === 0 ? 'btn-green' : 'btn-secondary'" @click="storeProfessional(0)">{{ $t('customerArea.quoteOverview.companyTags.unprofessial') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.price === 0 ? 'btn-green' : 'btn-secondary'" @click="storePrice(0)">{{ $t('customerArea.quoteOverview.companyTags.dumpingPrice') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.price === 1 ? 'btn-green' : 'btn-secondary'" @click="storePrice(1)">{{ $t('customerArea.quoteOverview.companyTags.fairPrice') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.price === 2 ? 'btn-green' : 'btn-secondary'" @click="storePrice(2)">{{ $t('customerArea.quoteOverview.companyTags.expensive') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.kindness === 1 ? 'btn-green' : 'btn-secondary'" @click="storeKindness(1)">{{ $t('customerArea.quoteOverview.companyTags.friendly') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.kindness === 0 ? 'btn-green' : 'btn-secondary'" @click="storeKindness(0)">{{ $t('customerArea.quoteOverview.companyTags.unfriendly') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.active === 1 ? 'btn-green' : 'btn-secondary'" @click="storeActive(1)">{{ $t('customerArea.quoteOverview.companyTags.interested') }}</button>
    <button class="btn btn-sm m-1" :class="formdata.active === 0 ? 'btn-green' : 'btn-secondary'" @click="storeActive(0)">{{ $t('customerArea.quoteOverview.companyTags.lowInterested') }}</button>
  </div>
  <p class="mt-2 text-xs" v-html="$t('customerArea.quoteOverview.companyTags.tagInfo')" />
</template>

<script>
import companyApi from '../../connections/company';
import store from '../../store/mainStore';

export default {
  name: 'CompanyTags',
  props: {
    companyLead: Object,
    companyTag: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      formdata: {
        professional: null,
        price: null,
        kindness: null,
        active: null,
      }

    };
  },
  created() {
    if(!this.companyTag) {
      this.formdata.company_id = this.companyLead.company_id;
      this.formdata.lead_id = this.companyLead.lead_id;
    } else {
      this.formdata = {};
      Object.assign(this.formdata, this.companyTag)
    }
  },
  methods: {
    storeProfessional(value) {
      this.formdata.professional = value;
      this.storeData()
    },
    storePrice(value) {
      this.formdata.price = value;
      this.storeData()
    },
    storeKindness(value) {
      this.formdata.kindness = value;
      this.storeData()
    },
    storeActive(value) {
      this.formdata.active = value;
      this.storeData()
    },
    storeData() {
      if(this.formdata.hasOwnProperty('id')) {
        companyApi.patch('companies/' + this.companyLead.company_id + '/company-tags/' + this.formdata.id, this.formdata, { notification: false, progress: true }).
            then(response => response.data.notificationType === 'success' ?
                [store.dispatch('customer/updateCompanyTag', response.data.content)] : '');
      } else {
      companyApi.post('companies/' + this.companyLead.company_id + '/company-tags', this.formdata, { notification: false, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              [store.dispatch('customer/updateCompanyTag', response.data.content), Object.assign(this.formdata, response.data.content)] : '');
      }
    }
  },
};
</script>

<style scoped>

</style>
