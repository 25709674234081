<template>
  <div class="flex flex-col rounded bg-white p-4" :class="{ 'inset-0':(!expanded) }">
    <div v-if="companyLead.status === 0" class="text-xs text-gray-500 border-b border-gray-300 mt-3 pb-1">{{ $t('classification.' + companyLead.lead.form.translation_key) }} - {{ companyLead.lead.city }}</div>
    <div v-else class="text-xs text-gray-500 border-b border-gray-300 mt-3 pb-1">{{ companyLead.lead.surname }} {{ companyLead.lead.name }}</div>
    <button v-if="!expanded"
            class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full"
            data-cy="close-lead-menu"
            @click="$emit('close-menu')"
    >
      <font-awesome-icon :icon="['fal','times']" />
    </button>
    <div v-for="menuItem in menu.filter(obj => obj.status.includes(companyLead.status))" class="w-full" :class="{ 'border-b border-gray-300':(!menuItem.hasOwnProperty('condition') || menuItem.condition[0])}">
      <button v-if="!menuItem.hasOwnProperty('condition') || menuItem.condition[0]"
              class="text-left font-semibold pb-2 mt-2"
              :data-cy="menuItem.action"
              @click="menuItem.confirmation ? [confirmingFunction = menuItem.action, confirmation[companyLead.id] = true, confirmationText = menuItem.confirmText] : handleMenuCall(menuItem.action)"
      >
        {{ menuItem.label }}  <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2" />
      </button>
    </div>
  </div>

  <transition name="modal-fade">
    <div v-if="confirmation[companyLead.id] === true" class="absolute flex justify-center inset-0 bg-white rounded z-30 p-4">
      <div class="flex flex-col">
        <div class="text-center font-semibold px-2">
          {{ confirmationText }}
        </div>
        <div v-if="confirmingFunction === 'noQuoteWanted' && noQuoteWantedRate > 5" class="text-center text-red-600 text-xs mt-2 px-2">
          <span v-if="noQuoteWantedRate > 15">{{ $t('menu.leadMenu.noQuoteWantedRateWarning1') }}</span>
          <span v-else-if="noQuoteWantedRate > 8">{{ $t('menu.leadMenu.noQuoteWantedRateWarning2') }}</span>
          <span v-else>{{ $t('menu.leadMenu.noQuoteWantedRateWarning3') }}</span>
        </div>
        <div v-if="confirmingFunction === 'isFake' && fakeRequestRate > 5" class="text-center text-red-600 text-xs mt-2 px-2">
          <span v-if="fakeRequestRate > 12">{{ $t('menu.leadMenu.fakeRequestRateWarning1') }}</span>
          <span v-else-if="fakeRequestRate > 7">{{ $t('menu.leadMenu.fakeRequestRateWarning2') }}</span>
          <span v-else>{{ $t('menu.leadMenu.fakeRequestRateWarning3') }}</span>
        </div>

        <div class="flex justify-center mt-3">
          <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3"
                  data-cy="confirm"
                  @click="handleMenuCall(confirmingFunction)"
          >
            <font-awesome-icon :icon="['fal','check']" />
            <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.yes') }}</span>
          </button>
          <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3"
                  data-cy="reject"
                  @click="confirmation[companyLead.id] = false; confirmingFunction = ''"
          >
            <font-awesome-icon :icon="['fal','times']" />
            <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.no') }}</span>
          </button>
        </div>
      </div>
    </div>
  </transition>

  <change-lead-date v-if="show.changeDate"
                    :company-lead="companyLead"
                    :show="show"
                    @close-edit="show.changeDate = false"
  />
  <invalid-email v-if="show.invalidEmail"
                 :company-lead="companyLead"
                 :show="show"
                 @close-edit="show.invalidEmail = false"
  />
  <invalid-phone v-if="show.invalidPhone"
                 :company-lead="companyLead"
                 :show="show"
                 @close-edit="show.invalidPhone = false"
  />

  <confirmation-modal v-if="show.modal" :content-size="show.quoteEdit" @close="show.modal = false">
    <make-quote-container v-if="show.quoteEdit"
                          :company-lead="companyLead"
                          :quote="quote"
                          @close="show.modal = false; show.quoteEdit = false"
    />
    <confirm-price v-if="show.confirmPrice"
                   :company-lead="companyLead"
                   :quote="companyLead.lead.quotes.length > 0 ? companyLead.lead.quotes[0] : {}"
                   @close="show.modal = false; show.confirmPrice = false"
    />
    <make-fake-request v-if="show.makeFakeRequest" :company-lead="companyLead" @close="show.modal = false; show.makeFakeRequest = false; $emit('close-menu')" />
  </confirmation-modal>
</template>

<script>
import store from '../store/mainStore';
import leadFormApi from '../connections/leadForm';
import QuoteEdit from '../customerArea/QuoteOverview/QuoteEdit.vue';
import ConfirmationModal from '../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from '../companyArea/Leads/LeadOverview/makeQuote/MakeQuoteContainer.vue';
import ChangeLeadDate from '../companyArea/Leads/LeadOverview/ChangeLeadDate.vue';
import ConfirmPrice from '../companyArea/Leads/LeadOverview/confirmPrice.vue';
import InvalidEmail from '../companyArea/Leads/LeadOverview/InvalidEmail.vue';
import InvalidPhone from '../companyArea/Leads/LeadOverview/InvalidPhone.vue';
import MakeFakeRequest from "../companyArea/Leads/LeadOverview/MakeFakeRequest.vue";
import dayjsHelper from '../../plugins/dayjsHelper';

export default {
  name: 'LeadMenu',
  components: {MakeFakeRequest, ConfirmPrice, ChangeLeadDate, MakeQuoteContainer, ConfirmationModal, QuoteEdit, InvalidEmail, InvalidPhone },
  props: {
    companyLead: Object,
    showMenu: Boolean,
    expanded: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['close-menu'],
  data() {
    return {
      confirmation: [],
      confirmingFunction: '',
      confirmationText: '',
      quote: {},
      noQuoteWantedRate: store.state.company.company.no_quote_wanted_rate,
      fakeRequestRate: store.state.company.company.fake_request_rate,
      show: {
        modal: false,
        quoteEdit: false,
        changeDate: false,
        confirmPrice: false,
        invalidEmail: false,
        invalidPhone: false,
        makeFakeRequest: false,
      },
      menu: [
        { status: [0], label: this.$t('menu.leadMenu.deleteLead'), action: 'deleteLead', confirmation: false },
        {
          status: [2],
          label: this.$t('menu.leadMenu.noQuoteWanted'),
          action: 'noQuoteWanted',
          condition: [this.companyLead.lead.commission && !dayjsHelper.isOlderThanInDays(this.companyLead.taken_at,21)],
          confirmation: true,
          confirmText: this.$t('menu.leadMenu.noQuoteWantedText'),
        },
        {
          status: [1,2,6,13,10,11],
          label: this.$t('menu.leadMenu.openFakeRequest'),
          action: 'openFakeRequest',
          confirmation: false,
          confirmText: this.$t('menu.leadMenu.openFakeRequestText') },
        {
          status: [2],
          label: this.$t('menu.leadMenu.noQuoteAccepted'),
          action: 'noQuoteAccepted',
          confirmation: true,
          condition: [! [1, 2, 3].includes(this.companyLead.lead.status) || dayjsHelper.isOlderThanInDays(this.companyLead.lead.date, 1) || dayjsHelper.isOlderThanInDays(this.companyLead.lead.valid_at, 60)],
          confirmText: this.$t('menu.leadMenu.noQuoteAcceptedText'),
        },
        {
          status: [2],
          label: this.$t('menu.leadMenu.noQuoteRejected'),
          action: 'noQuoteRejected',
          confirmation: true,
          condition: [! [1, 2, 3].includes(this.companyLead.lead.status) || dayjsHelper.isOlderThanInDays(this.companyLead.lead.date, 1) || dayjsHelper.isOlderThanInDays(this.companyLead.lead.valid_at, 60)],
          confirmText: this.$t('menu.leadMenu.noQuoteRejectedText'),
        },
        {
          status: [2],
          label: this.$t('menu.leadMenu.noQuote'),
          action: 'noQuote',
          confirmation: true,
          condition: [this.companyLead.lead.commission && [1, 2, 3].includes(this.companyLead.lead.status) && !dayjsHelper.isOlderThanInDays(this.companyLead.lead.date, 1) && !dayjsHelper.isOlderThanInDays(this.companyLead.lead.valid_at, 60)],
          confirmText: this.$t('menu.leadMenu.noQuoteText'),
        },
        {
          status: [2],
          label: this.$t('menu.leadMenu.noQuoteMade'),
          action: 'noQuote',
          confirmation: true,
          condition: [this.companyLead.lead.commission && (! [1, 2, 3].includes(this.companyLead.lead.status) || dayjsHelper.isOlderThanInDays(this.companyLead.lead.date, 1) || dayjsHelper.isOlderThanInDays(this.companyLead.lead.valid_at, 60))],
          confirmText: this.$t('menu.leadMenu.noQuoteMadeText'),
        },
        { status: [2, 3],
          label: this.$t('menu.leadMenu.newQuote'),
          action: 'openNewQuote',
          condition: [this.companyLead.status > 0 && this.companyLead.lead.quotes.length > 0],
          confirmation: false },
        { status: [2, 3], label: this.$t('menu.leadMenu.editQuote'), action: 'openQuoteEdit', condition: [this.companyLead.status > 0 && this.companyLead.lead.quotes.length > 0], confirmation: false },
        { status: [1, 2, 3], label: this.$t('companyArea.leads.leadOverview.changeJobDate'), action: 'openDateChange', condition: [this.companyLead.status > 0], confirmation: false },
        { status: [2, 3], label: this.$t('menu.leadMenu.invalidEmail'), action: 'openInvalidEmail', condition: [this.companyLead.lead.commission], confirmation: false },
        { status: [2, 3], label: this.$t('menu.leadMenu.invalidPhone'), action: 'openInvalidPhone', condition: [this.companyLead.lead.commission], confirmation: false },
        { status: [3], label: this.$t('menu.leadMenu.jobCancelled'), action: 'confirmedQuoteRejected', condition: [this.companyLead.lead.commission], confirmation: true, confirmText: this.$t('menu.leadMenu.jobCancelledText') },
        { status: [5], label: this.$t('menu.leadMenu.payCommission'), action: 'openConfirmPrice', condition: [this.companyLead.lead.commission], confirmation: false },
        { status: [6,7,8,9,10,11], label: this.$t('menu.leadMenu.noQuoteAccepted'), action: 'rejectedConfirmed', confirmation: true, confirmText: this.$t('menu.leadMenu.rejectedConfirmedText') },

      ],
    };
  },
  methods: {
    handleMenuCall(functionName) {
      this[functionName]();
    },

    deleteLead() {
      leadFormApi.delete('/company-leads/' + this.companyLead.id, { 'progress': true }).
          then(response => response.data.notificationType === 'success' ? [store.commit('company/setCompanyLeads', response.data.content)] : '');
      this.$emit('close-menu')
    },
    noQuoteWanted() {
      store.dispatch('company/noQuoteWanted', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    isFake() {
      leadFormApi.patch('company-leads/' + this.companyLead.id + '/fake').then(response => [store.dispatch('company/updateCompanyLead', response.data.content.companyLead)]);
      this.$emit('close-menu')
    },
    noQuoteAccepted() {
      store.dispatch('company/confirmedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    noQuoteRejected() {
      store.dispatch('company/rejectedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    noQuote() {
      store.dispatch('company/noQuote', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    rejectedConfirmed() {
      store.dispatch('company/confirmedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    openFakeRequest() {
      this.show.modal = true;
      this.show.makeFakeRequest = true;
    },
    openDateChange() {
      this.show.changeDate = true;
    },
    openInvalidEmail() {
      this.show.invalidEmail = true;
    },
    openInvalidPhone() {
      this.show.invalidPhone = true;
    },
    openQuoteEdit() {
      this.quote = this.companyLead.lead.quotes[0];
      this.show.modal = true;
      this.show.quoteEdit = true;
    },
    openNewQuote() {
      this.quote = {};
      this.show.modal = true;
      this.show.quoteEdit = true;
    },
    openConfirmPrice() {
      this.show.modal = true;
      this.show.confirmPrice = true;
    },
    confirmedQuoteRejected() {
      store.dispatch('company/rejectedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
  }
};
</script>

<style scoped>

.slideup-enter-active {
  /*transition: all 0.8s ease-in;*/
}

.slideup-enter, .slideup-leave-to {
  height: 100%;
  opacity: 1;
}

.slideup-leave, .slideup-enter-to {
  height: 0;
  opacity: 1;
}

.slideup-leave-active {
  /*transition: all 0.3s ease;*/
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .3s ease
}

</style>
