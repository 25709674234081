<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
    <label v-if="mode !== 'extendedForm'" class="mainform-label">{{ field.label }}</label>
    <div class="relative pt-2">
      <select :id="'selectField' + field.id"
              ref="field"
              v-model="formdata['desired_quotes']"
              :data-gtm-field-label="field.label"
              :data-gtm-field-id="field.id"
              class="mainform-color mainform-select"
              :aria-label="field.label"
              @change="editFields(); logFieldBlur($event)"
      >
        <option v-for="option in options" :key="option.id" :value="option.value">{{ option.value }} {{ $t(option.text) }}</option>
      </select>
      <label-state-icon :select-field="true" :label-state="null" :required="false" />
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';
import LabelStateIcon from './LabelStateIcon.vue';

export default {
  name: 'QuotesMetaField',
  components: { LabelStateIcon },
  mixins: [formcreatorMixin],
  props: {
    field: Object,
    formdata: Object,
    mode: String,
    validation: Object,
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['field-on-focus'],
  data() {
    return {
      activeOption: {},
      show: false,
      optionsSmall: [
        { value: '1', text: 'general.quote' },
        { value: '2', text: 'general.quotes' },
        { value: '3', text: 'general.quotes' },
      ],
      optionsBig: [
        { value: '1', text: 'general.quote' },
        { value: '2', text: 'general.quotes' },
        { value: '3', text: 'general.quotes' },
        { value: '4', text: 'general.quotesRecommended' },
        { value: '5', text: 'general.quotes' },
      ],
      options: [],
      vipany: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany'
    };
  },

  watch: {
    formdata: {
      immediate: true,
      handler() {
        this.getIfShow();
      }, deep: true,
    },
  },
  created() {
    if(this.vipany && ![1,3].includes(this.formdata.form_id)) {
      this.options = this.optionsSmall;
    } else {
      this.options = this.optionsBig;
    }
  },
  methods: {},
};
</script>

