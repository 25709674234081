<template>
  <layout>
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold text-center mt-6">{{ $t('publicArea.registerCompany.registerCompany.becomePartner') }}</p>
      <p class="text-center text-xs sm:text-sm mt-2">{{ $t('publicArea.registerCompany.registerCompany.registerCompany') }}</p>
    </template>

    <template v-slot:filters>
      <div class="flex justify-center mt-4">
        <info-circle :color="step >= 1 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6">1</info-circle>
        <info-circle :color="step >= 2 ? 'bg-black text-white' : 'bg-white'" class="mb-6">2</info-circle>
        <info-circle :color="step >= 3 ? 'bg-black text-white' : 'bg-white'" class="ml-4 mb-6">3</info-circle>
        <info-circle :color="step >= 4 ? 'bg-black text-white' : 'bg-white'" class="ml-4 mb-6">4</info-circle>
      </div>
    </template>

    <div class="sm:panel">
      <div>
        <component :is="currentComponent" :company-prop="company" @next-step="goToNextStep" />
      </div>
    </div>
  </layout>
</template>

<script>
  import { shallowRef } from 'vue';
  import Area from './steps/Area.vue';
  import TermsOfService from './steps/TermsOfService.vue';
  import RegisterUserWithCompany from './steps/RegisterUserWithCompany.vue';
  import Layout from '../../Layout.vue';
  import Classification from './steps/Classification.vue';
  import InfoCircle from '../../snippets/InfoCircle.vue';
  import store from '../../store/mainStore';

  const RegisterLayoutWithCompanyComponent = shallowRef(RegisterUserWithCompany);
  const ClassificationComponent = shallowRef(Classification);
  const AreaComponent = shallowRef(Area);
  const TosComponent = shallowRef(TermsOfService);

  export default {
    name: 'RegisterCompany',
    components: {
      InfoCircle,
      Layout,
      RegisterLayoutWithCompanyComponent,
      ClassificationComponent,
      AreaComponent,
    },
    data() {
      return {
        step: 1,
        menu: this.$router.options.routes.filter(obj => obj.meta.layout === 'public'),
        currentComponent: RegisterLayoutWithCompanyComponent,
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
    },
    mounted() {
      if (this.$route.query.step) {
        this.step = parseInt(this.$route.query.step);
        this.goToStep();
      } else {
        this.setStepQuery();
      }
    },
    methods: {
      goToNextStep: function () {
        this.step++;
        this.goToStep()
      },
      goToStep: function () {
        switch (this.step) {
          case 1:
            this.currentComponent = RegisterLayoutWithCompanyComponent;
            break;
          case 2:
            this.currentComponent = ClassificationComponent;
            break;
          case 3:
            this.currentComponent = AreaComponent;
            break;
          case 4:
            this.currentComponent = TosComponent;
            break;
        }
      },
      setStepQuery: function (data) {
        let currentQueryParams = { ...this.$route.query };
        currentQueryParams.step = this.step;
        this.$router.push({ path: '/register-company', query: currentQueryParams });
      },
    },
  };
</script>

<style scoped>

</style>
