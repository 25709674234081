<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.achievements.companyReviews.reviews') }}</p>
    </template>

    <div class="panel">
      <div v-if="companyReviews.length > 0">
        <company-reviews-list v-for="review in companyReviews" :key="review.id" :review="review" />
      </div>
      <div v-else>
        <p>{{ $t('companyArea.achievements.companyReviews.noReviews') }}</p>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import CompanyReviewsList from '../../sharedComponents/CompanyReviewsList.vue';

export default {
  name: 'CompanyReviews',
  components: { CompanyReviewsList, Layout },
  data() {
    return {
      show: {
        explanation: false,
      },
    };
  },
  computed: {
    companyReviews() {
      return store.state.company.company.company_reviews;
    },
  },
};
</script>

<style scoped>

</style>
