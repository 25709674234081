<template>
  <div class="relative panel">
    <div class="absolute top-16 right-3 flex flex-col items-end ">
      <!--      <div class="text-xs text-gray-500 mt-0.5">Auftragsdatum</div>-->
      <!--      <div class="text-base font-semibold">{{ dayjsHelper.showDate(companyLead.lead.date) }}</div>-->
    </div>

    <div class="flex flex-col">
      <div class="flex justify-between w-full">
        <div class="flex flex-col">
          <status-circle v-if="!show.quote" :company-lead="companyLead" area="company" />
          <div class="text-base relative font-semibold mt-2">
            <span v-if="companyLead.status === 0" @click="show.lead = ! show.lead">{{ $t('classification.' + companyLead.lead.form.translation_key) }}</span>
            <span v-else @click="$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})">{{ companyLead.lead.surname }} {{ companyLead.lead.name }} <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2" /></span>
          </div>
        </div>
        <div class="flex">
          <circle-button v-if="!expanded && companyLead.status !== 0"
                         :icon="['fal','expand-arrows']"
                         class="mr-1"
                         :data-cy="'lead-focus-' + companyLead.lead_id"
                         @click="$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})"
          />
          <circle-button v-if="!expanded"
                         :icon="['fas','bars']"
                         :data-cy="'lead-menu-' + companyLead.lead_id"
                         @click="show.menu[companyLead.id] = true"
          />
        </div>
      </div>
      <div class="text-xs font-normal"><span v-if="companyLead.status > 0" class="font-semibold">{{ $t('classification.' + companyLead.lead.form.translation_key) }} - </span>{{ companyLead.lead.postcode }} {{ companyLead.lead.city }}</div>
      <div class="text-xs font-semibold">{{ dayjsHelper.showDate(companyLead.lead.date) }} - <span class="font-normal text-gray-400">{{ $t('general.jobDate') }}</span></div>
      <div class="flex justify-between w-full mt-3">
        <div class="flex ">
          <slot />
        </div>
        <div v-if="!expanded" class="flex items-end -mr-1">
          <circle-button :icon="['fas','caret-down']" @click="show.lead = ! show.lead" />
        </div>
      </div>
      <lead-content v-if="expanded || show.lead"
                    :company-lead="companyLead"
                    :fields="fields"
                    :formdata="companyLead.lead"
                    class="mt-4"
      />
    </div>
    <lead-menu v-if="show.menu[companyLead.id] === true"
               :company-lead="companyLead"
               :expanded="false"
               @close-menu="show.menu[companyLead.id] = false"
    />
  </div>
</template>

<script>
import { companyMixin } from '../../../../plugins/mixin';
import CircleButton from '../../../snippets/CircleButton.vue';
import StatusCircle from '../../../snippets/StatusCircle.vue';
import store from '../../../store/mainStore';
import LeadContent from './LeadContent.vue';
import LeadMenu from '../../../menu/LeadMenu.vue';

export default {
  name: 'MobileCard',
  components: { LeadMenu, LeadContent, StatusCircle, CircleButton },
  mixins: [companyMixin],
  props: {
    companyLead: Object,
    fields: Array,
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: {
        quote: false,
        lead: false,
        menu: [],
      },
      company: this.companyLead.company,
    };
  },
  computed: {
    quote() {
      return this.getQuote();
    },
  },
  methods: {
    mobileCompanyName() {
      if(this.companyPublicName(this.company).length < 30) {
        return this.companyPublicName(this.company);
      } else {
        return this.companyPublicName(this.company).slice(0, 30) + '...';
      }
    },
    companyFocus(routeName) {
      this.$router.push({ name: routeName, params: { lead_uuid: this.companyLead.lead_uuid } }).catch(err => {});
    },
  },
};
</script>

<style scoped>

</style>
