<template>
  <div>
    <div class="inner-addon right-addon">
      <input id="search"
             v-model="form.search"
             class="input-search-round"
             name="search"
             :placeholder="$t('formcreator.classifications.classificationSearch.searchJobs')"
             @input="checkKeyword"
             @keyup.enter="getClassification"
      >
      <div class="fa-icon link" @click="getClassification">
        <font-awesome-icon icon="search" />
      </div>
    </div>

    <p class="text-center subline">{{ $t('formcreator.classifications.classificationSearch.goThroughDict') }}</p>

    <div v-if="form.search === '' && categories">
      <p v-for="option in tree"
         :key="option.id"
         class="folder"
         @click="getClassificationTree(option)"
      >
        <font-awesome-icon class="fa-icon link" icon="folder-plus" />
        {{
          option.name }}
      </p>
    </div>
    <div>
      <list-category v-for="suggestion in categoryFiltered"
                     :key="suggestion.id"
                     :custom-crawling="customCrawling"
                     :formdata="formdata"
                     :suggestion="suggestion"
                     :suggestions="suggestions"
      />
    </div>
  </div>
</template>

<script>
  import store from '../../store/mainStore'
  import ListCategory from './ListCategory.vue'
  import searchBackendApi from '../../connections/seachBackend'

  export default {
    name: 'ClassificationSearch',
    components: { ListCategory },
    props: {
      formdata: {},
    },
    data () {
      return {
        form: {
          search: '',
        },
        search: {},
        tree: {},
        show: false,
        //Variable for Modal
        currentSuggestion: {},
        categories: true,
        customCrawling: true,
      }
    },
    computed: {
      categoryFiltered () {
        if (this.suggestions.length > 0) {
          return this.suggestions.filter(obj => obj.is_category === 1)
        } else {
          return {}
        }
      },
      suggestions () {
        if (this.search.length > 0 && this.form.search !== '') {
          return this.search
        } else {
          return store.state.company.suggestionTree
        }
      },
    },
    created () {
      store.dispatch('company/getClassificationTree', {})
    },
    methods: {
      getClassification: function () {
        this.customCrawling = false;
        // Todo: import language dynamically
        searchBackendApi.post('/get-classification-search/de', this.form).then(response => this.search = response.data[0], this.show = true)
      },
      selectOption: function (option) {
        if (this.formdata.selected.find(item => item.id === option.id)) {
          for (var i = 0; i < this.formdata.selected.length; i++) {
            if (this.formdata.selected[i].id === option.id) {
              this.formdata.selected.splice(i, 1);
              i--
            }
          }
        } else {
          if (option.is_service) {
            this.formdata.selected.push(option)
          }
        }
      },
      getClassificationTree (classification) {
        store.dispatch('company/getClassificationTree', classification)
      },
      checkKeyword () {
        if (this.form.search === '') {
          this.customCrawling = true;
          this.getClassificationTree({})
        }
      },
    },
  }
</script>

<style scoped>

</style>
