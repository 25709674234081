<template>
  <div class="relative top-0 w-full rounded shadow-md bg-white p-4 mt-4 " :data-cy="'lead-card-' + companyLead.lead_id" :class="{ 'shadow-yellow-100 border border-yellow-200':($route.name === 'NewLeads' && $route.params.lead_uuid === companyLead.lead_uuid)}">
    <div v-if="expanded || show.menu[companyLead.id] !== true" class="flex flex-col" :class="{ 'mr-3':(expanded) }">
      <div class="flex justify-between">
        <status-circle :company-lead="companyLead" area="company" :status-change="companyLead.status !== 0" />
        <div v-if="[0,1].includes(companyLead.status)" class="text-right text-xs">
          {{
            $t('companyArea.leads.leadOverview.leadCard.takenBy', {
              taken_by: companyLead.lead.taken_by,
              desired_quotes: companyLead.lead.desired_quotes,
            })
          }}
        </div>
        <lead-costs v-else :credit-transactions="companyLead.lead.credit_transactions" />
      </div>
      <div class="flex justify-between">
        <div class="mt-3">
          <div v-if="companyLead.status === 0" class="flex justify-between text-xl font-semibold cursor-pointer" @click="show.lead = ! show.lead">
            {{ $t('classification.' + companyLead.lead.form.translation_key) }}
          </div>
          <div v-else
               class="flex justify-between text-xl font-semibold"
               :class="{ 'cursor-pointer':(!expanded) }"
               @click="show.lead = ! show.lead"
          >
            {{ companyLead.lead.surname }} {{ companyLead.lead.name }}
          </div>
          <div v-if="companyLead.status !== 0">
            <div>
              <span class="font-semibold">{{ $t('classification.' + companyLead.lead.form.translation_key) }} - {{ dayjsHelper.showDate(companyLead.date) }}</span>
            </div>
            <div class="py-1">
              <a :href="'https://www.google.com/maps/search/?api=1&query=' + companyLead.lead.address + '+' + companyLead.lead.postcode + '+' + companyLead.lead.city" target="_blank">
                <span>{{ companyLead.lead.address }}, </span>
                {{ companyLead.lead.postcode }}
                {{ companyLead.lead.city }}
              </a>
            </div>
            <div v-if="companyLead.lead.phone" class="py-1">
              <a :href="'tel:'+companyLead.lead.phone">
                {{ companyLead.lead.phone }}
              </a>
            </div>
            <div v-if="companyLead.lead.email !== 'deleted@deleted'" class="py-1">
              <a :href="'mailto:'+companyLead.lead.email">
                {{ companyLead.lead.email }}
              </a>
            </div>
          </div>
          <slot />
        </div>
        <div class="flex flex-col mt-1">
          <circle-button v-if="companyLead.status !== 0 && !expanded"
                         :icon="['fal','expand-arrows']"
                         size="sm"
                         class="mb-1"
                         :data-cy="'lead-focus-' + companyLead.lead_id"
                         @click="$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})"
          />
          <circle-button v-if="!expanded"
                         :icon="['fas','bars']"
                         size="sm"
                         class="mb-1"
                         :data-cy="'lead-menu-' + companyLead.lead_id"
                         @click="show.menu[companyLead.id] = true"
          />
          <circle-button v-if="!expanded"
                         :icon="['fas','caret-down']"
                         size="sm"
                         class="mb-1"
                         :active="show.lead"
                         @click="show.lead = ! show.lead"
          />
          <circle-button v-if="companyLead.status > 0 && companyLead.lead.quotes.length > 0 && !expanded"
                         size="sm"
                         :icon="['fal','clipboard-list']"
                         class="mb-1"
                         :class="{ 'active':(show.quote)}"
                         :active="show.quote"
                         :data-cy="'show-quote-' + companyLead.lead_id"
                         @click="show.quote = ! show.quote"
          />
          <circle-button v-if="companyLead.status > 0 && !expanded"
                         :icon="['fal','comments']"
                         size="sm"
                         class="hidden sm:block mb-1"
                         :class="{ 'active':(show.chat)}"
                         :active="show.chat"
                         :data-cy="'show-chat-' + companyLead.lead_id"
                         @click="show.chat = ! show.chat"
          />
        </div>
      </div>
      <lead-content v-if="expanded || show.lead"
                    :company-lead="companyLead"
                    :fields="fields"
                    :formdata="companyLead.lead"
                    class="mt-4"
      />
    </div>

    <div v-if="!expanded && show.quote" :class="{ 'mx-3':(expanded) }">
      <quote :company-lead="companyLead" />
    </div>
    <div v-if="!expanded && show.chat" :class="{ 'ml-3':(expanded) }">
      <chat :company-lead="companyLead" :scroll="false" />
    </div>
    <lead-menu v-if="show.menu[companyLead.id] === true"
               :company-lead="companyLead"
               :expanded="false"
               @close-menu="closeLeadMenu"
    />
  </div>
</template>

<script>
  import StatusCircle from '../../../snippets/StatusCircle.vue';
  import CircleButton from '../../../snippets/CircleButton.vue';
  import LeadContent from './LeadContent.vue';
  import LeadMenu from '../../../menu/LeadMenu.vue';
  import LeadCosts from './snippets/LeadCosts.vue';
  import Quote from './Quote.vue';
  import Chat from '../../../sharedComponents/Chat.vue';

  export default {
    name: 'LeadCard',
    components: { Chat, Quote, LeadCosts, LeadMenu, LeadContent, CircleButton, StatusCircle },
    props: {
      companyLead: Object,
      fields: Array,
      expanded: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close-lead-menu'],
    data() {
      return {
        show: {
          lead: this.$route.params.lead_uuid === this.companyLead.lead_uuid ? true : false,
          menu: [],
          quote: false,
          chat: false,
          modal: true,
        },
      };
    },
    methods: {
      closeLeadMenu() {
        this.show.menu[this.companyLead.id] = false;
      },

    },
  };
</script>

<style scoped>

</style>
