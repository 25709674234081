<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
    <label class="inline-flex items-center">
      <input :id="'checkboxField' + field.id"
             v-model="formdata['flexible_date']"
             :data-cy="'flexible_date'+field.id"
             :data-gtm-field-label="field.label"
             :data-gtm-field-id="field.id"
             class="mainform-color form-checkbox h-10 w-10 border rounded bg-white text-blue-500"
             type="checkbox"
             :name="createFieldName()"
             @change="editFields(), logFieldBlur($event), showHideTrigger()"
      >
      <span class="ml-2 font-semibold text-sm">{{ field.label }}</span>
    </label>
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import { vModelSelect } from 'vue';

  export default {
    name: 'FlexibleDateStaticField',
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
      calc: {
        type: Object,
        default: {}
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,

      };
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
    mounted() {
    },
  };
</script>
