<template>
  <div v-if="show.confirmation">
    <p class="text-center text-xl font-semibold">{{ $t('companyArea.leads.leadOverview.confirmPrice.priceQuestion', {name: companyLead.lead.name, surname: companyLead.lead.surname, currency: currency, toFixed: parseFloat(formdata.final_price).toFixed(2)}) }}</p>
    <p class="text-center mt-3" v-html="getPayInfoString()" />
    <div class="flex justify-center mt-3">
      <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3"
              data-cy="confirm"
              @click="confirmPrice"
      >
        <font-awesome-icon :icon="['fal','check']" />
        <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.yes') }}</span>
      </button>
      <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3"
              data-cy="reject"
              @click="show.confirmation = false"
      >
        <font-awesome-icon :icon="['fal','times']" />
        <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.no') }}</span>
      </button>
    </div>
  </div>
  <div v-else>
    <p v-if="companyLead.status === 5" class="text-center text-xl font-semibold">{{ $t('companyArea.leads.leadOverview.confirmPrice.payProvisionAfter', {surname: companyLead.lead.surname}) }}</p>
    <p v-else class="text-center text-xl font-semibold">{{ $t('companyArea.leads.leadOverview.confirmPrice.tellPricePayCommission', {surname: companyLead.lead.surname, commission: companyLead.lead.commission, appName: appName}) }}</p>
    <p v-if="companyLead.status === 5" class="text-center mt-3" v-html="$t('companyArea.leads.leadOverview.confirmPrice.changePricePayCommission', {currency: currency, toFixed: paidCommission(companyLead)[1].toFixed(2), toFixed_2: paidCommission(companyLead)[0].toFixed(2)})" />
    <p v-else class="text-center mt-3">{{ $t('companyArea.leads.leadOverview.confirmPrice.completePrice') }}</p>

    <div class="relative mt-4">
      <input v-model="formdata.final_price"
             class="pl-12 w-full border border-gray-400"
             type="number"
             :placeholder="$t('companyArea.leads.leadOverview.confirmPrice.priceBrutto')"
             data-cy="input-endprice"
             @keyup.enter="confirmPrice"
      >
      <div class="absolute left-3 top-2.5 text-xs z-10">
        {{ currency }}
      </div>
    </div>
    <p v-if="commission > 0" class="text-center mt-3" v-html="getPayInfoString()" />
    <div class="flex justify-center mt-4">
      <button class="btn btn-lg btn-primary" data-cy="confirm-endprice" @click="commission > 0 ? show.confirmation = true : ''">{{ $t('companyArea.leads.leadOverview.confirmPrice.confirm') }}</button>
    </div>
  </div>
</template>

<script>
import store from '../../../store/mainStore';

export default {
  name: 'ConfirmPrice',
  props: {
    quote: Object,
    companyLead: Object,
  },
  emits: [ 'close' ],
  data() {
    return {
      formdata: {
        final_price: this.companyLead.status === 5 ? this.companyLead.lead.company_logs[0].customer_price : '',
        quote_id: this.quote.hasOwnProperty('id') ? this.quote.id : '',
        currency: this.quote.hasOwnProperty('id') ? this.quote.currency : store.state.company.company.currency,
      },
      company: store.state.company.company,
      currency: this.quote.hasOwnProperty('id') ? this.quote.currency : store.state.company.company.currency,
      show: {
        confirmation: false,
      },
    };
  },
  computed: {
    commission() {
      if(this.formdata.final_price > 0) {
        return (this.formdata.final_price / 100 * this.companyLead.lead.commission) - this.paidCommission()[0];
      } else {
        return null;
      }
    },
  },
  methods: {
    confirmPrice() {
      if(this.companyLead.lead.credit_transactions.find(item => item.transaction_type_id === 5)) {
        store.dispatch('company/confirmPricePayExtra', [this.companyLead.lead.id, this.formdata]);
      } else {
        store.dispatch('company/confirmQuotePrice', [this.companyLead.lead.id, this.formdata]);
      }
      this.$emit('close')
    },
    paidCommission() {
      var commission = 0;
      var companyPrice = 0;
      this.companyLead.lead.credit_transactions.forEach(item => item.transaction_type_id === 5 ? [commission = commission + item.amount, companyPrice = companyPrice + item.company_price] : '');
      return [commission, companyPrice];
    },
    getPayInfoString() {
      let additional = this.companyLead.status === 5 ? 'zusätzlich' : '';
      let vat = this.company.vat ? `(inkl. ${this.company.vat}% MwSt.)` : '';
      return $t('companyArea.leads.leadOverview.confirmPrice.payInfo', { additional: additional, currency: currency, commission: commission.toFixed(2), vat: vat });
    }
  },
};
</script>

<style scoped>

</style>
