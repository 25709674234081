<template>
  <div class="grid grid-flow-col w-full border-b border-b-600 text-gray-500 pb-3">
    <button v-if="$route.meta.area === 'Customer' && companyLead.lead.quotes.find(obj => obj.company_id === companyLead.company.id)"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'CustomerQuote' || $route.name === 'CompanyFocus')}"
            @click="$router.push({ name:'CustomerQuote'})"
    >
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.quote') }}</span>
    </button>
    <button v-if="$route.meta.area === 'Customer'"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'CustomerChat')}"
            @click="$router.push({ name:'CustomerChat'})"
    >
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.chat') }}</span>
    </button>
    <button v-if="$route.meta.area === 'Customer'"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'CustomerReviews')}"
            @click="$router.push({ name:'CustomerReviews'})"
    >
      <font-awesome-icon :icon="['fal', 'star']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('customerArea.quoteOverview.company.reviews') }}</span>
    </button>

    <button v-if="$route.meta.area === 'Company' && companyLead.lead.quotes.find(obj => obj.company_id === companyLead.company_id)"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'CompanyQuote' || $route.name === 'LeadFocus')}"
            @click="$router.push({ name:'CompanyQuote'})"
    >
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.quote') }}</span>
    </button>
    <button v-if="$route.meta.area === 'Company'"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'CompanyNote')}"
            @click="$router.push({ name:'CompanyNote'})"
    >
      <font-awesome-icon :icon="['fal', 'pen-nib']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.note') }}</span>
    </button>
    <button v-if="$route.meta.area === 'Company' && companyLead.lead.email !== 'deleted@deleted'"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'CompanyChat')}"
            @click="$router.push({ name:'CompanyChat'})"
    >
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.chat') }}</span>
    </button>
    <button v-if="$route.meta.area === 'Company'"
            class="relative flex justify-center pt-3 pb-5"
            :class="{ ' text-black font-semibold':($route.name === 'LeadMenu')}"
            @click="$router.push({ name:'LeadMenu'})"
    >
      <font-awesome-icon :icon="['fas', 'bars']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.actions') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DesktopIconMenu',
  props: {
    companyLead: Object
  },
  emits: [ 'lead-menu' ],
  computed: {
    closingRoute() {
      if(this.$route.meta.area === 'Company') {
        if(this.companyLead.status === 2) {
          return 'PendingLeads'
        } else if(this.companyLead.status === 3) {
          return 'ConfirmedQuotes'
        } else {
          return 'Archive'
        }
      } else return 'QuoteOverview'
    }
  }
};
</script>

<style scoped>

</style>
