<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.classifications.selectIndustries') }}</p>
    </template>
    <div class="panel">
      <div v-if="changeDetected" class="flex justify-center mb-3">
        <button class="btn btn-lg btn-primary" @click="storeClassifications">{{ $t('general.save') }}</button>
      </div>

      <form-classifications v-model:formdata-prop="formdata" @change="changeDetected = true" />
    </div>
  </layout>
  <spinner v-if="showSpinner" style="z-index: 10000;" />
</template>

<script>
import store from '../../store/mainStore';
import Layout from '../../Layout.vue';
import FormClassifications from '../components/FormClassifications.vue';
import Spinner from '../../snippets/Spinner.vue';

export default {
  name: 'Classifications',
  components: { Spinner, FormClassifications, Layout },
  data() {
    return {
      formdata: {
        selected: [],
        customSuggestions: [],
      },
      changeDetected: false,
      showSpinner: false,
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    let data = JSON.parse(JSON.stringify(store.state.company.classifications));
    data.forEach(obj => obj.classification_number = obj.number)
    this.formdata.selected = data;
    this.showSpinner = false;
  },
  methods: {
    storeClassifications(classification) {
      this.showSpinner = true;
      store.dispatch('company/storeClassifications', this.formdata).then(() => {
        this.changeDetected = false;
        this.showSpinner = false;
      });
    },
  }
};
</script>

<style scoped>

</style>
