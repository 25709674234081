<template>
  <transition name="modal-fade">
    <div class="fixed inset-0 flex items-center justify-center bg-black/30 px-2 " style="z-index: 9000;" @click.self="$emit('close')">
      <div class="relative flex flex-col max-h-full shadow rounded px-5 pt-14 pb-8 z-50 overflow-auto" :class="{ 'bg-black':(theme === 'black'), 'bg-white':(theme === 'white'), 'w-full sm:w-3/4 md:w-1/2 lg:w-1/3':(!contentSize) }">
        <button class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border  rounded-full" :class="{ 'border-white text-white hover:bg-white hover:text-black':(theme === 'black'), 'border-black text-black hover:bg-black hover:text-white':(theme === 'white') }" @click="$emit('close')">
          <font-awesome-icon :icon="['fal','times']" />
        </button>
        <slot />

        <div v-if="closeButton" class="flex justify-center mt-3">
          <button class="btn btn-lg btn-secondary" @click="$emit('close')">{{ $t('general.close') }}</button>
        </div>

        <div v-if="confirmation.length > 0">
          <div class="text-center text-xl font-extrabold px-2">
            <div class="text-gray-900">{{ confirmation }}</div>
          </div>
          <div class="flex justify-center mt-3">
            <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                    @click="$emit('action')"
            >
              <font-awesome-icon :icon="['fal','check']" />
              <span class="absolute -bottom-6 text-gray-900 text-xs uppercase">{{ $t('general.yes') }}</span>
            </button>
            <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                    @click="$emit('close')"
            >
              <font-awesome-icon :icon="['fal','times']" />
              <span class="absolute -bottom-6 text-gray-900 text-xs lowercase">{{ $t('general.no') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import Spinner from "./Spinner.vue";

export default {
  name: 'ConfirmationModal',
    components: {Spinner},
  props: {
    show: Object,
    theme: {
      type: String,
      default: 'white'
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    contentSize: {
      type: Boolean,
      default: false,
    },
    confirmation: {
      type: String,
      default: ''
    },
  },
  emits: ['close', 'action'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
</style>
