<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.budgets.title') }}</p>
    </template>
    <div class="panel">
      <div v-if="changeDetected" class="flex justify-center mb-3">
        <button class="btn btn-lg btn-primary" @click="storeClassifications">{{ $t('general.save') }}</button>
      </div>

      <div class="px-2 mx-auto grid max-w-9xl grid-cols-1 gap-x-4 gap-y-5 lg:mx-0 lg:max-w-none md:grid-cols-2 2xl:grid-cols-3">
        <BudgetClassificationGroup v-for="companyClassificationGroup in companyClassificationGroups" :company-classification-group="companyClassificationGroup" @change="changeDetected = true" />
      </div>

      <p class="mt-5">{{ $t('companyArea.settings.budgets.info') }}</p>
    </div>
  </layout>
</template>

<script>
  import Layout from '../../Layout.vue';
  import companyApi from '../../connections/company';
  import BudgetClassificationGroup from '../components/BudgetClassificationGroup.vue';

  export default {
    name: 'Budgets',
    components: { BudgetClassificationGroup, Layout },
    data() {
      return {
        companyClassificationGroups: [],
        changeDetected: false,
      };
    },
    computed: {
      //
    },
    created() {
      // Must be get fresh, as it could have changed when the user changed the classifications
      companyApi.get('companies/' + this.$route.params.company_id + '/company-classification-group').then(response => {
        this.companyClassificationGroups = response.data.content.companyClassificationGroups;
      });
    },
    methods: {
      storeClassifications() {
        companyApi.patch('companies/' + this.$route.params.company_id + '/company-classification-group', { companyClassificationGroups: this.companyClassificationGroups }).then(response => {
          this.changeDetected = false;
        });
      },
    }
  };
</script>

<style scoped>

</style>
