<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.leads.confirmedQuotes.confirmedJobs') }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" :class="{ 'hidden sm:block':(company.activity_rate > 9) }">{{ $t('companyArea.leads.confirmedQuotes.provisionInfo') }}</p>
      <!--      <div class="text-center mt-3">-->
      <!--        <button class="btn btn-lg btn-primary" @click="show.modal = true">{{ $t('companyArea.leads.confirmedQuotes.more') }}</button>-->
      <!--      </div>-->
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead }">
        <keep-alive>
          <lead-card :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <confirmed-quotes-card :company-lead="companyLead" />
          </lead-card>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <confirmation-modal v-if="show.modal"
                        theme="white"
                        :close-button="true"
                        @close="show.modal = false"
    >
      <p v-html="$t('companyArea.leads.confirmedQuotes.provisionInfoText1')" />
      <p class="font-semibold mt-5" v-html="$t('companyArea.leads.confirmedQuotes.provisionInfoText2')" />
      <p class="mt-3" v-html="$t('companyArea.leads.confirmedQuotes.provisionInfoText3')" />
      <p class="mt-3" v-html="$t('companyArea.leads.confirmedQuotes.provisionInfoText4', {appName: appName})" />
    </confirmation-modal>
  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import ConfirmedQuotesCard from './LeadOverview/cards/ConfirmedQuotesCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';

export default {
  name: 'ConfirmedQuotes',
  components: { MobileCard, ConfirmedQuotesCard, ConfirmationModal, LeadCard, Layout, LeadOverviewWrapper },
  props: {
  },
  data() {
    return {
      show: {
        modal: false,
      },
    };
  },
  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
    company() {
      return store.state.company.company
    }
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
