<template>
  <template v-for="classification in classifications" :key="classification.id">
    <label class="inline-flex items-center mt-3">
      <input :id="'classification' + classification.id"
             type="checkbox"
             class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
             :checked="formdata && formdata.selected.find(obj => obj.classification_number === classification.classification_number) !== undefined"
             @change="select(classification)"
      >
      <span class="ml-2">{{ $t('classification.' + classification.translation_key) }}</span>
    </label>
  </template>
</template>
<script>
  export default {
    name: 'FormSubClassification',
    props: {
      formdata: {},
      classifications: {},
    },
    emits: ['change-classification'],
    methods: {
      select(classification) {
        this.$emit('change-classification', classification);
      },
    },
  };
</script>
