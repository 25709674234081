<template>
  <layout v-if="companyLead.hasOwnProperty('id')">
    <template v-slot:header>
      <p class="text-center mt-6">{{ $t('classification.' + lead.form.translation_key) }}</p>
      <p class="font-extrabold sm:text-2xl text-center mt-2">{{ mobileCompanyName() }}</p>
    </template>

    <div class="relative sm:shadow sm:bg-white md:hidden -mx-3">
      <mobile-icon-menu :company-lead="companyLead" />
      <div class="relative pt-4 px-3">
        <div v-if="$route.name === 'CompanyFocus'">
          <company :company-lead="companyLead"
                   :location="location"
                   :desktop="false"
                   class="mt-3"
          />
        </div>

        <router-view v-else-if="$route.name === 'CustomerQuote'"
                     :company-lead="companyLead"
                     :quote="quote"
                     :desktop="false"
        />
        <router-view v-else-if="$route.name === 'CustomerChat'" :company-lead="companyLead" />
        <router-view v-else-if="$route.name === 'CustomerReviews'" :company-lead="companyLead" :company="companyLead.company" />
      </div>
    </div>

    <div class="hidden md:grid grid-cols-2">
      <div class="col-span-2 pl-2 mt-2 text-base font-semibold cursor-pointer" @click="$router.push({ name: 'LeadQuotes', params: { lead_uuid: companyLead.lead_uuid } })"><< Übersicht</div>
      <div class="relative px-2">
        <company :company-lead="companyLead"
                 :location="location"
                 :desktop="false"
                 class="panel"
        />
      </div>
      <div class="relative px-2">
        <div class="panel">
          <desktop-icon-menu :company-lead="companyLead" class="-mt-2" />
          <div class="mt-5">
            <quote v-if="$route.name === 'CompanyFocus' && quote" :company-lead="companyLead" :quote="quote" />
            <router-view v-else-if="$route.name === 'CustomerQuote'" :company-lead="companyLead" :quote="quote" />
            <router-view v-else-if="$route.name === 'CustomerChat'" :company-lead="companyLead" :scroll="false" />
            <router-view v-else-if="$route.name === 'CustomerReviews'" :company-lead="companyLead" :company="companyLead.company" />
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../Layout.vue';
import store from '../store/mainStore.js';
import { companyMixin } from '../../plugins/mixin.js';
import Company from './QuoteOverview/Company.vue';
import MobileIconMenu from '../menu/MobileIconMenu.vue';
import Quote from './QuoteOverview/Quote.vue';
import Chat from '../sharedComponents/Chat.vue';
import CompanyReviews from '../companyArea/Achievements/CompanyReviews.vue';
import DesktopIconMenu from '../menu/DesktopIconMenu.vue';

export default {
  name: 'CompanyFocus',
  components: { DesktopIconMenu, CompanyReviews, Chat, Quote, MobileIconMenu, Company, Layout },
  mixins: [companyMixin],
  computed: {
    companyLead() {
      return store.state.customer.currentCompanyLead;
    },
    company() {
      if(this.companyLead.hasOwnProperty('id')) {
        return this.companyLead.company;
      } else {
        return {};
      }
    },
    lead() {
      return store.state.customer.currentLead;
    },
    location() {
      if(this.companyLead.hasOwnProperty('id')) {
        return this.getCompanyLocation(this.companyLead.website.locations, this.companyLead.postcode_id);
      } else {
        return {};
      }
    },
    companyLeads() {
      return store.state.customer.companyLeads;
    },
    quote() {
      return this.getQuote();
    },
  },
  watch: {
    companyLeads() {
      this.refreshCurrentCompanyLead();
    },
    companyLead: {
      handler() {
        if(this.$route.name === 'CompanyFocus' && ! this.quote) {
          this.$router.push({ name: 'CustomerReviews' });
        }
      }, deep: true,
    },
  },
  beforeCreate() {

  },
  created() {
    this.refreshCurrentCompanyLead();
  },
  methods: {
    mobileCompanyName() {
      if(this.companyPublicName(this.company).length < 30) {
        return this.companyPublicName(this.company);
      } else {
        return this.companyPublicName(this.company).slice(0, 30) + '...';
      }
    },
    refreshCurrentCompanyLead() {
      store.dispatch('customer/findCurrentCompanyLead', [this.$route.params.lead_uuid, this.$route.params.customer_company_id]);
    },
  },
};
</script>

<style scoped>

</style>
