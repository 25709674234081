<template>
  <layout v-if="lead.hasOwnProperty('id')" :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">{{ $t('customerArea.vipanyReview.makeReviewPlatform', { appName: appName }) }}</p>
    </template>

    <div class="panel">
      <div v-if="step === 1">
        <div class="text-base font-semibold">{{ $t('customerArea.vipanyReview.reviewWebsiteTitle') }}</div>
        <div class="">{{ $t('customerArea.vipanyReview.reviewPlatformQuestion', { appName: appName }) }}</div>
        <rating v-model:review="formdata.review_value" class="text-4xl mt-2" :read-only="false" />

        <div class="text-base font-semibold mt-8">{{ $t('customerArea.vipanyReview.personalText') }}</div>
        <textarea id="textarea"
                  v-model="formdata.review_text"
                  :placeholder="$t('customerArea.vipanyReview.publicReview')"
                  rows="3"
                  class="w-full border border-gray-400 mt-2"
                  data-cy="vipany-review-input-text"
        />
        <div class="flex justify-center mt-4">
          <button class="btn btn-lg btn-primary" data-cy="vipany-review-store" @click="storeVipanyReview">{{ $t('general.submit') }}</button>
        </div>
      </div>
      <div v-if="step === 2">
        <div class="text-base font-semibold">{{ $t('customerArea.vipanyReview.thankYou') }}</div>
        <p>{{ $t('customerArea.vipanyReview.thankYouText') }}</p>
      </div>
    </div>
  </layout>
</template>

<script>
  import store from '../store/mainStore';
  import leadFormApi from '../connections/leadForm';
  import Rating from '../snippets/Rating.vue';
  import Layout from '../Layout.vue';

  export default {
    name: 'VipanyReview',
    components: { Layout, Rating },
    props: {},
    data() {
      return {
        formdata: {
          review_value: 5,
          review_text: '',
          user_id: store.state.main.user.id,
          lead_id: store.state.customer.currentLead.id,
          lead_uuid: store.state.customer.currentLead.uuid,
          name: store.state.main.user.name,
          surname: store.state.main.user.surname,
          city: store.state.main.user.city,
        },
        step: 1,
      };
    },
    computed: {
      lead() {
        return store.state.customer.currentLead;
      },
      userCompanyReview() {
        if (store.state.customer.userReviews) {
          return store.state.customer.userReviews.filter(obj => obj.lead_id === this.lead.id)[0];
        }
        return null;
      },
      vipanyReview() {
        if (store.state.customer.vipanyReviews) {
          return store.state.customer.vipanyReviews.filter(obj => obj.lead_id === this.lead.id)[0];
        }
        return null;
      },
    },
    beforeCreate() {
      let companyLeads = store.state.customer.companyLeads;
      store.commit('customer/setCurrentCompanyLead', companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid));
    },
    mounted() {
      this.handleReview();
    },
    methods: {
      storeVipanyReview() {
        if (!this.formdata.surname) {

          this.formdata.surname = this.formdata.name;
        }
        leadFormApi.post('vipany-reviews', this.formdata, { notification: false }).then(response => response.data.notificationType === 'success' ? [this.step = 2, store.commit('customer/setVipanyReviews', response.data.content)] : '');
      },
      handleReview() {
        if (this.vipanyReview) {
          // Already has given a vipany review to this lead
          this.$emit('vipany-review-close');
        }
      },
    },
  };
</script>

<style scoped>

</style>
