<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('customerArea.quoteOverview.quoteOverview') }}</p>
      <!--      <div v-if="currentLead.taken_by > 0" class="text-center mt-3">-->
      <!--        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>-->
      <!--      </div>-->
    </template>

    <template v-slot:filters>
      <p v-if="leads.length > 1" class="text-center text-sm mt-5 -mb-5">
        <select v-model="leadId" class="border-none shadow rounded-full " @change="setCurrentLead()">
          <option v-for="lead in leads" :key="lead.id" :value="lead.id">{{ this.dayjsHelper.showDate(lead.created_at) }} {{ $t('classification.' + lead.form.translation_key) }}</option>
        </select>
      </p>
      <lead-buttons v-if="currentLead.hasOwnProperty('id')" :lead="currentLead" :leads="leads"/>
      <p v-if="companyLeads.length === 0" class="text-center mt-5">{{ $t('customerArea.quoteOverview.noQuotesReceived') }}</p>
    </template>

    <div class="flex sm:p-2">
      <explanation :lead="currentLead" class=" xl:hidden"/>
    </div>
    <div class="grid grid-cols-1 xl:grid-cols-3 mt-5">
      <div class="xl:col-span-2 sm:px-2 xl:px-0">
        <company-card v-for="companyLead in companyLeads"
                      :key="companyLead.id + '5'"
                      :company-lead="companyLead"
                      :desktop="false"
        />
      </div>

      <div class="hidden xl:block px-2 mt-2">
        <explanation :lead="currentLead"/>
<!--        <shop-widget/>-->
        <!--        <flipping-card v-for="companyLead in companyLeadsInCols[4]" :company-lead="companyLead" :col="3" />-->
      </div>

    </div>
<!--    <div class="xl:hidden sm:px-2 mt-5">-->
<!--    <shop-widget/>-->
<!--    </div>-->


    <confirmation-modal v-if="show.modal" :close-button="true" @close="show.modal = false">
      <span class="text-base font-semibold mt-5">Warum geben Ihnen wir 1% Cashback?</span>
      <span class="text-base mt-2">Stellen Sie sich vor, Sie möchten 4 Angebote vergleichen, aber 3  davon sind unbrauchbar, weil Sie entweder <strong>qualitativ oder preislich</strong> überhaupt nicht Ihren Vorstellungen entsprechen. Für Sie und für die Firmen <strong>verlorene Zeit</strong>.</span>
      <p class="text-base mt-3">Aus diesem Grund <strong>lernt unsere intelligente Firmensuche</strong> aus Ihren Angaben. Das lassen wir uns gerne etwas kosten.</p>
      <span class="text-base font-semibold mt-5">Wie können Sie profitieren?</span>
      <ul class="text-base mt-2">
        <li>Firma über ' + appName + ' buchen</li>
        <li>Qualitäts- und Kostenfeedback nach dem Auftrag machen</li>
      </ul>
      <span class="text-base font-semibold mt-5">Warum sehe ich bei meinem Auftrag keine Cashback Möglichkeit?</span>
      <p class="text-base mt-3">
        Bei kleineren Auftragstypen, wie z.B. Umzügen oder Reinigungen, sind die Cashback-Beträge zu klein, so dass der administrative Aufwand zu gross wäre. Die
        Cashback-Möglichkeit wird nur bei grösseren Auftragsvolumen angeboten.
      </p>
    </confirmation-modal>

    <confirmation-modal v-if="show.selectLead" @close="show.selectLead = false">
      <div v-for="lead in leads"
           :key="lead.id"
           class="text-lg font-semibold cursor-pointer mb-4"
           @click="$router.push({ name: 'LeadQuotes', params: { lead_uuid: lead.uuid } })"
      >
        {{ $t('classification.' + lead.form.translation_key) }}
        <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"/>
        <div class="text-xs font-normal">Anfragedatum: {{ this.dayjsHelper.showDate(lead.created_at) }}</div>
      </div>
    </confirmation-modal>
  </layout>
</template>

<script>
  import store from '../store/mainStore.js';
  import CompanyCard from './QuoteOverview/CompanyCard.vue';
  import Layout from '../Layout.vue';
  import ConfirmationModal from '../snippets/ConfirmationModal.vue';
  import LeadButtons from './QuoteOverview/LeadButtons.vue';
  import Explanation from './QuoteOverview/Explanation.vue';
  import ShopWidget from './QuoteOverview/ShopWidget.vue';

  export default {
    name: 'QuoteOverview',
    components: { ShopWidget, Explanation, LeadButtons, ConfirmationModal, Layout, CompanyCard },
    data() {
      return {
        show: {
          modal: false,
          confirm: '',
          selectLead: false,
        },
        leadId: '',
        tenant: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY,
      };
    },
    computed: {
      companyLeads() {
        if (store.state.customer.companyLeads && Array.isArray(store.state.customer.companyLeads)) {
          return store.state.customer.companyLeads.filter(obj => obj.lead_id === this.currentLead?.id);
        }
        return [];
      },
      currentLead() {
        return store.state.customer.currentLead;
      },
      leads() {
        return store.state.customer.leads;
      },
    },
    watch: {
      currentLead() {
        this.leadId = this.currentLead.id;
      },
    },
    methods: {
      setCurrentLead() {
        store.commit('customer/setCurrentLead', this.leads.find(obj => obj.id === this.leadId));
      },
    },
    created() {
      if(this.$route.params.lead_uuid) {

        this.leadId = this.leads.find(obj => obj.uuid === this.$route.params.lead_uuid).id;
      }
    }
  };
</script>

<style scoped>
</style>
