<template>
  <div class="text-center sm:text-left text-xl font-semibold">{{ $t('companyArea.leads.leadOverview.makeFakeRequest.makeFakeRequest') }}</div>
  <label class="text-gray-600 mt-3">
    {{ $t('companyArea.leads.leadOverview.makeFakeRequest.reason') }}
  </label>
  <select v-model="formdata.reason" class="w-full border border-gray-400">
    <option value="1">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.contact') }}
    </option>
    <option value="2">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.area') }}
    </option>
    <option value="3">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.jobType') }}
    </option>
    <option value="4">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.onlyPriceRequest') }}
    </option>
    <option value="5">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.tooShort') }}
    </option>
    <option value="7">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.job') }}
    </option>
    <option value="8">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.double') }}
    </option>
    <option value="6">
      {{ $t('companyArea.leads.leadOverview.makeFakeRequest.other') }}
    </option>
  </select>
  <div v-if="formdata.reason === '8'" class="text-red-600 mt-3 mb-1">
    {{ $t('companyArea.leads.leadOverview.makeFakeRequest.doubleInfo') }}<br>
  </div>
  <label v-else class="text-gray-600 mt-3 mb-1">
    {{ $t('companyArea.leads.leadOverview.makeFakeRequest.description') }}
  </label>

  <textarea v-model="formdata.comments"
            rows="6"
            class="w-full border border-gray-400"
  />
  <div class="flex justify-center mt-4">
    <button class=" btn btn-lg btn-primary" @click="makeFakeRequest">{{ $t('general.submit') }}</button>
  </div>
  <div class="text-gray-600 text-xs mt-3">
    {{ $t('companyArea.leads.leadOverview.makeFakeRequest.info') }}<br>
  </div>
</template>

<script>
import leadFormApi from "../../../connections/leadForm";
import store from "../../../store/mainStore";

export default {
  name: "MakeFakeRequest",
  props: {
    companyLead: Object,
  },
  emits: ['close'],
  data() {
    return {
      formdata: {
        reason: 1,
        comments: ''
      }
    }
  },
  methods: {
    makeFakeRequest() {
      leadFormApi.patch('company-leads/' + this.companyLead.id + '/fake', this.formdata, { 'progress': true}).then(response => [store.dispatch('company/updateCompanyLead', response.data.content.companyLead), this.$emit('close')]);

      // leadFormApi.post('/make-fake-request/', this.formdata).
      // then(response => [this.$emit('success','store'),
      //   store.dispatch('company/updateCompanyLead', response.data.content.companyLead), this.waitForServerResponse = false,
      //   response.data.content['company'] ? store.commit('company/setCredit', response.data.content['company'].credit) : '']);
    }
  }
}
</script>

<style scoped>

</style>
