<template>
  <div class="panel">
    <div class="text-base font-semibold">{{ $t('companyArea.accounting.credit.topupByBankTransfer') }}</div>
        <p class="text-xs sm:text-sm text-gray-400">{{ $t('companyArea.accounting.credit.topupByBankTransferInfo') }}</p>

    <p class="mt-3" v-html="$t('companyArea.accounting.credit.topupByBankTransferInfo2', { id: company.id })" />

    <p class="mt-3">
      {{ appData.company }}<br>
      {{ appData.street }}<br>
      {{ appData.zip }} {{ appData.location }}
    </p>
    <p class="mt-2">
      {{ appData.bank.name }}<br>
      IBAN: {{ appData.bank.iban }}<br>
      Swift: {{ appData.bank.swift }}
    </p>
  </div>
</template>
<script>
    import appCompanyData from '../../../../../appCompanyData';

    export default {
        name: 'BankAccountInfo',
        props: {
            company: {},
        },
        data() {
            return {
                appData: appCompanyData[import.meta.env.VITE_VUE_APP_CURRENT_COMPANY],
            };
        },
    };
</script>
