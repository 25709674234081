<template>
  <div class="border rounded p-4 cursor-pointer w-64 mx-3" @click="$emit('current-product', product)">
    <a href="#order">
      <div class="font-semibold text-center text-lg">{{ product.name }}</div>
      <div class="text-center text-gray-700">{{ $t('sharedComponents.productOverview.order', {showDate: this.dayjsHelper.showDate(bulkOrder.duedate)}) }}</div>
      <div v-if="!currentProduct.hasOwnProperty('id')" class="flex justify-center">
        <div class="w-48">
          <img v-if="product.image === 'umzugsbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/umzugsbox.jpeg">
          <img v-else-if="product.image === 'Buecherbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/Buecherbox.jpeg">
          <img v-else-if="product.image === 'Kleiderbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/Kleiderbox.jpeg">
        </div>
      </div>
      <product-chart :product="product"
                     :bulk-order="bulkOrder"
                     :amount="orderProducts.length > 0 ? orderProducts.find(obj => obj.shop_product_id === product.id).amount : 0"
      />
      <div class="flex justify-center mt-5">
        <button class="btn btn-md btn-primary w-full">{{ $t('general.select') }}</button>
      </div>
    </a>
  </div>
</template>

<script>
  import ProductChart from './ProductChart.vue';
  import store from '../../store/mainStore';

  export default {
    name: 'ProductOverview',
    components: { ProductChart },
    props: {
      product: {
        type: Object
      },
      currentProduct: {
        type: Object
      },
      bulkOrder: {
        type: Object
      }
    },
    emits: ['current-product'],
    data() {
      return {
      };
    },
    computed: {
      orderProducts() {
        return store.state.shop.orderProducts.filter(item => item.shop_bulk_order_id === this.bulkOrder.id && item.shop_product_id === this.product.id);
      },
    },
    created() {

    },
    methods: {

    }
  };
</script>

<style scoped>

</style>
