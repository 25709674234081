<template>
  <layout>
    <template v-slot:header>
      <p class="font-sm sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10 capitalize" data-cy="header-title">{{ $t('general.login') }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" data-cy="header-text">{{ $t('auth.login.loginText') }}</p>
      <div class="text-center mt-3">
        <!--        <button class="btn btn-lg btn-primary">Mehr erfahren</button>-->
      </div>
    </template>

    <div v-if="user" class="w-full mt-10">
      <div class="flex justify-center my-8 text-xl text-center" v-html="$t('auth.login.alreadyLoggedIn', { user: user.name } )" />
      <div v-if="companies && companies.length > 0" class="flex justify-center">
        <button v-for="company in companies"
                :key="company.id"
                class="btn btn-lg btn-primary capitalize m-1"
                data-cy="manage-company"
                :aria-label="$t('menu.mainMenu.companySpace')"
                @click="$router.push({ name: 'PendingLeads', params: { company_id: company.company_id}}).catch(err => {})"
        >
          {{ $t('menu.mainMenu.manageCompany', { company: company.company_name }) }}
        </button>
      </div>
      <div v-else class="flex justify-center">
        <button class="btn btn-lg btn-primary capitalize m-1"
                data-cy="show-intern"
                :aria-label="$t('menu.mainMenu.internal')"
                @click="$router.push({ name: 'QuoteOverview', params: { user_id: user.user_id }}).catch(err => {})"
        >
          {{ $t('menu.mainMenu.internal') }}
        </button>
      </div>
      <div class="flex justify-center mt-2">
        <button class="btn btn-lg btn-secondary capitalize m-1"
                data-cy="logout"
                :aria-label="$t('menu.mainMenu.logout')"
                @click="$router.push('/logout').catch(err => {})"
        >
          {{ $t('menu.mainMenu.logout') }}
        </button>
      </div>
    </div>
    <div v-else class="w-full flex justify-center mt-10">
      <form @reset="onReset" @submit="onSubmit">
        <div class="w-52 max-w-full">
          <input id="email"
                 v-model="form.email"
                 class="shadow placeholder:capitalize"
                 :placeholder="$t('general.email')"
                 required
                 type="email"
                 data-cy="email"
          >

          <input id="password"
                 v-model="form.password"
                 :placeholder="$t('general.password')"
                 class="shadow mt-2 placeholder:capitalize"
                 required
                 type="password"
                 data-cy="password"
          >
          <router-link :to="{ name: 'ForgotPassword' }" class="text-sm font-thin italic" data-cy="btn-reset-password">{{ $t('general.passwordLost') }}</router-link>
          <div class="flex justify-center mt-8">
            <button type="submit" class="btn btn-lg btn-primary capitalize" data-cy="login">{{ $t('general.login') }}</button>
          </div>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import authApi from '../connections/auth';

  export default {
    name: 'Login',
    components: { Layout },
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        loggedIn: false,
      };
    },
    computed: {
      routeUri: function () {
        if (store.state.main.user.hasOwnProperty('id')) {
          if (this.$route.query.redirect) {
            return this.$route.query.redirect;
          } else if (store.state.main.user && store.state.main.user.employees && store.state.main.user.employees && store.state.main.user.employees.length) {
            return '/companies/' + store.state.main.user.employees[0].company_id;
          }
          return '/customers/' + store.state.main.user.id;
        } else {
          return '';
        }
      },
        companies() {
            return store.state.main.companies;
        },
        user() {
            if (store.getters.isAuthenticated) {
               if (this.companies && this.companies.length > 0) {
                    return { name: store.state.main.user.name, role: 'companyAdmin' };
                } else {
                    return { name: store.state.main.user.name, role: 'user', user_id: store.state.main.user.id };
                }
            } else {
                return null;
            }
        },
    },
    watch: {
      loggedIn: function (newVal) {
        if (newVal && this.routeUri.length > 0) {
          this.$router.push(this.routeUri);
        }
      },
      routeUri: function (newVal) {
        if (this.loggedIn && newVal.length > 0) {
          this.$router.push(newVal);
        }
      },
    },
    created() {
    },
    mounted() {
      authApi.get('/sanctum/csrf-cookie');
    },
    methods: {
      onSubmit(evt) {
        this.loggedIn = false;
        evt.preventDefault();
        // store.commit('SET_VALIDATION_ERROR', null, { root: true });
        // console.log('loginform:', JSON.stringify(this.form), this.form.email, this.form.password)
        store.dispatch('auth/login', this.form).then(() => {
          this.loggedIn = true;
        });
      },
      onReset(evt) {
        evt.preventDefault();
        // Reset our form values
        this.form.email = '';
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
  };
</script>

<style scoped>

</style>
