<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.achievements.partnerStatus.partnerStatus') }}</p>
    </template>

    <div class="panel">
      <div class="text-lg font-semibold">{{ $t('companyArea.achievements.partnerStatus.currentStatus') }}: <span v-if="company.partner_status">{{ $t('customerArea.quoteOverview.flippingCard.'+company.partner_status+'Partner') }}</span><span v-else>{{ $t('companyArea.achievements.partnerStatus.standard') }}</span></div>
      <div class="flex border-t pt-2 mt-2">
        <rating v-if="ratingComponent === 'Rating'"
                :review="company.ps_avg > 0 ? company.ps_avg : 0"
                class="text-xs"
        />
        <rating-topofferten v-else
                            :review="company.ps_avg > 0 ? company.ps_avg : 0"
                            class="w-24"
                            style="height:17px"
        />
        <div v-if="company.ps_count > 0" class="ml-2 text-gray-500">Ø {{ company.ps_avg }} / Total {{ company.ps_count }}</div>
      </div>

      <widget></widget>

      <div class="text-sm mt-6 " v-html="$t('customerArea.quoteOverview.flippingCard.partnerStatusInfo')" />
      <div v-if="companyReviews.length > 0">
        <div class="font-semibold mt-6 text-lg">{{ $t('companyArea.achievements.partnerStatus.relevantReviews') }}</div>
        <company-reviews-list v-for="review in companyReviews" :key="review.id" :review="review" />
      </div>
      <div v-else>
        <p>{{ $t('companyArea.achievements.companyReviews.noReviews') }}</p>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import CompanyReviewsList from '../../sharedComponents/CompanyReviewsList.vue';
import RatingTopofferten from '../../snippets/RatingTopofferten.vue';
import Rating from '../../snippets/Rating.vue';
import dayjsHelper from '../../../plugins/dayjsHelper';
import Widget from './Widget.vue';

export default {
  name: 'PartnerStatus',
  components: { Rating, RatingTopofferten, CompanyReviewsList, Layout, Widget },
  data() {
    return {
      ratingComponent: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany' ? 'Rating' : 'RatingTopofferten',
      show: {
        explanation: false,
      },
    };
  },
  computed: {
    companyReviews: function () {
      const threeYearsAgo = dayjsHelper.dayjs().subtract(3, 'years');
      return store.state.company.company.company_reviews.filter(obj => obj.status === 1).filter(obj => {
        return dayjsHelper.dayjs(obj.created_at).isAfter(threeYearsAgo); // && reviewDate.isBefore(startOfMonth);
      });
    },
    company() {
      return store.state.company.company;
    },
  },
};
</script>

<style scoped>

</style>
