<template>
  <div>
    <div class="text-gray-500">{{ $t('companyArea.components.companyNotes.internNote') }}</div>
    <button class="text-xs px-2 py-1 border rounded-md mr-3 mb-3 bg-gray-200 hover:bg-gray-300 cursor-pointer mt-2" @click="openModal('')">
      <font-awesome-icon :icon="['fal', 'plus']" />
    </button>
    <div v-for="note in companyLead.company_lead_notes" :key="note.id" class="bg-gray-200 rounded-md p-2 mt-2">
      <div class="flex justify-between mb-1">
        <div class="text-xs text-gray-500">{{ this.dayjsHelper.showDateTime(note.created_at ) }} | {{ note.name }}</div>
        <div class="cursor-pointer" @click="deleteNote(note.id)">
          <font-awesome-icon :icon="['fal', 'trash-alt']" />
        </div>
      </div>
      <div v-if="note.title" class="font-semibold">{{ note.title }}</div>
      <div v-if="note.text" class="">{{ note.text }}</div>
    </div>
  </div>

  <confirmation-modal v-if="show.modal" :content-size="false" @close="show.modal = false">
    <div class="font-semibold py-2 border-b text-center mb-2">{{ companyLead.lead.name }} {{ companyLead.lead.surname }}</div>
    <div class="flex flex-wrap">
      <button v-for="button in buttons" class="text-xs px-2 py-1 border rounded-md mr-3 mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer" @click="openModal(button.text)">
        {{ button.text }}
      </button>
    </div>

    <input v-model="formdata.title"
           type="text"
           class="border rounded-md mb-3"
           :placeholder="$t('general.title')"
    >
    <textarea v-model="formdata.text"
              class="border rounded-md"
              rows="3"
              :placeholder="$t('general.text')"
    />
    <div class="flex justify-center mt-2">
      <button class="btn btn-md btn-primary" @click="store">{{ $t('general.save') }}</button>
    </div>
  </confirmation-modal>
</template>
<script>
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import leadFormApi from '../../connections/leadForm';
  import store from '../../store/mainStore';

  export default {
    name: 'CompanyNote',
    components: { ConfirmationModal },
    props: {
      companyLead: Object
    },
    data() {
      return {
        formdata: {
          company_lead_id: this.companyLead.id,
          company_id: this.companyLead.company_id,
          text: '',
          title: '',
        },
        buttons: [
          { text: this.$t('companyArea.components.companyNotes.reachedByPhone') },
          { text: this.$t('companyArea.components.companyNotes.notReachedByPhone') },
          { text: this.$t('companyArea.components.companyNotes.emailSent') },
          { text: this.$t('companyArea.components.companyNotes.orderConfirmation') },
          { text: this.$t('companyArea.components.companyNotes.visitArranged') },
        ],
        show: {
          modal: false
        }
      }
    },
    computed: {
      user() {
        return store.state.main.user
      }
    },
    methods: {
      openModal(title) {
        this.show.modal = true
        this.formdata.title = title
        this.formdata.text = ''
      },
      store() {
        if(this.formdata.title === '' && this.formdata.text === '') {
          return;
        }
        this.formdata.user_id = this.user.id
        leadFormApi.post('company-lead-notes/store', this.formdata).then(response => [store.dispatch('company/updateCompanyLeadNote', [this.companyLead, response.data.content]), this.show.modal = false]);
      },
      update(noteId) {
        if(this.formdata.title === '' && this.formdata.text === '') {
          return;
        }
        leadFormApi.patch('company-lead-notes/' + noteId + '/update', this.formdata).then(response => [store.dispatch('company/updateCompanyLeadNote', [this.companyLead, response.data.content]), this.show.modal = false]);
      },
      deleteNote(noteId) {
        leadFormApi.delete('company-lead-notes/' + noteId + '/delete').then(response => [store.dispatch('company/updateCompanyLeadNote', [this.companyLead, noteId])]);
      }
    }
  };
</script>
<style scoped>

</style>
