<template>
  <div class="sticky top-0 inset-x-0 grid grid-flow-col w-full bg-gray-100/95 border border-b-600 text-gray-700 z-10">
    <!--    <div class="relative flex justify-center pt-3 pb-5">-->
    <!--      <font-awesome-icon :icon="['fal', 'edit']" class="text-2xl"></font-awesome-icon>-->
    <!--      <span class="absolute bottom-1 text-xxs">Bearbeiten</span>-->
    <!--    </div>-->
    <div v-if="$route.meta.area === 'Customer' && companyLead.lead.quotes.find(obj => obj.company_id === companyLead.company.id)"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CustomerQuote')}"
         data-cy="icon-menu-quote"
         @click="$router.push({ name:'CustomerQuote'})"
    >
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.quote') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Customer'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyFocus')}"
         data-cy="icon-menu-contact"
         @click="$router.push({ name:'CompanyFocus'})"
    >
      <font-awesome-icon :icon="['fal', 'at']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.contact') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Customer'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CustomerChat')}"
         data-cy="icon-menu-chat"
         @click="$router.push({ name:'CustomerChat'})"
    >
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.chat') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Customer'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CustomerReviews')}"
         data-cy="icon-menu-reviews"
         @click="$router.push({ name:'CustomerReviews'})"
    >
      <font-awesome-icon :icon="['fal', 'star']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('customerArea.quoteOverview.company.reviews') }}</span>
    </div>

    <div v-if="$route.meta.area === 'Company'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'LeadFocus')}"
         data-cy="icon-menu-contact"
         @click="$emit('lead-menu', false); $router.push({ name:'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})"
    >
      <font-awesome-icon :icon="['fal', 'at']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.request') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Company' && companyLead.lead.quotes.length > 0"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyQuote')}"
         data-cy="icon-menu-quote"
         @click="$emit('lead-menu', false); $router.push({ name:'CompanyQuote', params: { lead_uuid: companyLead.lead_uuid}})"
    >
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.quotes') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Company'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyNote')}"
         data-cy="icon-menu-chat"
         @click="$emit('lead-menu', false); $router.push({ name:'CompanyNote', params: { lead_uuid: companyLead.lead_uuid}})"
    >
      <font-awesome-icon :icon="['fal', 'pen-nib']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.desktopIconMenu.note') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Company' && companyLead.lead.email !== 'deleted@deleted'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyChat')}"
         data-cy="icon-menu-chat"
         @click="$emit('lead-menu', false); $router.push({ name:'CompanyChat', params: { lead_uuid: companyLead.lead_uuid}})"
    >
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.chat') }}</span>
    </div>
    <div v-if="$route.meta.area === 'Company'"
         class="relative flex justify-center pt-3 pb-5"
         :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'LeadMenu')}"
         data-cy="icon-menu-menu"
         @click="$router.push({ name:'LeadMenu'})"
    >
      <font-awesome-icon :icon="['fas', 'bars']" class="text-2xl" />
      <span class="absolute bottom-1 text-xxs">{{ $t('menu.mobileIconMenu.actions') }}</span>
    </div>

    <div class="relative flex justify-center pt-3" @click="$router.push({ name: closingRoute, params: { lead_uuid: companyLead.lead_uuid} })">
      <font-awesome-icon :icon="['fal', 'times']" class="text-3xl" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileIconMenu',
  props: {
    companyLead: Object
  },
  emits: [ 'lead-menu' ],
  computed: {
    closingRoute() {
      if(this.$route.meta.area === 'Company') {
        if([1,2,13].includes(this.companyLead.status)) {
          return 'PendingLeads'
        } else if(this.companyLead.status === 3) {
          return 'ConfirmedQuotes'
        } else {
          return 'Archive'
        }
      } else return 'QuoteOverview'
    }
  }
};
</script>

<style scoped>

</style>
