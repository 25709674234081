<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.leads.newLeads.newLeads') }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" :class="{ 'hidden sm:block':(company.activity_rate > 9) }">{{ $t('companyArea.leads.newLeads.newLeadsInfo') }}</p>
      <!--      <div class="text-center mt-3">-->
      <!--        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>-->
      <!--      </div>-->
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead }">
        <keep-alive>
          <lead-card :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <new-leads-card :company-lead="companyLead"
                            :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)"
                            @account-blocked="show.accountBlocked = true"
                            @evaluatePaymentMethod="evaluatePaymentMethod"
                            @leadTaken="leadTaken($event.companyLead, $event.companyCredit)"
            />
          </lead-card>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <confirmation-modal v-if="show.modal"
                        theme="white"
                        :close-button="true"
                        @close="show.modal = false"
    >
      <p v-html="$t('companyArea.leads.newLeads.confirmationText1', {appName: appName})" />
      <p class="mt-3">{{ $t('companyArea.leads.newLeads.confirmationText2', {appName: appName}) }}</p>
    </confirmation-modal>

    <confirmation-modal v-if="show.accountBlocked" theme="white" @close="show.accountBlocked = false">
      <p class="text-xl font-extrabold">Oups!</p>
      <div v-if="company.status === 3">
        <p class="mt-2" v-html="$t('companyArea.leads.newLeads.accountBlockedText1')" />
        <p class="mt-2">{{ $t('companyArea.leads.newLeads.accountBlockedText2') }}</p>
        <div class="flex justify-center mt-4">
          <button class="btn btn-lg btn-primary" data-cy="go-to-required-actions" @click="$router.push({ name: 'Credit' })">{{ $t('companyArea.leads.newLeads.accountBlockedButton') }}</button>
        </div>
      </div>
      <div v-else>
        <p class="mt-2" v-html="$t('companyArea.leads.newLeads.accountByLeadBlockedText1')" />
        <p class="mt-2">{{ $t('companyArea.leads.newLeads.accountByLeadBlockedText2') }}</p>
        <div class="flex justify-center mt-4">
          <button class="btn btn-lg btn-primary" data-cy="go-to-required-actions" @click="$router.push({ name: 'RequiredActions' })">{{ $t('companyArea.leads.newLeads.accountByLeadBlockedButton') }}</button>
        </div>
      </div>
    </confirmation-modal>

    <postfinance-payment ref="postfinancePayment"
                         :show="show.postfinanceForm"
                         @update:show="show.postfinanceForm = $event"
                         @leadTaken="leadTaken($event.companyLead, $event.companyCredit)"
    />
  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';
import NewLeadsCard from './LeadOverview/cards/NewLeadsCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import CircleButton from '../../snippets/CircleButton.vue';
import PostfinancePayment from '../Accounting/credit/PostfinancePayment.vue';
import notification from '../../connections/notification';

export default {
  name: 'NewLeads',
  components: { PostfinancePayment, ConfirmationModal, NewLeadsCard, LeadCard, Layout, LeadOverviewWrapper },
  props: {},
  data() {
    return {
      show: {
        confirmation: [],
        modal: false,
        accountBlocked: false,
        postfinanceForm: false,
      },
    };
  },
  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
    company() {
      return store.state.company.company
    }
  },
  methods: {
    evaluatePaymentMethod(content, notification) {
      this.$refs.postfinancePayment.evaluatePaymentIntent(content, notification);
    },
    leadTaken(companyLead, companyCredit) {
      if (companyLead) {
        notification['success'](this.$t('companyArea.leads.leadOverview.cards.newLeadsCard.leadBought'));
        store.dispatch('company/updateCompanyLead', companyLead);
        store.commit('company/setCredit', companyCredit);
        this.$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid } }).catch(err => {
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
