<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
    <label class="form-label">{{ field.label }}</label>
    <input id="local"
           v-model="formdata['local']"
           max="1"
           min="0"
           step="0.1"
           type="range"
           :data-gtm-field-label="field.label"
           :data-gtm-field-id="field.id"
           @blur="logFieldBlur($event.target.value)"
           @change="editFields"
    >
    <div class="form-between">
      <div class="price-quality">{{ $t('form.unimportant') }}</div>
      <div class="price-quality">{{ $t('form.important') }}</div>
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    name: 'LocalMetaField',
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    computed: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
    methods: {},
  };
</script>
