import authApi from '../connections/auth';
import store from './mainStore';
import router from '../../router.js';
import Bugsnag from '@bugsnag/js';
import { VueCookieNext } from 'vue-cookie-next';

const getDefaultState = () => {
  return {
    isGettingUserData: false, // prohibits multiple parallel getUser calls
  };
};

const AuthStore = {
  namespaced: true,
  state: getDefaultState(),

  getters: {
    user(state, getters, rootState) {
      return rootState.main.user;
    },
  },

  actions: {
    login(context, payload) {
      return authApi.post('/login', payload, {}).then(() => {
        store.dispatch('resetAllStoreStates');
        store.dispatch('auth/getUser');
      }).catch(error => {
        let tempValidationErrors = store.state.main.validationErrors;
        store.dispatch('resetAllStoreStates');
        if ([401, 403].includes(error.response.status)) {
          store.dispatch('auth/logout');
        }
        store.commit('SET_VALIDATION_ERROR', tempValidationErrors, { root: true });
      });
    },
    logout() {
      return authApi.post('/logout').then((response) => {
        store.dispatch('resetAllStoreStates');
        if (response.data.content === 'stop-impersonating') {
          router.push({ name: 'Autologin' }).catch(err => {});
        } else {
          router.push({ name: 'Login' }).catch(err => {});
        }
      }).catch(() => {
        store.dispatch('resetAllStoreStates');
        router.push({ name: 'Login' }).catch(err => {});
      });
    },
    /**
     * Try to autologin the user from store, cookie or session cookie (backend)
     *
     * @param context
     * @param userData optional: Object with autologin token to auto-log-in the user in the backend. Structure: { user.userId, user.autologinToken }
     * @returns {Promise<void>}
     */
    async autologin(context, userData = null) {
      if (store.getters.isGuest) {
        // If the store has no user, try to get it from the cookie
        let user = VueCookieNext.getCookie('user');
        if (user) {
          console.log('Set User from cookie', user);
          let response = await context.commit('setUser', user, { root: true });
        } else {
          // If we still have no user, try to get it from the backend (per session cookie)
          let user = await store.dispatch('auth/getUser', userData);
          if (!user) {
            if (!user && userData.hasOwnProperty('autologinToken')) {
              console.log('Autologin with autologinToken did not work, BUT it SHOULD have, redirected to login page. User from Cookie: ' + user + ' autologin token: ' + userData.autologinToken);
              Bugsnag.notify(e, function (event) {
                event.severity = 'error';
                event.context = 'Autologin with autologinToken did not work, BUT it SHOULD have, redirected to login page. User from Cookie: ' + user + ' autologin token: ' + user.autologinToken;
              });
              // still no user - go to login page
              router.push({ name: 'Login' }).catch(err => {});
            }
          }
        }
        return user;
      }
    },
    /**
     * Try to get the the user from the backend, per session cookie
     * optionally there can be added a autologin token from the backend, to auto login the user
     *
     * @param context
     * @param userData Object with autologin token to auto-log-in the user in the backend. Structure: { user.userId, user.autologinToken }
     * @returns {Promise<AxiosResponse<any> | void>}
     */
    getUser(context, userData = null) {
      if (context.state.isGettingUserData === false) {
        context.commit('setIsGettingUserDataFromBackend', true);
        let uri = '/user';
        if (userData) {
          uri += '?user_id=' + userData.userId + '&autologin_token=' + userData.autologinToken;
        }
        console.log('authStore.getUser: (authApi Header is): ' + authApi.defaults.headers.common['Accept-Language']);

        return authApi.get(uri).then(response => {
          if (response.data) {
            context.commit('setUser', response.data, { root: true });
            context.commit('setCompanies', response.data.employees, { root: true });
          }
          return response.data;
        }).catch((e) => {
          var user = VueCookieNext.getCookie('user');
          if (user && user !== 'null') {
            Bugsnag.notify(e, function (event) {
              event.severity = 'error';
              event.context = 'Autologin did not work, BUT it SHOULD have, redirected to login page. User from Cookie: ' + user;
            });
          }

          // To be able to catch the error in the autologin component
          throw e;
        }).finally(() => context.commit('setIsGettingUserDataFromBackend', false));
      }
    },
    checkUser(context) {
      if (context.state.isGettingUserData === false) {
        context.commit('setIsGettingUserDataFromBackend', true);

        return authApi.get('/user').then(response => {
          context.commit('setUser', response.data, { root: true });
          context.commit('setCompanies', response.data.employees, { root: true });
          router.push({ name: 'Login' }).catch(err => {});
        }).catch(() => {
          context.dispatch('logout');
        }).finally(() => context.commit('setIsGettingUserDataFromBackend', false));
      }
    },
  },

  mutations: {
    setIsGettingUserDataFromBackend(state, value) {
      state.isGettingUserData = value;
    },
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
  },
};

export default AuthStore;
