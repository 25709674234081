<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.leads.requiredActions.leadsNeedAttention') }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">{{ $t('companyArea.leads.requiredActions.leadsNeedAttentionInfo') }}</p>
      <div class="text-center mt-3">
        <button class="btn btn-lg btn-primary" @click="show.modal = true">{{ $t('companyArea.leads.requiredActions.more') }}</button>
      </div>
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <component :is="desktop ? 'LeadCard' : 'MobileCard'" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <lead-action v-if="companyLead.status > 0 && companyLead.lead.required_actions.length > 0" :company-lead="companyLead" />
          </component>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <template v-slot:modal>
      <confirmation-modal v-if="show.modal" theme="white" @close="show.modal = false">
        <make-quote-container :company-lead="currentCompanyLeadForQuote.lead" :quote="{}" @close="show.modal = false" />
      </confirmation-modal>
    </template>
  </layout>
</template>

<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from './LeadOverview/makeQuote/MakeQuoteContainer.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import PendingLeadsCard from './LeadOverview/cards/PendingLeadsCard.vue';
import ConfirmedQuotesCard from './LeadOverview/cards/ConfirmedQuotesCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import { companyMixin } from '../../../plugins/mixin';
import LeadAction from './LeadOverview/snippets/LeadAction.vue';

export default {
  name: 'RequiredActions',
  components: { LeadAction, MobileCard, ConfirmedQuotesCard, PendingLeadsCard, MakeQuoteContainer, ConfirmationModal, LeadCard, Layout, LeadOverviewWrapper },
  mixins: [companyMixin],
  props: {
    desktop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: {
        modal: false,
        confirmation: [],
      },
      currentCompanyLeadForQuote: {},
    };
  },
  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
