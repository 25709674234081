<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'flex w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <input :id="'field' + field.id"
           ref="field"
           v-model="input"
           :name="createFieldName()"
           :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
           :data-cy="'zipCityField'+field.id"
           :data-gtm-field-label="field.label"
           :data-gtm-field-id="field.id"
           :placeholder="placeholder"
           class="mainform-color mainform-input"
           type="text"
           autocomplete="off"
           @blur="checkAndSelectCityOption($event.target.value)"
           @focus="editFields(); disableAutocomplete()"
           @input="getCityOptions"
           @keyup.down.prevent="hoverCityOption('down')"
           @keyup.up.prevent="hoverCityOption('up')"
           @keydown.esc="clearInput"
           @keydown.enter.prevent="selectCityOption(hoveredCityOption)"
    >
    <label-state-icon :label-state="labelState" :required="field.required" />
    <div v-if="cityOptions.length > 0" class="absolute top-10 w-full border shadow bg-white font-semibold z-50">
      <div v-for="option in listedCityOptions"
           :key="field.id + option.id"
           :class="{ 'bg-blue-100':(cityOptions.indexOf(option) === hoveredCityOption) }"
           :data-cy="'zip-'+option.zip"
           class="w-full p-2 cursor-pointer hover:bg-blue-100"
           @click="selectCityOption(cityOptions.indexOf(option))"
      >
        {{ option.zip }} {{ option.city }}
      </div>
    </div>
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'ZipCityField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      postcodes: Array,
      field: Object,
      optionFields: Array,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
      calc: {
        type: Object,
        default: {},
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        cityOptions: [],
        show: false,
        placeholder: this.field.label,
        input: '',
        hoveredCityOption: null,
        maxCityOptions: 3,
        labelState: null,
      };
    },
    computed: {
      currentResidence() {
        return this.formdata.city;
      },
      listedCityOptions() {
        let start = 0;
        let end = this.maxCityOptions;
        if (this.hoveredCityOption === null || this.hoveredCityOption < (this.maxCityOptions - 2)) {

        } else {
          if ((this.hoveredCityOption - this.maxCityOptions + 2) < (this.cityOptions.length - this.maxCityOptions)) {
            start = this.hoveredCityOption - this.maxCityOptions + 2;
          } else {
            start = this.cityOptions.length - this.maxCityOptions;
          }
          end = start + this.maxCityOptions;
        }
        return this.cityOptions.slice(start, end);
      },
    },
    watch: {
      currentResidence() {
        this.calculateDistance();
      },
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
        }, deep: true,
      },
    },
    created() {
      if (this.formdata.dynamic_form['zip' + this.field.id]) {
        this.placeholder = this.formdata.dynamic_form['zip' + this.field.id] + ' ' + this.formdata.dynamic_form[this.field.id];
      }
      this.getLabelState();
    },
    methods: {
      getCityOptions() {

        let cityOptions = [];
        if (this.input.length > 0) {
          cityOptions = this.postcodes.filter(obj => {

              return obj.zip.startsWith(this.input.toLowerCase()) ||
                obj.city.toLowerCase().startsWith(this.input.toLowerCase()) ||
                obj.city.toLowerCase().includes(this.input.toLowerCase()) ||
                (obj.zip + ' ' + obj.city.toLowerCase()).startsWith(this.input.toLowerCase());
            })
            .sort((a, b) => {
              const isExactMatchA =
                a.city.toLowerCase() === this.input.toLowerCase();
              const isExactMatchB =
                b.city.toLowerCase() === this.input.toLowerCase();
              if (isExactMatchA && !isExactMatchB) {
                return -1;
              }
              if (!isExactMatchA && isExactMatchB) {
                return 1;
              }
              return 0;
            });
          if (cityOptions.length === 0) {
            let inputToSearch = this.input.toLowerCase();
            let inputWithoutUmlauts = inputToSearch
              .replace(/ä/g, 'a')
              .replace(/ö/g, 'o')
              .replace(/ü/g, 'u')
              .replace(/ /g, '');

            cityOptions = this.postcodes.filter(obj => {
              const cityWithoutUmlauts = obj.city
                .replace(/ä/g, 'a')
                .replace(/ö/g, 'o')
                .replace(/ü/g, 'u')
                .replace(/ /g, '');

              return obj.zip.startsWith(inputWithoutUmlauts) ||
                cityWithoutUmlauts.toLowerCase().startsWith(inputWithoutUmlauts) ||
                cityWithoutUmlauts.toLowerCase().includes(inputWithoutUmlauts) ||
                (obj.zip + ' ' + cityWithoutUmlauts.toLowerCase()).startsWith(inputWithoutUmlauts);
            });
          }
          if (cityOptions.length === 0) {
            let inputToSearch = this.input.toLowerCase();
            let inputWithoutUmlauts = inputToSearch
              .replace(/ä/g, 'a')
              .replace(/ö/g, 'o')
              .replace(/ü/g, 'u');
            while (inputWithoutUmlauts.length > 0 && cityOptions.length === 0) {
              cityOptions = this.postcodes.filter(obj => {
                const cityWithoutUmlauts = obj.city
                  .replace(/ä/g, 'a')
                  .replace(/ö/g, 'o')
                  .replace(/ü/g, 'u').toLowerCase();

                return obj.zip.startsWith(inputWithoutUmlauts) ||
                  cityWithoutUmlauts.startsWith(inputWithoutUmlauts) ||
                  cityWithoutUmlauts.includes(inputWithoutUmlauts) ||
                  (obj.zip + ' ' + cityWithoutUmlauts).startsWith(inputWithoutUmlauts);
              });
              if (cityOptions.length === 0) {
                const match = inputWithoutUmlauts.lastIndexOf(' ') > inputWithoutUmlauts.lastIndexOf('-') ? inputWithoutUmlauts.lastIndexOf(' ') : inputWithoutUmlauts.lastIndexOf('-');
                if (match !== -1) {
                  inputWithoutUmlauts = inputWithoutUmlauts.substring(0, match);
                } else {
                  break;
                }
              }
            }
          }
          // der String wird aufgespalten und nach einem Match gesucht
          if (cityOptions.length === 0) {
            let inputToSearch = this.input.toLowerCase();
            let inputWithoutUmlauts = inputToSearch
              .replace(/ä/g, 'a')
              .replace(/ö/g, 'o')
              .replace(/ü/g, 'u')
              .replace(/ae/g, 'a')
              .replace(/oe/g, 'o')
              .replace(/ue/g, 'u');
            const parts = inputWithoutUmlauts.split(/[\s-]+/);
            parts.filter(part => part !== '').forEach((term) => {
              for (let i = 0; i < parts.length; i++) {
                const filteredResults = this.postcodes.filter((obj) => {
                  const cityWithoutUmlauts = obj.city
                    .replace(/ä/g, 'a')
                    .replace(/ö/g, 'o')
                    .replace(/ü/g, 'u')
                    .replace(/ae/g, 'a')
                    .replace(/oe/g, 'o')
                    .replace(/ue/g, 'u')
                    .toLowerCase();

                  return (
                    obj.zip.startsWith(term) ||
                    cityWithoutUmlauts.startsWith(term) ||
                    cityWithoutUmlauts.includes(term)
                  );
                });

                // Überprüfe, ob die Ergebnisse bereits in cityOptions vorhanden sind, und füge sie nur hinzu, wenn nicht
                filteredResults.forEach((result) => {
                  if (!cityOptions.some((item) => item.id === result.id)) {
                    cityOptions.push(result);
                  }
                });
              }
            });

          }
        }
        this.cityOptions = cityOptions;
        this.hoveredCityOption = null;
        this.getLabelState();
      },
      focus() {
        this.$refs['zip'].focus();
      },
      getLabelState() {
        if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
          this.labelState = false;
        } else if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
          this.labelState = this.formdata.dynamic_form[this.field.id].length > 0;
        } else if (this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
          this.labelState = this.formdata.dynamic_form[this.field.id].length > 0 ? true : null;
        } else {
          this.labelState = null;
        }
      },
      hoverCityOption(direction) {
        if (direction === 'down') {
          if (this.hoveredCityOption === null) {
            this.hoveredCityOption = 0;
          } else {
            this.hoveredCityOption < this.cityOptions.length - 1 ? this.hoveredCityOption++ : '';
          }
        } else if (direction === 'up') {
          this.hoveredCityOption--;
          this.hoveredCityOption === -1 ? this.hoveredCityOption = null : '';

        }
      },
      clearInput() {
        this.input = '';
        this.cityOptions = [];
        this.hoveredCityOption = null;
      },
      selectCityOption(index) {
        if (index === null) {
          if (this.cityOptions.length === 1) {
            index = 0;
          } else {
            return;
          }
        }
        this.formdata.dynamic_form['zip' + this.field.id] = this.cityOptions[index].zip;
        this.formdata.dynamic_form[this.field.id] = this.cityOptions[index].city;
        this.placeholder = this.formdata.dynamic_form['zip' + this.field.id] + ' ' + this.formdata.dynamic_form[this.field.id];
        this.calculateDistance();
        this.clearInput();
        this.getLabelState();
      },
      checkAndSelectCityOption(value) {
        setTimeout(() => {
          if (this.cityOptions.length > 0) {
            this.formdata.dynamic_form['zip' + this.field.id] = this.cityOptions[0].zip;
            this.formdata.dynamic_form[this.field.id] = this.cityOptions[0].city;
            this.placeholder = this.formdata.dynamic_form['zip' + this.field.id] + ' ' + this.formdata.dynamic_form[this.field.id];
            this.clearInput();
            this.getLabelState();
          }
          this.logFieldBlur(value);
        }, 500);
      },
      calculateDistance() {
        if (this.formdata.city.length > 0 && this.formdata.dynamic_form.hasOwnProperty(this.field.id) && this.formdata.dynamic_form[this.field.id].length > 0) {
          let start = this.postcodes.find(value => value.id === this.formdata.postcode_id);
          let end = this.postcodes.find(value => value.zip === this.formdata.dynamic_form['zip' + this.field.id]);
          let distance = this.getDistanceFromLatLonInKm(start.latitude, start.longitude, end.latitude, end.longitude);
          let calc = '1';
          if (distance > 10 && distance <= 20) {
            calc = '1.05';
          } else if (distance > 20 && distance <= 30) {
            calc = '1.06';
          } else if (distance > 30 && distance <= 40) {
            calc = '1.1';
          } else if (distance > 40 && distance <= 50) {
            calc = '1.18';
          } else if (distance > 50 && distance <= 60) {
            calc = '1.23';
          } else if (distance > 60) {
            calc = '1.5';
          }
          this.calc[this.field.id] = calc;
        }
      },
      getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2 - lon1);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
      },
      deg2rad(deg) {
        return deg * (Math.PI / 180);
      },
      disableAutocomplete() {
        this.$refs.field.setAttribute('autocomplete', 'off');
      },
    },

  };
</script>
