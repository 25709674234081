<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.companyLocale.title') }}</p>
    </template>

    <div class="panel">
      <div class="font-semibold">
        {{ $t('companyArea.settings.area.customerLanguages') }}
      </div>
      <div class="mt-1 flex flex-col">
        <label v-for="locale in locales"
               :id="'customer-'+locale"
               :key="locale.locale"
               class="inline-flex items-center my-1"
               @change="submitCustomerLocales()"
        >
          <input v-model="locale.checked"
                 type="checkbox"
                 class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
          >
          <span class="ml-1 mr-3">{{ $t(locale.translation_key) }}</span>
        </label>
      </div>
    </div>

    <div class="panel">
      <label for="company-locale" class="block text-sm font-semibold text-gray-700 mb-2">{{ $t('companyArea.settings.companyLocale.companyLocaleSubTitle') }}</label>
      <select id="company-locale" v-model="companyLocale" name="company-locale" class="pr-12 w-auto" @change="updateCompanyLocale()">
        <option v-for="locale in locales" :key="locale.locale" :value="locale.locale">{{ locale.label }}</option>
      </select>
      <p class="my-4">{{ $t('companyArea.settings.companyLocale.companyLocaleText') }}</p>
    </div>

    <div class="panel">
      <label for="user-locale" class="block text-sm font-semibold text-gray-700 mb-2">{{ $t('companyArea.settings.companyLocale.userLocaleSubTitle') }}</label>
      <select id="user-locale" v-model="userLocale" name="user-locale" class="pr-12 w-auto" @change="updateUserLocale()">
        <option v-for="locale in locales" :key="locale.locale" :value="locale.locale">{{ locale.label }}</option>
      </select>
      <p class="my-4">{{ $t('companyArea.settings.companyLocale.userLocaleText') }}</p>
    </div>
  </layout>
</template>

<script>
  import store from '../../store/mainStore';
  import Layout from '../../Layout.vue';
  import companyApi from '../../connections/company';
  import { LOCALES } from '../../../plugins/i18n';
  import authApi from '../../connections/auth';

  export default {
    name: 'CompanyLocale',
    components: { Layout },
    data() {
      return {
        companyLocale: store.state.company.company.locale,
        userLocale: store.state.main.user.locale,
        locales: LOCALES,
      };
    },
    computed: {
      company () {
        return store.state.company.company;
      },
      user() {
        return store.state.main.user;
      },
    },
    mounted() {
      if (this.company.hasOwnProperty('id')) {
        Object.keys(this.locales).forEach(localeKey => {
          let locale = this.locales[localeKey];
          if (this.company.customer_languages.hasOwnProperty(localeKey)) {
            locale.checked = this.company.customer_languages[localeKey];
          }
        });
      }
    },
    methods: {
      updateCompanyLocale() {
        companyApi.patch('companies/' + this.company.id + '/locale/', { locale: this.companyLocale }, {
          'progress': false,
          notification: true,
        }).then(response => store.commit('company/setCompany', response.data.content.company));
      },
      updateUserLocale() {
        authApi.patch('/users/' + this.user.id + '/locale', { locale: this.userLocale }, {
          'progress': false,
          notification: true,
        }).then(response => [console.log(response.data.content.user),store.commit('setUser', response.data.content.user, { root: true })]);
      },
      submitCustomerLocales() {
        let formdata = {
          customer_languages: {},
        };

        Object.keys(this.locales).forEach(localeKey => {
          let locale = this.locales[localeKey];
          formdata.customer_languages[locale.locale] = locale.checked;
        });

        companyApi.patch('/companies/' + this.company.id, formdata, { notification: true, progress: false })
          .then(response => [store.commit('company/setCompany', response.data.content.company), this.changesDetected = false]);
      },
    },
  };
</script>

<style scoped>

</style>
