<template>
  <div class="flex justify-center mt-3">
    <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3"
            data-cy="confirm"
            @click="$emit('yes')"
    >
      <font-awesome-icon :icon="['fal','check']" />
      <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.yes') }}</span>
    </button>
    <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3"
            data-cy="reject"
            @click="$emit('no')"
    >
      <font-awesome-icon :icon="['fal','times']" />
      <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.no') }}</span>
    </button>
  </div>
</template>

<script>
export default {
name: "YesOrNo",
  emits: ['yes','no']
}
</script>

<style scoped>

</style>
