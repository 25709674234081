<template>
  <div>
    <form @submit="onSubmitOption">
      <div>
        <select v-model="optionId" class="selectOptions" @change="getOptionForEdit">
          <option v-for="option in allOptions"
                  :key="'allOption' + option.id"
                  :value="option.id"
                  class="selectOption"
          >
            {{
              option.label
            }}
          </option>
        </select>
      </div>

      <input v-model="formOption.label_deCH"
             :state="labelStateDeCH"
             aria-describedby="input-live-help input-live-feedback"
             placeholder="Label of the Input-Field"
             class="border border-gray-400 w-full mt-2"
      >
      <input v-model="formOption.label_enUS"
             :state="labelStateEnUS"
             aria-describedby="input-live-help input-live-feedback"
             placeholder="Label of the Input-Field in English"
             class="border border-gray-400 w-full mt-2"
      >
      <input v-model="formOption.label_frCH"
             :state="labelStateFrCH"
             aria-describedby="input-live-help input-live-feedback"
             placeholder="Label of the Input-Field in French"
             class="border border-gray-400 w-full mt-2"
      >
      <input v-model="formOption.label_itCH"
             :state="labelStateItCH"
             aria-describedby="input-live-help input-live-feedback"
             placeholder="Label of the Input-Field in Italian"
             class="border border-gray-400 w-full mt-2"
      >
      <input v-model="formOption.label_ptPT"
             :state="labelStatePtPT"
             aria-describedby="input-live-help input-live-feedback"
             placeholder="Label of the Input-Field in Portuguese"
             class="border border-gray-400 w-full mt-2"
      >
      <input v-model="formOption.label_trTR"
             :state="labelStateTrTR"
             aria-describedby="input-live-help input-live-feedback"
             placeholder="Label of the Input-Field in Turkish"
             class="border border-gray-400 w-full mt-2"
      >
      <div id="input-live-feedback">
        All Labels Max 40 charakters.
      </div>

      <div class="w-full">
        <input v-model="formOption.label" type="text" placeholder="Label of the Option-Field">
      </div>

      <div class="w-full">
        <input v-model="formOption.value" type="text" placeholder="Value of the Option-Field">
      </div>

      <div class="w-full">
        <input v-model="formOption.class" type="text" placeholder="Class of the Input-Field">
      </div>


      <div class="w-full">
        <input v-model="formOption.javascript"
               type="text"
               placeholder="Javascript of the Input-Field"
        >
      </div>
      <div class="mt-4">If the field is relevant for price calculation</div>
      <input v-model="formOption.price_calc_form"
             type="text"
             class="border border-gray-400 w-full mt-2"
             name="price-calc-corm"
      >

      <div class="w-full">
        <input v-model="formOption.selected" type="checkbox" value="1"> Selected
      </div>
      <button type="submit" class="btn btn-sm btn-primary">Update</button>
    </form>

    <button class="btn btn-sm btn-secondary" @click="DeleteOption">Delete Option</button>
    <button class="btn btn-sm btn-secondary" @click="SubmitUp('form')">Up</button>
    <button class="btn btn-sm btn-secondary" @click="SubmitDown('form')">Down</button>
  </div>
</template>

<script>
import store from '../store/mainStore'
// This Component is used in FieldCreatorComponent
export default {
    name: 'EditOption',
    props: {
        field: Object,
        fields: Array,
    },
    data() {
        return initialState()
    },
    computed: {
        labelState: function () {
            return this.form.label_deCH.length > 40 ? false : null
        },
        allOptions: function () {
            return this.fields.filter(Obj => Obj.parent === this.field.id)
        },
        labelStateDeCH: function () {
            // returns error in testing because label_deCH.length is not defined
            if (this.formOption.label_deCH) {
                return this.formOption.label_deCH.length > 50 ? false : null;
            } else {
                return null;
            }
        },
        labelStateEnUS: function () {
            // returns error in testing because label_enUS.length is not defined
            if (this.formOption.label_enUS) {
                return this.formOption.label_enUS.length > 50 ? false : null;
            } else {
                return null;
            }
        },
        labelStateFrCH: function () {
            // returns error in testing because label_frCH.length is not defined
            if (this.formOption.label_frCH) {
                return this.formOption.label_frCH.length > 50 ? false : null;
            } else {
                return null;
            }
        },
        labelStateItCH: function () {
            // returns error in testing because label_frCH.length is not defined
            if (this.formOption.label_itCH) {
                return this.formOption.label_itCH.length > 50 ? false : null;
            } else {
                return null;
            }
        },
        labelStatePtPT: function () {
            // returns error in testing because label_ptPT.length is not defined
            if (this.formOption.label_ptPT) {
                return this.formOption.label_ptPT.length > 50 ? false : null;
            } else {
                return null;
            }
        },
        labelStateTrTR: function () {
            // returns error in testing because label_trTR.length is not defined
            if (this.formOption.label_trTR) {
                return this.formOption.label_trTR.length > 50 ? false : null;
            } else {
                return null;
            }
        },
    },
    watch: {},
    mounted() {
    },
    methods: {
        // Lower Form Submit
        onSubmitOption(evt) {
            evt.preventDefault();
            alert(JSON.stringify(this.formOption));
            store.dispatch('formcreator/updateField', [this.currentOption, this.formOption])
        },

        // Move Options
        // Move Fields
        SubmitUp(view) {
            store.dispatch('formcreator/moveUpField', [this.currentOption.id, view])
        },
        SubmitDown(view) {
            store.dispatch('formcreator/moveDownField', [this.currentOption.id, view])
        },

        DeleteOption(evt) {
            evt.preventDefault();
            store.dispatch('formcreator/deleteField', this.currentOption);

            this.formOption.label_deCH = '';
            this.formOption.class = '';
            this.formOption.javascript = ''
        },

        getOptionForEdit() {
            this.currentOption = this.fields.find(obj => obj.id === this.optionId);
            this.formOption.id = this.optionId;
            this.formOption.label_deCH = this.currentOption.label_deCH;
            this.formOption.class = this.currentOption.class;
            this.formOption.value = this.currentOption.value;
            this.formOption.selected = this.currentOption.selected;
            this.formOption.javsascript = this.currentOption.javsascript
            this.formOption.price_calc_form = this.currentOption.price_calc_form
            this.formOption.label_deCH = this.currentOption.label_deCH
            this.formOption.label_enUS = this.currentOption.label_enUS
            this.formOption.label_frCH = this.currentOption.label_frCH
            this.formOption.label_itCH = this.currentOption.label_itCH
            this.formOption.label_ptPT = this.currentOption.label_ptPT
            this.formOption.label_trTR = this.currentOption.label_trTR
        },

        resetForm: function () {
            Object.assign(this.$data, initialState())
        },

    },

}

function initialState() {
    return {
        formOption: {
            label_deCH: '',
            label_enUS: '',
            label_frCH: '',
            label_itCH: '',
            label_ptPT: '',
            label_trTR: '',
            label: '',
            class: '',
            value: '',
            javascript: '',
        },
        optionId: '',

        // Option to be edited
        currentOption: {},

    }
}
</script>
