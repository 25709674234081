<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">
        {{ $t('companyArea.accounting.invoices.invoicesTitle') }}
      </p>
    </template>

    <div class="panel">
      <div v-if="invoices.length > 0" class="text-base font-semibold">
        {{ $t('companyArea.accounting.invoices.invoicesOverview') }}
      </div>
      <div v-else class="text-base font-semibold">{{ $t('companyArea.accounting.invoices.noInvoicesYet') }}</div>
      <div class="grid grid-cols-5 gap-1 mt-3 font-semibold" data-cy="invoices-list">
        <div>{{ $t('companyArea.accounting.invoices.titleName') }}</div>
        <div>{{ $t('companyArea.accounting.invoices.titleAmount') }}</div>
        <div>{{ $t('companyArea.accounting.invoices.titleStatus') }}</div>
        <div>{{ $t('companyArea.accounting.invoices.titleDate') }}</div>
        <div>{{ $t('companyArea.accounting.invoices.titleActions') }}</div>
      </div>
      <a v-for="invoice in invoices"
         :key="invoice.id"
         :href="invoiceUrl(invoice)"
         target="_blank"
      >
        <div class="grid grid-cols-5 gap-1 mt-3" data-cy="invoices-list">
          <div> {{ invoice.name }}</div>
          <div>{{ invoice.currency }} {{ invoice.amount }}</div>
          <div>{{ invoice.paid_at ? $t('companyArea.accounting.invoices.paid') : invoice.reminder_1_at ? $t('companyArea.accounting.invoices.due') : $t('companyArea.accounting.invoices.open') }}</div>
          <div>{{ this.dayjsHelper.showDate(invoice.created_at) }}</div>
          <div><a :href="invoiceUrl(invoice)" target="_blank"><font-awesome-icon class="icon mr-2"
                                                                                 :icon="['far', 'magnifying-glass']"
                                                                                 size="s"
               /></a>
            <a :href="invoiceUrl(invoice)+'/download'" target="_blank"><font-awesome-icon class="icon mr-2"
                                                                                          :icon="['far', 'download']"
                                                                                          size="s"
            /></a></div>
        </div>
      </a>
    </div>
  </layout>
</template>

<script>
import companyApi from '../../connections/company';
import store from '../../store/mainStore';
import Layout from '../../Layout.vue';

export default {
    name: 'Invoices',
    components: {Layout},
    data() {
        return {
            show: {
                newReport: false,
            },
            url: import.meta.env.VITE_VUE_APP_STORAGE_URL,
        };
    },
    computed: {
        invoices() {
            if (store.state.company.invoices.length > 0) {
                return store.state.company.invoices;
            } else {
                return [];
            }
        },
    },
    watch: {},
    created() {
        companyApi.get('/companies/' + store.state.company.company.id + '/invoices', {notification: false}).then(response => response.data.content.length > 0 ? store.commit('company/setInvoices', response.data.content) : this.show.newReport = true).catch(error => error.response.data.content);
    },
    methods: {
      invoiceUrl(invoice) {
        return import.meta.env.VITE_VUE_APP_BACKEND_API_URL + '/company-backend/companies/' + store.state.company.company.id + '/invoices/' + invoice.id;
      },
    },
};
</script>

<style scoped>

</style>
