<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.accounting.reports.reportsTitle') }}</p>
    </template>

      <div class="panel">
        <div class="flex flex-col sm:flex-row justify-center px-3 sm:mb-0">
          <div class="flex flex-col sm:flex-row items-center flex-grow">
            <p class="text-base font-semibold mr-4 mb-2 ml-2 sm:ml-0 pt-2 ">Neuen Bericht erstellen: </p>
            <div class="flex flex-col sm:flex-row items-center">
              <div class="flex flex-col 2xl:flex-row items-center">
                <div class="relative w-min mr-1">
                  <select v-model="formdata.type" class="rounded-full border-none shadow w-56 sm:w-52">
                    <option :value="1">{{ $t('companyArea.accounting.reports.deposits') }}</option>
                    <option :value="0">{{ $t('companyArea.accounting.reports.allTransactions') }}</option>
                    <option :value="2">{{ $t('companyArea.accounting.reports.boxes') }}</option>
                  </select>
                </div>
                <div class="relative w-min mr-1 mt-2 2xl:mt-0">
                  <select v-model="formdata.period" class="rounded-full border-none shadow w-56 sm:w-52" data-cy="select-month">
                    <option v-for="option in period" :key="option.value" :value="option.value">{{ $t(option.text) }}</option>
                  </select>
                </div>
                <div v-if="formdata.period > 0" class="relative w-min mr-1 mt-2 2xl:mt-0">
                  <select v-model="formdata.year" class="rounded-full border-none shadow w-56 sm:w-52" data-cy="select-year">
                    <option v-for="option in years" :key="'year' + option.value" :value="option.value">{{ option.text }}</option>
                  </select>
                </div>
              </div>

              <div class="flex flex-col 2xl:flex-row items-center">
                <date-picker v-if="[0,'0'].includes(formdata.period)"
                             v-model:date="formdata.start_date"
                             :placeholder="$t('companyArea.accounting.reports.from')"
                             input-styles="h-10 w-56 sm:w-52 mr-1 mt-2 sm:mt-0 rounded-full border-none shadow"
                />
                <date-picker v-if="[0,'0'].includes(formdata.period)"
                             v-model:date="formdata.end_date"
                             :placeholder="$t('companyArea.accounting.reports.until')"
                             input-styles="h-10 w-56 sm:w-52 mr-1 mt-2 2xl:mt-0 rounded-full border-none shadow"
                />
              </div>
              <circle-button :icon="['fas','arrow-circle-right']"
                             class="ml-1 pl-1 mt-2 sm:mt-0 border-none shadow"
                             :text="$t('general.execute')"
                             data-cy="new-report"
                             @click="newReport"
              />
            </div>
          </div>
        </div>
      </div>

    <div class="panel">
      <div v-if="reports.length > 0" class="text-base font-semibold">{{ $t('companyArea.accounting.reports.downloadReports') }}</div>
      <div v-else class="text-base font-semibold">{{ $t('companyArea.accounting.reports.noReportsYet') }}</div>
      <table class="w-full" data-cy="table-reports">
        <tr>
          <th class="text-left font-semibold">{{ $t('companyArea.accounting.reports.period') }}<span v-if="company.vat"><br>&nbsp;</span></th>
          <th class="text-right font-semibold">{{ $t('companyArea.accounting.reports.records') }}<span v-if="company.vat"><br> </span></th>
          <th class="text-right font-semibold">{{ $t('companyArea.accounting.reports.amount', { currency: company.currency }) }}<span v-if="company.vat">*</span></th>
          <th class="text-right font-semibold">{{ $t('companyArea.accounting.reports.actions') }}<span v-if="company.vat"><br> </span></th>
        </tr>
        <tr v-for="report in reports" :key="report.id" class="border-b">
          <td class="flex flex-col mt-2 mb-2"><a :href="reportUrl(report)" target="_blank">{{ report.name }}</a></td>
          <td class="align-top text-right pt-2">{{ report.records }}</td>
          <td class="text-right align-top pt-2">{{ report.amount }} {{ report.currency }}</td>
          <td class="align-top text-right pt-2">
            <a :href="reportUrl(report)" target="_blank">
              <font-awesome-icon class="icon mr-2"
                                 :icon="['far', 'magnifying-glass']"
                                 size="s"
              />
            </a>
            <a :href="reportUrl(report)+'/download'" target="_blank">
              <font-awesome-icon class="icon mr-2"
                                 :icon="['far', 'download']"
                                 size="s"
              />
            </a>
            <button>
              <font-awesome-icon class="icon"
                                 :icon="['far', 'trash-alt']"
                                 size="s"
                                 @click="deleteReport(report.id)"
              />
            </button>
          </td>
        </tr>
      </table>
    </div>
  </layout>
</template>

<script>
  import companyApi from '../../connections/company';
  import store from '../../store/mainStore';
  import notification from '../../connections/notification';
  import Layout from '../../Layout.vue';
  import CircleButton from '../../snippets/CircleButton.vue';
  import DatePicker from '../../snippets/DatePicker.vue';

  export default {
    name: 'Reports',
    components: { DatePicker, CircleButton, Layout },
    data() {
      return {
        show: {
          newReport: false,
          explanation: false,
          datepickerFrom: false,
        },
        formdata: {
          period: 0,
          year: '',
          start_date: '',
          end_date: '',
          type: 1,
        },
        start_date_prov: '',
        end_date_prov: '',
        period: [
          { value: 0, text: 'general.custom' },
          { value: 1, text: 'general.date.january' },
          { value: 2, text: 'general.date.february' },
          { value: 3, text: 'general.date.march' },
          { value: 4, text: 'general.date.april' },
          { value: 5, text: 'general.date.may' },
          { value: 6, text: 'general.date.june' },
          { value: 7, text: 'general.date.july' },
          { value: 8, text: 'general.date.august' },
          { value: 9, text: 'general.date.september' },
          { value: 10, text: 'general.date.october' },
          { value: 11, text: 'general.date.november' },
          { value: 12, text: 'general.date.december' },
        ],
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      years() {
        var textOptions = { year: 'numeric' };
        var d = new Date();
        var period = [{ value: d.getFullYear(), text: d.toLocaleDateString('de-DE', textOptions) }];
        var i;
        for (i = 0; i < 3; i++) {
          d.setFullYear(d.getFullYear() - 1);
          period.push({ value: d.getFullYear(), text: d.toLocaleDateString('de-DE', textOptions) });
        }
        return period;
      },
      reports() {
        if (store.state.company.reports.length > 0) {
          return store.state.company.reports;
        } else {
          return [];
        }
      },
    },
    watch: {
      start_date_prov: function (newVal) {
        this.formdata.start_date = newVal.toISOString().substring(0, 10);
      },
      end_date_prov: function (newVal) {
        this.formdata.end_date = newVal.toISOString().substring(0, 10);
      },
    },
    created() {
      companyApi.get('/companies/' + store.state.company.company.id + '/reports', { notification: false }).then(response => response.data.content.length > 0 ? store.commit('company/setReports', response.data.content) : this.show.newReport = true).catch(error => error.response.data.content);
      var d = new Date();
      this.formdata.year = d.getFullYear();
    },
    methods: {
      newReport() {
        if (this.formdata.period === 0 && (this.formdata.start_date === '' || this.formdata.end_date === '')) {
          notification.warning(this.$t('companyArea.accounting.reports.noPeriod'));
          return;
        }
        companyApi.post('/companies/' + store.state.company.company.id + '/reports/create', this.formdata, {
          'progress': true, notification: true,
        }).then(response => response.data.notificationType === 'success' ? [store.commit('company/setReports', response.data.content), this.show.newReport = false] : '').catch(error => error.response.data.content);
      },
      deleteReport(report) {
        store.dispatch('company/deleteReport', report);
      },
      reportUrl(report) {
        return import.meta.env.VITE_VUE_APP_BACKEND_API_URL + '/company-backend/companies/' + store.state.company.company.id + '/reports/' + report.id;
      },
    },
  };
</script>

<style scoped>

</style>
