<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full text-lg font-semibold' : field.class, show ? 'showField' : 'hidden']">
    <div :id="'titleText' + field.id"
         class="form-title text-center relative mt-5"
         :data-gtm-field-label="field.label"
         :data-gtm-field-id="field.id"
         @blur="logFieldBlur($event.target.value)"
         @click="editFields"
    >
      <span class="bg-white px-4 text-lg font-semibold relative z-10">{{ field.label }}</span>
      <div class="absolute top-1/2 left-0 w-full h-[1px] bg-gray-400" />
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    name: 'TitleText',
    mixins: [formcreatorMixin],

    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
    mounted() {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: this.field.label });
    },
    methods: {
    },
  };
</script>
