<template>
  <div v-if="company.weekly_refill_amount || mainPostfinancePaymentMethod || mainStripePaymentMethod" class="panel">
    <div class="text-base font-semibold">{{ $t('companyArea.accounting.credit.automaticRefillTitle') }}</div>
    <p class="text-xs sm:text-sm text-gray-400">{{ $t('companyArea.accounting.credit.automaticRefill') }}</p>

    <div v-if="company.weekly_refill_amount" class="mt-3">
      {{
        $t('companyArea.accounting.credit.automaticRefillActivated', {
          currency: company.currency,
          amount: company.weekly_refill_amount,
        })
      }}
    </div>
    <div v-else class="mt-3" v-html="$t('companyArea.accounting.credit.automaticRefillDeactivated')" />

    <button v-if="company.weekly_refill_amount === null"
            class="hidden sm:flex btn btn-sm btn-secondary mt-4"
            data-cy="btn-add-automatic-refill"
            @click="showForm = true"
    >
      {{ $t('general.activate') }}
    </button>
    <button v-else
            class="hidden sm:flex btn btn-sm btn-secondary mt-4"
            data-cy="btn-add-automatic-refill"
            @click="deactivateAutomaticRefill"
    >
      {{ $t('general.deactivate') }}
    </button>

    <confirmation-modal v-if="showForm" theme="white" @close="showForm = false">
      <div class="flex justify-center mt-3">
        <div class="flex flex-col w-full justify-center mt-3">
          <p class="text-xl font-semibold mb-8">{{ $t('companyArea.accounting.credit.automaticRefillTitle') }}</p>
          <p class="text-sm font-light mb-4">{{ $t('companyArea.accounting.credit.automaticRefillExplanation') }}</p>

          <div v-if="(company.postfinance_tokens.length + company.stripe_payment_methods.length) > 0">
            <p class="text-sm font-light mb-1">{{ $t('companyArea.accounting.credit.automaticRefillCardLabel') }}:</p>
            <stripe-payment-method-info v-if="mainStripePaymentMethod" :payment-method="mainStripePaymentMethod" />
            <postfinance-token-info v-if="mainPostfinancePaymentMethod" :token="mainPostfinancePaymentMethod" />
          </div>

          <p v-if="formRefill.showMinAmount">{{ $t('companyArea.accounting.credit.automaticRefillMinimum', { currency: company.currency, amount: formRefill.minAmount }) }}</p>

          <div class="relative my-4">
            <input v-model="formRefill.amount"
                   type="number"
                   class="pl-12 w-full border border-gray-400"
                   :placeholder="$t('companyArea.accounting.credit.enterAmount')"
                   data-cy="input-amount"
                   @keyup.enter="submitAutomaticRefill"
            >

            <div class="absolute left-2 top-1.5 text-xxs z-10">
              {{ company.currency }}
            </div>
          </div>

          <p v-if="formRefill.amount > 0" class="text-center mt-2 text-red-600">{{ $t('companyArea.accounting.credit.feesIncluded') }} CHF {{ currentCardFee }}</p>

          <div class="flex justify-center mt-3">
            <button class="btn btn-lg btn-secondary" data-cy="btn-top-up-add" @click="submitAutomaticRefill">{{ $t('general.activate') }}</button>
          </div>
        </div>
      </div>
    </confirmation-modal>
  </div>
</template>
<script>
    import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
    import companyApi from '../../../connections/company';
    import store from '../../../store/mainStore';
    import notification from '../../../connections/notification';
    import StripePaymentMethodInfo from './StripePaymentMethodInfo.vue';
    import PostfinanceTokenInfo from './PostfinanceTokenInfo.vue';

    export default {
        name: 'AutomaticRefill',
        components: { PostfinanceTokenInfo, StripePaymentMethodInfo, ConfirmationModal },
        props: {
            company: {},
        },
        data() {
            return {
                showForm: false,
                formRefill: {
                    amount: null,
                    minAmount: 25,
                    showMinAmount: false,
                    currency: store.state.company.company.currency,
                },
            };
        },
        computed: {
            currentCardFee() {
              if (this.formRefill.amount > 0) {
                  if (this.mainStripePaymentMethod) {
                      return (this.formRefill.amount * 1.029 + 0.30).toFixed(2);
                  }  else {
                      return (this.formRefill.amount * 1.017 + 0.20).toFixed(2);
                  }
              } else {
                  return 0;
              }
            },
            mainPaymentMethod() {
              if (this.mainStripePaymentMethod) {
                  return this.mainStripePaymentMethod;
              }  else {
                    return this.mainPostfinancePaymentMethod;
              }
            },
            mainStripePaymentMethod() {
                if (this.company.stripe_payment_methods.length > 0) {
                    return this.company.stripe_payment_methods.find(obj => obj.main == true);
                } else {
                    return null;
                }
            },
            mainPostfinancePaymentMethod() {
                if (this.company.postfinance_tokens.length > 0) {
                    return this.company.postfinance_tokens.find(obj => obj.main == true);
                } else {
                    return null;
                }
            },
        },
        methods: {
            deactivateAutomaticRefill() {
                this.formRefill.amount = null;
                this.submitAutomaticRefill();
            },
            submitAutomaticRefill() {
                if (this.formRefill.amount !== null && this.formRefill.amount < this.formRefill.minAmount) {
                    this.formRefill.showMinAmount = true;
                    return;
                }
                this.showForm = false;
                companyApi.patch('/companies/' + this.company.id + '/credit/automatic-refill/', this.formRefill)
                    .then(response => response.data.notificationType === 'success'
                        ? [store.commit('company/setCompany', response.data.content.company)]
                        : [notification['error'](response.data.message)]);
            },
        },
    };
</script>
