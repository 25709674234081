import { formcreatorMixin } from '../../../plugins/mixin';
<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <input :id="'field' + field.id"
           v-model="formdata['phone']"
           :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
           :data-cy="'phoneStaticField'+field.id"
           :data-gtm-field-label="field.label"
           :data-gtm-field-id="field.id"
           :placeholder="field.label"
           :required="show && !!field.required"
           :state="labelState"
           class="mainform-color mainform-input"
           type="tel"
           @blur="logFieldBlur($event.target.value)"
           @focus="editFields"
    >
    <label-state-icon :label-state="labelState" :required="true" />
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'PhoneStaticField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState() {
        if (this.field.required) {
          return this.formdata['phone'] !== null && this.formdata['phone'].toString().length > 0;
        } else {
          return this.formdata['phone'] !== null && this.formdata['phone'].toString().length > 0 ? true : null;
        }
      },
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
        }, deep: true,
      },
    },
    methods: {},
  };
</script>
