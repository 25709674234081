<template>
  <div class="panel">
    <vue-cal class="vuecal--rounded-theme vuecal--new-leads"
             xsmall
             :events="events"
             hide-view-selector
             :time="false"
             active-view="month"
             :disable-views="['week']"
             :locale="locale"
             today-button
             style="width: 100%;height: 300px"
             @cell-click="checkDateFilter($event)"
    >
      <template v-slot:today-button>
        <div v-if="dateFilter" class="btn btn-secondary btn-sm" @click="$emit('update:dateFilter', null)">{{ $t('companyArea.leads.leadOverview.snippets.overviewCalendar.all') }}</div>
      </template>
    </vue-cal>
  </div>
</template>

<script>
  import VueCal from 'vue-cal';
  import 'vue-cal/dist/vuecal.css';
  import { getCurrentLocaleForVueCal } from '../../../../../plugins/i18n';
  import dayjsHelper from '../../../../../plugins/dayjsHelper';

  export default {
    name: 'OverviewCalendar',
    components: { VueCal },
    props: {
      events: Array,
      dateFilter: Date,
    },
    emits: ['update:dateFilter'],
    computed: {
      locale() {
        return getCurrentLocaleForVueCal();
      },
    },
    methods: {
      checkDateFilter(event) {
        if (this.events.find(obj => dayjsHelper.dayjs(obj.date).isSame(event, 'day'))) {
          this.$emit('update:dateFilter', event);
        }
      },
    },

  };
</script>

<style scoped>

</style>
