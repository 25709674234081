<template>
  <div class="panel">
    <edit-lead-container :filtered-input-fields="fields"
                         :lead="lead"
                         mode="extendedForm"
                         @success="$emit('success', 'ExtendedForm')"
    />
  </div>
</template>

<script>
  import EditLeadContainer from '../EditLead/EditLeadContainer.vue';

  export default {
    name: 'ExtendedForm',
    components: { EditLeadContainer },
    props: {
      lead: Object,
      showDesiredQuotes: Boolean,
    },
    emits: ['success'],
    computed: {
      fields() {
        let fields = this.lead.form.fields.filter(obj => obj.extended_form);
        if (this.showDesiredQuotes) {
          // puts the quotesMetaField on top of the array
          let quotesMetaField = this.lead.form.fields.filter(obj => obj.type === 'quotesMetaField');
          fields = quotesMetaField.concat(fields);
        }
        return fields;
      },
    },
  };
</script>

<style scoped>

</style>
