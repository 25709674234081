import { createStore } from 'vuex';
import companyApi from '../connections/company';
import leadFormApi from '../connections/leadForm';
import authApi from '../connections/auth';

const getDefaultState = () => {
  return {
    companyLeads: [],
    currentLead: {},
    leads: [],
    sendingSchedules: [],
    currentCompanyLead: {},
    companiesOfLead: [],
    vipanyReviews: [],
    trustedShopsReviewUrl: '',
    userReviews: [],
  };
};

const CustomerStore = {
  namespaced: true,
  state: getDefaultState(),

  actions: {
    getCustomerCompanyLeads(context, user_id) {
      leadFormApi.get('/users/' + user_id + '/leads', { notification: false, progress: false })
        .then(response => {
          if (response.data.content.companyLeads.length > 0) {
            const sortedCompanyLeads = response.data.content.companyLeads.sort((a, b) => {
              return new Date(b.created_at) - new Date(a.created_at);
            });
            context.commit('setCompanyLeads', sortedCompanyLeads);
          }
          if (response.data.content.leads.length > 0) {
            context.commit('setCurrentLead', response.data.content.leads[0]);
            context.commit('setLeads', response.data.content.leads);
          }
        });
    },
    getCompanyFinderData(context, leadUuid) {
      leadFormApi.get('leads/' + leadUuid + '/company-finder')
          .then(response => {
            context.commit('setCurrentLead', response.data.content.lead);
          });
    },
    getSendingSchedules(context, leadUuid) {
      const fetchSendingSchedules = (waitTime = 50) => {
        leadFormApi.get('leads/' + leadUuid + '/sending-schedules')
          .then(response => {
            const sendingSchedules = response.data.content.sendingSchedules;
            waitTime = Math.min(waitTime*2, 10000);
            if (sendingSchedules === null || typeof sendingSchedules === 'undefined' || sendingSchedules.length === 0) {
              setTimeout(() => fetchSendingSchedules(waitTime), waitTime);
            } else {
              context.commit('setSendingSchedules', sendingSchedules);
            }
          })
          .catch(error => {
            console.error('Error fetching sendingSchedules:', error);
            setTimeout(() => fetchSendingSchedules(waitTime), waitTime);
          });
      };

      fetchSendingSchedules();
    },
    getCustomerCurrentLead(context, meta) {
      leadFormApi.get('/users/' + meta[0] + '/leads/' + meta[1], { notification: false, progress: false })
          .then(response => context.commit('setCurrentLead', response.data.content));
    },
    getCompaniesOfLead(context, lead) {
      companyApi.get('leads/' + lead.id + '/companies/', { notification: false, progress: false })
          .then(response => context.commit('setCompaniesOfLead', response.data.content));
    },
    confirmedJob(context, meta) {
      leadFormApi.patch('/company-leads/' + meta[0] + '/customer/confirmed-job', {}, { 'progress': true })
          .then(response => response.data.notificationType === 'success' ? context.commit('setCompanyLeads', response.data.content) : '');
    },
    rejectedJob(context, meta) {
      leadFormApi.patch('/company-leads/' + meta[0] + '/customer/rejected-job', {}, { 'progress': true })
          .then(response => response.data.notificationType === 'success' ? context.commit('setCompanyLeads', response.data.content) : '');
    },
    setInfoStatus(context, infoStatus) {
      authApi.patch('/users/' + context.rootState.main.user.id + '/info-status/' + infoStatus, {}, {
        notification: false,
        progress: false,
      }).then(response => context.commit('setUser', response.data.content, { root: true }));
    },
    findCurrentLead(context, lead_uuid) {
      context.commit('setCurrentLead', context.state.companyLeads.find(obj => obj.lead_uuid === lead_uuid).lead);
    },
    findCurrentCompanyLead(context, companyLeadData) {
      context.commit('setCurrentCompanyLead', context.state.companyLeads.find(obj => obj.lead_uuid === companyLeadData[0] && obj.company_id === parseInt(companyLeadData[1])));

    },
    getVipanyReviews(context, user_id) {
      leadFormApi.get('/users/' + user_id + '/vipany-reviews', {
        notification: false,
        progress: false,
      }).then(response => [context.commit('setVipanyReviews', response.data.content.vipanyReviews, context.commit('setTrustedShopsReviewUrl', response.data.content.trustedShopsReviewUrl))]);
    },
    getUserReviews (context, user_id) {
      companyApi.get('/users/' + user_id + '/company-reviews', { notification: false, progress: false }).
      then(response => [context.commit('setUserReviews', response.data.content.userReviews)]);
    },
    updateCompanyTag(context, companyTag) {
      let companyLeads = JSON.parse(JSON.stringify(context.state.companyLeads));
      companyLeads.find(obj => obj.company_id === companyTag.company_id).lead.company_tags = []
      companyLeads.find(obj => obj.company_id === companyTag.company_id).lead.company_tags.push(companyTag)
      context.commit('setCompanyLeads', companyLeads)
    }
  },

  mutations: {
    setCompanyLeads(state, companyLeads) {
      state.companyLeads = companyLeads;
    },
    setLeads(state, leads) {
      state.leads = leads;
    },
    setCurrentLead(state, lead) {
      console.log('set current lead '  + lead.uuid);
      state.currentLead = lead;
    },
    setSendingSchedules(state, sendingSchedules) {
      state.sendingSchedules = sendingSchedules;
    },
    setCurrentCompanyLead(state, companyLead) {
      state.currentCompanyLead = companyLead;
      state.currentLead = companyLead.lead;
    },
    setCompaniesOfLead(state, companies) {
      state.companiesOfLead = companies;
    },
    setCompanyOfLead(state, company) {
      state.companiesOfLead[company['companyIndex']] = company['company'];
    },
    resetState(state) {
      // Merge rather than replace, so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setVipanyReviews(state, vipanyReviews) {
      state.vipanyReviews = vipanyReviews;
    },
    setUserReviews (state, userReviews) {
      state.userReviews = userReviews;
    },
    setTrustedShopsReviewUrl(state, url) {
      state.trustedShopsReviewUrl = url;
    },
  },
};
export default CustomerStore;
