<template>
  <div v-if="showPrice" class="mt-2">
    <div class="text-gray-500 text-xs">{{ $t('companyArea.shop.currentPrice') }}</div>
    <div class="font-bold text-xl -mt-1">CHF {{ currentPrice }}</div>
  </div>
  <!--  <div class="my-5">-->
  <!--    <div class="relative w-full bg-gray-300 h-7">-->
  <!--      <div class="absolute -right-1 -top-4 text-gray-500 text-xxs">CHF {{ maxPrice.price }}</div>-->
  <!--      <div class="absolute -right-1 -bottom-4 text-gray-500 text-xxs">{{ maxPrice.amount_limit }}</div>-->

  <!--      <div v-for="price in product.shop_company_prices" class="absolute border-r border-gray-400 h-7" :style="{ width: calcWidth(price.amount_limit) + '%' }">-->
  <!--        <div v-if="nextLevel && price.amount_limit === nextLevel.amount_limit && price.amount_limit !== maxPrice.amount_limit" class="absolute -right-3 -top-4 text-gray-500 text-xxs">{{ price.price }}</div>-->
  <!--        <div v-if="nextLevel && price.amount_limit === nextLevel.amount_limit && price.amount_limit !== maxPrice.amount_limit" class="absolute -right-3 -bottom-4 text-gray-500 text-xxs">-->
  <!--          {{-->
  <!--            price.amount_limit-->
  <!--          }}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div v-if="amount > 0" class="absolute bg-blue-600 h-7 opacity-20" :style="{ width: calcWidth(orderedAmount + amount) + '%' }" />-->
  <!--      <div class="absolute bg-blue-600 h-7 opacity-70" :style="{ width: calcWidth(orderedAmount) + '%' }" />-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div>-->
  <!--    <div class="font-bold text-xl -mb-1">{{ orderedAmount + amount }}</div>-->
  <!--    <div v-if="amount > 0" class="text-gray-500 text-xs">{{ $t('companyArea.shop.newOrderAmount') }}</div>-->
  <!--    <div v-else class="text-gray-500 text-xs">{{ $t('companyArea.shop.currentOrderAmount') }}</div>-->
  <!--  </div>-->
  <!--  <div v-if="nextLevel && nextLevel.price < currentPrice && !showPrice" class="mt-3 text-red-600">-->
  <!--    {{ $t('companyArea.shop.reachingNextLevel', { missing: nextLevel.amount_limit - (orderedAmount + amount), nextLevel: nextLevel.price }) }}-->
  <!--  </div>-->

  <div v-if="!showPrice" class="mt-3 text-red-600 font-semibold">
    {{ $t('companyArea.shop.refundExplained') }}
  </div>
</template>

<script>

  import { shopMixin} from '../../../plugins/mixin';

  export default {
    name: 'ProductChart',
    mixins: [shopMixin],
    props: {
      product: {
        type: Object,
      },
      currentProduct: {
        type: Object,
      },
      bulkOrder: {
        type: Object,
      },
      amount: {
        type: Number,
        default: 0
      },
      showPrice: {
        type: Boolean,
        default: true
      }
    },
    emits: ['current-product'],
    data() {
      return {
        maxPrice: {},
      };
    },
    computed: {

    },
    created() {
      let sortedPrices = [...this.product.shop_company_prices].sort((a, b) => b.amount_limit - a.amount_limit);
      this.maxPrice = sortedPrices[0];
    },
    methods: {
      calcWidth(amount) {
        let calc = 100 / this.maxPrice.amount_limit * amount
        return calc >= 100 ? 100 : calc;
      },
    },

  };
</script>

<style scoped>

</style>
