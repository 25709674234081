<template>
  <div id="order" class="border rounded grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-6 pt-3 order-1 md:order-2">
    <!-- "Umzugsbox Standard" und Masse -->
    <div class="col-span-2 order-1 lg:order-2">
      <div class="font-semibold text-xl pl-2">{{ product.name }}</div>
      <div class="text-gray-500 pl-2">{{ $t('companyArea.shop.products.dimensions', { dimensions: product.dimensions }) }}</div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
        <!-- "Jetzt bestellen" -->
        <div class="order-1 md:order-2 p-2  bg-gray-100 md:bg-transparent">
          <div class="text-base font-semibold">{{ $t('companyArea.shop.orderNow') }}</div>
          <div class="flex items-end">
            <div class="text-2xl text-red-600 font-bold">CHF {{ currentPrice }}</div>
            <div class="font-semibold mb-1 ml-2 text-xs">{{ $t('general.perItem') }}</div>
          </div>
          <div class="font-light text-xs">{{ $t('general.vatExcluded') }}</div>
          <div class="flex flex-wrap mt-6">
            <div v-for="suggestion in suggestions" class="px-2 text-xs border rounded-md mr-3 mb-3 bg-gray-50  hover:bg-gray-100 cursor-pointer" @click="amount = suggestion">{{ suggestion }}</div>
          </div>
          <div>
            <input v-model="amount"
                   type="number"
                   class="rounded w-full"
                   :class="{ 'border-red-600':(error)}"
            >
          </div>
          <div v-if="amount > 0 && amount < 200" class="text-xs text-red-600 mt-2">
            {{ $t('companyArea.shop.minimumOrderQuantity') }}
          </div>
          <div v-if="clicheToBuy" class="mt-3">
            <label class="inline-flex items-center">
              <input v-model="cliche"
                     type="checkbox"
                     class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
              >
              <span class="ml-1 mr-3" v-html="$t('companyArea.shop.productOrder.ownLabelCost', {price: clicheToBuy.shop_company_prices[0].price})" />
            </label>
          </div>

          <div class="flex justify-between mt-5 font-semibold text-base">
            <div>{{ $t('general.total') }}</div>
            <div>CHF {{ total }}</div>
          </div>
          <div class="flex justify-center mt-5 pb-8 relative">
            <button class="btn btn-md btn-primary w-full" @click="addToCart">{{ $t('general.addToCart') }}</button>
          </div>

          <product-chart :product="product"
                         :bulk-order="bulkOrder"
                         :amount="amount"
                         :show-price="false"
          />
        </div>

        <div class="order-2 md:order-1 p-2">
          <div class="text-base font-semibold">{{ $t('companyArea.shop.productOrder.pricesInChf') }}</div>
          <div class="mt-2">
            <div v-for="price in product.shop_company_prices" class="flex justify-between" :class="{ 'text-blue-600': (price.price === currentPrice )}">
              <div>ab {{ price.amount_limit }} Stk.</div>
              <div><span class="font-semibold">{{ price.price }}</span> {{ $t('companyArea.shop.productOrder.byUnit') }}</div>
            </div>
          </div>
          <ul class="mt-3">
            <li>{{ $t('companyArea.shop.productOrder.unitPricesInfo') }}</li>
            <li>{{ $t('companyArea.shop.productOrder.topoffertenLogoInfo') }}</li>
          </ul>
          <div v-if="clicheToBuy">
            <div class="text-base font-semibold mt-3">{{ $t('companyArea.shop.productOrder.labeling') }}</div>
            <div class="font-semibold">{{ $t('companyArea.shop.productOrder.clicheCost', { price: clicheToBuy.shop_company_prices[0].price }) }}</div>
            <div class="mt-2 text-gray-500">{{ $t('companyArea.shop.productOrder.labelingInfo') }}</div>
            <div class="mt-2 text-gray-500">{{ $t('companyArea.shop.productOrder.clicheInfomation') }}<br></div>
          </div>
        </div>
      </div>
    </div>


    <!-- Bild -->
    <div class="order-2 lg:order-1 p-2">
      <img v-if="product.image === 'umzugsbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/umzugsbox.jpeg">
      <img v-else-if="product.image === 'Buecherbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/Buecherbox.jpeg">
      <img v-else-if="product.image === 'Kleiderbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/Kleiderbox.jpeg">
    </div>
  </div>

  <confirmation-modal v-if="clicheInfo" :close-button="true" @close="clicheInfo = false">
    <div v-if="dayjsHelper.isInMoreThanDays(bulkOrder.duedate, 18)" class="text-base">
      {{ $t('companyArea.shop.productOrder.printDataDeadline', { deadline: dayjsHelper.showDate(dayjsHelper.dayjs(bulkOrder.duedate).subtract(14, 'day')) }) }}<br><br>

      {{ $t('companyArea.shop.productOrder.printDataDisclaimer') }}
    </div>
    <div v-else class="text-base">
      {{ $t('companyArea.shop.productOrder.printDataNextBulkOrder') }}
    </div>
  </confirmation-modal>
</template>

<script>
  import ProductChart from './ProductChart.vue';
  import { shopMixin } from '../../../plugins/mixin';
  import store from '../../store/mainStore';
  import notification from '../../connections/notification';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import dayjsHelper from '../../../plugins/dayjsHelper';

  export default {
    name: 'ProductOrder',
    components: { ConfirmationModal, ProductChart },
    mixins: [shopMixin],
    props: {
      product: {
        type: Object,
      },
      relatedProducts: {
        type: Array,
      },
      bulkOrder: {
        type: Object,
      },
      area: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        error: false,
        amount: 0,
        suggestions: [200, 500, 1000, 1500],
        cliche: false,
        clicheInfo: false
      };
    },
    computed: {
      dayjsHelper() {
        return dayjsHelper
      },
      total() {
        let addCost = this.amount * this.currentPrice;
        if (this.clicheToBuy && this.cliche) {
          addCost += parseInt(this.clicheToBuy.shop_company_prices[0].price);
        }
        return addCost.toFixed(2);
      },
      clicheToBuy() {
        if (this.relatedProducts.find(item => item.name_translatekey === 'Cliche')) {
          let cliche = this.relatedProducts.find(item => item.name_translatekey === 'Cliche');
          if (store.state.shop.companyProfile && store.state.shop.companyProfile.cliche && store.state.shop.companyProfile.cliche.hasOwnProperty(cliche.id)) {
            return null;
          } else {
            return cliche;
          }
        } else return null;
      },
    },
    watch: {
      product() {
        this.refreshProduct();
      },
      bulkOrder() {
        this.refreshProduct();
      },
      cliche() {
        if(this.cliche) {
          this.clicheInfo = true
        }
      },
    },
    created() {
      this.refreshProduct();
    },
    methods: {
      addToCart() {
        if (this.amount < 200) {
          this.error = true;
        } else {
          this.error = false;
          let success = false;
          let orderProduct = { area: this.area, shop_product_id: this.product.id, amount: this.amount, price: this.currentPrice, shop_bulk_order_id: this.bulkOrder.id, name: this.product.name };
          store.dispatch('shop/addToCart', orderProduct).then(success = true);

          if (this.cliche) {
            success = false;
            orderProduct = { area: this.area, shop_product_id: this.clicheToBuy.id, amount: 1, price: this.clicheToBuy.shop_company_prices[0].price, shop_bulk_order_id: this.bulkOrder.id, name: this.clicheToBuy.name };
            store.dispatch('shop/addToCart', orderProduct).then(success = true);
          }
          if (success) {
            notification.success('Hinzugefügt');
          }
        }

      },
      refreshProduct() {
        if (store.state.shop.orderProducts.find(item => item.shop_bulk_order_id === this.bulkOrder.id && item.shop_product_id === this.product.id)) {
          this.amount = store.state.shop.orderProducts.find(item => item.shop_bulk_order_id === this.bulkOrder.id && item.shop_product_id === this.product.id).amount;
        } else {
          this.amount = 0;
        }
        if (store.state.shop.orderProducts.find(item => item.shop_bulk_order_id === this.bulkOrder.id && item.shop_product_id === this.clicheToBuy.id)) {
          this.cliche = true;
        } else {
          this.cliche = false;
        }
      },
    },
  };
</script>

<style scoped>

</style>
