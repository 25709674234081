<template>
  <div class="w-full min-h-screen flex flex-col" :class="{ 'bg-shine-mobile bg-cover sm:bg-none':bgImageMobile }" data-cy="layout">
    <header class="w-full flex flex-col justify-center items-center pt-14 sm:pt-0 pb-5 sm:pb-10 px-4"
            :class="{ 'bg-shine bg-cover shadow-inner-bottom':!bgImageMobile, 'sm:bg-shine sm:bg-cover sm:shadow-inner-bottom':bgImageMobile }"
    >
      <main-menu :company="company" />
      <slot name="header" />
    </header>

    <confirmation-modal v-if="isInactive" @close="reload">
      <p class="text-center font-semibold text-lg">{{ $t('layout.refreshSiteInfo') }}</p>
      <div class="flex justify-center mt-5">
        <button class="btn btn-lg btn-primary" @click="reload">{{ $t('layout.refresh') }}</button>
      </div>
    </confirmation-modal>

    <div class="container grid grid-cols-10 px-2 sm:px-4 mx-auto">
      <div id="1" :class="[$route.meta.childOf ? 'col-span-10 lg:col-span-7 2xl:col-span-8 lg:col-start-4 2xl:col-start-3' : 'col-span-10']">
        <validation-errors />
      </div>
      <div id="2" :class="[$route.meta.childOf ? 'col-span-10 lg:col-span-7 2xl:col-span-8 lg:col-start-4 2xl:col-start-3' : 'col-span-10']">
        <slot name="filters" />
      </div>
    </div>
    <div class="flex-grow container grid grid-cols-10 px-2 sm:px-4 mx-auto">
      <div v-if="$route.meta.childOf" class="hidden col-span-3 2xl:col-span-2 lg:block pr-2">
        <sidebar-menu :company="company" />
        <slot name="sidebar-left" />
      </div>
      <div id="3" class="" :class="[$route.meta.childOf ? 'col-span-10 lg:col-span-7 2xl:col-span-8' : 'col-span-10']">
        <slot />
      </div>
    </div>

    <!--      Footer-->
    <div v-if="$route.meta.area === 'Public'" class="container mx-auto rounded-t-md bg-black text-gray-400 p-4">
      <div class="flex flex-col justify-center items-center sm:hidden mb-3">
        <a href="tel:+41445178000" class="text-lg font-bold" data-gtm="phone">
          {{ $t('menu.footer.phone') }}</a>
        <a href="tel:+41445178000" data-gtm="phone">044 517 80 00</a>
      </div>
      <div class="grid grid-cols-2 sm:grid-cols-3">
        <div>
          <div class="text-sm font-bold">{{ $t('menu.footer.information') }}</div>
          <a :href="mainUrl + '/partnership'" class="block py-2 sm:py-2" data-gtm="footer-nav-info-partnership">{{ $t('menu.footer.becomePartner') }}</a>
          <a :href="mainUrl + '/faq'" class="block py-2 sm:py-2" data-gtm="footer-nav-info-faq">{{ $t('menu.footer.faq') }}</a>
          <a :href="mainUrl + '/legal-info'" class="block py-2 sm:py-2" data-gtm="footer-nav-info-legel">{{ $t('menu.footer.legal') }}</a>
          <a :href="mainUrl + '/privacy'" class="block py-2 sm:py-2" data-gtm="footer-nav-info-privacy">{{ $t('menu.footer.privacy') }}</a>
          <a :href="mainUrl + '/conditions'" class="block py-2 sm:py-2" data-gtm="footer-nav-info-conditions">{{ $t('menu.footer.terms') }}</a>
          <a :href="mainUrl + '/cart'" class="block py-2 sm:py-2" data-gtm="footer-nav-info-cart">{{ $t('menu.footer.cart') }}</a>
        </div>
        <div class="flex flex-col items-end sm:items-start" />
        <div class="hidden sm:block" />
      </div>
    </div>
  </div>
</template>

<script>

  import MainMenu from './menu/MainMenu.vue';
  import SidebarMenu from './menu/SidebarMenu.vue';
  import ValidationErrors from './snippets/ValidationErrors.vue';
  import ConfirmationModal from './snippets/ConfirmationModal.vue';
  import store from './store/mainStore';
  import * as packageJson from './../../package.json';

  export default {
    name: 'Layout',
    components: { ConfirmationModal, ValidationErrors, SidebarMenu, MainMenu },
    props: {
      bgImageMobile: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        app: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY,
        mainUrl: import.meta.env.VITE_VUE_APP_LANDINGPAGE_URL,
        userActivityThrottlerTime: 7200000,
        isInactive: false,
        userActivityThrottlerTimeout: null,
        userActivityTimeout: null,
        company: store.state.company.company,
      };
    },
    head() {
      return {
        title: {
          inner: this.appName,
          complement: 'Member area',
        },
        link: [
          { r: 'icon', h: '/src/assets/images/' + this.app + '/favicon-32x32.png', sz: '32x32', t: 'image/png' },
          { r: 'icon', h: '/src/assets/images/' + this.app + '/favicon-16x16.png', sz: '16x16', t: 'image/png' },
          { r: 'icon', h: '/src/assets/images/' + this.app + '/android-chrome-192x192.png', sz: '192x192', t: 'image/png' },
          { r: 'icon', h: '/src/assets/images/' + this.app + '/android-chrome-512x512.png', sz: '512x512', t: 'image/png' },
          { r: 'icon', h: '/src/assets/images/' + this.app + '/favicon.ico', t: 'image/x-icon' },
          { r: 'apple-touch-icon', h: '/src/assets/images/' + this.app + '/apple-touch-icon.png' },
        ],

      };
    },
    computed: {
      myVipanyBackendVersion() {
        return store.state.main.myVipanyBackendVersion;
      },
    },
    watch: {
      myVipanyBackendVersion: {
        handler() {
          this.checkVersionUpdate(packageJson.version, store.state.main.myVipanyBackendVersion);
        },
      },
    },
    created() {
      if (this.$route.meta.requiresAuth) {
        this.activateActivityTracker();
      }
    },
    methods: {
      activateActivityTracker() {
        window.addEventListener('mousemove', this.userActivityThrottler);
        window.addEventListener('keydown', this.userActivityThrottler);
      },
      resetUserActivityTimeout() {
        clearTimeout(this.userActivityTimeout);
        this.userActivityTimeout = setTimeout(() => {
          this.isInactive = true;
        }, this.userActivityThrottlerTime);
      },
      userActivityThrottler() {
        if (!this.userActivityThrottlerTimeout) {
          this.userActivityThrottlerTimeout = setTimeout(() => {
            this.resetUserActivityTimeout();

            clearTimeout(this.userActivityThrottlerTimeout);
            this.userActivityThrottlerTimeout = null;
          }, this.userActivityThrottlerTime);
        }
      },
      reload() {
        location.reload();
      },

      /**
       * Check if the user has to reload the page, because my-vipany is out of date. We compare on semver MAJOR & MINOR, not on PATCH
       * -> compare 1.0.0 - 1.1.0 = outdated, while 1.0.0 - 1.0.1 = ok
       *
       * @param myVipanyVersion           The version from my-vipany package.json
       * @param myVipanyBackendVersion    The version which does the backend send with the responses - it gets the version from the my-vipany deployment
       */
      checkVersionUpdate(myVipanyVersion, myVipanyBackendVersion) {
        if (myVipanyVersion && myVipanyBackendVersion) {
          const myVipVer = myVipanyVersion.split('.');
          const myVipBackVer = myVipanyBackendVersion.split('.');
          if (myVipVer.shift() < myVipBackVer.shift()) {
            this.isInactive = true;
          } else if (myVipVer.shift() < myVipBackVer.shift()) {
            this.isInactive = true;
          }
        }
      },
    },
  };
</script>

<style scoped>
</style>
