<template>
  <div class="flex flex-col items-start border border-gray-200 bg-gray-50 pl-5 pt-3 rounded-lg">
    <span class="flex font-semibold justify-center pb-2">{{ translatedGroupLabel }}</span>
    <button class="grid bg-gray-100 border border-gray-400 rounded h-24 w-24 content-center">
      <slot name="image">
        <img src="../../../assets/images/icons/umzug.svg" :alt="translatedGroupLabel">
      </slot>
    </button>

    <div class="flex flex-wrap flex-col justify-center mt-3 mb-8">
      <form-sub-classification :formdata="formdata"
                               :classifications="forms"
                               @change-classification="changeClassification"
      />
    </div>
  </div>
</template>
<script>
  import FormSubClassification from './FormSubClassification.vue';

  export default {
    name: 'FormClassificationGroup',
    components: { FormSubClassification },
    props: {
      groupLabel: {
        type: String,
        default: 'umzug',
      },
      formdata: {},
      forms: {},
      group: {},
    },
    emits: ['change-classification'],
    computed: {
      translatedGroupLabel() {
        switch (this.groupLabel) {
          case 'umzug':
            return this.$t('companyArea.components.formClassifications.moving');
          case 'reinigung':
            return this.$t('companyArea.components.formClassifications.cleaning');
          case 'maler':
            return this.$t('companyArea.components.formClassifications.painter');
          case 'boden':
            return this.$t('companyArea.components.formClassifications.floorTiler');
        }
      },
    },
    methods: {
      changeClassification(classification) {
        this.$emit('change-classification', classification);
      },
    },
  };
</script>
