<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.pauseAccount.title') }}</p>
    </template>

    <div class="panel">
      <div class="flex flex-col justify-between">
        <div class="flex justify-between">
          <div class="text-lg font-semibold">
            {{ $t('companyArea.settings.pauseAccount.statusInfo') }}
            <span v-if="company.no_leads_wanted === 1" class="text-red-600">{{ $t('companyArea.settings.pauseAccount.notActive') }}</span>
            <span v-else class="text-green-500">{{ $t('companyArea.settings.pauseAccount.active') }}</span>
          </div>
          <div v-if="company.no_leads_wanted === 0">
            <font-awesome-icon :icon="['fas', 'check']" class="text-lg text-green-500" />
          </div>
        </div>
        <div class="my-4">{{ $t('companyArea.settings.pauseAccount.description')}}</div>
        <div class="mt-3">
          <div v-if="company.no_leads_wanted === 1">
            <button class="btn btn-primary btn-sm" @click="submit('activate')">{{ $t('companyArea.settings.pauseAccount.activate') }}</button>
          </div>
          <div v-else>
            <button class="btn btn-primary btn-sm" @click="submit('deactivate')">{{ $t('companyArea.settings.pauseAccount.deactivate') }}</button>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import companyApi from '../../connections/company';

export default {
  name: 'PauseAccount',
  components: { Layout },
  data() {
    return {
      formdata: {
        activation: ''
      }
    }
  },
  computed: {
    company() {
      return store.state.company.company;
    },
  },
  methods: {
    submit(activation) {
      this.formdata.activation = activation;
      companyApi.patch('/companies/' + this.company.id + '/mail-activation', this.formdata).then(response => store.commit('company/setCompany', response.data.content.company));
    },
  },
};
</script>

<style scoped>

</style>
