<template>
  <div class="relative">
    <input id="email"
           v-model="company.email"
           :placeholder="$t('companyArea.components.contact.companyContact.email')"
           required
           type="email"
           class="border border-gray-400 w-full mt-2"
           @blur="checkEmail"
    >
    <font-awesome-icon v-if="stateEmail(company.email)" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500" />
    <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base " />
  </div>
  <div class="relative">
    <input id="phone"
           v-model="company.phone"
           :placeholder="$t('companyArea.components.contact.companyContact.phone')"
           required
           class="border border-gray-400 w-full mt-2"
           type="text"
    >
    <font-awesome-icon v-if="statePhone(company.phone)" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500" />
    <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base " />
  </div>
</template>

<script>
import companyApi from '../../../connections/company';

export default {
  name: 'CompanyContact',
  props: {
    company: Object,
  },
  data() {
    return {
      emailLastValue: null,
      errors: {},
    };
  },
  watch: {
    errors: {
      handler(newVal) {
        if(Object.keys(newVal).length > 0) {
          // for (let [key, value] of Object.entries(newVal)) {
          // notification.warning(value,key.charAt(0).toUpperCase() +
          //   key.slice(1));
          // }
        }
      }, deep: true,
    },
  },
  mounted() {
    this.emailLastValue = this.company.email;
  },
  methods: {
    checkEmail() {
      this.errors = {};
      if(this.company.email && this.emailLastValue !== this.company.email) {
        companyApi.post('companies/email-checker', this.company, { 'progress': true, 'progressMessage': 'Wir überprüfen Ihre Email.' }).
            catch(error => [this.errors = error.response.data.errors, console.log('errorx: ' + error.response.data.errors)]);
      }

      this.emailLastValue = this.company.email;
    },
    stateEmail(val) {
      if(this.errors.hasOwnProperty('email')) { return false; } else if(val.length > 0) { return true; } else { return null; }
    },
    statePhone(val) {
      if(val.length > 0) { return true; } else { return null; }
    },
  },

};
</script>

<style scoped>

</style>
