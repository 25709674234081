<template>
  <div v-show="show" :class="mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : 'hidden'" @blur="logFieldBlur($event.target.value)" />
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    name: 'Seperator',
    mixins: [formcreatorMixin],

    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
    mounted() {
      Object.assign(this.formdata.dynamic_form, { [this.field.id]: '' });
    },
    methods: {},
  };
</script>
