<template>
  <div class="flex flex-col justify-center px-3 mt-6 mb-4 sm:mb-0">
    <p v-if="newLeadText" class="text-center text-gray-500 mt-10">{{ $t('customerArea.leadButtons.newLeadText') }}</p>
    <div v-if="lead.hasOwnProperty('id')" class="flex flex-wrap justify-center mt-1">
      <button v-if="editLead"
              class="btn btn-md btn-secondary mt-2 mx-1"
              data-cy="btn-edit-lead"
              @click="$router.push({ name: 'EditLead', params: { lead_uuid: lead.uuid } })"
      >
        {{ $t('customerArea.leadButtons.editLead') }}
      </button>
      <button v-if="cancelLead"
              class="btn btn-md btn-secondary mx-1 mt-2"
              data-cy="cancel-lead"
              :disabled="!cancelLead"
              :class="{ 'btn-disabled':(!cancelLead)}"
              @click="show.confirm = 'cancelLead'"
      >
        {{ $t('customerArea.leadButtons.cancelLead') }}
      </button>
      <button v-if="lead.taken_by > 0 && 1 === 2"
              class="btn btn-md btn-secondary mx-1 mt-2"
              :disabled="!noCalls"
              :class="{ 'btn-disabled':(!noCalls)}"
              @click="show.confirm = 'noCalls'"
      >
        {{ $t('customerArea.leadButtons.noCalls') }}
      </button>
      <button v-if="rejectAllQuotes && 1 === 2"
              class="btn btn-md btn-secondary mx-1 mt-2"
              :disabled="!rejectAllQuotes"
              :class="{ 'btn-disabled':(!rejectAllQuotes)}"
              data-cy="reject-all-quotes"
              @click="show.confirm = 'rejectAllQuotes'"
      >
        {{ $t('customerArea.leadButtons.rejectAllQuotes') }}
      </button>
      <button v-if="deleteAccount && 1 === 2"
              class="btn btn-md btn-secondary mx-1 mt-2"
              :disabled="!deleteAccount"
              :class="{ 'btn-disabled':(!deleteAccount)}"
              data-cy="delete-account"
              @click="show.confirm = 'deleteAccount'"
      >
        {{ $t('customerArea.leadButtons.deleteAccount') }}
      </button>
      <button v-if="lead.taken_by > 0 && lead.desired_quotes > lead.taken_by"
              class="btn btn-md btn-secondary mt-2 mx-1"
              :disabled="!noMoreCompanies"
              :class="{ 'btn-disabled':(!noMoreCompanies)}"
              @click="show.confirm = 'noMoreCompanies'"
      >
        {{ $t('customerArea.leadButtons.noMoreCompanies') }}
      </button>
    </div>
  </div>

  <confirmation-modal v-if="show.confirm.length > 0" @close="show.confirm = ''">
    <div>
      <div v-if="leads && leads.length > 1" class="text-center text-xs mb-3">{{ $t('customerArea.leadButtons.onlyForLead', { form: $t(lead.form.translate_key) }) }}</div>
      <div class="text-center text-xl font-extrabold px-2">
        <div v-if="show.confirm === 'noCalls'" v-html="$t('customerArea.leadButtons.confirmNoCalls')" />
        <div v-else-if="show.confirm === 'rejectAllQuotes'" v-html="$t('customerArea.leadButtons.confirmRejectAllQuotes')" />
        <div v-else-if="show.confirm === 'deleteAccount'" v-html="$t('customerArea.leadButtons.confirmDeleteAccount')" />
        <div v-else-if="show.confirm === 'cancelLead'" v-html="$t('customerArea.leadButtons.confirmCancelLead')" />
        <div v-else-if="show.confirm === 'noMoreCompanies'" v-html="$t('customerArea.leadButtons.confirmNoMoreCompanies')" />
      </div>
      <div class="flex justify-center mt-3">
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                data-cy="confirm"
                @click="quickAction(show.confirm)"
        >
          <font-awesome-icon :icon="['fal','check']" />
          <span class="absolute -bottom-6 text-gray-900 text-xs uppercase">{{ $t('general.yes') }}</span>
        </button>
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                data-cy="reject"
                @click="show.confirm = ''"
        >
          <font-awesome-icon :icon="['fal','times']" />
          <span class="absolute -bottom-6 text-gray-900 text-xs uppercase">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </confirmation-modal>
</template>

<script>
import leadFormApi from '../../connections/leadForm';
import store from '../../store/mainStore';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

export default {
  name: 'LeadButtons',
  components: { ConfirmationModal },
  props: {
    lead: Object,
    leads: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: {
        modal: false,
        confirm: '',
        selectLead: false,
      },
    };
  },
  computed: {
    noCalls() {
      return ! this.lead.calls_unwanted_at && this.lead.taken_by > 0 && [1, 2, 3].includes(this.lead.status);
    },
    rejectAllQuotes() {
      return this.lead.taken_by > 0 && [1, 2, 3].includes(this.lead.status);
    },
    noMoreCompanies() {
      return this.lead.taken_by > 0 && this.lead.desired_quotes > this.lead.taken_by && [1, 2, 3].includes(this.lead.status);
    },
    deleteAccount() {
      // deleteaAccount is only possible if all leads are on status LostJobs
      if(this.leads !== null && this.leads.length > 0) {
        return this.leads.length === this.leads.filter(obj => obj.status === 7).length;
      } else {
        return [7].includes(this.lead.status);
      }
    },
    cancelLead() {
      // return ! dayjsHelper.isOlderThanInDays(this.lead.valid_at, 3) && [0, 1, 2, 3].includes(this.lead.status);
      return this.lead.taken_by === 0 && this.lead.status !== 5;
    },
    newLeadText() {
      return [0, 1].includes(this.lead.status) && this.lead.taken_by === 0 && this.$route.name === 'QuoteOverview';
    },
    editLead() {
      return [0, 1, 2, 3].includes(this.lead.status) && (! this.leads || this.leads.length === 1);
    },
  },
  methods: {
    quickAction(action) {
      if(action === 'noCalls') {
        leadFormApi.patch('leads/' + this.lead.id + '/no-calls', { 'progress': true, notification: true }).
            then(response => [store.commit('customer/setCompanyLeads', response.data.content.companyLeads), store.dispatch('customer/findCurrentLead', this.lead.uuid)]);
      } else if(action === 'rejectAllQuotes') {
        leadFormApi.patch('leads/' + this.lead.id + '/reject-all-quotes', { 'progress': true, notification: true }).
            then(response => [store.commit('customer/setCompanyLeads', response.data.content.companyLeads), store.dispatch('customer/findCurrentLead', this.lead.uuid)]);
      } else if(action === 'deleteAccount') {
        leadFormApi.delete('leads/' + this.lead.id + '/account-delete', { 'progress': true, notification: true }).then(response => [store.dispatch('auth/logout')]);
      } else if(action === 'cancelLead') {
        leadFormApi.patch('leads/' + this.lead.id + '/cancel-lead', { 'progress': true, notification: true }).
            then(response => [
              response.data.content['onlyLead'] ? store.commit('customer/setCurrentLead', response.data.content['leads']) : [
                store.commit('customer/setCompanyLeads', response.data.content.companyLeads),
                store.dispatch('customer/findCurrentLead', this.lead.uuid)], this.$router.push({ name: 'QuoteOverview', params: { lead_uuid: this.lead.uuid } })]);
      } else if(action === 'noMoreCompanies') {
        leadFormApi.patch('leads/' + this.lead.id + '/no-more-companies', { 'progress': true, notification: true }).
            then(response => [store.commit('customer/setCompanyLeads', response.data.content.companyLeads), store.dispatch('customer/findCurrentLead', this.lead.uuid)]);
      }
      this.show.confirm = '';
    },
  },
};
</script>

<style scoped>

</style>
