<template>
  <div>
    <p :class="{ 'suggestion-section':true, 'folder':(mode === 'customCrawling') }" @click="getClassificationTree">
      <font-awesome-icon v-if="mode === 'customCrawling'" class="fa-icon" icon="folder-plus" />
      {{ suggestion.name }}
    </p>
    <list-branch v-for="item in branchFiltered"
                 :key="item.id"
                 :formdata="formdata"
                 :suggestion="item"
                 :suggestions="suggestions"
    />
  </div>
</template>

<script>
  import store from '../../store/mainStore'
  import ListBranch from './ListBranch.vue'

  export default {
    name: 'ListSection',
    components: { ListBranch },
    props: {
      suggestion: {},
      suggestions: {},
      formdata: {},
      customCrawling: { default: false },
    },
    computed: {
      branchFiltered () {
        return this.suggestions.filter(obj => obj.parent === this.suggestion.number && obj.is_branch === 1)
      },
      mode () {
        if (this.branchFiltered.length === 0) {
          return 'customCrawling'
        } else {
          return 'suggestions'
        }

      },
    },
    methods: {
      getClassificationTree () {
        store.dispatch('company/getClassificationTree', this.suggestion)
      },
    },
  }
</script>

<style scoped>

</style>
