<template>
  <button class="btn btn-secondary flex items-center justify-center"
          :class="{'border-gray-200 text-gray-200 hover:bg-white hover:text-gray-200 hover:border-gray-200':(disabled), 'bg-yellow-200':(active), 'btn-lg':(size==='lg'), 'w-auto h-10 p-4':(size === 'normal'), 'h-8 px-3 btn-sm':(size === 'sm'), 'w-6 h-6 text-xs btn-xs':(size === 'xs') }"
          :data-cy="dataCy"
          :disabled="disabled"
  >
    <font-awesome-icon v-if="icon.length > 0" :icon="icon" class="mr-1" />
    <span :class="{'hidden md:block':(hideTextOnXs)}">{{ text }}</span>
  </button>
</template>

<script>
  export default {
    name: 'TextButton',
    props: {
      icon: {
        type: Array,
        default: () => [],
      },
      text: {
        type: String,
        default: '',
      },
      hideTextOnXs: {
        type: Boolean,
        default: false,
      },
      dataCy: {
        type: String,
        default: 'text-button',
      },
      active: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'normal',
      },
    },
  };
</script>

<style scoped>

</style>
