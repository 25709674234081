<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
      <select :id="'selectField' + field.id"
              ref="field"
              v-model="formdata['availability']"
              :data-gtm-field-label="field.label"
              :data-gtm-field-id="field.id"
              class="mainform-color mainform-select"
              :aria-label="field.label"
              @change="editFields(); logFieldBlur($event)"
      >
        <option v-for="option in options" :key="option.id" :value="option.value">{{ $t(option.text) }}</option>
      </select>
      <label-state-icon :select-field="true" :label-state="null" :required="false" />
    </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'AvailabilityStaticField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        activeOption: {},
        show: false,
        options: [
          { value: '', text: 'tableForm.fields.availability' },
          { value: 'tableForm.fields.wholeDay', text: 'tableForm.fields.wholeDay' },
          { value: 'tableForm.fields.morning', text: 'tableForm.fields.morning' },
          { value: 'tableForm.fields.afternoon', text: 'tableForm.fields.afternoon' },
          { value: 'tableForm.fields.officeHours', text: 'tableForm.fields.officeHours' },
          { value: 'tableForm.fields.noon', text: 'tableForm.fields.noon' },
          { value: 'tableForm.fields.afterWork', text: 'tableForm.fields.afterWork' },
          { value: 'tableForm.fields.weekend', text: 'tableForm.fields.weekend' },
        ],
        vipany: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany'
      };
    },

    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
    methods: {},
  };
</script>

