<template>
  <!--    <div v-if="Object.entries(form).length === 0">-->
  <div v-if="fields.length === 0">
    <button id="newForm" @click="newForm">New Form</button>
    <select id="select-type" v-model="formToCopy">
      <option v-for="form in forms" :key="'form' + form.id" :value="form.id">
        {{
          form.name }}
      </option>
    </select>
    <button @click="copyForm">Copy Form</button>
  </div>
  <div v-else>
    <edit-field :field="field"
                :fields="fields"
                :form="form"
                :form-view="formView"
                @form-existing="formExisting"
    />

    <edit-option v-if="option && formView"
                 :field="field"
                 :fields="fields"
                 @form-existing="formExisting"
    />
  </div>
</template>

<script>
  // This Component is used in FormCreatorComponent
  import store from '../store/mainStore'
  import EditField from './EditField.vue'
  import EditOption from './EditOption.vue'
  import leadFormApi from '../connections/leadForm';

  export default {
    name: 'FieldCreator',
    components: { EditField, EditOption },
    props: {
      formView: Boolean,
    },
    data () {
      return {
        forms: {},
        formToCopy: ''
      }
    },
    computed: {
      option: function () {
        return this.field.type === 'selectField' || this.field.type === 'selectFormField' || this.field.type === 'tabsFormField' || this.field.type === 'radioField'
      },
      field () {
        return store.state.formcreator.field
      },
      fields () {
        return store.state.formcreator.fields
      },
      form () {
        return store.state.formcreator.form
      },
    },
    created () {
      if(this.fields.length === 0) {
        leadFormApi.get('/countries/CH/forms', { notification: false }).then(response => this.forms = response.data.content)
      }
    },
    methods: {

      newForm () {
        store.dispatch('formcreator/newForm')
      },
      copyForm () {
        store.dispatch('formcreator/copyForm', this.formToCopy)
      },
      // Sends the newest form Data to Parent Component
      formExisting: function (event) {
        this.$emit('form-existing', event)
      },
    },

  }

</script>
