<template>
  <div class="flex justify-between mt-5">
    <div v-if="makeReview()">
      <button class="btn btn-lg btn-primary"
              :data-cy="'make-review-' + companyLead.company_id"
              @click="$router.push({ name: 'MakeReview', params: { lead_uuid: companyLead.lead_uuid, customer_company_id: companyLead.company_id } })"
      >
        {{ $t('customerArea.customerButtons.makeReview') }}
      </button>
    </div>
    <div class="flex">
      <button v-if="![3, 5].includes(companyLead.status)"
              class="btn btn-lg"
              :class="{ 'btn-primary':showQuote, 'btn-secondary':!showQuote}"
              :data-cy="'quote-accepted-' + companyLead.company_id"
              @click="confirm = 'confirmed'"
      >
        {{ $t('customerArea.customerButtons.engage') }}
      </button>
    </div>
    <div v-if="!makeReview()" class="flex items-end">
      <circle-button v-if="[1,2,3].includes(companyLead.status)"
                     :icon="['fal','thumbs-down']"
                     class="hidden sm:flex"
                     :data-cy="'quote-rejected-' + companyLead.company_id"
                     @click="confirm = 'rejected'"
      />
      <button v-if="[1,2,3].includes(companyLead.status)"
              class="btn btn-lg btn-secondary sm:hidden"
              :data-cy="'quote-rejected-' + companyLead.company_id"
              @click="confirm = 'rejected'"
      >
        {{ $t([3].includes(companyLead.status) ? 'customerArea.customerButtons.cancelJob' : 'customerArea.customerButtons.rejectQuote') }}
      </button>
    </div>
  </div>

  <div v-if="confirm.length > 0"
       class="absolute flex justify-center inset-0 bg-black/95 rounded z-50 pt-3"
       :class="{ 'bg-green-700':(confirm === 'confirmed'), 'bg-red-900':(confirm === 'rejected')}"
  >
    <div>
      <div class="text-center text-xl text-white font-extrabold px-2">
        <span v-if="confirm === 'confirmed'">{{ $t('customerArea.customerButtons.engageCompany') }}</span>
        <span v-else-if="confirm === 'rejected'">{{ $t('customerArea.customerButtons.rejectCompany') }}</span>
        <span v-else-if="confirm === 'confirmed'">{{ $t('customerArea.customerButtons.rejectConfirmedJob') }}</span>
      </div>
      <div class="flex justify-center mt-3">
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black text-black hover:text-white hover:border-black mb-4 mr-3"
                data-cy="confirm"
                @click="confirmJob(confirm)"
        >
          <font-awesome-icon :icon="['fal','check']" />
          <span class="absolute -bottom-6 text-white text-xs">{{ $t('general.yes') }}</span>
        </button>
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black text-black hover:text-white hover:border-black mb-4 ml-3"
                data-cy="reject"
                @click="confirm = ''"
        >
          <font-awesome-icon :icon="['fal','times']" />
          <span class="absolute -bottom-6 text-white text-xs uppercase">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { customerMixin } from '../../../plugins/mixin';
import CircleButton from '../../snippets/CircleButton.vue';
import store from '../../store/mainStore';

export default {
  name: 'CustomerButtons',
  components: { CircleButton },
  mixins: [customerMixin],
  props: {
    companyLead: Object,
    showQuote: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      confirm: '',
    };
  },
  methods: {
    confirmJob(action) {
      if(action === 'confirmed') {
        store.dispatch('customer/confirmedJob', [this.companyLead.id]);
      } else if(action === 'rejected') {
        store.dispatch('customer/rejectedJob', [this.companyLead.id]);
      }
      this.confirm = '';
    },
  },
};
</script>

<style scoped>

</style>
