<template>
  <p class="text-center text-base font-semibold mt-2 mb-5">{{ $t('publicArea.registerCompany.steps.classification.selectLeadCategories') }}</p>
  <form-classifications v-model:formdata-prop="formdata" :register="true" />
  <div class="flex justify-center mt-5">
    <button class="btn btn-lg btn-primary" @click="submitClassification">{{ $t('general.next') }}</button>
  </div>
</template>

<script>
  import store from '../../../store/mainStore'
  import searchBackendApi from '../../../connections/seachBackend'
  import companyApi from '../../../connections/company'
  import FormClassifications from '../../../companyArea/components/FormClassifications.vue';

  export default {
    name: 'Classification',
    components: {
      FormClassifications
    },
    emits: ['next-step'],
    data () {
      return {
        formdata: {
          selected: [],
          customSuggestions: [],
        },
        preSelection: [],
        method: 'ClassificationSuggestions',
      }
    },
    beforeCreate: function () {
      if (store.state.company.suggestions.length === 0 && store.state.main.register.classification.length === 0) {
        store.dispatch('getClassificationSuggestions')
      }
    },
    created () {
      if (store.state.main.register.classification.length > 0) {
        store.state.main.register.classification.forEach(item => searchBackendApi.get(
          '/get-classification-tree/de/' + item).then(response => [response.data[0].forEach(elmt => this.preSelection.push(elmt))]))
      }
    },

    methods: {

      selectOption: function (option) {
        if (this.formdata.selected.find(item => item.id === option.id)) {
          for (var i = 0; i < this.formdata.selected.length; i++) {
            if (this.formdata.selected[i].id === option.id) {
              this.formdata.selected.splice(i, 1);
              i--
            }
          }
        } else {
          if (option.is_service) {
            this.formdata.selected.push(option)
          }
        }
      },
      removeSuggestion (option) {
        for (var i = 0; i < this.formdata.customSuggestions.length; i++) {
          if (this.formdata.customSuggestions[i].name === option.name) {
            this.formdata.customSuggestions.splice(i, 1);
            i--
          }
        }
      },
      nextStep () {
        this.$emit('next-step')
      },
      submitClassification: function () {
        companyApi.post('/websites/' + store.state.company.website.id + '/classifications/sync/', this.formdata, { notification: false } )
          .then(response => store.commit('company/setClassifications', response.data.content));

        this.$emit('next-step')
      },

    },
  }
</script>

<style scoped>

</style>
