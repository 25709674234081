<template>
  <div class="relative" :class="[mode === 'editContent' || mode === 'extendedForm' ? 'flex w-full' : field.class, show ? 'showField' : 'hidden']">
    <input :id="'field' + field.id"
           ref="field"
           v-model="input"
           :name="createFieldName()"
           type="text"
           class="mainform-color mainform-input"
           :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
           :placeholder="placeholder"
           :data-cy="'zipCityStaticField'+field.id"
           :data-gtm-field-label="field.label"
           :data-gtm-field-id="field.id"
           autocomplete="off"
           @keyup.down="hoverCityOption('down')"
           @keyup.up="hoverCityOption('up')"
           @keydown.enter.prevent="selectCityOption(hoveredCityOption)"
           @blur="checkAndSelectCityOption($event.target.value)"
           @focus="editFields(); disableAutocomplete()"
           @input="getCityOptions"
    >
    <label-state-icon v-if="validationSign" :required="true" :label-state="labelState" />
    <div v-if="cityOptions.length > 0" class="absolute top-10 w-full border shadow bg-white font-semibold z-50">
      <div v-for="option in listedCityOptions"
           :key="field.id + option.id"
           class="w-full p-2 cursor-pointer hover:bg-blue-100"
           :class="{ 'bg-blue-100':(cityOptions.indexOf(option) === hoveredCityOption) }"
           :data-cy="'zip-'+option.zip"
           @click="selectCityOption(cityOptions.indexOf(option))"
      >
        {{ option.zip }} {{ option.city }}
      </div>
    </div>
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';
  import leadFormPreviewApi from '../../connections/leadFormPreview';

  export default {
    name: 'ZipCityStaticField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      postcodes: Array,
      field: Object,
      optionFields: Array,
      formdata: Object,
      // live, extendedForm, editContent, noForm
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
      postcode: {
        type: Number,
        default: null,
      },
      calc: {
        type: Object,
        default: {},
      },
      validationSign: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        cityOptions: [],
        show: false,
        placeholder: (this.field.label && this.field.label.length > 0) ? this.field.label : 'PLZ / Ort',
        input: '',
        hoveredCityOption: null,
        maxCityOptions: 3,
      };
    },
    computed: {
      listedCityOptions() {
        let start = 0;
        let end = this.maxCityOptions;
        if (this.hoveredCityOption === null || this.hoveredCityOption < (this.maxCityOptions - 2)) {

        } else {
          if ((this.hoveredCityOption - this.maxCityOptions + 2) < (this.cityOptions.length - this.maxCityOptions)) {
            start = this.hoveredCityOption - this.maxCityOptions + 2;
          } else {
            start = this.cityOptions.length - this.maxCityOptions;
          }
          end = start + this.maxCityOptions;
        }
        return this.cityOptions.slice(start, end);
      },
      labelState() {
        return this.formdata['postcode'] ? true : false;
      },
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          if (this.mode !== 'noForm') {
            this.validateField();
          }
          this.getIfShow();
        }, deep: true,
      },
    },
    created() {
      if (this.formdata['postcode']) {
        this.placeholder = this.formdata['postcode'] + ' ' + this.formdata['city'];
      } else if (this.postcode) {
        this.getCityOptions('initial');
      }
    },
    methods: {
      getCityOptions(event = null) {
        if (event === 'initial') {
          this.cityOptions = this.postcodes.filter(obj => obj.id === this.postcode);
          this.formdata['postcode'] = this.cityOptions[0].zip;
          this.formdata['city'] = this.cityOptions[0].city;
          this.formdata['postcode_id'] = this.cityOptions[0].id;
          this.formdata['country'] = this.cityOptions[0].country;
          this.cityOptions = [];
        } else {
          let cityOptions = [];
          if (this.input.length > 0) {
            cityOptions = this.postcodes.filter(obj => {
                return obj.zip.startsWith(this.input.toLowerCase()) ||
                  obj.city.toLowerCase().startsWith(this.input.toLowerCase()) ||
                  obj.city.toLowerCase().includes(this.input.toLowerCase()) ||
                  (obj.zip + ' ' + obj.city.toLowerCase()).startsWith(this.input.toLowerCase());
              })
              .sort((a, b) => {
                const isExactMatchA =
                  a.city.toLowerCase() === this.input.toLowerCase();
                const isExactMatchB =
                  b.city.toLowerCase() === this.input.toLowerCase();
                if (isExactMatchA && !isExactMatchB) {
                  return -1;
                }
                if (!isExactMatchA && isExactMatchB) {
                  return 1;
                }
                return 0;
              });
            // Es wird ohne Umlaute gesucht
            if (cityOptions.length === 0) {
              let inputToSearch = this.input.toLowerCase();
              let inputWithoutUmlauts = inputToSearch
                .replace(/ä/g, 'a')
                .replace(/ö/g, 'o')
                .replace(/ü/g, 'u')
                .replace(/ /g, '');

              cityOptions = this.postcodes.filter(obj => {
                const cityWithoutUmlauts = obj.city
                  .replace(/ä/g, 'a')
                  .replace(/ö/g, 'o')
                  .replace(/ü/g, 'u')
                  .replace(/ /g, '');

                return obj.zip.startsWith(inputWithoutUmlauts) ||
                  cityWithoutUmlauts.toLowerCase().startsWith(inputWithoutUmlauts) ||
                  cityWithoutUmlauts.toLowerCase().includes(inputWithoutUmlauts) ||
                  (obj.zip + ' ' + cityWithoutUmlauts.toLowerCase()).startsWith(inputWithoutUmlauts);
              });
            }
            // von hinten nach vorne wird der String bei " " oder - abgetrennt und nach einem Match gesucht
            if (cityOptions.length === 0) {
              let inputToSearch = this.input.toLowerCase();
              let inputWithoutUmlauts = inputToSearch
                .replace(/ä/g, 'a')
                .replace(/ö/g, 'o')
                .replace(/ü/g, 'u');
              while (inputWithoutUmlauts.length > 0 && cityOptions.length === 0) {
                cityOptions = this.postcodes.filter(obj => {
                  const cityWithoutUmlauts = obj.city
                    .replace(/ä/g, 'a')
                    .replace(/ö/g, 'o')
                    .replace(/ü/g, 'u').toLowerCase();

                  return obj.zip.startsWith(inputWithoutUmlauts) ||
                    cityWithoutUmlauts.startsWith(inputWithoutUmlauts) ||
                    cityWithoutUmlauts.includes(inputWithoutUmlauts) ||
                    (obj.zip + ' ' + cityWithoutUmlauts).startsWith(inputWithoutUmlauts);
                });
                if (cityOptions.length === 0) {
                  const match = inputWithoutUmlauts.lastIndexOf(' ') > inputWithoutUmlauts.lastIndexOf('-') ? inputWithoutUmlauts.lastIndexOf(' ') : inputWithoutUmlauts.lastIndexOf('-');
                  if (match !== -1) {
                    inputWithoutUmlauts = inputWithoutUmlauts.substring(0, match);
                  } else {
                    break;
                  }
                }
              }
            }
            // der String wird aufgespalten und nach einem Match gesucht
            if (cityOptions.length === 0) {
              let inputToSearch = this.input.toLowerCase();
              let inputWithoutUmlauts = inputToSearch
                .replace(/ä/g, 'a')
                .replace(/ö/g, 'o')
                .replace(/ü/g, 'u')
                .replace(/ae/g, 'a')
                .replace(/oe/g, 'o')
                .replace(/ue/g, 'u');
              const parts = inputWithoutUmlauts.split(/[\s-]+/);
              parts.filter(part => part !== '').forEach((term) => {
                for (let i = 0; i < parts.length; i++) {
                  const filteredResults = this.postcodes.filter((obj) => {
                    const cityWithoutUmlauts = obj.city
                      .replace(/ä/g, 'a')
                      .replace(/ö/g, 'o')
                      .replace(/ü/g, 'u')
                      .replace(/ae/g, 'a')
                      .replace(/oe/g, 'o')
                      .replace(/ue/g, 'u')
                      .toLowerCase();

                    return (
                      obj.zip.startsWith(term) ||
                      cityWithoutUmlauts.startsWith(term) ||
                      cityWithoutUmlauts.includes(term)
                    );
                  });

                  // Überprüfe, ob die Ergebnisse bereits in cityOptions vorhanden sind, und füge sie nur hinzu, wenn nicht
                  filteredResults.forEach((result) => {
                    if (!cityOptions.some((item) => item.id === result.id)) {
                      cityOptions.push(result);
                    }
                  });
                }
              });

            }
          }
          this.cityOptions = cityOptions;
          this.hoveredCityOption = null;
        }
      },
      clearInput() {
        this.input = '';
        this.cityOptions = [];
        this.hoveredCityOption = null;
      },
      hoverCityOption(direction) {
        if (direction === 'down') {
          if (this.hoveredCityOption === null) {
            this.hoveredCityOption = 0;
          } else {
            this.hoveredCityOption < this.cityOptions.length - 1 ? this.hoveredCityOption++ : '';
          }
        } else if (direction === 'up') {
          this.hoveredCityOption--;
          this.hoveredCityOption === -1 ? this.hoveredCityOption = null : '';

        }
      },
      selectCityOption(index) {
        if (index === null) {
          if (this.cityOptions.length === 1) {
            index = 0;
          } else {
            return;
          }
        }
        this.formdata['postcode'] = this.cityOptions[index].zip;
        this.formdata['city'] = this.cityOptions[index].city;
        this.formdata['postcode_id'] = this.cityOptions[index].id;
        this.formdata['country'] = this.cityOptions[index].country;
        this.placeholder = this.formdata['postcode'] + ' ' + this.formdata['city'];
        if (this.mode === 'live') {
          let calc = 1;
          if (this.formdata['postcode'][0] === '1') {
            calc = '1.03';
          } else if (this.formdata['postcode'][0] === '2') {
            calc = '0.872';
          } else if (this.formdata['postcode'][0] === '3') {
            calc = '1.004';
          } else if (this.formdata['postcode'][0] === '4') {
            calc = '1.041';
          } else if (this.formdata['postcode'][0] === '5') {
            calc = '1.01';
          } else if (this.formdata['postcode'][0] === '6') {
            calc = '1.076';
          } else if (this.formdata['postcode'][0] === '7') {
            calc = '0.907';
          } else if (this.formdata['postcode'][0] === '8') {
            calc = '1.087';
          } else if (this.formdata['postcode'][0] === '9') {
            calc = '0.974';
          }
          this.calc[this.field.id] = calc;
        }

        this.clearInput();

      },
      checkAndSelectCityOption(value) {
        // field.id = 0 if the field is used outside the form
        if (this.mode === 'live' && this.field.id > 0) {
          setTimeout(() => {
            if (this.cityOptions.length > 0) {
              this.updateFormData(this.cityOptions[0]);
            }

            leadFormPreviewApi.post('/leads/field/log', {
              type: 'field',
              random_id: this.formdata.random_id,
              session_id: this.formdata.session_id,
              field_id: this.field.id,
              data: value,
              valid: this.labelState !== false,
            }).then((response) => {
              if (response.data.notificationType === 'update') {
                this.updateFormData(response.data.content);
              } else {
                this.clearInput();
              }
            });
          }, 500);
        }
      },
      updateFormData(data) {
        this.formdata.postcode = data.zip;
        this.formdata.city = data.city;
        this.formdata.postcode_id = data.id;
        this.formdata.country = data.country;
        this.placeholder = `${this.formdata.postcode} ${this.formdata.city}`;
        this.clearInput();
      },
      disableAutocomplete() {
        this.$refs.field.setAttribute('autocomplete', 'off');
      },
    },

  };
</script>
