<template>
  <div v-if="register" class="mt-2">
    <div class="relative">
      <input id="name"
             v-model="form.name"
             :placeholder="$t('publicArea.registerCompany.steps.registerUserWithCompany.firstName')"
             required
             type="text"
             class="border border-gray-400 w-full mt-2"
      >
      <font-awesome-icon v-if="form.name.length > 0" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500" />
      <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base " />
    </div>
    <div class="relative">
      <input id="surname"
             v-model="form.surname"
             :placeholder="$t('publicArea.registerCompany.steps.registerUserWithCompany.lastName')"
             required
             class="border border-gray-400 w-full mt-2"
             type="text"
      >
      <font-awesome-icon v-if="form.surname.length > 0" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500" />
      <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base " />
    </div>
    <company-name v-model:company="form.company" />
    <company-city v-model:company="form.company" :location-index="0" :register="true" />
    <company-website v-model:company="form.company" :register="true" />
    <company-contact v-model:company="form.company" />
    <input id="password"
           v-model="form.password"
           :placeholder="$t('publicArea.registerCompany.steps.registerUserWithCompany.choosePassword')"
           required
           type="password"
           class="border border-gray-400 w-full mt-2"
    >
    <div class="flex justify-center mt-3">
      <button class="btn btn-lg btn-primary" @click="submit">{{ $t('general.next') }}</button>
    </div>
  </div>
  <div v-else>
    <p>{{ $t('publicArea.registerCompany.steps.registerCompany.checkEmail') }}</p>
    <p>{{ $t('publicArea.registerCompany.steps.registerCompany.sentEmailText', { email: form.email }) }}</p>
  </div>
</template>

<script>
  import store from '../../../store/mainStore';
  import CompanyContact from '../../../companyArea/components/contact/CompanyContact.vue';
  import CompanyName from '../../../companyArea/components/contact/CompanyName.vue';
  import CompanyCity from '../../../companyArea/components/contact/CompanyCity.vue';
  import CompanyWebsite from '../../../companyArea/components/contact/CompanyWebsite.vue';
  import searchBackendApi from '../../../connections/seachBackend';
  import notification from '../../../connections/notification';
  import authApi from '../../../connections/auth';

  export default {
    name: 'RegisterUserWithCompany',
    components: { CompanyWebsite, CompanyCity, CompanyName, CompanyContact },
    props: {
      companyProp: Object,
    },
    emits: ['next-step'],
    data() {
      return {
        form: {
          name: '',
          surname: '',
          password: '',
          locale: this.i18n.global.locale,
          company: {
            name: '',
            email: '',
            website: '',
            phone: '',
            locations: [
              {
                postcode_id: Number,
                address: '',
                postcode: '',
                city: '',
                headquarter: 1,
              }],
          },
        },
        register: true,
      };
    },
    computed: {
      formCompleted() {
        if (this.formIsValid()) {
          return true;
        } else {
          return false;
        }
      },
      company() {
        return this.companyProp;
      }
    },
    mounted() {
      if (this.$route.params.classifications) {
        store.commit('setRegisterClassification', JSON.parse(this.$route.params.classifications));
      }
    },
    methods: {
      formIsValid: function () {
        return this.form.company.name && this.form.company.email && this.form.company.website && this.form.company.phone && this.form.company.locations[0].address &&
          this.form.company.locations[0].postcode && this.form.company.locations[0].city;
      },
      submit: function () {
        if (this.formIsValid()) {
          authApi.post('/users/create-with-company', this.form, { notification: false }).then(response => response.data.content.valid ? [
            store.commit('setUser', response.data.content.user, { root: true }),
            this.setCompany(response.data.content),
            // GTM Event, used for Tracking
            window.dataLayer.push({
              'event': 'register_company',
              'email': response.data.content['user'].email,
            }),
            this.$emit('next-step')] : '');
        } else {
          notification.warning(this.$t('general.fieldsMissing'));
          if (!this.form.company.name) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningCompanyName'));
          } else if (!this.form.company.email) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningEmail'));
          } else if (!this.form.company.website) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningWebsite'));
          } else if (!this.form.company.phone) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningPhone'));
          } else if (!this.form.company.locations[0].address) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningAddress'));
          } else if (!this.form.company.locations[0].postcode) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningZip'));
          } else if (!this.form.company.locations[0].city) {
            notification.warning(this.$t('publicArea.registerCompany.steps.registerUserWithCompany.warningLocation'));
          }
        }
      },
      onReset(evt) {
        evt.preventDefault();
        // Reset our form values
        this.form.company.email = '';
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
      setCompany: function (data) {
        store.commit('company/setInitialCompanyData', data['company']);
        store.commit('setCompanies', data['employees']);
        store.commit('setSelectedCompany', data['company'].id);
        // if(store.state.main.register.classification === '') {
        //   searchBackendApi.post('/service-suggestion/' + store.state.company.website.id, this.form.company);
        // }
      },
    }
  };
</script>

<style scoped>

</style>
