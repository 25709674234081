<template>
  <div>
    <confirmation-modal v-if="showForm"
                        theme="white"
                        @close="closeForm"
    >
      <div class="flex justify-center mt-3">
        <div class="flex flex-col w-full justify-center mt-3">
          <div>
            <p class="text-xl font-semibold mb-8">
              {{ paymentMethodId ? $t('companyArea.accounting.credit.topupWithSavedCreditCard') : $t('companyArea.accounting.credit.topupWithCreditCard') }}
            </p>
<!--            <datatrans-payment-method-info v-if="paymentMethodId" :payment-method="datatransPaymentMethods.find(obj => obj.id === paymentMethodId)" />-->
            <p :class="formCharge.showMinAmount === false ? 'hidden text-red-700 text-lg' : 'text-red-700 text-lg'">
              {{ $t('companyArea.accounting.credit.automaticRefillMinimum', { currency: company.currency, amount: showDatatransPaymentIntent.minAmount }) }}
            </p>
            <div class="relative mt-4">
              <input v-model="formCharge.amount"
                     type="number"
                     class="pl-12 w-full border border-gray-400"
                     :placeholder="$t('companyArea.accounting.credit.enterAmount')"
                     data-cy="input-amount"
                     @keyup.enter="charge"
              >

              <div class="absolute left-2 top-1.5 text-xxs z-10">
                {{ company.currency }}
              </div>
            </div>
            <p v-if="formCharge.amount > 0" class="text-center mt-2 text-red-600">
              {{ $t('companyArea.accounting.credit.feesIncluded') }} {{
                (formCharge.amount * 1.015 + 0.29).toFixed(2)
              }}
            </p>
            <div class="flex justify-center mt-3">
              <button class="btn btn-lg btn-secondary" data-cy="btn-datatrans-payment-intent-top-up-add" @click="charge">
                {{
                  $t('companyArea.accounting.credit.topUp')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </confirmation-modal>

    <confirmation-modal v-if="transactionSuccessful" @close="closeDatatransModalAndRemoveQueryString">
      <div class="flex flex-col w-full">
        <p class="text-xl font-semibold place-self-center">
          <font-awesome-icon :icon="['fas', 'check']" style="color: #4f7a28;" />
          {{ $t('companyArea.accounting.credit.topupSuccessful') }}
        </p>
        <text-button size="lg"
                     class="place-self-center mt-5"
                     :text="$t('general.close')"
                     @click="closeDatatransModalAndRemoveQueryString"
        />
      </div>
    </confirmation-modal>

    <confirmation-modal v-if="transactionFailed" @close="closeDatatransModalAndRemoveQueryString">
      <div class="flex flex-col w-full">
        <p class="text-xl font-semibold place-self-center">
          <font-awesome-icon :icon="['fass', 'xmark']" style="color: #b51a00;" />
          {{ $t('companyArea.accounting.credit.topupFailed') }}
        </p>
        <p v-if="datatransError" class="text-lg font-semibold place-self-center mt-5 mb-2 text-red-600">{{ datatransError }}</p>
        <div class="flex justify-center">
          <button class="btn btn-lg btn-secondary my-5 w-auto mx-5" data-cy="btn-datatrans-payment-intent-failed-close" @click="closeDatatransModalAndRemoveQueryString">
            <span>{{ $t('general.close') }}</span>
          </button>
        </div>
      </div>
    </confirmation-modal>

    <spinner v-if="showSpinner" style="z-index: 9999;" />
  </div>
</template>

<script>
  import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
  import TextButton from '../../../snippets/TextButton.vue';
  import companyApi from '../../../connections/company';
  import store from '../../../store/mainStore';
  import notification from '../../../connections/notification';
  import Spinner from '../../../snippets/Spinner.vue';

  export default {
    name: 'DatatransPayment',
    components: { Spinner, ConfirmationModal, TextButton },
    props: {
      show: { type: Boolean, default: false },
      paymentMethodId: { type: Number, default: null },
      paymentIntentContent: { type: Object, default: null }, // response from server
    },
    emits: ['success', 'failed'],
    data() {
      return {
        showSpinner: false,
        transactionSuccessful: false,
        transactionFailed: false,
        errorMessage: '',
        datatransError: '',
        formCharge: {
          amount: '',
          currency: store.state.company.company.currency,
          showMinAmount: false,
          minAmount: 3,
          returnBaseUri: window.location.pathname,
          paymentMethodId: null,
        },
        showDatatransPaymentIntent: {
          creditTransactionUuid: { type: String, default: null },
          clientSecret: { type: String, default: null },
          datatransObject: { type: Object, default: null },
          datatransElements: { type: Object, default: null },
        },
      };
    },
    computed: {
      showForm: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit('update:show', value);
          this.formCharge.paymentMethodId = null;
        },
      },
      company() {
        return store.state.company.company;
      },
    },
    mounted() {
      let datatransStatus = this.$route.query.datatrans; // successful | cancel | error
      let creditTransactionUuid = this.$route.query.credit_transaction_uuid;
      let datatransTransactionId = this.$route.query.datatransTrxId;

      if (datatransStatus) {
        this.showSpinner = false;
        if (datatransStatus === 'successful') {
          this.transactionSuccessful = true;
          companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-datatrans/successful', { creditTransactionUuid: creditTransactionUuid, datatransTransactionId: datatransTransactionId }, {
            'progress': false,
            'notification': false,
            'axios-retry': { retries: 1 }
          }).then(response => [store.commit('company/setCredit', response.data.content.creditNew), this.$emit('success')]);
        } else {
          this.transactionFailed = true;
          companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-datatrans/failed', { creditTransactionUuid: creditTransactionUuid, datatransTransactionId: datatransTransactionId }, {
            'progress': false,
            'notification': false,
            'axios-retry': { retries: 1 }
          }).then(response => [this.$emit('failed')]);
        }
      }
    },
    methods: {
      toggleShowForm() {
        this.showForm = !this.showForm;
      },
      closeForm() {
        this.toggleShowForm();
        this.showMinAmount = false;
        this.formCharge.showMinAmount = false;
      },
      loadDatatransJs() {
        return new Promise((resolve, reject) => {
          try {
              const script = document.createElement('script');
              script.src = import.meta.env.VITE_VUE_APP_DATATRANS_JS_URL;
              script.async = true;
              script.onload = () => {
                resolve();
              };
              script.onerror = () => {
                reject(new Error('Failed to load the DatatransJs script.'));
              };
              document.head.appendChild(script);
          } catch (error) {
            reject(error);
          }
        });
      },
      datatransTransactionErrorCallback(error) {
        console.log('datatransPaymentIntentErrorCallback - error: ', error);
        this.showSpinner = false;
        notification['error'](this.$t('companyArea.accounting.credit.datatransLoadError'));
        this.transactionFailed = true;
        this.showForm = false;
      },
      closeDatatransModalAndRemoveQueryString() {
        this.transactionSuccessful = false;
        this.transactionFailed = false;

        let cleanURL = window.location.protocol + '//' + window.location.host + window.location.pathname;

        // Update the URL without reloading the page
        if (cleanURL !== undefined && cleanURL.length > 0 && cleanURL !== window.location.href) {
          window.history.replaceState({}, document.title, cleanURL);
        }
      },
      charge() {
        if (this.formCharge.amount < this.formCharge.minAmount) {
          this.formCharge.showMinAmount = true;
          return;
        }

        this.toggleShowForm();
        this.showSpinner = true;
        this.formCharge.paymentMethodId = this.paymentMethodId;

        // Call the backend to prepare the Datatrans Transaction and get the transactionId
        companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-datatrans/create-payment-intent', this.formCharge, {
          'progress': true,
          'notification': true,
          'axios-retry': { retries: 0 }
        }).then(response => [this.initializeDatatransTransaction(response.data.content, response.data.notification)])
          .catch(error => this.datatransTransactionErrorCallback(error));
      },
      async initializeDatatransTransaction(content, notification) {
        try {
          if (content && content.status) {
            this.showSpinner = false;
            this.showForm = false;
            if (content.status === 'succeeded') {
              this.transactionSuccessful = true;
              store.commit('company/setCredit', content.creditNew);
            } else if (content.status === 'initialized' || content.status === 'challenge_required' || content.status === 'challenge_ongoing') {
              await this.loadDatatransJs();
              this.useDatatransPaymentIntent(content.transactionId);
            } else {
              this.transactionFailed = true;
              this.datatransError = notification;
            }
          } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('No return from the Datatrans API');
          }
        } catch (error) {
          this.datatransTransactionErrorCallback(error);
        }
      },
      useDatatransPaymentIntent(transactionId) {
        Datatrans.startPayment({
          transactionId:  transactionId,
          'opened': function() {console.log('payment-form opened');},
          'loaded': function() {console.log('payment-form loaded');},
          'closed': function() {console.log('payment-page closed');},
          'error': function() {console.log('error');}
        });
      },
    },
  };
</script>
