import leadFormPreviewApi from '../components/connections/leadFormPreview.js';

export const companyMixin = {
  methods: {
    companyPublicName(company) {
      return company.label && company.label.length > 0 ? company.label : company.name;
    },
    getQuote() {
      if (this.companyLead.lead.quotes.filter(item => item.company_id === this.company.id).length > 0) {
        return this.companyLead.lead.quotes.filter(item => item.company_id === this.company.id)[0];
      } else {
        return null;
      }
    },
    validateQuoteStep2() {
      let validateStep2 = true;
      if (this.formdata.price === '') {
        this.validation.price = 'companyArea.leads.leadOverview.makeQuote.validation.noPrice';
        validateStep2 = false;
      } else if (this.formdata.price < 10) {
        this.validation.price = 'Mit diesem Preis kann etwas nicht stimmen.';
        validateStep2 = false;
      } else {
        this.validation.price = '';
      }

      if (this.formdata.type === 'atCost') {
        if (this.formdata.unit === '' || this.formdata.unit === '---') {
          this.validation.unit = 'companyArea.leads.leadOverview.makeQuote.validation.unitMissing';
          validateStep2 = false;
        } else if (this.formdata.unit.length > 50) {
          this.validation.unit = 'companyArea.leads.leadOverview.makeQuote.validation.unitWrong';
          validateStep2 = false;
        } else {
          this.validation.unit = '';
        }

        if (this.formdata.price_2 === '') {
          this.validation.price_2 = 'companyArea.leads.leadOverview.makeQuote.validation.noPrice2';
          validateStep2 = false;
        } else if (parseFloat(this.formdata.price_2) <= parseFloat(this.formdata.price)) {
          this.validation.price_2 = 'companyArea.leads.leadOverview.makeQuote.validation.price2Wrong';
          validateStep2 = false;
        } else {
          this.validation.price_2 = '';
        }
      }
      return validateStep2;
    },
    getCardComponent(companyLead) {
      if (companyLead.status === 3) {
        return 'ConfirmedQuotesCard';
      } else {
        return 'PendingLeadsCard';
      }
    },
    getCompanyLocation(locations, postcode_id) {
      return locations.find(obj => obj.postcode_id === postcode_id) ?? locations[0];
    },
  },
};

export const customerMixin = {
  methods: {
    makeReview(quoteView = false) {
      if (this.companyLead.company.company_reviews.filter(obj => obj.user_id === this.companyLead.lead.user_id).length === 0) {
        if (this.companyLead.status === 3 || this.companyLead.status === 5) {
          return true;
        } else if (this.companyLead.lead.review_mail_sent_at !== null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};

export const formcreatorMixin = {
  methods: {
    getIfShow: function () {
      if (this.isVisible) {
        if (this.field.display_hide) {
          this.show = eval(this.field.display_hide);
        } else {
          this.show = true;
        }
      } else {
        this.show = false;
      }
    },
    addFieldToFormdata: function (value = null) {
      if (!this.formdata.dynamic_form.hasOwnProperty(this.field.id) && (this.field.display_hide === null || eval(this.field.display_hide))) {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: value === null ? '' : value });
      } else if (this.formdata.dynamic_form.hasOwnProperty(this.field.id) && this.field.display_hide && !eval(this.field.display_hide)) {
        delete this.formdata.dynamic_form[this.field.id];
      }
    },
    logFieldBlur: function (value) {
      if (this.mode === 'live' && !this.formdata.logCallSending) {
        this.formdata.logCallSending = true;
        leadFormPreviewApi.post('/leads/field/log',
          { type: 'field', random_id: this.formdata.random_id, session_id: this.formdata.session_id, field_id: this.field.id, data: value, valid: this.labelState !== false });
        this.formdata.logCallSending = false;
      }
    },
    editFields: function () {
      this.$emit('field-on-focus', this.field.id);
    },
    showHideTrigger() {
      // this is a hack to trigger the watcher on the other components for the ShowHide function
      this.formdata.showHide = this.formdata.showHide === 'value1' ? 'value2' : 'value1';
    },
    validateField() {
      this.validation[this.field.id] = { step: this.field.step, valid: this.labelState !== false, key: this.field.id, sort: this.field.sort_form };
    },
    createFieldName: function () {
      if (typeof this.field.label === 'string' || this.field.label instanceof String) {
        return this.field.type + this.field.label.replace(/[^a-zA-Z]/g, '') + this.field.id;
      } else {
        return this.field.type + this.field.id;
      }
    },
  },
};

export const shopMixin = {
  computed: {
    orderedAmount() {
      let amount = 0;
      this.bulkOrder.paid_order_products.forEach(obj => {
        if (obj.shop_product_id === this.product.id) {
          amount += obj.amount;
        }
      });

      return amount;
    },
    currentPrice() {
      // Stellen Sie sicher, dass `this.product.prices` existiert und `amount` definiert ist
      if (!this.product.shop_company_prices || this.orderedAmount === undefined) {
        return null; // oder einen Standardwert zurückgeben
      }

      // Sortieren Sie die Preise nach amount_limit aufsteigend
      let sortedPrices = [...this.product.shop_company_prices].sort((a, b) => a.amount_limit - b.amount_limit);

      // Finden Sie den passenden Preis
      let currentPrice = null;
      for (let price of sortedPrices) {
        if (this.orderedAmount + this.amount < price.amount_limit) {
          break;
        } else {
          currentPrice = price.price;
        }
      }

      // Rückgabe des aktuellen Preises, wenn einer gefunden wurde, ansonsten Rückgabe eines Standardpreises
      return currentPrice !== null ? currentPrice : (sortedPrices.length > 0 ? sortedPrices[0].price : null);
    },
    nextLevel() {
      // Stellen Sie sicher, dass `this.product.prices` existiert und `amount` definiert ist
      if (!this.product.shop_company_prices || this.orderedAmount === undefined) {
        return null; // oder einen Standardwert zurückgeben
      }

      // Sortieren Sie die Preise nach amount_limit aufsteigend
      let sortedPrices = [...this.product.shop_company_prices].sort((a, b) => b.amount_limit - a.amount_limit);

      // Finden Sie den passenden Preis
      let nextLevel = null;
      for (let price of sortedPrices) {
        if (price.amount_limit <= this.orderedAmount + this.amount) {
          break;
        } else {
          nextLevel = price;
        }
      }

      // Rückgabe des aktuellen Preises, wenn einer gefunden wurde, ansonsten Rückgabe eines Standardpreises
      return nextLevel;
    },
    totalPrice() {
      let price = 0;
      let vat = 8.1;
      this.orderProducts.forEach(item => price += item.amount * item.price);

      if (this.$route.meta.area === 'Company') {
        return { price_excl: price.toFixed(2), vat: (price / 100 * vat).toFixed(2), price_incl: (price / 100 * (100 + vat)).toFixed(2) };
      } else {
        let endprice = price + this.formdata.delivery_costs
        return { price_incl: endprice.toFixed(2), vat: (endprice / 100 * vat).toFixed(2), price_excl: (price / (100 + vat) * 100).toFixed(2) };
      }
    },
  },
};
