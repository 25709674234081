<template>
  <form v-if="!loading" @submit="onSubmit">
    <div v-if="mode === 'extendedForm'" class="flex justify-end mb-3">
      <button class="btn btn-md btn-primary" data-cy="btn-submit" type="submit">{{ $t('general.next') }}</button>
    </div>

    <div v-for="field in inputFields" :key="field.id">
      <div v-if="getIfShow(field)" class="md:grid md:grid-cols-4 mt-3">
        <label v-if="showLabel(field)" :for="field.id" class="text-right text-sm text-gray-500 px-2 pt-4">{{
          field.label
        }}</label>
        <div class="col-span-3 col-start-2">
          <component :is="field.type"
                     v-model:formdata="formdata"
                     v-model:validation="validation"
                     :field="field"
                     :is-visible="true"
                     :mode="mode"
                     :option-fields="field.type === 'selectField' || field.type === 'radioField' ? fields : null"
                     :postcodes="field.type === 'zipCityStaticField' || field.type === 'zipCityField' ? postcodes : null"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-3">
      <button class="btn btn-lg btn-primary" data-cy="btn-submit" type="submit">{{ $t('general.save') }}</button>
    </div>
  </form>
</template>

<script>
  import ZipCityField from '../../formcreator/fieldComponents/zipCityField.vue';
  import ZipCityStaticField from '../../formcreator/fieldComponents/zipCityStaticField.vue';
  import EmailStaticField from '../../formcreator/fieldComponents/emailStaticField.vue';
  import PhoneStaticField from '../../formcreator/fieldComponents/phoneStaticField.vue';
  import ForenameStaticField from '../../formcreator/fieldComponents/forenameStaticField.vue';
  import SurnameStaticField from '../../formcreator/fieldComponents/surnameStaticField.vue';
  import AddressStaticField from '../../formcreator/fieldComponents/addressStaticField.vue';
  import DateStaticField from '../../formcreator/fieldComponents/dateStaticField.vue';
  import SelectField from '../../formcreator/fieldComponents/selectField.vue';
  import DateField from '../../formcreator/fieldComponents/dateField.vue';
  import TextareaStaticField from '../../formcreator/fieldComponents/textareaStaticField.vue';
  import TextareaField from '../../formcreator/fieldComponents/textareaField.vue';
  import TextField from '../../formcreator/fieldComponents/textField.vue';
  import CheckboxField from '../../formcreator/fieldComponents/checkboxField.vue';
  import RadioField from '../../formcreator/fieldComponents/radioField.vue';
  import NumberField from '../../formcreator/fieldComponents/numberField.vue';
  import TitleText from '../../formcreator/fieldComponents/titleText.vue';
  import store from '../../store/mainStore';
  import leadFormApi from '../../connections/leadForm';
  import QuotesMetaField from '../../formcreator/fieldComponents/quotesMetaField.vue';
  import FlexibleDateStaticField from '../../formcreator/fieldComponents/flexibleDateStaticField.vue';
  import SalutationStaticField from '../../formcreator/fieldComponents/salutationStaticField.vue';
  import AvailabilityStaticField from '../../formcreator/fieldComponents/availabilityStaticField.vue';

  export default {
    name: 'EditLeadContainer',
    components: {
      FlexibleDateStaticField,
      QuotesMetaField,
      ZipCityField,
      ZipCityStaticField,
      EmailStaticField,
      PhoneStaticField,
      ForenameStaticField,
      SurnameStaticField,
      AddressStaticField,
      DateStaticField,
      SelectField,
      DateField,
      TextareaStaticField,
      TextareaField,
      TextField,
      CheckboxField,
      RadioField,
      NumberField,
      TitleText,
      SalutationStaticField,
      AvailabilityStaticField
    },
    props: {
      lead: Object,
      filteredInputFields: Array,
      mode: String,
    },
    emits: ['success'],
    data() {
      return {
        validation: {},
        fields: this.lead.form.fields,
        formdata: {
          form_id: this.lead.form.id,
          address: !this.lead.address ? '' : this.lead.address,
          postcode: this.lead.postcode,
          city: this.lead.city,
          name: this.lead.name,
          surname: this.lead.surname,
          phone: !this.lead.phone ? '' : this.lead.phone,
          email: this.lead.email,
          date: this.lead.date,
          availability: this.lead.availability,
          salutation: this.lead.salutation,
          flexible_date: this.lead.flexible_date,
          description: !this.lead.description ? '' : this.lead.description,
          postcode_id: this.lead.postcode_id,
          desired_quotes: this.lead.desired_quotes,
          country: '',
          showHide: '',
          dynamic_form: JSON.parse(this.lead.dynamic_form),
        },
      };
    },
    computed: {
      inputFields() {
        return this.filteredInputFields.filter(obj => obj.parent === null && obj.type !== 'selectFormField' && obj.type !== 'tabsFormField');
      },
      loading() {
        if (this.lead.hasOwnProperty('id') && this.postcodes.length > 0) {
          return false;
        } else {
          return true;
        }
      },
      postcodes() {
        return store.state.main.postcodes;
      },
      desiredQuotes() {
        return this.formdata.desired_quotes;
      },
    },
    watch: {
      lead: function (newVal) {
        this.formdata.address = newVal.address;
        this.formdata.postcode = newVal.postcode;
        this.formdata.city = newVal.city;
        this.formdata.name = newVal.name;
        this.formdata.surname = newVal.surname;
        this.formdata.phone = newVal.phone;
        this.formdata.email = newVal.email;
        this.formdata.date = newVal.date;
        this.formdata.flexible_date = newVal.flexible_date;
        this.formdata.description = newVal.description;
        this.formdata.dynamic_form = JSON.parse(newVal.dynamic_form);
      },
      desiredQuotes() {
        let lead = JSON.parse(JSON.stringify(this.lead));
        lead.desired_quotes = this.desiredQuotes;
        store.commit('customer/setCurrentLead', lead);
      },
    },
    methods: {
      showLabel(field) {
        if (field.type === 'titleText' || field.type === 'checkboxField') {
          return false;
        } else {
          return true;
        }
      },
      onSubmit(evt) {
        evt.preventDefault();
        // console.log('EditLeadContainer.vue (-> from FinishLead.vue): submit ' + this.lead.id + ' ' + this.formdata);
        leadFormApi.patch('leads/' + this.lead.id, this.formdata, { notification: true }).then(response => [
              response.data.content['onlyLead'] ? [store.commit('customer/setCurrentLead', response.data.content.leads)] :
                  [
                    store.commit('customer/setCompanyLeads', response.data.content.companyLeads),
                    store.dispatch('customer/findCurrentLead', this.$route.params.lead_uuid)],
              this.$route.name === 'FinishLead' ? this.$emit('success') : this.$router.push({ name: 'QuoteOverview' }),
            ],
        );
        // console.log('EditLeadContainer.vue (-> from FinishLead.vue): submit successful');
      },
      // When the form has changed and an old requests didn't had this field in it
      oldForm(field) {
        if (field.static === 0 && !this.formdata.dynamic_form.hasOwnProperty(field.id)) {
          return true;
        } else {
          return false;
        }
      },
      getIfShow: function (field) {
        if (field.type === 'seperator') {
          return false;
        } else if (field.display_hide) {
          return eval(field.display_hide);
        } else {
          return true;
        }
      },
    },
  };
</script>

<style scoped>

</style>
