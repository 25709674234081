<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.leads.pendingLeads.pendingQuotes') }}</p>
      <p class="hidden sm:block text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" :class="{ 'hidden sm:block':(company.activity_rate > 9) }">{{ $t('companyArea.leads.pendingLeads.pendingQuotesInfo') }}</p>
      <!--      <div class="text-center mt-3">-->
      <!--        <button class="btn btn-lg btn-primary" @click="show.modal = true">{{ $t('companyArea.leads.pendingLeads.more') }}</button>-->
      <!--      </div>-->
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <lead-card v-if="!desktop" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <pending-leads-card :company-lead="companyLead" @make-quote="show.makeQuote = true; currentCompanyLeadForQuote = companyLead" />
          </lead-card>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <confirmation-modal v-if="show.makeQuote" :content-size="true" @close="show.makeQuote = false">
      <make-quote-container :company-lead="currentCompanyLeadForQuote" :quote="{}" @close="show.makeQuote = false" />
    </confirmation-modal>

    <confirmation-modal v-if="show.modal"
                        theme="white"
                        :close-button="true"
                        @close="show.modal = false"
    >
      <p class="font-semibold mt-5">{{ $t('companyArea.leads.pendingLeads.commissionTitle') }}</p>
      <p>{{ $t('companyArea.leads.pendingLeads.commissionInfo1', { 'appName': appName }) }}</p>
      <p class="font-semibold mt-5">{{ $t('companyArea.leads.pendingLeads.commissionFairTitle') }}</p>
      <p class="mt-3" v-html="$t('companyArea.leads.pendingLeads.commissionFairText1')" />
      <p class="mt-3" v-html="$t('companyArea.leads.pendingLeads.commissionFairText2')" />
    </confirmation-modal>
  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import PendingLeadsCard from './LeadOverview/cards/PendingLeadsCard.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import MakeQuoteContainer from './LeadOverview/makeQuote/MakeQuoteContainer.vue';

export default {
  name: 'PendingLeads',
  components: { MakeQuoteContainer, ConfirmationModal, MobileCard, PendingLeadsCard, LeadCard, Layout, LeadOverviewWrapper },
  props: {},
  data() {
    return {
      show: {
        modal: false,
        makeQuote: false,
        confirmation: [],
      },
      currentCompanyLeadForQuote: {},
    };
  },
  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
    company() {
      return store.state.company.company
    }
  },
};
</script>

<style scoped>

</style>
