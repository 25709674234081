<template>
  <div class="panel">
    <router-link v-for="item in menu"
                 :to="{ name: item.name }"
                 class="block text-left text-md font-normal uppercase hover:bg-gray-300 hover:text-black py-2 px-1"
                 :class="[$route.name === item.name ? 'bg-gray-200' : 'bg-white text-black']"
                 :data-cy="'sidebar-menu-'+item.name.toLowerCase()"
    >
      {{ $t(item.meta.menu) }}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'SidebarMenu',
    props: {
      company: Object,
    },
    data() {
      return {
        menu: this.$router.options.routes.filter(obj => {
          if (obj.meta.hasOwnProperty('prepaid')) {
            return obj.meta.childOf === this.$route.meta.childOf && obj.meta.prepaid === this.company.prepaid;
          } else {
              return obj.meta.childOf === this.$route.meta.childOf;
          }
        }),
      };
    },
  };
</script>

<style scoped>

</style>
