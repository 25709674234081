<template>
  <div v-if="message" class="mt-2">
    <div v-if="show.pending.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.problem') }}</div>
      <p class="text-base font-semibold text-red-600">{{ $t('companyArea.leads.leadOverview.snippets.leadDateExceeded') }}</p>

      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.solveProblem') }}</div>
      <div class="mt-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.gotJobFromUser') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'quote-accepted-' + companyLead.lead_id" @click="show.confirmation[companyLead.id] = 'confirmed'">{{ $t('companyArea.leads.leadOverview.snippets.gotJob') }}</button>

      <div class="mt-6 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.quoteRejected') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'quote-rejected-' + companyLead.lead_id" @click="show.confirmation[companyLead.id] = 'rejected'">{{ $t('companyArea.leads.leadOverview.snippets.lostJob') }}</button>

      <div class="mt-6 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.changedDate') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'openDateChange-' + companyLead.lead_id" @click="show.changeLeadDate.push(companyLead.id); show.changeDate = true">{{ $t('companyArea.leads.leadOverview.snippets.changeDate') }}</button>
    </div>

    <div v-else-if="show.confirmedJob.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.problem') }}</div>
      <p class="text-base font-semibold text-red-600">{{ $t('companyArea.leads.leadOverview.snippets.jobShouldBeExecuted') }}</p>

      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.solveProblem') }}</div>
      <div class="mt-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.jobFinished') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'endprice-' + companyLead.lead_id" @click="show.modal = true">{{ $t('companyArea.leads.leadOverview.snippets.tellPrice') }}</button>

      <div class="mt-6 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.jobNotTakenPlace') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'openDateChange-' + companyLead.lead_id" @click="show.changeLeadDate.push(companyLead.id); show.changeDate = true">{{ $t('companyArea.leads.leadOverview.snippets.changeJobDate') }}</button>

      <div class="mt-6 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.jobNotYetFinished') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'openJobCompletionChange-' + companyLead.lead_id" @click="show.changeJobCompletionDate.push(companyLead.id); show.changeJobCompletion = true">{{ $t('companyArea.leads.leadOverview.snippets.tellEndDate') }}</button>

      <div v-if="!companyLead.lead.required_actions.find(obj => obj.company_review_id !== null)">
        <div class="mt-6 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.jobCancelled') }}</div>
        <button class="btn btn-md btn-secondary mt-1" :data-cy="'quote-rejected-' + companyLead.lead_id" @click="rejected()">{{ $t('companyArea.leads.leadOverview.snippets.jobCancelledLostJob') }}</button>
      </div>
    </div>

    <div v-else-if="show.lostJobConfirmed.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.problem') }}</div>
      <p class="text-base font-semibold text-red-600">{{ $t('companyArea.leads.leadOverview.snippets.gotClientReview') }}</p>
      <p>{{ $t('companyArea.leads.leadOverview.snippets.gotClientReviewInfo') }}</p>
      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.solveProblem') }}</div>
      <div class="mt-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.youGotJob') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'quote-accepted-' + companyLead.lead_id" @click="confirmed()">{{ $t('companyArea.leads.leadOverview.snippets.gotJob') }}</button>

      <div class="mt-6 mb-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.falseReview') }}</div>
      <a href="mailto:info@vipany.ch" class="btn btn-md btn-primary mt-2">{{ $t('companyArea.leads.leadOverview.snippets.service') }}</a>
    </div>

    <div v-else-if="show.payExtra.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.problem') }}</div>
      <p class="text-base font-semibold text-red-600">{{ $t('companyArea.leads.leadOverview.snippets.clientToldOtherPrice') }}</p>
      <p class="mt-2">
        {{ $t('companyArea.leads.leadOverview.snippets.yourPrice') }} <span class="font-semibold">{{ companyLead.lead.form.currency }} {{ prices[0] }}</span><br>
        {{ $t('companyArea.leads.leadOverview.snippets.clientPrice') }} <span class="font-semibold">{{ companyLead.lead.form.currency }} {{ prices[1] }}</span>
      </p>
      <p>{{ $t('companyArea.leads.leadOverview.snippets.clientToldOtherPriceInfo') }}</p>

      <div class="mt-6 text-gray-700">{{ $t('companyArea.leads.leadOverview.snippets.solveProblem') }}</div>

      <div class="mt-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.changePrice') }}</div>
      <button class="btn btn-md btn-primary mt-1" :data-cy="'pay-extra-' + companyLead.lead_id" @click="show.modal = true">{{ $t('companyArea.leads.leadOverview.snippets.payAdditionalProvision') }}</button>

      <div class="mt-6 mb-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.clientFault') }}</div>
      <a href="mailto:info@vipany.ch" class="btn btn-md btn-primary">{{ $t('companyArea.leads.leadOverview.snippets.service') }}</a>
    </div>


    <div v-if="show.confirmation[companyLead.id] && show.confirmation[companyLead.id].length > 0"
         class="absolute flex justify-center inset-0 rounded pt-10 z-50 bg-opacity-95"
         :class="{ 'bg-green-700':(show.confirmation[companyLead.id] === 'confirmed'), 'bg-red-900':(show.confirmation[companyLead.id] === 'rejected')}"
    >
      <div>
        <div class="text-center text-xl text-white font-extrabold px-2">
          <div v-if="show.confirmation[companyLead.id] === 'confirmed'">
            {{ $t('companyArea.leads.leadOverview.snippets.youGotJobQuestion') }}<br>
            <div class="text-xs font-normal mt-2">{{ $t('companyArea.leads.leadOverview.snippets.leadToConfirmed') }}</div>
          </div>
          <div v-else-if="show.confirmation[companyLead.id] === 'rejected'">
            {{ $t('companyArea.leads.leadOverview.snippets.quoteWasRejectedQuestion') }}<br>
            <div class="text-xs font-normal" v-html="$t('companyArea.leads.leadOverview.snippets.leadToArchiv')" />
          </div>
        </div>
        <div class="flex justify-center mt-3">
          <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                  data-cy="confirm"
                  @click="confirmQuote(show.confirmation[companyLead.id])"
          >
            <font-awesome-icon :icon="['fal','check']" />
            <span class="absolute -bottom-6 text-white text-xs uppercase">{{ $t('general.yes') }}</span>
          </button>
          <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                  data-cy="reject"
                  @click="show.confirmation[companyLead.id] = ''"
          >
            <font-awesome-icon :icon="['fal','times']" />
            <span class="absolute -bottom-6 text-white text-xs uppercase">{{ $t('general.no') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <change-lead-date v-if="show.changeLeadDate.includes(companyLead.id) || show.changeJobCompletionDate.includes(companyLead.id)"
                    :company-lead="companyLead"
                    :show="show"
                    @close-edit="show.changeLeadDate = []; show.changeDate = false; show.changeJobCompletion = false"
  />

  <confirmation-modal v-if="show.modal" @close="show.modal = false">
    <confirm-price v-if="show.modal"
                   :company-lead="companyLead"
                   :quote="companyLead.lead.quotes.length > 0 ? companyLead.lead.quotes[0] : {}"
                   @close="show.modal = false"
    />
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from '../../../../snippets/ConfirmationModal.vue';
import ConfirmPrice from '../confirmPrice.vue';
import store from '../../../../store/mainStore';
import CircleButton from '../../../../snippets/CircleButton.vue';
import ChangeLeadDate from '../ChangeLeadDate.vue';
import dayjsHelper from '../../../../../plugins/dayjsHelper';

export default {
  name: 'LeadAction',
  components: { ChangeLeadDate, CircleButton, ConfirmPrice, ConfirmationModal },
  props: {
    companyLead: Object,
  },
  data() {
    return {
      show: {
        payExtra: [],
        lostJobConfirmed: [],
        confirmedJob: [],
        pending: [],
        changeLeadDate: [],
        changeJobCompletionDate: [],
        confirmation: [],
        // this is only because the child component changeLeadDate needs it
        changeDate: false,
        changeJobCompletion: false,
      },
    };
  },
  computed: {
    message() {
      this.show.payExtra = [];
      this.show.lostJobConfirmed = [];
      this.show.confirmedJob = [];
      this.show.pending = [];
      this.show.changeLeadDate = [];
      this.show.confirmation = [];
      if(this.companyLead.lead.required_actions.find(obj => obj.company_review_id !== null)) {
        if(this.companyLead.status === 3) {
          this.show.confirmedJob.push(this.companyLead.id);
        } else {
          this.show.lostJobConfirmed.push(this.companyLead.id);
        }
        return true;
      } else if(this.companyLead.lead.required_actions.find(obj => obj.company_log_id !== null)) {
        this.show.payExtra.push(this.companyLead.id);
        return true;
      } else if(this.companyLead.lead.required_actions.find(obj => obj.duedate !== null)) {
        if(this.companyLead.status === 3) {
          this.show.confirmedJob.push(this.companyLead.id);
          return true;
        } else {
          this.show.pending.push(this.companyLead.id);
          return true;
        }
      } else {
        return false;
      }
    },
    prices() {
      if(this.show.payExtra.length > 0) {
        return [this.companyLead.lead.company_logs[0].company_price, this.companyLead.lead.company_logs[0].customer_price];
      } else {
        return null;
      }
    },
    blockingLead() {
      return this.companyLead.lead.required_actions.find(obj => dayjsHelper.isInPast(obj.duedate));
    },
  },
  methods: {
    confirmed() {
      store.dispatch('company/confirmedJob', [this.companyLead.id]);
    },
    rejected() {
      store.dispatch('company/rejectedJob', [this.companyLead.id]);
    },
    confirmQuote(action) {
      if(action === 'confirmed') {
        store.dispatch('company/confirmedJob', [this.companyLead.id]);
      } else if(action === 'rejected') {
        store.dispatch('company/rejectedJob', [this.companyLead.id]);
      }
      this.show.confirmation[this.companyLead.id] = '';
    },
  },
};
</script>

<style scoped>

</style>
