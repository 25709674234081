import axios from 'axios';
import store from '../store/mainStore';
import authApi from './auth';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import Bugsnag from '@bugsnag/js';
import notification from './notification';
import { i18n } from '../../main';

const TIMEOUT = 50000;

let baseUrl;
try {
  baseUrl = Cypress.env('BACKEND_API_URL') + '/company-backend';
} catch (e) {
  if (window.location.hostname.split('.')[0] === 'testing') {
    baseUrl = import.meta.env.VITE_VUE_APP_LANDINGPAGE_ADMIN_URL + '/api';
  } else {
    baseUrl = import.meta.env.VITE_VUE_APP_LANDINGPAGE_ADMIN_URL + '/api';
  }
}

const landingpageApi = axios.create(
  {
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      accept: 'application/json',
    },
    withCredentials: true, // required to handle the CSRF token
  },
);

// error does not always have a response. there is no error.code. if there is a response, we can get the status code from error.response.status https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
axiosRetry(landingpageApi, {
    retries: 2,
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
    retryCondition: e => {
      return isNetworkOrIdempotentRequestError(e) || (e.response && e.response.status === 429);
    },
  },
);

/**
 * Add a request interceptor
 */
landingpageApi.interceptors.request.use(function (config) {
  config.params = config.params || {};
  config.params.language = i18n.global.locale;

  // console.log('landingpageApi Request', config)
  config.notification = config.notification || false;
  config.progress = config.progress || false;
  store.commit('SET_VALIDATION_ERROR', null, { root: true });
  notification.destroy();
  if (config.progress && config.timeout === TIMEOUT) {
    // only show on first try, not on retries - config.timeout changes on each retry
    notification.progress(config.progressMessage);
  }

  return config;
}, function (error) {
  // We rarely ever get into this catch, only if we make a mistake in the use() function here
  notification.destroy();
  notification.error();
  Bugsnag.notify(error);

  return Promise.reject(error);
});

const handleResponse = async (response) => {
  notification.destroy();

  const { data, config } = response;

  if (config.notification === true && typeof data.notificationType === 'string') {
    notification[data.notificationType](data.notification);
  }

  return response;
};

const handleError = async (error) => {
  notification.destroy();

  Bugsnag.notify(error);

  const { response, config } = error;

  if (!response) {
    notification.error();
  } else if ([401, 403].includes(response.status)) {
    store.dispatch('auth/checkUser');
  } else if (response.status === 422) {
    // validation error
    store.commit('SET_VALIDATION_ERROR', response.data.errors, { root: true });
    // Return the response to be handled by the caller
    // console.log('response', response)
    // console.log('error', error)
    return response;
  } else if (response.status === 419 && !config._retry) {
    // CSRF Token missmatch -> make a csrf call and retry the original request
    config._retry = true;
    await authApi.get('/sanctum/csrf-cookie');
    return axios(config);
  } else if (config.notification === true && ((response.status >= 500 && response.status <= 599) || response.status === 429) && error.message) {
    notification.error(i18n.global.t('general.serverError'));
  } else if (config.notification === true && typeof response.data === 'object' && response.data !== null) {
    // all other errors, if it has a message, use it
    const errorMessage = response.data.message || error.message;
    notification.error(errorMessage);
  }

  throw error;
};

landingpageApi.interceptors.response.use(handleResponse, handleError);

export default landingpageApi;
