<template>
  <div class="flex flex-col">
    <div class="flex mt-0 sm:mt-2">
      <div v-if="companyLead.lead.quotes.length > 0">
        <div class="text-base font-extrabold overflow-visible cursor-pointer">
          {{
            companyLead.lead.quotes[0].type === 'fixprice' ? $t('companyArea.leads.leadOverview.makeQuote.step1.fixprice') + ' ' : $t('companyArea.leads.leadOverview.makeQuote.step1.nonBinding') + ' '
          }}
          <span class="relative backside-hidden overflow-visible">
            <img class="max-w-none absolute w-36 -top-1 -left-1" src="../../../../../assets/images/price-swash.svg">
            <span class="relative" :data-cy="'quote-price-' + companyLead.lead_id">
              <quote-price :quote="companyLead.lead.quotes[0]" />
            </span>
          </span>
        </div>
        <div v-if="companyLead.lead.quotes[0].customer_seen_at" class="text-xs text-gray-400">
          {{ $t('companyArea.leads.leadOverview.cards.seenByCustomer') }} <span class="md:hidden lg:inline-block">({{ $t('general.date.last') }} {{
            this.dayjsHelper.showDateTime(companyLead.lead.quotes[0].customer_seen_at)
          }})</span>
        </div>
      </div>
      <div v-else-if="this.dayjsHelper.isInFuture(companyLead.date) && companyLead.lead.email !== 'deleted@deleted'">
        <button class="btn btn-md btn-secondary mt-3 mr-2" :data-cy="'make-quote-' + companyLead.lead_id" @click="$emit('makeQuote')">{{ $t('companyArea.leads.leadOverview.cards.waitingForQuotesCard.createQuote') }}</button>
      </div>
      <div />
    </div>
  </div>


  <div v-if="confirm[companyLead.id] && confirm[companyLead.id].length > 0"
       class="absolute flex justify-center inset-0 rounded pt-10 z-50 bg-opacity-95"
       :class="{ 'bg-green-700':(confirm[companyLead.id] === 'confirmed'), 'bg-red-900':(confirm[companyLead.id] === 'rejected')}"
  >
    <div>
      <div class="text-center text-xl text-white font-extrabold px-2">
        <div v-if="confirm[companyLead.id] === 'confirmed'">
          <span v-if="this.dayjsHelper.isInPast(companyLead.date)">{{ $t('companyArea.leads.leadOverview.cards.jobCompleted?') }}</span><span v-else>{{ $t('companyArea.leads.leadOverview.cards.jobConfirmed?') }}</span><br>
          <div class="text-xs font-normal mt-2">{{ $t('companyArea.leads.leadOverview.cards.leadWillBeMovedToConfirmed') }}</div>
        </div>
        <div v-else-if="confirm[companyLead.id] === 'rejected'">
          {{ $t('companyArea.leads.leadOverview.cards.offerRejected?') }}<br>
          <div class="text-xs font-normal">{{ $t('companyArea.leads.leadOverview.cards.leadWillBeMovedToArchive') }}</div>
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                data-cy="confirm"
                @click="confirmQuote(confirm[companyLead.id])"
        >
          <font-awesome-icon :icon="['fal','check']" />
          <span class="absolute -bottom-6 text-white text-xs">{{ $t('general.yes') }}</span>
        </button>
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black mb-4 ml-3"
                data-cy="reject"
                @click="confirm[companyLead.id] = ''"
        >
          <font-awesome-icon :icon="['fal','times']" />
          <span class="absolute -bottom-6 text-white text-xs">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../../../store/mainStore';
import QuotePrice from '../../../../sharedComponents/QuotePrice.vue';

export default {
  name: 'PendingLeadsCard',
  components: { QuotePrice },
  props: {
    companyLead: Object,
  },
  emits: ['makeQuote'],
  data() {
    return {
      confirm: [],
    };
  },
  methods: {
    confirmQuote(action) {
      if(action === 'confirmed') {
        store.dispatch('company/confirmedJob', [this.companyLead.id]);
      } else if(action === 'rejected') {
        store.dispatch('company/rejectedJob', [this.companyLead.id]);
      }
      this.confirm[this.companyLead.id] = '';
    },
  },
};
</script>

<style scoped>

</style>
