<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.closedDays.closedDays') }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">{{ $t('companyArea.settings.closedDays.info') }}</p>
    </template>

    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center px-3 mt-8 mb-4 sm:mb-0">
        <button class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1" @click="storeClosedDays('month')">{{ $t('companyArea.settings.closedDays.closeMonth') }}</button>
        <button class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1" @click="deleteClosedDays('month')">{{ $t('companyArea.settings.closedDays.openMonth') }}</button>
      </div>
    </template>

    <div class="panel">
      <p class="mt-2 mb-5">{{ $t('companyArea.settings.closedDays.info2') }}</p>
      <p class="mt-2 mb-5" v-html="$t('companyArea.settings.closedDays.info3')"></p>
      <vue-cal ref="vuecal"
               class="vuecal--rounded-theme vuecal--closed-days"
               hide-view-selector
               :time="false"
               :events="closedDays"
               active-view="month"
               :disable-views="['day', 'years', 'year', 'week']"
               :locale="locale"
               style="height: 500px"
               @cell-click="singleClick($event)"
               @cell-dblclick="doubleClick($event)"
               @ready="month = $event.startDate"
               @view-change="month = $event.startDate"
      />
      <p class="text-lg font-semibold mt-5">{{ $t('companyArea.settings.closedDays.legend') }}</p>
      <p class="mt-1">
        <strong>{{ $t('general.click') }}</strong>: {{ $t('companyArea.settings.closedDays.closeOrOpenDay') }}<br>
        <strong>{{ $t('general.doubleClick') }}</strong>: {{ $t('companyArea.settings.closedDays.closeOrOpenWeek') }}
      </p>
      <div class="flex mt-2">
        <div class="block h-5 w-5 mr-3">
          <div class="rounded-full bg-red-600 h-5 w-5" />
        </div>
        {{ $t('companyArea.settings.closedDays.dayClosed') }}<br>{{ $t('companyArea.settings.closedDays.dayClosedInfo') }}
      </div>
    </div>

    <confirmation-modal v-if="show.newEvent" @close="show.newEvent = false">
      <div class="flex justify-center">
        <button class="btn btn-md btn-primary mr-2" @click="storeClosedDays('week'); show.newEvent = false">{{ $t('companyArea.settings.closedDays.closeWeek') }}</button>
      </div>

      <div class="flex justify-center mt-5">
        <button class="btn btn-md btn-secondary mr-2" @click="deleteClosedDays('week'); show.newEvent = false">{{ $t('companyArea.settings.closedDays.openWeek') }}</button>
      </div>
    </confirmation-modal>
  </layout>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import Layout from '../../Layout.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import companyApi from '../../connections/company';
import { getCurrentLocaleForVueCal } from '../../../plugins/i18n';

let time = null;
export default {
  name: 'ClosedDays',
  components: { ConfirmationModal, Layout, VueCal },
  data() {
    return {
      closedDays: [],
      show: {
        newEvent: false,
      },
      formdata: {
        event: {},
      },
      month: '',
    };
  },
  computed: {
    locale() {
      return getCurrentLocaleForVueCal();
    },
  },
  created() {
    companyApi.get('companies/' + this.$route.params.company_id + '/closed-days').
        then(response => response.data.notificationType === 'success' ?
            this.prepareEvents(response.data.content) : '');
  },
  methods: {
    prepareEvents(events, fresh = false) {
      fresh ? this.closedDays = [] : '';
      let array = [];
      if(Array.isArray(events)) {
        array = events;
      } else {
        array.push(events);
      }
      for(let i = 0; i < array.length; i++) {
        array[i].start = this.dayjsHelper.dayjs(array[i].day).toDate(); // .format('YYYY-MM-DD HH:mm');
        array[i].end = this.dayjsHelper.dayjs(array[i].day).toDate();
        this.closedDays.push(array[i]);
      }
    },
    singleClick(event) {
      // first clear  time
      clearTimeout(time);
      let that = this;
      time = setTimeout(() => {
        if(that.closedDays.find(obj => this.dayjsHelper.dayjs(obj.day).isSame(event, 'day'))) {
          that.deleteClosedDays('single', that.closedDays.find(obj => this.dayjsHelper.dayjs(obj.day).isSame(event, 'day')));
        } else {
          that.formdata.event = this.dayjsHelper.dayjs(event).format('YYYY-MM-DD');
          that.storeClosedDays('single');
        }
      }, 300);
    },
    doubleClick(event) {
      clearTimeout(time);
      this.show.newEvent = true;
      this.formdata.event = event;
    },
    storeClosedDays(mode) {
      this.formdata.event = mode === 'month' ? this.dayjsHelper.dayjs(this.month).add(15, 'day') : this.formdata.event;
      companyApi.post('companies/' + this.$route.params.company_id + '/closed-days/store/' + mode, this.formdata, { notification: true, progress: false }).
          then(response => response.data.notificationType === 'success' ?
              mode === 'single' ? this.prepareEvents(response.data.content) : this.prepareEvents(response.data.content, true) : '');
    },
    deleteClosedDays(mode, closedDay = null) {
      this.formdata.event = mode === 'month' ? this.dayjsHelper.dayjs(this.month).add(15, 'day') : this.formdata.event;
      let string = mode;
      closedDay ? string += '/' + closedDay.id : '';
      companyApi.post('companies/' + this.$route.params.company_id + '/closed-days/delete/' + string, this.formdata, { notification: true, progress: false }).
          then(response => response.data.notificationType === 'success' ?
              mode === 'single' ? this.closedDays = this.closedDays.filter(obj => obj.id !== closedDay.id) : this.prepareEvents(response.data.content, true) : '');
    },
  },
};
</script>

<style scoped>

</style>
