<template>
  <div class="">
    <div class="font-semibold text-lg mb-3">{{ $t('sharedComponents.cartOverview.orderOverview') }}</div>
    <div v-if="orderProducts.length === 0">
      {{ $t('sharedComponents.cartOverview.emptyCart') }}
    </div>
    <div v-else>
      <div class="flex justify-between">
        <div>{{ $t('sharedComponents.cartOverview.purchase') }}</div>
        <div>{{ totalPrice.price_excl }}</div>
      </div>
      <div v-if="formdata.delivery_costs > 0" class="flex justify-between">
        <div>{{ $t('sharedComponents.cartOverview.deliverCosts') }}</div>
        <div>{{ formdata.delivery_costs.toFixed(2) }}</div>
      </div>
      <div class="flex justify-between">
        <div>8.1% {{ $t('general.vat') }}</div>
        <div>{{ totalPrice.vat }}</div>
      </div>
      <div class="flex justify-between font-semibold">
        <div>Total</div>
        <div>{{ totalPrice.price_incl }}</div>
      </div>
      <div v-if="!order.paid">
        <div v-if="!checkout">
          <button class="btn btn-md btn-primary w-full mt-5" @click="$emit('store-order')">{{ $t('sharedComponents.cartOverview.orderWithCosts') }}</button>
        </div>

        <router-link v-else :to="{ name: checkout }">
          <button class="btn btn-md btn-primary w-full mt-5">{{ $t('sharedComponents.cartOverview.checkout') }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

  import store from '../store/mainStore';
  import { shopMixin } from '../../plugins/mixin';

  export default {
    name: 'CartOverview',
    mixins: [shopMixin],
    props: {
      checkout: {
        type: String,
        default: 'CompanyCart',
      },
      formdata: {
        type: Object,
        default: function () {
          return {
            delivery_costs: 0,
          };
        },
      },
    },
    emits: ['store-order'],
    data() {
      return {};
    },
    computed: {
      orderProducts() {
        return store.state.shop.orderProducts;
      },
      order() {
        return store.state.shop.order;
      },
    },
    methods: {},
  };
</script>


<style scoped>

</style>
