<template>
  <div class="flex flex-col justify-center items-center mx-8 sm:mx-16 lg:mx-28 lg:mx-64 xl:mx-72 2xl:mx-96">
    <p class="text-center text-base font-semibold mt-2 mb-5">{{ $t('publicArea.registerCompany.steps.termsOfService.title') }}</p>
    <h2 class="text-center text-base font-semibold mt-2 mb-5 h-2">{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryTitle') }}:</h2>
    <ul class="list-disc list-inside text-left mt-4 ">
      <li>{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP1') }}</li>
      <li>{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP2') }}</li>
      <li>{{ $t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP3') }}</li>
      <li v-html="$t('publicArea.registerCompany.steps.termsOfService.conditionsSummaryP4')" />
    </ul>
    <a :href="conditionsUrl" target="_blank" class="btn btn-primary mt-12 px-12 py-2">{{ $t('publicArea.registerCompany.steps.termsOfService.showConditions') }}</a>
    <div class="mt-6">
      <input v-model="accept" type="checkbox" class="mt-2 mr-2 mb-3">{{ $t('publicArea.registerCompany.steps.termsOfService.accept') }}
    </div>
    <div class="flex justify-center mt-5">
      <button class="btn btn-lg btn-primary" @click="submit">{{ $t('general.next') }}</button>
    </div>
  </div>
</template>

<script>
import store from '../../../store/mainStore';
import companyApi from '../../../connections/company';
import notification from '../../../connections/notification';

export default {
  name: 'TermsOfService',
  components: {},
  data() {
    return {
      accept: false,
      showFinish: false,
    };
  },
  computed: {
    conditionsUrl() {
      return import.meta.env.VITE_VUE_APP_LANDINGPAGE_URL + '/conditions';
    },
    company() {
      return store.state.company.company;
    },
  },
  methods: {
    submit() {
      if(this.accept === false) {
        notification.warning(this.$t('publicArea.registerCompany.steps.termsOfService.notifyMissingAccept'));
        return;
      }

      companyApi.patch('/companies/' + this.company.id + '/accept-conditions', { notification: false }).then(response => store.commit('company/setCompany', response.data.content.company));

      this.$router.push({ name: 'PendingLeads', params: { company_id: this.company.id } });
    },
  },
};
</script>

<style scoped>

</style>
