<template>
  <button class="flex items-center justify-center bg-white border border-gray-400 rounded-full hover:bg-black text-gray-800 hover:text-white"
          :class="{ 'border-gray-200 text-gray-200 hover:bg-white hover:text-gray-200 hover:border-gray-200':(disabled), 'bg-yellow-200':(active), 'w-auto h-10 p-4 text-base':(size === 'normal'), 'h-8 px-3 text-base':(size === 'sm'), 'w-6 h-6 text-xs':(size === 'xs') }"
          :disabled="disabled"
          :data-cy="dataCy"
          :data-gtm="dataGtm"
  >
    <font-awesome-icon :icon="icon" :class="{'pr-2 pl-2':(text)}"/> {{ text }}
  </button>
</template>

<script>
export default {
  name: 'CircleButton',
  props: {
    icon: Array,
    text: { type: String, default: '' },
    dataCy: { type: String, default: '' },
    dataGtm: { type: String, default: '' },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    }
  },

};
</script>

<style scoped>

</style>
