<template>
  <div class="font-semibold text-base">{{ $t('companyArea.settings.contact.enterUid') }}</div>
  <div>
    {{ $t('companyArea.settings.contact.uidInfo') }}
    <a href="https://www.uid.admin.ch" target="_blank" class="underline">uid.admin.ch</a>
  </div>
  <div class="relative">
    <input id="phone"
           v-model="formdata.uid"
           placeholder="UID Nummer"
           class="border border-gray-400 w-full mt-3"
           type="text"
    >
    <font-awesome-icon v-if="isValidSwissUID" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500" />
    <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base text-red-500" />
  </div>
  <div class="text-gray-500">{{ $t('companyArea.settings.contact.formatOfNumber') }}: CHE-000.000.000</div>
  <div class="flex justify-center">
    <button v-if="error && suggestion.length > 0"
            class="btn btn-sm btn-secondary mt-3"
            :disabled="!isValidSwissUID && !formdata.uid && formdata.uid !== ''"
            @click="updateUid('check')"
    >
      {{ $t('general.checkAgain') }}
    </button>
  </div>
  <div v-if="error && suggestion.length > 0 && !suggestion.includes('no result')" class="bg-yellow-200 p-2 rounded mt-3">
    <div>{{ $t('companyArea.settings.contact.namesDontMatch') }}</div>
    <div class="text-base font-semibold w-full text-center py-3">{{ suggestion }}</div>
    <br>
    <div>{{ $t('companyArea.settings.contact.namesDontMatch2') }}</div>
    <input id="label"
           v-model="formdata.label"
           :placeholder="$t('companyArea.settings.contact.label')"
           required
           type="text"
           class="border border-gray-400 w-full mt-3"
    >
  </div>
  <div v-else-if="error" class="bg-yellow-200 p-2 rounded mt-3">
    {{ $t('companyArea.settings.contact.uidWrong') }} <a href="https://www.uid.admin.ch" target="_blank" class="underline">uid.admin.ch</a>
  </div>
  <div v-if="!suggestion.includes('no result')" class="flex justify-center">
    <button v-if="suggestion.length > 0" class="btn btn-sm btn-secondary mt-3" @click="updateUid('update')">{{ $t('companyArea.settings.contact.saveUidAndName') }}</button>
    <button v-else
            class="btn btn-sm btn-secondary mt-3"
            :disabled="!isValidSwissUID && !formdata.uid && formdata.uid !== ''"
            @click="updateUid('check')"
    >
      {{ $t('general.save') }}
    </button>
  </div>
</template>

<script>
  import store from '../../../store/mainStore';
  import companyApi from '../../../connections/company';

  export default {
    name: 'CheckRegister',
    components: {},
    props: {
      company: Object,
    },
    emits: ['close'],
    data() {
      return {
        formdata: {
          uid: this.company.register,
          name: this.company.name,
          label: this.company.label,
          mode: '',
        },
        suggestion: '',
        error: false,
      };
    },
    computed: {
      isValidSwissUID() {
        // Definieren Sie das Muster für die Überprüfung
        var regex = /^CHE-\d{3}\.\d{3}\.\d{3}$/;

        // Überprüfen Sie, ob die Eingabe dem Muster entspricht
        return regex.test(this.formdata.uid);
      },
    },
    mounted() {
      if (this.company.register_name_check) {
        this.error = true;
        this.suggestion = this.company.register_name_check;
      }
    },
    methods: {
      updateUid(action) {
        this.formdata.mode = action;
        if (action === 'update') {
          this.formdata.name = this.suggestion;
        }
        companyApi.patch('companies/' + this.$route.params.company_id + '/update-uid', this.formdata, {
          notification: true,
          progress: true,
        }).then(response => response.data.notificationType === 'success'
          ?
          [store.commit('company/setCompany', response.data.content.company), this.$emit('close'), this.error = false]
          : [this.suggestion = response.data.content, this.error = true]);
      },

    },
  };
</script>

<style scoped>

</style>
