<template>
  <div class="px-2 mx-auto grid max-w-9xl grid-cols-1 gap-x-4 gap-y-5 lg:mx-0 lg:max-w-none md:grid-cols-2 xl:grid-cols-4 ">
    <FormClassificationGroup :formdata="formdata"
                             :forms="forms.filter(obj => obj.stats_group === 'umzug')"
                             :get-classification-tree="getClassificationTree"
                             :group="group"
                             @change-classification="changeClassification"
    >
      <template v-slot:image>
        <img src="../../../assets/images/icons/umzug.svg" :alt="$t('companyArea.components.formClassifications.moving')">
      </template>
    </FormClassificationGroup>

    <FormClassificationGroup :formdata="formdata"
                             :forms="forms.filter(obj => obj.stats_group === 'reinigung')"
                             :get-classification-tree="getClassificationTree"
                             :group="group"
                             group-label="reinigung"
                             @change-classification="changeClassification"
    >
      <template v-slot:image>
        <img src="../../../assets/images/icons/reinigung.svg" :alt="$t('companyArea.components.formClassifications.cleaning')">
      </template>
    </FormClassificationGroup>

    <FormClassificationGroup :formdata="formdata"
                             :forms="forms.filter(obj => obj.stats_group === 'maler')"
                             :get-classification-tree="getClassificationTree"
                             :group="group"
                             group-label="maler"
                             @change-classification="changeClassification"
    >
      <template v-slot:image>
        <img src="../../../assets/images/icons/maler.svg" :alt="$t('companyArea.components.formClassifications.painter')">
      </template>
    </FormClassificationGroup>

    <FormClassificationGroup :formdata="formdata"
                             :forms="forms.filter(obj => obj.stats_group === 'boden')"
                             :get-classification-tree="getClassificationTree"
                             :group="group"
                             group-label="boden"
                             @change-classification="changeClassification"
    >
      <template v-slot:image>
        <font-awesome-icon :icon="['fal','bring-front']" class="text-4xl text-blue-800" />
      </template>
    </FormClassificationGroup>
  </div>
  <spinner v-if="showSpinner" style="z-index: 10000;" />
</template>

<script>
  import store from '../../store/mainStore';
  import leadFormApi from '../../connections/leadForm';
  import FormClassificationGroup from './FormClassificationGroup.vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import Spinner from '../../snippets/Spinner.vue';

  export default {
    name: 'FormClassifications',
    components: { Spinner, FormClassificationGroup, FontAwesomeIcon },
    props: {
      formdataProp: Object,
      register: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['change'],
    data() {
      return {
        forms: [],
        group: '',
        formdata: this.formdataProp,
        showSpinner: true,
      };
    },
    computed: {
      classifications() {
        return store.state.company.classifications;
      },
      subClassifications() {
        return this.forms.filter(obj => obj.stats_group === this.group);
      },
      suggestions() {
        if (store.state.company.suggestionTree.length > 0) {
          return store.state.company.suggestionTree;
        } else {
          return [];
        }
      },
    },
    beforeCreate() {
      leadFormApi.get('/countries/CH/forms', { notification: false }).then(response => [this.forms = response.data.content, this.showSpinner = false]);
    },
    methods: {
      changeClassification(classification) {
        if (this.formdata.selected.find(obj => obj.classification_number === classification.classification_number)) {
          this.formdata.selected = this.formdata.selected.filter(obj => obj.classification_number !== classification.classification_number);
        } else {
          this.formdata.selected.push(classification);
        }
        this.$emit('change');
      },
    },
  };
</script>

