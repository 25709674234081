<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('customerArea.deleteAccount.deleteAccount') }}</p>
      <!--      <div v-if="currentLead.taken_by > 0" class="text-center mt-3">-->
      <!--        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>-->
      <!--      </div>-->
    </template>
    <div class="panel">
      {{ $t('customerArea.deleteAccount.instructions') }}
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.deleteAccount.instructionsTitle1') }}</div>
      {{ $t('customerArea.deleteAccount.instructionsText1') }}
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.deleteAccount.instructionsTitle2') }}</div>
      {{ $t('customerArea.deleteAccount.instructionsText2') }}
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.deleteAccount.instructionsTitle3') }}</div>
      {{ $t('customerArea.deleteAccount.instructionsText3') }}

      <div class="mt-3">
        <label class="mr-5 mt-3">
          <input type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="active = !active"
          >
          <span class="ml-2">{{ $t('customerArea.deleteAccount.confirmation') }}</span>
        </label>
      </div>


      <div class="flex justify-center mt-4">
        <button class="btn btn-lg btn-primary mr-2"
                :class="{ 'opacity-50':(!active) }"
                :disabled="!active"
                @click="deleteAccount"
        >
          {{ $t('customerArea.deleteAccount.deleteAccount') }}
        </button>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import leadFormApi from '../connections/leadForm';
  import store from '../store/mainStore';

  export default {
    name: 'DeleteAccount',
    components: { Layout },
    data() {
      return {
        active: false
      }
    },
    computed: {
      lead() {
        return store.state.customer.currentLead;
      },
    },
    methods: {
      deleteAccount() {
        leadFormApi.delete('leads/' + this.lead.id + '/account-delete', { 'progress': true, notification: true }).then(response => [store.dispatch('auth/logout')]);
      }
    },
  }
</script>

<style scoped>

</style>