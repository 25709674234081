<template>
  <div class="flex flex-col ml-2">
    <div class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step3.optionalInfoForClient') }}</label>
      <textarea :id="'text-' + step"
                v-model="formdata.text"
                :placeholder="$t('companyArea.leads.leadOverview.makeQuote.step3.optionalInfoForClient')"
                rows="6"
                class="w-full border border-gray-400"
                :data-cy="'make-quote-text-' + step"
      />
    </div>
    <div class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step3.uploadDocuments') }} <br><span class="text-sm text-gray-500">{{ $t('companyArea.leads.leadOverview.makeQuote.step3.uploadMaxSize') }}</span></label>
      <!--      <input type="file" @change="addFiles($event)" accept="application/pdf" ref="quoteFiles" multiple class="w-full border border-gray-400">-->
      <file-input v-model:files="files" placeholder-button-text="Datei wählen" is-pdf />
      <div v-if="validation.files.length > 0" class="text-red-600">{{ validation.files }}</div>
    </div>
    <div v-if="Object.entries(quote).length > 0 && quote.quote_files.length > 0">
      <p v-for="file in quote.quote_files" class="quote-file">
        <a :href="url + '/' + file.path"
           target="_blank"
        >{{
          file.original_name
        }}</a>
        <font-awesome-icon v-if="quote.quote_files.length > 1"
                           :icon="['far', 'trash-alt']"
                           class="ml-1"
                           @click="deleteFile(file)"
        />
      </p>
    </div>
    <div class="flex justify-center mt-4 mb-10">
      <button v-if="editMode"
              class=" btn btn-lg btn-primary"
              :data-cy="'make-quote-send-' + step"
              @click="storeQuote('update')"
      >
        {{ $t('companyArea.leads.leadOverview.makeQuote.step3.saveAndSend') }}
      </button>
      <button v-else
              class=" btn btn-lg btn-primary"
              :data-cy="'make-quote-send-' + step"
              @click="storeQuote('store')"
      >
        {{ $t('companyArea.leads.leadOverview.makeQuote.step3.sendQuote') }}
      </button>
    </div>
  </div>
  <spinner v-if="waitForServerResponse" />
</template>

<script>
import { companyMixin } from '../../../../../plugins/mixin';
import store from '../../../../store/mainStore';
import leadFormApi from '../../../../connections/leadForm';
import FileInput from '../../../../snippets/FileInput.vue';
import Spinner from '../../../../snippets/Spinner.vue';
export default {
  name: 'Step3',
  components: { Spinner, FileInput },
  mixins: [ companyMixin ],
  props: {
    formdata: Object,
    validation: Object,
    step: String,
    permanentFiles: Array,
    companyLead: Object,
    quote: Object,
    editMode: Boolean,
  },
  emits: ['success'],
  data() {
    return {
      url: import.meta.env.VITE_VUE_APP_STORAGE_URL,
      waitForServerResponse: false,
      show: {
        permanentFiles: false,
      },
      files: [],
    };
  },
  computed: {
  },
  methods: {
    storeQuote(mode) {
      if(this.validateQuoteStep2()) {

        let formData = new FormData();
        formData.append('company_id', store.state.company.company.id);
        formData.append('lead_id', this.companyLead.lead_id);
        formData.append('price', this.formdata.price);
        formData.append('price_2', this.formdata.price_2);
        formData.append('unit', this.formdata.unit);
        formData.append('type', this.formdata.type);
        formData.append('text', this.formdata.text);
        formData.append('currency', this.formdata.currency);
        formData.append('vat_incl', this.formdata.vat_incl ? 1 : 0); // otherwise it will be transmitted as "true" string, with this as "1" string which is better for the backend
        formData.append('date', this.formdata.date);

        for(var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          // a file should not be heavier than 5mb
          if(file.size > 5242880) {
            this.validation.files = 'Achtung, eine oder mehrere Ihrer Dateien sind zu gross.';
            return;
          } else {
            this.validation.files = '';
          }
          formData.append('files[' + i + ']', file.file);
        }

        const config = {
          'progress': true,
          headers: { 'content-type': 'multipart/form-data' },
        };

        if(mode === 'update') {
          // has to be post (not patch), otherwise the FormData Object is not transmitted properly
          leadFormApi.post('/quotes/' + this.quote.id, formData, config).then(response => [this.$emit('success','update'), store.dispatch('company/updateCompanyLead', response.data.content.companyLead)]);
        } else {
          if(!this.waitForServerResponse) {
            this.waitForServerResponse = true
            console.log('formdata', formData);
            leadFormApi.post('/quotes/', formData, config).
                then(response => [this.$emit('success','store'),
                  store.dispatch('company/updateCompanyLead', response.data.content.companyLead), this.waitForServerResponse = false,
                  response.data.content['company'] ? store.commit('company/setCredit', response.data.content['company'].credit) : '']);
          }

        }
      } else {
      }
    },
    addFiles(event) {
      console.log(event.target.files)
      this.files = event.target.files[0]
      // this.files = this.$refs.quoteFiles.files
    },
    deleteFile(file) {
      store.dispatch('company/deleteQuoteFile', [file, this.companyLead.id]);
    },
  },
};
</script>

<style scoped>

</style>
