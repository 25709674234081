<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.area.areaSetting') }}</p>
    </template>
    <area-setup v-model:register="register" />
  </layout>
</template>

<script>
import AreaSetup from '../components/AreaSetup.vue';
import Layout from '../../Layout.vue';

export default {
  name: 'Area',
  components: { Layout, AreaSetup },
  data() {
    return {
      register: {
        mode: false,
        saved: false,
      },
    };
  },

};
</script>

<style scoped>

</style>
