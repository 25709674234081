<template>
  <td class="pl-0">
    <div v-if="token">
      <span v-if="token.brand">
        <strong>{{ token.brand }}</strong> ({{ token.name }})
      </span>
      <span v-else>
        Noch keine Informationen zu diesem Zahlungsmittel von Postfinance erhalten
      </span>
      <span v-if="user.is_admin" class="text-xs text-gray-500">
        {{ token.id }} - {{ token.token }}
      </span>
    </div>
  </td>
</template>
<script>
    import store from '../../../store/mainStore';

    export default {
        name: 'PostfinanceTokenInfo',
        props: {
            token: {},
        },
        data() {
            return {
                user: store.state.main.user,
            };
        },
    };
</script>
