import locationApi from '../connections/location';
import searchBackendApi from '../connections/seachBackend';
import leadFormApi from '../connections/leadForm';

const getDefaultState = () => {
  return {
    countries: {},
    classifications: {},
    country: {},
    classification: {},
    form: {},
    fields: [],
    field: {},
  };
};


const FormcreatorStore = {
  namespaced: true,
  state: getDefaultState(),

  actions: {
    getCountries(context) {
      locationApi.get('/countries/CH/neighbors').then(response => context.commit('setCountries', response.data));
    },
    getCountry(context, form) {
      locationApi.get('/countries/' + form.country).then(response => context.commit('setCountry', response.data));
    },
    getClassification(context, form) {
      searchBackendApi.get('/classifications/de/' + form.classification_number).then(response => context.commit('setClassification', response.data));
    },
    getForm(context) {
      leadFormApi.get(
        '/countries/' + context.state.country.iso2 + '/classifications/' +
        context.state.classification.number + '/forms', { notification: false, 'axios-retry': { retries: 0 } }).then(response => !response.data.content ?
        [context.commit('setForm', {}),
          context.commit('setFields', [])] :
        [context.commit('setForm', response.data.content),
          context.commit('setFields', response.data.content.fields)]);
    },
    getFields(context, locale) {
      leadFormApi.get('/forms/' + context.state.form.id + '/fields?locale=' + locale, { notification: false, 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
    newForm(context) {
      leadFormApi.post('/countries/' + context.state.country.iso2 + '/forms',
        context.state.classification, { notification: false, 'axios-retry': { retries: 0 } }).then(response => [
        context.commit('setForm', response.data.content),
        context.commit('setFields', response.data.content.fields)]);
    },
    copyForm(context, formToCopy) {
      leadFormApi.post('/countries/' + context.state.country.iso2 + '/forms/' + formToCopy + '/copy',
        context.state.classification, { notification: false, 'axios-retry': { retries: 0 } }).then(response => [
        context.commit('setForm', response.data.content),
        context.commit('setFields', response.data.content.fields)]);
    },
    updateField(context, data) {
      leadFormApi.patch('/fields/' + data[0].id + '/update', data[1], { 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
    createField(context, form) {
      leadFormApi.post('/fields/' + context.state.form.id + '/create', form, { 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
    deleteField(context, field) {
      leadFormApi.get('/fields/' + field.id + '/delete', { 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
    moveUpField(context, data) {
      leadFormApi.get('/fields/' + data[0] + '/move-up/' + data[1], { 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
    moveDownField(context, data) {
      leadFormApi.get('/fields/' + data[0] + '/move-down/' + data[1], { 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
    setMode(context, newMode) {
      leadFormApi.get('/forms/' + context.state.form.id + '/fields/mode/' + newMode, { 'axios-retry': { retries: 0 } }).then(response => context.commit('setFields', response.data.content));
    },
  },

  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setClassification(state, classification) {
      state.classification = classification;
    },
    setForm(state, form) {
      state.form = form;
    },
    setFields(state, fields) {
      state.fields = fields;
    },
    setField(state, field) {
      state.field = field;
    },
    resetState(state) {
      // Merge rather than replace, so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
  },
};
export default FormcreatorStore;
