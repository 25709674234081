<template>
  <router-link v-if="orderProducts.length > 0" :to="{ name: $route.meta.area + 'Cart' }">
    <div class="relative block lg:inline-block text-sm">
      <div class="relative menu-item px-6 py-2">
        <div v-if="!order.paid" class="absolute right-3 -top-1 rounded-full bg-red-600 text-white text-xs px-1 py-0">{{ orderProducts.length }}</div>
        <font-awesome-icon class="text-lg"
                           :icon="['fal','cart-shopping']"
        />
      </div>
    </div>
  </router-link>
</template>

<script>


  import store from '../store/mainStore';

  export default {
    name: 'CartMenu',
    components: {},
    props: {},
    computed: {
      orderProducts() {
        return store.state.shop.orderProducts;
      },
      order() {
        return store.state.shop.order;
      },

    },
  };
</script>

<style scoped>

</style>