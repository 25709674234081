<template>
  <div class="relative mt-2 rounded shadow-md p-4 bg-white w-full" >
    <div class="flex justify-between cursor-pointer" @click="show = !show">
    <div class="font-bold text-lg">{{ $t('customerArea.quoteOverview.explanation.title') }}</div>
      <div><circle-button :icon="['fas','caret-down']" size="xs" /></div>
    </div>
    <div v-if="show">
      <div v-if="lead.desired_quotes > lead.taken_by" class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.companySearchTitle') }}</div>
      <div v-if="lead.desired_quotes > lead.taken_by">{{ $t('customerArea.quoteOverview.explanation.companySearchText') }}</div>
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.companyContactTitle') }}</div>
      <div>{{ $t('customerArea.quoteOverview.explanation.companyContactText') }}</div>
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.selectCompanyTitle') }}</div>
      <div>{{ $t('customerArea.quoteOverview.explanation.selectCompanyText') }}</div>
      <div class="text-base font-semibold mt-3">{{ $t('customerArea.quoteOverview.explanation.reviewTitle') }}</div>
      <div>{{ $t('customerArea.quoteOverview.explanation.reviewText') }}</div>
    </div>

  </div>
</template>

<script>
  import CircleButton from '../../snippets/CircleButton.vue';

  export default {
    name: 'Explanation',
    components: { CircleButton },
    props: {
      lead: Object
    },
    data() {
      return {
        show: false
      }
    }
  };
</script>
