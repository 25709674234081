<template>
  <div class="flex flex-col mb-4">
    <div v-if="formdata.type === 'atCost'" class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.unit') }}</label>
      <select v-if="!customUnit"
              v-model="formdata.unit"
              :data-cy="'make-quote-unit-' + step"
              class="w-full border border-gray-400"
              @change="detectCustom"
      >
        <option :value="formdata.unit" selected>{{ formdata.unit }}</option>
        <option>---</option>
        <option value="Stunde">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.hour') }}</option>
        <option value="m2">m2</option>
        <option value="m">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.meter') }}</option>
        <option value="custom">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.own') }}</option>
      </select>
      <input v-else
             :id="'unit-' + step"
             ref="customUnit"
             v-model="formdata.unit"
             :state="validate_unit"
             :data-cy="'make-quote-customUnit-' + step"
             class="w-full border border-gray-400"
      >
      <div v-if="validation.unit.length > 0" class="text-red-600">{{ $t(validation.unit) }}</div>
    </div>

    <div v-if="formdata.type === 'estimated'" class="mb-2" v-html="$t('companyArea.leads.leadOverview.makeQuote.step2.estimatedInfo')" />
    <div class="flex flex-col mb-4">
      <label v-if="formdata.type === 'fixprice'">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.price') }}</label>
      <label v-else-if="formdata.type === 'atCost'">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.priceByUnit', {expr: formdata.unit === '' ? 'Einheit' : formdata.unit}) }}</label>
      <label v-else>{{ formdata.price_2 === '' ? 'Richtpreis' : 'Preis Minimum' }}</label>
      <input :id="'price-' + step"
             v-model="formdata.price"
             :placeholder="$t('companyArea.leads.leadOverview.makeQuote.step2.price')"
             type="number"
             :data-cy="'make-quote-price-' + step"
             class="w-full border border-gray-400"
      >
      <div v-if="validation.price.length > 0" class="text-red-600">{{ $t(validation.price) }}</div>
    </div>

    <div v-if="formdata.type === 'estimated'" class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.priceMax') }}</label>
      <input :id="'price_2-' + step"
             v-model="formdata.price_2"
             :placeholder="$t('companyArea.leads.leadOverview.makeQuote.step2.optional')"
             type="number"
             :data-cy="'make-quote-price_2-' + step"
             class="w-full border border-gray-400"
      >
      <div v-if="validation.price.length > 0" class="text-red-600">{{ $t(validation.price) }}</div>
    </div>

    <div class="grid grid-cols-2">
      <div class="flex flex-col mb-4 mr-1">
        <select v-model="formdata.currency" :data-cy="'make-quote-currency-' + step" class="w-full border border-gray-400">
          <option value="CHF">CHF</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="USD">USD</option>
        </select>
      </div>
      <div class="flex flex-col mb-4 ml-1">
        <select v-model="formdata.vat_incl" :data-cy="'make-quote-vat-' + step" class="w-full border border-gray-400">
          <option value="1">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.inclVat') }}</option>
          <option value="0">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.exclVat') }}</option>
        </select>
      </div>
    </div>
    <div v-if="formdata.type === 'atCost'" class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.estimatedTargetPrice') }}</label>
      <input :id="'price_2-' + step"
             v-model="formdata.price_2"
             :placeholder="$t('companyArea.leads.leadOverview.makeQuote.step2.estimatedTargetPrice')"
             type="number"
             :data-cy="'make-quote-price_2-' + step"
             class="w-full border border-gray-400"
      >
      <div v-if="validation.price_2.length > 0" class="text-red-600">{{ $t(validation.price_2) }}</div>
      <p v-if="formdata.price_2.length > 0" class="text-xs">{{ $t('companyArea.leads.leadOverview.makeQuote.step2.priceClassificationInfo') }}</p>
    </div>
    <div v-if="step !== 'none'" class="flex justify-center mt-4">
      <button class=" btn btn-lg btn-primary" data-cy="make-quote-next" @click="changeStep">{{ $t('general.next') }}</button>
    </div>
  </div>
</template>

<script>
import { companyMixin } from '../../../../../plugins/mixin';

export default {
  name: 'Step2',
  mixins: [companyMixin],
  props: {
    formdata: Object,
    validation: Object,
    step: String,
    companyLead: Object,
  },
  emits: ['next-step'],
  data() {
    return {
      customUnit: false,
    };
  },
  computed: {
    validate_unit() {
      if(! this.formdata.unit || this.formdata.unit.length === 0 || this.formdata.unit.length > 50) {
        return false;
      } else {
        return null;
      }
    },
  },
  methods: {
    changeStep() {
      if(this.validateQuoteStep2()) {
        this.$emit('next-step', 'Step3');
      }
    },
    detectCustom() {
      if(this.formdata.unit === 'custom') {
        this.customUnit = true;
        this.formdata.unit = '';
        console.log(this.$refs[0]);
      }
    },
  },
};
</script>

<style scoped>

</style>
