<template>
  <div v-if="show.changeDate || show.changeJobCompletion" class="absolute inset-0 bg-white/95 rounded p-3">
    <div class="w-full">
      <div v-if="show.changeJobCompletion" class="text-xl font-semibold">
        Auftrags-Abschluss (ca.)
      </div>
      <div v-else class="text-xl font-semibold">
        {{ $t('companyArea.leads.leadOverview.changeJobDate') }}
      </div>
      <button class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full"
              @click="$emit('close-edit', false)"
      >
        <font-awesome-icon :icon="['fal','times']" />
      </button>
      <div class="mt-3">
        <date-picker v-model:date="formQuoteLog.new_date" input-styles="w-full rounded border border-gray-400 mt-3" />
      </div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-lg btn-secondary" data-cy="confirm-date-change" @click="update">{{ $t('general.submit') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import leadFormApi from '../../../connections/leadForm';
import store from '../../../store/mainStore';
import DatePicker from '../../../snippets/DatePicker.vue';

export default {
  name: 'ChangeLeadDate',
  components: { DatePicker },
  props: {
    show: Object,
    companyLead: Object,
  },
  emits: ['close-edit'],
  data() {
    return {
      date_prov: this.companyLead.date,
      formQuoteLog: {
        quote_id: null,
        company_lead_id: this.companyLead.id,
        new_date: this.companyLead.date,
      },
      priceChanged: false,
    };
  },
  watch: {
    date_prov: function(newVal) {
      // timezone hack
      var tzoffset = (new Date(newVal)).getTimezoneOffset() * 60000; //offset in milliseconds
      this.formQuoteLog.new_date = (new Date(new Date(newVal) - tzoffset)).toISOString().substring(0, 10);
    },
  },
  methods: {
    update() {
      if(this.show.hasOwnProperty('changeJobCompletion') && this.show.changeJobCompletion) {
        leadFormApi.patch('company-leads/' + this.companyLead.id + '/change-job-completion-date', this.formQuoteLog, { 'progress': true }).
            then(response => [store.dispatch('company/updateCompanyLead', response.data.content.companyLead), this.$emit('close-edit', false)]);
      } else {
        leadFormApi.post('quote-logs/company/' + this.companyLead.company_id, this.formQuoteLog, { 'progress': true }).
            then(response => [store.commit('company/setCompanyLeads', response.data.content), this.$emit('close-edit', false)]);
      }
    },
  },
};
</script>

<style scoped>

</style>
