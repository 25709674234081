<template>
  <div v-if="required || labelState" class="triangle" :class="labelState ? 'label-success' : 'label-warning'" />
</template>

<script>
export default {
  name: 'LabelStateIcon',
  props: {
    labelState: Boolean,
    required: Boolean,
    selectField: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style scoped>
.triangle {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 12px;
    right: 12px;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
}
.label-warning {
    border-color: #87b1ff;
    background-color: #87b1ff;
}
.label-success {
    border-color: #95C11F;
    background-color: #95C11F;
}
</style>