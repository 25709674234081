<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">
        {{ $t('companyArea.accounting.transactions.saldoHistory') }}
      </p>
    </template>

    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center items-center px-3 mt-8 mb-4 sm:mb-0">
        <div class="relative w-min">
          <select v-model="formdata.period"
                  class="rounded-full border-none shadow w-56 sm:w-52 m-1"
                  @change="getCreditTransactions('period')"
          >
            <option v-for="period in periods"
                    :key="period.value"
                    :value="period.value"
                    @click="show.month = true"
                    @focus="show.month = true"
                    @blur="show.month = false"
            >
              {{ period.text }}
            </option>
          </select>
        </div>
      </div>
    </template>


    <div class="panel">
      <div v-if="creditTransactions.length === 0">
        {{
          $t('companyArea.accounting.transactions.noTransactions')
        }}
      </div>
      <table v-else class="w-full" data-cy="table-transactions">
        <tr>
          <th class="text-left">
            {{ $t('companyArea.accounting.transactions.type') }}<span v-if="company.vat"><br>&nbsp;</span>
          </th>
          <th class="text-right">
            {{ $t('companyArea.accounting.transactions.amount') }}<span v-if="company.vat">*</span>
          </th>
          <th class="text-right">
            {{ $t('companyArea.accounting.transactions.saldo', { currency: company.currency }) }}<span v-if="company.vat"><br>&nbsp;</span>
          </th>
        </tr>
        <tr v-for="transaction in creditTransactions" :key="transaction.id" class="border-b">
          <td class="flex flex-col mt-2 mb-2">
            <span>{{ $t('transactionType.' + transaction.transaction_type.name) }} {{ transaction.info }} {{ transaction.transaction_status.final === false ? ' (' + transaction.transaction_status.status_description + ')' : '' }} {{ transaction.company_price ? transaction.company_price : '' }} {{
              transaction.invoice ? transaction.invoice : ''
            }}</span>
            <span class="font-semibold">
              <router-link v-if="transaction.lead_uuid" :to="{ name: 'LeadFocus', params: { lead_uuid: transaction.lead_uuid } }">{{ transaction.lead.name }} {{
                transaction.lead.surname
              }}</router-link>
              <font-awesome-icon v-if="transaction.lead_uuid"
                                 :icon="['fas','arrow-circle-right']"
                                 class="ml-2"
                                 @click="getCreditTransactions('lead', transaction.lead_uuid)"
              />
            </span>
            <span class="text-xs">{{ this.dayjsHelper.showDateTime(transaction.created_at) }}</span>
          </td>
          <td :class="{ 'align-top text-right pt-2':true, 'text-green-500':(transaction.top_up > 0), 'text-red-500':(transaction.top_up === 0) }">
            {{ transaction.amount.toFixed(2) }}
            <font-awesome-icon v-if="deletable(transaction)"
                               class="icon"
                               :icon="['fal', 'trash-alt']"
                               size="xs"
                               @click="deleteTransaction(transaction.id)"
            />
          </td>
          <td class="text-right align-top pt-2">{{ transaction.credit_new }}</td>
        </tr>
      </table>
      <p v-if="company.vat" class="mt-2 mb-2">
        {{
          $t('companyArea.accounting.transactions.vat', { vat: company.vat })
        }}
      </p>
    </div>
  </layout>
</template>

<script>
  import store from '../../store/mainStore';
  import companyApi from '../../connections/company';
  import Layout from '../../Layout.vue';

  export default {
    name: 'Transactions',
    components: { Layout },
    data() {
      return {
        formdata: {
          period: String,
        },
        user: store.state.main.user,
        show: {
          explanation: false,
        },
      };
    },
    computed: {
      creditTransactions() {
        return store.state.company.creditTransactions;
      },
      periods() {
        var textOptions = { year: 'numeric', month: 'long' };
        var d = new Date();
        var period = [{
          value: d.getFullYear() + '-' + (d.getMonth() + 1),
          text: d.toLocaleDateString(this.i18n.global.locale, textOptions),
        }];
        var i;
        for (i = 0; i < 24; i++) {
          d.setMonth(d.getMonth() - 1);
          period.push({
            value: d.getFullYear() + '-' + (d.getMonth() + 1),
            text: d.toLocaleDateString(this.i18n.global.locale, textOptions),
          });
        }
        return period;
      },
      company() {
        return store.state.company.company;
      },
    },
    mounted() {
      var d = new Date();
      this.formdata.period = d.getFullYear() + '-' + (d.getMonth() + 1);
      companyApi.get('/companies/credit-transactions/' + this.$route.params.company_id + '/current', { notification: false }).then(response => store.commit('company/setCreditTransactions', response.data.content));
    },
    methods: {
      getCreditTransactions(mode, lead_uuid) {
        // if mode is 'lead', all transactions belonging to the lead are requested
        // if mode is 'period', mode is replaced to the given period and all leads of the month are requested
        mode === 'period' ? mode = this.formdata.period : '';
        companyApi.get('/companies/credit-transactions/' + this.company.id + '/' + mode + '/' + lead_uuid, { notification: false }).then(response => store.commit('company/setCreditTransactions', response.data.content));
      },
      deletable(creditTransaction) {
        if (this.user.is_admin && this.dayjsHelper.isYoungerThan(creditTransaction.created_at, 14) &&
          (creditTransaction.transaction_type.name === 'top-up-bank' || creditTransaction.transaction_type.name === 'plus-correction-booking' || creditTransaction.transaction_type.name === 'minus-correction-booking' || creditTransaction.transaction_type.name === 'gift')) {
          return true;
        } else {
          return false;
        }
      },
      deleteTransaction(transaction) {
        store.dispatch('company/deleteBooking', transaction);
      },

    },
  };
</script>

<style scoped>

</style>
