<template>
  <div class="relative py-2 sm:py-0 text-xs cursor-pointer z-50" @click="open.localesMenu = !open.localesMenu">
    <span class="sm:hover:text-white">{{ activeLocale }} <font-awesome-icon :icon="['fas', 'caret-down']"
                                                                            class="ml-1 mt-1"
    /></span>
    <div v-if="open.localesMenu" class="relative sm:absolute left-0 bg-black cursor-pointer">
      <div v-for="locale in locales"
           :key="locale.locale"
           class="block py-2 p-2 hover:text-white"
           @click="selectLocale(locale.locale)"
      >
        {{ locale.label }}
      </div>
    </div>
  </div>
</template>

<script>
  import { loadAndSetLocale, LOCALES } from '../../plugins/i18n';
  import store from './../store/mainStore';

  export default {
    name: 'LocalePicker',
    props: {},
    data() {
      return {
        open: {
          localesMenu: false,
        },
      };
    },
    computed: {
      activeLocale() {
        console.log('this.i18n.global.locale: ' + this.i18n.global.locale);
        console.log('this.locales: ' + this.locales);
        if (this.i18n.global.locale && this.i18n.global.locale.length > 0) {
          return this.locales[this.i18n.global.locale].label || this.i18n.global.locale;
        } else {
          return this.locales['de-CH'].label;
        }
      },
      locales() {
        return LOCALES;
      },
    },
    methods: {
      selectLocale(locale) {
        loadAndSetLocale(locale).then(() => {
          store.dispatch('localeChangedEvent', locale);
        });
      },
    },
  };
</script>

<style scoped>

</style>
