<template>
  <div class="panel mt-4">
    <div class="flex flex-col items-center justify-center">
      <p class="text-center font-extrabold text-xl mt-3">{{ $t('customerArea.finishLead.companyFinder.whatIsImportant') }}</p>
      <div class="text-center mt-4">{{ $t('customerArea.finishLead.companyFinder.importantInfo') }}</div>

      <div class="flex flex-col w-full max-w-xl">
        <div class="w-full mt-5">
          <label class="relative flex justify-center text-base font-semibold text-center">{{ $t('customerArea.finishLead.companyFinder.localCompanies') }}</label>
          <input v-model="local"
                 class="rounded-lg appearance-none bg-gray-200 h-2 w-full mt-5"
                 data-cy="input-range-local"
                 data-gtm="company-finder-local"
                 max="1"
                 min="0"
                 step="0.1"
                 type="range"
          >
          <div class="flex justify-between">
            <div class="text-sm mt-1">{{ $t('customerArea.finishLead.companyFinder.unImportant') }}</div>
            <div class="text-sm mt-1">{{ $t('customerArea.finishLead.companyFinder.important') }}</div>
          </div>
        </div>

        <div class="w-full mt-4 ">
          <label class="relative flex justify-center text-base font-semibold text-center">{{ $t('customerArea.finishLead.companyFinder.priceOrQuality') }}</label>
          <input v-model="quality_price"
                 class="rounded-lg appearance-none bg-gray-200 h-1 w-full mt-5"
                 data-cy="input-range-price-quality"
                 data-gtm="company-finder-price-quality"
                 max="1"
                 min="0"
                 step="0.1"
                 type="range"
          >
          <div class="flex justify-between">
            <div class="text-sm mt-1">{{ $t('customerArea.finishLead.companyFinder.cheapPrice') }}</div>
            <div class="text-sm mt-1">{{ $t('customerArea.finishLead.companyFinder.highQuality') }}</div>
          </div>
        </div>

        <div v-if="appName === 'Vipany'" class="w-full mt-4">
          <label class="relative flex justify-center text-base font-semibold text-center">{{ $t('customerArea.finishLead.companyFinder.whenQuestion') }}</label>
          <input v-model="speed"
                 class="rounded-lg appearance-none bg-gray-200 h-1 w-full mt-5"
                 data-cy="input-range-speed"
                 data-gtm="company-finder-speed"
                 max="5"
                 min="0"
                 step="0.5"
                 type="range"
          >
          <div class="flex justify-between">
            <div class="text-sm mt-1">{{ $t('customerArea.finishLead.companyFinder.immediately') }}</div>
            <div class="text-sm mt-1">{{ $t('customerArea.finishLead.companyFinder.suitableFirst') }}</div>
          </div>

          <input v-if="weekendIncluded && 1 === 2"
                 v-model="weekendSearch"
                 data-cy="input-checkbox-weekend-search"
                 data-gtm="company-finder-weekend-search"
                 name="Auch am Wochenende suchen"
                 type="checkbox"
          >
        </div>
      </div>

      <div class="relative w-full pt-7 sm:pt-8">
        <div class="absolute text-lg inset-x-0 text-center font-semibold opacity-0 transition duration-700 ease-in-out">
          <span>{{ $t('customerArea.finishLead.companyFinder.companiesNewSorted') }} </span>Lokale Firmen zuerst.
        </div>
        <div class="absolute text-lg inset-x-0 text-center font-semibold opacity-0 transition duration-700 ease-in-out">
          <span>{{ $t('customerArea.finishLead.companyFinder.companiesNewSorted') }} </span>{{ $t('customerArea.finishLead.companyFinder.cheapFirst') }}
        </div>
        <div class="absolute text-lg inset-x-0 text-center font-semibold opacity-0 transition duration-700 ease-in-out">
          <span>{{ $t('customerArea.finishLead.companyFinder.companiesNewSorted') }} </span>{{ $t('customerArea.finishLead.companyFinder.qualityFirst') }}
        </div>
        <div :class="{ 'opacity-100':(direction === 'match') }" class="absolute text-md inset-x-0 text-center font-semibold opacity-0 transition duration-700 ease-in-out">
          <span v-if="express"> {{ $t('customerArea.finishLead.companyFinder.notRecommendedWhenFast') }} </span>
          <span class="font-normal">{{ $t('customerArea.finishLead.companyFinder.staggered') }}</span><br>
          {{ $t('customerArea.finishLead.companyFinder.matchingCompaniesHaveMoreTime') }}
        </div>
        <div :class="{ 'opacity-100':(direction === 'speed') }" class="absolute text-md inset-x-0 text-center font-semibold opacity-0 transition duration-700 ease-in-out">{{ $t('customerArea.finishLead.companyFinder.fasterQuotes') }}</div>
      </div>

      <div class="mt-16">
        <div class="flex justify-center">
          <a class="btn btn-primary btn-lg mb-2 mt-5 "
             data-cy="btn-save"
             href="#form"
             role="button"
             @click="save"
          >{{ $t('general.next') }}</a>
        </div>

        <!--                <p v-if="lastEmail.hasOwnProperty('$d')" class="text-center font-small mt-2">{{ $t('customerArea.finishLead.companyFinder.lastCompanyDate') }} <strong>{{ showDateTime(lastEmail) }}{{ $t('customerArea.finishLead.companyFinder.clock') }}</strong> {{ $t('customerArea.finishLead.companyFinder.asked') }}</p>-->
      </div>

      <div v-if="1 === 2" class="hidden w-full md:flex justify-end mt-12">
        <circle-button v-if="showSendingGroups > 0"
                       :icon="['fal','chevron-left']"
                       class="mr-3"
                       size="sm"
                       @click="showSendingGroups--"
        />
        <circle-button v-if="showSendingGroups < sendingGroups.length-3"
                       :icon="['fal','chevron-right']"
                       size="sm"
                       @click="showSendingGroups++"
        />
      </div>
      <div v-if="times.length > 0 && 1 === 2" class="w-full flex flex-col md:flex-row mt-5">
        <div v-for="group in sendingGroups"
             :key="'group' + group"
             :class="{ 'md:hidden':(group < showSendingGroups || group > showSendingGroups + 2) }"
             class="w-full md:w-1/3 flex flex-col md:px-2"
        >
          <div v-if="group===0 || speed === 0" :class="{ 'opacity-0':(group>0)}" class=""><span class="text-3xl font-extrabold mr-2">{{ group + 1 }}.</span><span class="text-lg font-semibold mr-2">{{ $t('customerArea.finishLead.companyFinder.prioShort') }}</span>{{ $t('customerArea.finishLead.companyFinder.askImmediatelly') }}</div>
          <div v-if="group>0 && speed > 0" class="mt-4 md:mt-0">
            <span class="text-3xl font-extrabold mr-2">{{ group + 1 }}.</span><span class="text-lg font-semibold mr-2">{{ $t('customerArea.finishLead.companyFinder.prioShort') }}</span>{{ $t('customerArea.finishLead.companyFinder.askOnTime', {timeFromNow: this.dayjsHelper.timeFromNow(times[(group)].date)}) }} <span v-if="times[(group)].night"><br>{{ $t('customerArea.finishLead.companyFinder.noNight') }}</span>
            <span v-if="times[(group)].weekend"><br>{{ $t('customerArea.finishLead.companyFinder.afterWeekend') }}</span>
          </div>
          <div :class="{ 'no-mail-time':(group>0 && speed == 0)}" class="flex flex-wrap justify-center">
            <div v-for="sendingSchedule in sendingSchedules.filter(item => item.sending_group === (group) && !item.banned)"
                 :key="sendingSchedule.id"
                 :class="{ 'bg-yellow-200': (show.companyExplanation === sendingSchedule.id) }"
                 class="w-full border border-yellow-400 rounded mt-4 p-2"
            >
              <div v-if="show.companyExplanation !== sendingSchedule.id" @click="show.companyExplanation = sendingSchedule.id">
                <div class="font-semibold cursor-pointer">{{ sendingSchedule.company.name }}</div>
                <div class="d-flex justify-content-between cursor-pointer">
                  <div class="text-xs">{{ getCompanyLocation(sendingSchedule.company.locations, sendingSchedule.postcode_id).city }}</div>
                </div>
              </div>
              <div v-else class="cursor-pointer" @click="show.companyExplanation = 0">
                <div v-if="group === 0" class="font-semibold">{{ $t('customerArea.finishLead.companyFinder.matchPerfect') }}</div>
                <div v-else-if="group === 1" class="font-semibold">{{ $t('customerArea.finishLead.companyFinder.matchWell') }}</div>
                <div v-else-if="group === 2" class="font-semibold">{{ $t('customerArea.finishLead.companyFinder.match') }}</div>
                <div v-else-if="group < 7" class="font-semibold">{{ $t('customerArea.finishLead.companyFinder.matchOk') }}</div>
                <div v-else class="font-semibold">{{ $t('customerArea.finishLead.companyFinder.badMatch') }}</div>
                <div v-if="group === 0">{{ $t('customerArea.finishLead.companyFinder.weAskNow') }}</div>
                <div v-else>
                  <div>{{ $t('customerArea.finishLead.companyFinder.askCompanyIf', { timeFromNow: this.dayjsHelper.timeFromNow(times[(group)].date), desired_quotes: lead.desired_quotes}) }}</div>
                  <div class="pt-2">{{ $t('customerArea.finishLead.companyFinder.requestTime', { showDateTime: this.dayjsHelper.showDateTime(times[(group)].date)}) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="1 === 2">
        <div>
          <div class="flex justify-center pt-3">
            <a class="btn btn-primary btn-lg mb-2 mt-5"
               data-cy="btn-save"
               href="#form"
               role="button"
               @click="save"
            >{{ $t('general.next') }}</a>
          </div>

          <p v-if="lastEmail.hasOwnProperty('$d')" class="text-center font-small mt-2 mb-4">{{ $t('customerArea.finishLead.companyFinder.lastCompanyDate') }} <strong>{{ this.dayjsHelper.showDateTime(lastEmail) }}{{ $t('customerArea.finishLead.companyFinder.clock') }}</strong> {{ $t('customerArea.finishLead.companyFinder.asked') }}</p>
        </div>
      </div>
    </div>
    <spinner v-if="waitForServerResponse" />
  </div>
</template>

<script>
  import { companyMixin } from '../../../plugins/mixin';
  import CircleButton from '../../snippets/CircleButton.vue';
  import leadFormApi from '../../connections/leadForm';
  import store from '../../store/mainStore';
  import Spinner from '../../snippets/Spinner.vue';

  export default {
    name: 'CompanyFinder',
    components: { Spinner, CircleButton },
    mixins: [companyMixin],
    props: {
      lead: Object,
      // no use
      showDesiredQuotes: Boolean,
    },
    emits: ['success'],

    data() {
      return {
        sendingSchedules: JSON.parse(JSON.stringify(store.state.customer.sendingSchedules)),
        speed: this.appName === 'Vipany' ? 1 : 0,
        local: 0.5,
        quality_price: 0.5,
        times: [],
        lastEmail: {},
        weekendIncluded: false,
        weekendSearch: false,
        direction: '',
        sendingGroups: [],
        showSendingGroups: 0,
        timerCount: -1, // -> must be -1, see timerCount()
        finish: false,
        editEmail: false,
        editPhone: false,
        phone: '',
        express: false,
        show: {
          range: false,
          // used when speed is changed because of date
          direction: true,
          companyExplanation: 0,
        },
        waitForServerResponse: false,
      };
    },
    watch: {
      speed: function (newVal, oldVal) {
        this.setTimes();
        this.show.direction ? newVal > oldVal ? this.direction = 'match' : this.direction = 'speed' : this.show.direction = true;
      },
      local: function (newVal, oldVal) {
        this.setPriorityValue();
        newVal > oldVal ? this.direction = 'local' : this.direction = 'notLocal';
      },
      quality_price: function (newVal, oldVal) {
        this.setPriorityValue();
        newVal > oldVal ? this.direction = 'quality' : this.direction = 'price';
      },
      weekendSearch: function () {
        this.setPriorityValue();
      },
    },
    created() {
      store.dispatch('customer/getCompanyFinderData', this.$route.params.lead_uuid);
      if (!this.lead.hasOwnProperty('sending_schedules')) {
        store.dispatch('customer/getSendingSchedules', this.$route.params.lead_uuid);
      }
    },
    mounted() {
      this.setPriorityValue();
      this.slideDown();
      this.setSpeed();
      setInterval(() => {
        this.countdown();
      }, 1000);
    },
    methods: {
      save() {
        this.waitForServerResponse = true;

        try {
          // GTM Event, used for Analytics Tracking
          window.dataLayer.push({
            'event': 'company_finder_filled',
            'leadUuid': this.$route.params.lead_uuid,
            'local': this.local,
            'priceQuality': this.quality_price,
            'speed': this.speed,
          });
        } catch (error) {
          Bugsnag.notify(error);
        }

        var sendingData = {
          leadUuid: this.$route.params.lead_uuid,
          local: this.local,
          quality_price: this.quality_price,
          speed: this.speed,
          weekendIncluded: this.weekendIncluded,
          weekendSearch: this.weekendSearch,
          sendingSchedules: this.sendingSchedules.map(({ company, ...keepAttrs }) => keepAttrs),
        };

        console.log('CompanyFinder.vue: save ' + this.$route.params.lead_uuid, sendingData);

        leadFormApi.post('leads/' + this.$route.params.lead_uuid + '/company-finder', sendingData)
            .then(response => [
                console.log('CompanyFinder.vue: successful saved ' + this.$route.params.lead_uuid),
                store.commit('customer/setCurrentLead', response.data.content.lead),
                this.$emit('success', 'CompanyFinder'),
                this.waitForServerResponse = false,
              ],);
      },
      setTimes() {
        for (let i = 0; i < this.sendingSchedules.length; i++) {
          this.sendingSchedules[i].workInterval = this.sendingSchedules[i].interval * this.speed;
          // if(this.speed == 0 && this.sendingSchedules[i].success < 8) {
          //   this.sendingSchedules[i].banned = true;
          // } else {
          //   this.sendingSchedules[i].banned = null;
          // }
          this.sendingSchedules[i].banned = null;
        }
        let array = [];
        let night = false;
        let newDate = null;
        let weekend = false;
        for (let i = 0; i < this.sendingSchedules.length; i++) {

          if (this.sendingSchedules[i].workInterval > 0) {
            night = false;
            weekend = false;
          }
          newDate = this.dayjsHelper.dayjs().add(this.sendingSchedules[i].workInterval, 'minutes');
          if (newDate.isAfter(newDate.hour(19).minute(30))){
            newDate = this.dayjsHelper.dayjs(newDate).add(1, 'day').hour(7).minute(0).second(0);
            night = true;
          }
          if (newDate.day() === 6 && this.sendingSchedules[i].sending_group > 0 && !this.weekendSearch) {
            newDate = this.dayjsHelper.dayjs(newDate).add(2, 'days').hour(7).minute(0).second(0);
            this.weekendIncluded = true;
            weekend = true;
          }
          if (newDate.day() === 0 && !this.weekendSearch) {
            newDate = this.dayjsHelper.dayjs(newDate).add(1, 'days').hour(7).minute(0).second(0);
            this.weekendIncluded = true;
            weekend = true;
          }
          array[this.sendingSchedules[i].sending_group] = { date: newDate, night: night, weekend: weekend };
          if (i === this.sendingSchedules.length - 1) {
            this.lastEmail = newDate;
          }
        }
        this.times = array;
      },
      setPriorityValue() {
        this.setPriorityValues('distance', this.local, 10, true, true);
        this.setPriorityValues('review', this.quality_price, 3, false);
        // this.setPriorityValues('price', (1 - this.quality_price), 3, true)
        this.setPriorityValues('success', 1, 5, false);
        this.setPriorityValues('activity', 1, 4, false);
        this.sendingSchedules.sort(function (a, b) {
          return a.priority_list - b.priority_list;
        });

        let count = 0;
        let sendingGroup = 0;
        let sendingGroupArray = [0];
        let intervalArray = [0, 30, 30, 30, 30, 15, 15, 15, 15, 7, 7];
        for (let i = 0; i < this.sendingSchedules.length; i++) {
          if (count === 5 || (sendingGroup === 0 && i === this.lead.desired_quotes)) {
            sendingGroup++;
            sendingGroupArray.push(sendingGroup);
            this.sendingSchedules[i].interval = sendingGroup > 9 ? 7 : intervalArray[sendingGroup];
            count = 1;
          } else {
            this.sendingSchedules[i].interval = 0;
            count++;
          }
          this.sendingSchedules[i].sending_group = sendingGroup;
        }
        this.sendingGroups = sendingGroupArray;
        this.setTimes();
      },
      setPriorityValues(criteria, value, factor, asc, first = false) {
        this.sendingSchedules.sort(function (a, b) {
          return asc ? a[criteria] - b[criteria] : b[criteria] - a[criteria];
        });
        for (let i = 0; i < this.sendingSchedules.length; i++) {
          first ? this.sendingSchedules[i].priority_list = (i + 1) * value * factor : this.sendingSchedules[i].priority_list += (i + 1) * value * factor;
        }
      },
      slideDown() {
        if (this.lead.company_finder_finished_at) {
          this.show.range = true;
        } else {
          let localThis = this;
          setTimeout(function () {
            localThis.show.range = true;
          }, 2000);
        }
      },
      redirectToFrontend() {
        window.location.href = this.myVipanyUrl + '/autologin';
      },
      setSpeed() {
        if (this.dayjsHelper.isInLessThanDays(this.lead.date, 10)) {
          this.show.direction = false;
          this.speed = 0;
          this.express = true;
        }
      },
      countdown() {
        if (this.timerCount > 0) {
          if (this.timerCount === 0) {
            this.redirectToFrontend();
          } else {
            this.timerCount--;
          }
        }
      },
    },
  };
</script>

<style scoped>

    input[type="range"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 2px;
        padding: 0;
        background-color: #000;
        border-radius: 2px;
        outline: none;
        cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb {
        width: 24px;
        height: 24px;
        -webkit-appearance: none;
        appearance: none;
        cursor: ew-resize;
        background: #FFF;
        border-radius: 50%;
        border: 1px solid #000;

    }
</style>
