<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <textarea :id="'field' + field.id"
              ref="field"
              v-model="formdata['description']"
              :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
              :data-cy="'textareaStaticField'+field.id"
              :data-gtm-field-label="field.label"
              :data-gtm-field-id="field.id"
              :placeholder="field.label"
              :required="show && !!field.required"
              :state="labelState"
              class="mainform-color mainform-textarea"
              rows="3"
              @blur="logFieldBlur($event.target.value)"
              @focus="editFields"
    />
    <label-state-icon :label-state="labelState" :required="field.required" />
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'TextareaStaticField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState: function () {
        if (this.field.required) {
          return this.formdata['description'].length > 0 ? true : false;
        } else {
          if (this.formdata['description'] && this.formdata['description'].length > 0) {
            return true;
          } else {
            return null;
          }
        }
      },
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          if (['live', 'preview'].includes(this.mode)) {
            this.validateField();
          }
        }, deep: true,
      },
    },
    methods: {},
  };
</script>
