import Chat from '../components/sharedComponents/Chat.vue';
import Reviews from '../components/customerArea/CompanyFocus/Reviews.vue';
import CustomerQuote from '../components/customerArea/QuoteOverview/Quote.vue';
import VipanyReview from '../components/customerArea/VipanyReview.vue';
import EditLead from '../components/customerArea/EditLead.vue';

import FinishLead from '../components/customerArea/FinishLead.vue';
import QuoteOverview from '../components/customerArea/QuoteOverview.vue';
import CompanyFocus from '../components/customerArea/CompanyFocus.vue';
import MakeReview from '../components/customerArea/MakeReview.vue';
import DeleteAccount from '../components/customerArea/DeleteAccount.vue';
import Shop from '../components/publicArea/Shop.vue';
import Cart from '../components/sharedComponents/Cart.vue';

export default [
  //Customer
  { path: '/customers/:user_id', component: QuoteOverview, name: 'QuoteOverview', meta: { area: 'Customer', menu: 'menu.mainMenu.overview', requiresAuth: true } },
  { path: '/customers/:user_id/shop', component: Shop, name: 'CustomerShop', meta: { area: 'Customer', menu: 'menu.mainMenu.boxes', requiresAuth: true } },
  { path: '/customers/:user_id/cart', component: Cart, name: 'CustomerCart', meta: { area: 'Customer', requiresAuth: true } },
  { path: '/customers/:user_id/delete-account', component: DeleteAccount, name: 'DeleteAccount', meta: { area: 'Customer', requiresAuth: true } },
  { path: '/customers/:user_id/leads/:lead_uuid/', component: QuoteOverview, name: 'LeadQuotes', meta: { area: 'Customer', requiresAuth: true } },
  // has to be customer_company_id because of routeGuard.js line 42 (12.2.2021)
  { path: '/customers/:user_id/leads/:lead_uuid/companies/:customer_company_id', component: CompanyFocus, name: 'CompanyFocus', meta: { area: 'Customer', requiresAuth: true },
    children: [
      { path: 'chat', component: Chat, name: 'CustomerChat', meta: { area: 'Customer', requiresAuth: true } },
      { path: 'reviews', component: Reviews, name: 'CustomerReviews', meta: { area: 'Customer', requiresAuth: true } },
      { path: 'quote', component: CustomerQuote, name: 'CustomerQuote', meta: { area: 'Customer', requiresAuth: true } },
    ]
  },
  { path: '/customers/:user_id/leads/:lead_uuid/companies/:customer_company_id/make-review/:value?', component: MakeReview, name: 'MakeReview', meta: { area: 'Customer', requiresAuth: true } },
  { path: '/customers/:user_id/leads/:lead_uuid/vipany-review/:value?', component: VipanyReview, name: 'Vipany-Review', meta: { area: 'Customer', requiresAuth: true } },
  { path: '/customers/:user_id/leads/:lead_uuid/edit-lead', component: EditLead, name: 'EditLead', meta: { area: 'Customer', requiresAuth: true } },
  { path: '/customers/:user_id/leads/:lead_uuid/finish-lead', component: FinishLead, name: 'FinishLead', meta: { area: 'Customer', menu: 'menu.mainMenu.finishLead', requiresAuth: true, condition: 'this.currentLead.status === 0' } },
  { path: '/customers/:user_id/leads/:lead_uuid/company-finder', name: 'RedirectCompanyFinder', redirect: { name: 'FinishLead'}, meta: { area: 'Customer' } },

];
