<template>
  <div class="flex flex-col justify-center items-center">
    <p class="text-center text-base font-semibold mt-2 mb-5">{{ $t('publicArea.registerCompany.steps.area.title') }}</p>
    <Map v-if="classifications.length > 0"
         :locations="locations"
         :classifications="classifications"
         :classification="classifications[0]"
         :register="true"
         :company="companyProp"
         @saved="nextStep"
    />
  </div>
</template>

<script>
  import store from '../../../store/mainStore';
  import Map from '../../../companyArea/components/Map.vue';

  export default {
    name: 'Area',
    components: { Map },
    props: {
      companyProp: Object,
    },
    data() {
      return {
        register: {
          mode: true,
          saved: false,
        },
        showRadius: false,
        showCountries: false,
        showFinish: false,
        locations: JSON.parse(JSON.stringify(store.state.company.locations)),
      };
    },
    computed: {
      classifications() {
        return JSON.parse(JSON.stringify(store.state.company.classifications));
      },
      currentLocation() {
        return this.locations[0];
      },
    },

    methods: {
      nextStep() {
        this.$emit('next-step');
      },
      // submitRadius() {
      //   companyApi.patch('/locations/' + this.location.id + '/radius', this.location, { notification: false }).then(response => store.commit('company/setCompany', response.data.content.company));
      //
      //   this.$emit('next-step')
      // },
    },
  };
</script>

<style scoped>

</style>
