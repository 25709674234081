<template>
  <div class="absolute flex justify-center items-center inset-0 bg-white/50">
    <div class="sk-chase">
      <div class="sk-chase-dot sk-chase-dot-1" />
      <div class="sk-chase-dot sk-chase-dot-2" />
      <div class="sk-chase-dot sk-chase-dot-3" />
      <div class="sk-chase-dot sk-chase-dot-4" />
      <div class="sk-chase-dot sk-chase-dot-5" />
      <div class="sk-chase-dot sk-chase-dot-6" />
      <div class="sk-chase-dot sk-chase-dot-7" />
    </div>
  </div>
</template>

<script>
export default {
name: "Spinner"
}
</script>

<style scoped>

</style>
