<template>
  <div v-if="show.invalidEmail" class="absolute inset-0 bg-white/95 rounded p-3">
    <div class="w-full">
      <div class="text-xl font-semibold">
        {{ $t('companyArea.leads.leadOverview.invalidEmail.emailInvalid') }}
      </div>
      <button class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full"
              @click="$emit('close-edit', false)"
      >
        <font-awesome-icon :icon="['fal','times']" />
      </button>
      <div class="mt-3">
        <p>{{ $t('companyArea.leads.leadOverview.invalidEmail.emailInvalidInfo') }}</p>
      </div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-lg btn-secondary" data-cy="confirm-date-change" @click="update">{{ $t('companyArea.leads.leadOverview.invalidEmail.report') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import leadFormApi from '../../../connections/leadForm';

export default {
  name: 'InvalidEmail',
  components: {  },
  props: {
    show: Object,
    companyLead: Object,
  },
  emits: ['close-edit'],
  data() {
    return {};
  },
  methods: {
    update() {
      leadFormApi.patch('/company-leads/'+ this.companyLead.id +'/email-invalid', { }, { 'progress': true }).
          then(response => [this.$emit('close-edit', false)]);
    },
  },
};
</script>

<style scoped>

</style>
