<template>
  <confirmation-modal>
    <div v-if="website.quotes === '1' || website.quotes === 1 || $route.meta.area === 'Formcreator'">
      <p>{{ $t('formcreator.classifications.classificationModal.whatDoYouOffer') }}</p>
      <div class="form-company flex-column">
        <label v-for="option in treeSliced" class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox"
                 :checked="formdata.selected.find(obj => obj.number === option.number) !== undefined"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="selectOption(option)"
          >
          <span class="ml-2">{{ option.name }}</span>
        </label>
        <p v-if="tree.length > listAmount"
           class="text-center link-sm"
           style="margin: 40px 0;"
           @click="add5"
        >
          {{ $t('general.showMore') }}
        </p>
        <!--                    <div v-if="$route.meta.area !== 'formcreator'">-->
        <!--                        <p class="text-center headline">Keine passende Tätigkeit in der Liste gefunden? Erfassen Sie-->
        <!--                            Ihre-->
        <!--                            Arbeiten selbst!</p>-->
        <!--                        <div class="inner-addon right-addon">-->
        <!--                            <b-input class="input-search-round" placeholder="Arbeit erfassen" v-model="newSuggestion"-->
        <!--                                     v-on:keyup.enter="addSuggestion"></b-input>-->
        <!--                            <div @click="addSuggestion" class="fa-icon link">-->
        <!--                                <font-awesome-icon icon="plus"></font-awesome-icon>-->
        <!--                            </div>-->
        <!--                            <div class="response-icon" v-if="response">-->
        <!--                                <font-awesome-icon icon="check"></font-awesome-icon>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--        <div class="btn-wrapper">-->
        <!--          <button class="btn btn-lg btn-primary" @click="submitModal">{{ $route.meta.area === 'company' ? 'Speichern' : 'Ok'}}</button>-->
        <!--        </div>-->
      </div>
    </div>
    <div v-else>
      <p>{{ $t('formcreator.classifications.classificationModal.theseJobs') }}</p>
      <ul>
        <li v-for="option in treeSliced"
            :key="option.id"
        >
          {{ option.name }}
        </li>
      </ul>
      <p v-if="tree.length > listAmount"
         class="text-center link-sm"
         style="margin: 40px 0;"
         @click="add5"
      >
        {{ $t('general.showMore') }}
      </p>
    </div>
  </confirmation-modal>
<!--    <div>-->
<!--        <b-modal :id="'suggestionModal' + currentSuggestion.id" :title="currentSuggestion.name" @ok="submitModal" @close="resetModal" @hidden="resetModal" hide-footer>-->
<!--            -->
<!--        </b-modal>-->
<!--    </div>-->
</template>

<script>
  import store from '../../store/mainStore';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

  export default {
    name: 'ClassificationModal',
    components: { ConfirmationModal },
    props: {
      tree: {
        type: Array,
        default: () => [],
      },
      formdata: {},
      currentSuggestion: {},
    },
    emits: ['update:formdata'],
    data() {
      return {
        website: store.state.company.website,
        listAmount: 5,
        newSuggestion: '',
        response: false,
      };
    },
    computed: {
      treeSliced() {
        return this.tree.filter(obj => obj.is_service === 1).slice(0, this.listAmount);
      },
    },
    methods: {
      add5() {
        this.listAmount += 5;
      },
      addSuggestion() {
        this.formdata.customSuggestions.push({
          'classification_id': this.currentSuggestion.id,
          'name': this.newSuggestion,
        });
        this.newSuggestion = '';

        this.response = true;

        (function(that) { // you create an IIFE (see http://tinyurl.com/js-iife)
          setTimeout(function() {
            that.response = false;
          }, 3000);
        }(this));
      },
      selectOption: function(option) {
        if(this.formdata.selected.find(item => item.id === option.id)) {
          for(var i = 0; i < this.formdata.selected.length; i++) {
            if(this.formdata.selected[i].id === option.id) {
              this.formdata.selected.splice(i, 1);
              i--;
            }
          }
        } else {
          this.formdata.selected.push(option);
        }
      },
      submitModal() {
        // not in register state
        if(this.$route.meta.area === 'company') {
          store.dispatch('company/storeClassifications', this.formdata);
        }
        this.$bvModal.hide('suggestionModal' + this.currentSuggestion.id);
      },
      resetModal() {
        // not in register state
        if(this.$route.meta.area === 'company') {
          this.formdata.selected = JSON.parse(JSON.stringify(store.state.company.classifications));
        }
      },
    },
  };
</script>

<style scoped>

</style>
