<template>
  <data-preparation v-if="['Customer','User','Public','Company','Formcreator'].includes(area)" :area="area" />
</template>

<script>
  import DataPreparation from './components/DataPreparation.vue';

  export default {
    components: { DataPreparation },

    computed: {
      area() {
        return (this.$route.meta.area);
      },
    },
    mounted() {
      console.log('Layout.vue: mounted');
    },
  };
</script>

<style>

</style>
