import { createStore } from 'vuex';
import FormcreatorStore from './formcreatorStore';
import CompanyStore from './companyStore';
import CustomerStore from './customerStore';
import AuthStore from './authStore';
import ShopStore from './shopStore';
import locationApi from '../connections/location';
import Bugsnag from '@bugsnag/js';
import { VueCookieNext } from 'vue-cookie-next';
import { loadAndSetLocale } from '../../plugins/i18n';
import leadFormApi from '../connections/leadForm';

const getDefaultState = () => {
  return {
    menu: [],
    postcodes: [],
    user: {},
    companies: [],
    cookiesAccepted: true,
    register: {
      company: false, // when true and sb registers a vipany ID he is redirected to register-company
      classification: '',
    },
    validationErrors: null,
    myVipanyBackendVersion: null,
    sessionId: '',
    locale: null,
  };
};

const MainStore = {
  state: getDefaultState(),

  getters: {
    isAuthenticated(state) {
      return state.user ? Object.keys(state.user).length > 0 : false;
    },
    user(state) {
      return state.user;
    },
    isGuest(state) {
      return state.user ? Object.keys(state.user).length === 0 : true;
    },
    isAdmin(state) {
      return state.user ? state.user['is_admin'] : false;
    },
    hasCompanyById: (state) => (companyId) => {
      return state.companies.length && state.companies.find(company => company.company_id === companyId);
    },
    areaGuard: (state) => (companyId, area) => {
      return state.companies.find(company => company.company_id === companyId)[area];
    },
    getLocale: state => state.locale,
  },

  actions: {
    getPostcodes(context, country) {
      var countryCode;
      !country ? countryCode = 'CH' : countryCode = country.iso2;
      locationApi.get('/countries/' + countryCode + '/postcodes', { notification: false, progress: false }).then(response => context.commit('setPostcodes', response.data));
    },
    localeChangedEvent({ commit }, eventData) {
      console.log('mainStore.js localeChangedEvent: ' + eventData);
      commit('localeChangedEvent', eventData);
    },
    resetAllStoreStates(context) {
      context.commit('resetState');
      context.commit('auth/resetState');
      context.commit('company/resetState');
      context.commit('customer/resetState');
      context.commit('shop/resetState');
      // context.commit('formcreator/resetState'); -> TODO: activate when formcreator store is created
    },
    async userLoggerEvent(context, formdata) {
      if (!context.sessionId) {
        let randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        context.commit('setSessionId', randomId);
        formdata.session_id = randomId;
      } else {
        formdata.session_id = context.state.sessionId;
      }
      await leadFormApi.post('/user-logger/event', formdata, { notification: false, progress: false });
    },
    async fetchSessionIdIfNeeded(context, userId) {
      if (!context.sessionId) {
        try {
          leadFormApi.get('/users/' + userId + '/user-logger/session', { notification: false, progress: false }).then(response => context.commit('setSessionId', response.data.content));
        } catch (error) {
          console.error('Fehler beim Abrufen der sessionId:', error);
        }
      }
    },
  },

  mutations: {
    setMenu(state, menu) {
      state.menu = menu;
    },
    setUser(state, user, setCookie = true) {
      // We intentionally set the user even if it is null.
      console.log('mainStore.js setUser: ' + JSON.stringify(user));
      state.user = user;
      if (setCookie) {
        VueCookieNext.setCookie('user', user);
      }
      Bugsnag.setUser(user.id, user.email);
      console.log('set user:' +  user.id + ' ' + user.email + ' ' + user.locale);
      let locale = user?.locale ?? 'de-CH';
      locale = locale.replace('en-CH', 'en-US'); // TODO remove after all en-CH are replaced with en-US in the database and in the cookies
      loadAndSetLocale(locale);
    },
    setSelectedCompany(state, company) {
      state.user.selected_company = company;
    },
    setPostcodes(state, postcodes) {
      state.postcodes = postcodes['data'];
    },
    setRegisterCompany(state) {
      state.register.company = true;
    },
    setRegisterClassification(state, classifications) {
      state.register.classification = classifications;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },

    setCookiesAccepted(state, payload) {
      //state.cookiesAccepted = payload;
      VueCookieNext.setCookie('cookies', payload);
    },
    resetState(state) {
      VueCookieNext.removeCookie('user');
      // Merge rather than replace, so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setMyVipanyBackendVersion(state, version) {
      state.myVipanyBackendVersion = version;
    },
    localeChangedEvent(state, eventData) {
      state.locale = eventData;
    },
    SET_VALIDATION_ERROR(state, error) {
      state.validationErrors = error;
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
  },
};

export default createStore({
  strict: import.meta.env.PROD !== true, // should not be enabled in production
  modules: {
    main: MainStore,
    company: CompanyStore,
    formcreator: FormcreatorStore,
    auth: AuthStore,
    customer: CustomerStore,
    shop: ShopStore,
  },

});
