<script>
  import store from '../store/mainStore';
  import FieldCreator from './FieldCreator.vue';
  import FormContainer from './FormContainer.vue';
  import LeadContent from '../companyArea/Leads/LeadOverview/LeadContent.vue';
  import Layout from '../Layout.vue';

  export default {
    name: 'FormCreator',
    components: {
      Layout,
      FormContainer,
      LeadContent,
      FieldCreator,
    },

    data() {
      return {
        country: {},
        classification: {},
        field: {},
        fieldOnFocus: {},
        mode: 'preview', // create, preview, live
        formType: 'compact',
        formView: true,
        formTestdata: {
          description: 'Inhalt',
          date: '2020-07-03',
          city: 'Donzhausen',
          postcode: 8583,
          address: 'Im Rötler 33',
          name: 'Philipp',
          surname: 'Etter',
          phone: '0765641234',
          email: 'info@vipany.ch',
          dynamic_form: {
            '18': 'Holzgasse 47',
            '21': 22,
            '24': 25,
            '27': 28,
            '30': 31,
            '33': 35,
            '46': 52,
            '64': 65,
            '67': 69,
            '70': 72,
            '83': true,
            '84': true,
            '133': 'Urtenen-Schönbühl',
            'zip133': '3322',
          },
        },
        companylead: {
          lead: {
            desired_quotes: 4,
          },
          status: 1,
        },
        extended: false,
        priceCalc: false,
        fields: [],
      };

    },

    computed: {
      form() {
        return store.state.formcreator.form;
      },
        buttonLabel() {
            return 'Formular ' + (this.mode === 'create' ? 'speichern' : 'bearbeiten');
        },
      vuexFields() {
        return store.state.formcreator.fields;
      },
      listFields() {
        let fields = JSON.parse(JSON.stringify(store.state.formcreator.fields));
        return fields.sort(function (a, b) {
          var x = a['sort_list'];
          var y = b['sort_list'];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      },
      postcodes() {
        return store.state.main.postcodes;
      },
      preview() {
        if (store.state.formcreator.fields && store.state.formcreator.fields.length > 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      vuexFields: {
        immediate: true,
        handler() {
          this.fields = store.state.formcreator.fields;
        }, deep: true,
      },
    },
    mounted() {
      if (!this.form.hasOwnProperty('id')) {
        this.mode = 'create';
      }
    },
    methods: {
      formExisting(fields, postcodes) {
        this.fields = fields;
        if (postcodes) {
          this.postcodes = postcodes;
        }
        this.fields.length > 0 ? this.preview = true : this.preview = false;
      },
      getFieldOnFocus(fieldOnFocus) {
        store.commit('formcreator/setField', this.fields.find(obj => obj.id === fieldOnFocus));
        // console.log(this.field);
      },
      setMode() {
        let newMode = this.mode === 'preview' ? 'create' : 'preview';
        store.dispatch('formcreator/setMode', newMode)
            .then(this.mode = newMode);
      },
    },
  };
</script>

<template>
  <layout>
    <div class="panel grid grid-cols-6">
      <div class="col-span-2">
        <button v-if="form.hasOwnProperty('id')" class="btn btn-lg btn-primary" @click="setMode">{{ buttonLabel }}</button>
        <field-creator v-if="mode === 'create'" :form-view="formView" />
      </div>
      <div v-if="preview" class="col-span-4">
        <form-container v-if="formView"
                        :fields="fields"
                        :form="form"
                        :form-type="formType"
                        :mode="mode"
                        :postcodes="postcodes"
                        @field-on-focus="getFieldOnFocus"
        />
        <lead-content v-else
                      :company-lead="companylead"
                      :fields="listFields"
                      :formdata="formTestdata"
                      view="create"
                      @field-on-focus="getFieldOnFocus"
        />
      </div>
    </div>

    <template v-slot:filters>
      <div v-if="preview" class="flex justify-center mt-4">
        <button class="btn btn-md btn-secondary mr-2" @click="formView = true; formType = 'compact';">Formular live</button>
        <button class="btn btn-md btn-secondary mr-2" @click="formView = true; formType = 'extended';">Formular extended</button>
        <button class="btn btn-md btn-secondary" @click="formView = false">Anfragenübersicht</button>
        <button class="btn btn-md btn-secondary" @click="formView = true; formType = 'calculator';">Price Calculator</button>
      </div>
    </template>

    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">{{ form.name }}</p>
    </template>
  </layout>
</template>
