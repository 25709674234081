import axios from 'axios';
// import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

/**
 * This connection is ONLY for the FormPreviewComponent, which gets built
 * as an own component and loaded in the landing-pages.
 * It's difference is:
 * - it does not check for 401 status errors and user
 *   logout, as this function call to the VUEX store pulls in the hole
 *   store as dependency in the prebuild component.
 *   -> which results in errors
 * - it does not check for CSRF token, as we intentionally disabled
 *   the CORS check on the leadform backend on the leads store route,
 *   as we take this risk rather than having users not being able
 *   to send the form.
 */
let backendUrl;
let landingpageUrl;
let myUrl;

try {
  if (window.Cypress) {
    console.log('IS Cypress');
    // Cypress Testing - could be from my-vipany, from landingpage or seo-websites project
    backendUrl = Cypress.env('BACKEND_API_URL') + '/lead-form';
    landingpageUrl = Cypress.env('LANDINGPAGE_URL');
    myUrl = Cypress.env('MY_VIPANY_URL');
  } else if (import.meta.env) {
    console.log('IS import\.meta\.env'); // must be escaped!
    // get from landingpage or my-vipany project, both with vue-vite
    backendUrl = import.meta.env.VITE_VUE_APP_BACKEND_API_URL + '/lead-form';
    landingpageUrl = import.meta.env.VITE_VUE_APP_LANDINGPAGE_URL;
    myUrl = import.meta.env.VITE_VUE_APP_MY_VIPANY_URL;
  } else if (window.location.hostname.split('.')[0] === 'testing') {
    console.log('IS testing');
    // Testing Domain
    backendUrl = import.meta.env.VITE_VUE_TESTING_BACKEND_API_URL + '/lead-form';
    landingpageUrl = import.meta.env.VITE_VUE_TESTING_LANDINGPAGE_URL;
    myUrl = import.meta.env.VITE_VUE_TESTING_MY_VIPANY_URL;
  }
} catch (e) {
  console.log('Checks in leadFormPreview.js raised an error');
  console.log('catch ', e);
}

console.log('backendUrl for backend: ' + backendUrl);
console.log('landingpageUrl for landingpage: ' + landingpageUrl);
console.log('myUrl for my: ' + myUrl);

const leadFormPreviewApi = axios.create(
  {
    baseURL: backendUrl,
    timeout: 50000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      accept: 'application/json',
    },
    withCredentials: true, // required to handle the CSRF token
    withXSRFToken: true,
  },
);


leadFormPreviewApi.baseUrl = backendUrl;
leadFormPreviewApi.landingpageUrl = landingpageUrl;
leadFormPreviewApi.myUrl = myUrl;

// error does not always have a response. there is no error.code. if there is a response, we can get the status code from error.response.status https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
// axiosRetry(leadFormPreviewApi, {
//     retries: 2,
//     retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
//     retryCondition: e => {
//       return isNetworkOrIdempotentRequestError(e) || (e.response && e.response.status === 429);
//     },
//   },
// );

export default leadFormPreviewApi;
