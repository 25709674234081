<template>
  <div>
    <div v-if="$route.meta.area === 'Formcreator'">
      <p style="margin: 0;">
        <span class="suggestion-branch"
              @click="selectOption(suggestion)"
        >
          <font-awesome-icon :class="{ 'fa-icon':true, 'icon-selected':(selected) }"
                             :icon="selected ? ['fas','check-circle'] : ['far','circle']"
          />
          {{ suggestion.name }} </span>
        <span @click="getClassificationTree">
          <font-awesome-icon class="information-icon"
                             :icon="['fal','chevron-right']"
          />
        </span>
      </p>
    </div>
    <div v-else-if="website.quotes === '1' || website.quotes === 1">
      <p class="suggestion-branch"
         @click="getClassificationTree"
      >
        <font-awesome-icon class="fa-icon" icon="folder-plus" />
        {{ suggestion.name }}
      </p>
    </div>
    <div v-else>
      <p style="margin: 0;">
        <span class="suggestion-branch"
              @click="selectOption(suggestion)"
        >
          <font-awesome-icon :class="{ 'fa-icon':true, 'icon-selected':(selected) }"
                             :icon="selected ? ['fas','check-circle'] : ['far','circle']"
          />
          {{ suggestion.name }} </span>
        <span @click="getClassificationTree">
          <font-awesome-icon class="information-icon"
                             icon="question-circle"
          />
        </span>
      </p>
    </div>
    <classification-modal v-if="show.modal"
                          :current-suggestion="suggestion"
                          :formdata="formdata"
                          :tree="tree"
                          @close="show.modal = false"
    />
  </div>
</template>

<script>
import store from '../../store/mainStore';
import ClassificationModal from './ClassificationModal.vue';
import searchBackendApi from '../../connections/seachBackend';

export default {
  name: 'ListBranch',
  components: { ClassificationModal },
  props: {
    suggestion: {},
    suggestions: {},
    formdata: {},
  },
  data() {
    return {
      website: store.state.company.website,
      tree: [],
      show: {
        modal: false
      }
    };
  },
  computed: {
    selected() {
      if(this.formdata.selected.find(obj => obj.number === this.suggestion.number)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getClassificationTree: function() {
      searchBackendApi.get('/get-classification-tree/de/' + this.suggestion.number).then(response => this.tree = response.data[0], this.show.modal = true);
    },
    selectOption: function(option) {
      if(this.formdata.selected.find(item => item.id === option.id)) {
        for(var i = 0; i < this.formdata.selected.length; i++) {
          if(this.formdata.selected[i].id === option.id) {
            this.formdata.selected.splice(i, 1);
            i--;
          }
        }
      } else {
        this.formdata.selected.push(option);
      }
    },
  },
};
</script>

<style scoped>

</style>
