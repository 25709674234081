import companyApi from '../connections/company';

const getDefaultState = () => {
  return {
    bulkOrders: [],
    order: {},
    orderProducts: [],
    companyProducts: [],
    customerProducts: [],
    companyProfile: {},
    companyProfiles: [],
    stocks: [],
  };
};

const ShopStore = {
  namespaced: true,
  state: getDefaultState(),

  getters: {},

  actions: {
    getOrderFromLocalStorage(context, area) {
      if (context.state.orderProducts.length === 0) {
        try {
          let storedCartData = localStorage.getItem('cart');
          if (!storedCartData) {
            return;
          }

          storedCartData = JSON.parse(storedCartData);
          // The area must be the same as we have put the cart in the localStorage, as the prices may vary
          if (storedCartData && storedCartData[0].area === area) {
            context.commit('setOrderProducts', storedCartData);
          }
        } catch (error) {
          // Handle error, e.g. localStorage is not supported, or data is corrupted
        }
      }
    },
    getCompanyShopProducts(context, company_id) {
      companyApi.get('/shop/companies/' + company_id + '/company-products', {
        notification: false,
        progress: false,
      }).then(response => [
        context.commit('setCompanyShopProducts', response.data.content.products),
        context.commit('setCompanyShopBulkOrders', response.data.content.bulkOrders),
        context.commit('setCompanyProfile', response.data.content.companyProfile),
      ]);
    },
    getCustomerShopProducts(context) {
      if (context.state.customerProducts.length === 0) {
        companyApi.get('/shop/customer-products', {
          notification: false,
          progress: false,
        }).then(response => [
          context.commit('setCustomerShopProducts', response.data.content.shopProducts),
          context.commit('setCompanyProfiles', response.data.content.shopCompanyProfiles)]);
      }
    },
    addToCart(context, orderProduct) {
      let orderProducts = JSON.parse(JSON.stringify(context.state.orderProducts));
      if (orderProducts.length > 0) {
        orderProducts = orderProducts.filter(item => item.shop_product_id !== orderProduct.shop_product_id);
      }
      orderProducts.push(orderProduct);
      context.commit('setOrderProducts', orderProducts);
    },
    removeFromCart(context, orderProduct) {
      let orderProducts = JSON.parse(JSON.stringify(context.state.orderProducts));
      if (orderProducts.length > 0) {
        orderProducts = orderProducts.filter(item => item.shop_product_id !== orderProduct.shop_product_id);
      }
      context.commit('setOrderProducts', orderProducts);
    },
    getClosestRetailer(context, postcode_id) {
      companyApi.get('/shop/retailers/closest?postcode_id=' + postcode_id, {
        notification: false,
        progress: false,
      }).then(response => [
        context.commit('setCompanyProfile', response.data.content.retailer)]);
    },

  },

  mutations: {
    setCompanyShopProducts(state, products) {
      state.companyProducts = products;
    },
    setCustomerShopProducts(state, products) {
      state.customerProducts = products;
    },
    setCompanyShopBulkOrders(state, bulkOrders) {
      state.bulkOrders = bulkOrders;
    },
    setCompanyShopStocks(state, stocks) {
      state.stocks = stocks;
    },
    setOrderProducts(state, orderProducts) {
      state.orderProducts = orderProducts;
      localStorage.setItem('cart', JSON.stringify(orderProducts));
    },
    setCompanyProfile(state, companyProfile) {
      state.companyProfile = companyProfile;
    },
    setCompanyProfiles(state, companyProfiles) {
      state.companyProfiles = companyProfiles;
    },
    setOrder(state, order) {
      if (order) {
        if (order.hasOwnProperty('shop_order_products')) {
          state.orderProducts = order.shop_order_products;
          delete order.shop_order_products;
        }
        state.order = order;
      }
    },
    resetState(state) {
      // Merge rather than replace, so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
  },
};
export default ShopStore;
