<template>
  <div v-if="status.icon"
       class="flex text-xs flex items-center"
       :class="{ 'cursor-pointer':(statusChange)}"
       @click="statusChange ? show.changeStatus = true : null"
  >
    <div class="w-5 h-5 flex items-center justify-center text-xs rounded-full text-white mr-2" :class="status.customClass">
      <font-awesome-icon :icon="status.icon" />
    </div>
    <span v-if="showText" :data-cy="area === 'Company' ? 'quote-status-' + companyLead.lead_id : 'quote-status-' + companyLead.company_id">{{ $t(status.text) }}</span>
  </div>
  <confirmation-modal v-if="show.changeStatus" :content-size="true" @close="show.changeStatus = false">
    <div class="font-semibold py-2 border-b border-gray-400 text-center">{{ companyLead.lead.name }} {{ companyLead.lead.surname }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="changeStatus(1)">{{ $t('companyLeadStatus.open') }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="changeStatus(13)">{{ $t('companyLeadStatus.visitScheduled') }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="changeStatus(2)">{{ $t('companyLeadStatus.quoteSent') }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="confirmed">{{ $t('companyLeadStatus.confirmed') }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="rejected">{{ $t('companyLeadStatus.lost') }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="noQuote">{{ $t('companyLeadStatus.noQuote') }}</div>
    <div class="text-md py-3 bg-gray-50 hover:bg-blue-100 text-center cursor-pointer px-3 border-b border-gray-200" @click="noQuoteWanted">{{ $t('companyLeadStatus.noQuoteWanted') }}</div>
  </confirmation-modal>
</template>

<script>
  import ConfirmationModal from './ConfirmationModal.vue';
  import store from '../store/mainStore';

  export default {
    name: 'StatusCircle',
    components: { ConfirmationModal },
    props: {
      companyLead: Object,
      showText: {
        type: Boolean,
        default: true,
      },
      statusChange: {
        type: Boolean,
        default: false,
      },
      area: String,
    },
    data() {
      return {
        show: {
          changeStatus: false,
        },
      };
    },
    computed: {
      status() {
        let icon = null;
        let customClass = '';
        let text = '';
        if (this.area === 'customer') {
          if ([3, 4, 5].includes(this.companyLead.status)) {
            icon = ['fas', 'check'];
            customClass = 'bg-green-500';
            text = 'companyLeadStatus.taken';
          } else if ([0,1,2,13].includes(this.companyLead.status)) {
            icon = ['fas', 'question'];
            customClass = 'bg-blue-400';
            text = 'companyLeadStatus.interested';
          } else {
            icon = ['fal', 'times'];
            customClass = 'bg-red-600';
            text = 'companyLeadStatus.rejected';
          }
        } else if (this.area === 'company') {
          if (this.companyLead.status === 0) {
            icon = ['fal', 'plus'];
            customClass = 'bg-yellow-300';
            text = 'companyLeadStatus.new';
          } else if ([1, 2, 13].includes(this.companyLead.status)) {
            icon = ['fas', 'question'];
            if (this.companyLead.status === 1) {
              customClass = 'bg-blue-400';
              text = 'companyLeadStatus.open';
            } else if (this.companyLead.status === 2) {
              customClass = 'bg-yellow-500';
              text = 'companyLeadStatus.quoteSent';
            } else if (this.companyLead.status === 13) {
              customClass = 'bg-blue-600';
              text = 'companyLeadStatus.visitScheduled';
            }
          } else if (this.companyLead.status === 3) {
            icon = ['fal', 'check'];
            customClass = 'bg-green-500';
            text = 'companyLeadStatus.confirmed';
          } else if (this.companyLead.status === 4 || this.companyLead.status === 5) {
            icon = ['fal', 'circle'];
            customClass = 'bg-green-500';
            text = 'companyLeadStatus.completed';
          } else {
            icon = ['fal', 'times'];
            customClass = 'bg-gray-300';
            if (this.companyLead.status === 6) {
              text = 'companyLeadStatus.lost';
            } else if (this.companyLead.status === 7) {
              text = 'companyLeadStatus.cancellation';
            } else if (this.companyLead.status === 8) {
              text = 'companyLeadStatus.cancelled';
            } else if (this.companyLead.status === 9) {
              text = 'companyLeadStatus.cancelRejected';
            } else if (this.companyLead.status === 10) {
              text = 'companyLeadStatus.noQuote';
            } else if (this.companyLead.status === 11) {
              text = 'companyLeadStatus.noQuoteWanted';
            } else if (this.companyLead.status === 12) {
              text = 'companyLeadStatus.completed';
            }
          }
        }
        return { 'icon': icon, 'customClass': customClass, 'text': text };
      },
    },
    methods: {
      confirmed() {
        store.dispatch('company/confirmedJob', [this.companyLead.id])
          .then(response => this.show.changeStatus = false);
      },
      rejected() {
        store.dispatch('company/rejectedJob', [this.companyLead.id])
          .then(response => this.show.changeStatus = false);
      },
      noQuote() {
        store.dispatch('company/noQuote', [this.companyLead.id])
          .then(response => this.show.changeStatus = false);
      },
      noQuoteWanted() {
        store.dispatch('company/noQuoteWanted', [this.companyLead.id])
          .then(response => this.show.changeStatus = false);
      },
      changeStatus(status) {
        let formdata = {};
        formdata.status = status;
        store.dispatch('company/changeCompanyLeadStatus', [this.companyLead.id, formdata])
          .then(response => this.show.changeStatus = false);
      },
    },
  };
</script>

<style scoped>

</style>
