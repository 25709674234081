<template>
  <div class="flex flex-col mb-4">
    <div class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step1.jobDate') }}</label>
      <date-picker v-model:date="formdata.date" input-styles="w-full rounded border border-gray-400" :data-cy="'make-quote-date-' + step" />
    </div>
    <div class="flex flex-col mb-4">
      <label class="">{{ $t('companyArea.leads.leadOverview.makeQuote.step1.quoteType') }}</label>
      <select :id="'type-' + step"
              v-model="formdata.type"
              :data-cy="'make-quote-type-' + step"
              class="w-full border border-gray-400"
      >
        <option value="fixprice">{{ $t('companyArea.leads.leadOverview.makeQuote.step1.fixprice') }}</option>
        <option value="atCost">{{ $t('companyArea.leads.leadOverview.makeQuote.step1.effort') }}</option>
        <option value="estimated">{{ $t('companyArea.leads.leadOverview.makeQuote.step1.targetPrice') }}</option>
      </select>
    </div>
    <div v-if="step !== 'none'" class="flex justify-center mt-4">
      <button class=" btn btn-lg btn-primary" data-cy="make-quote-next" @click="changeStep">{{ $t('general.next') }}</button>
    </div>
  </div>
</template>

<script>
import DatePicker from '../../../../snippets/DatePicker.vue';
  export default {
    name: 'Step1',
    components: { DatePicker },
    props: {
      formdata: Object,
      validation: Object,
      step: String,
      companyLead: Object,
    },
    emits: [ 'next-step' ],
    data() {
      return {
      };
    },
    watch: {
    },
    methods: {
      changeStep() {
        this.$emit('next-step', 'Step2');
      },

    },
  };
</script>

<style scoped>

</style>
