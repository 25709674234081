<template>
  <layout>
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">{{ $t('formcreator.title', { appName: appName}) }}</p>
    </template>

    <div class="panel">
      <ul v-if="loaded">
        <li v-for="form in forms" class="cursor-pointer" @click="selectForm(form)">{{ form.name }} - {{ form.classification_number }} - {{ form.id }}</li>
      </ul>
    </div>
  </layout>
</template>

<script>
  import store from '../store/mainStore'
  import leadFormApi from '../connections/leadForm'
  import Layout from '../Layout.vue';

  export default {
    name: 'FormsComponent',
    components: { Layout },
    data () {
      return {
        forms: {},
        country: {
          iso2: 'CH',
        },
      }
    },
    computed: {
      loaded () {
        if (this.forms.length > 0) {
          return true
        } else {
          return false
        }
      },
    },
    beforeCreate () {
      leadFormApi.get('/countries/CH/forms', { notification: false }).then(response => this.forms = response.data.content)
    },
    methods: {
      selectForm (form) {
        console.log('FormsComponente.selectForm locale: ' + this.i18n.global.locale);

        store.dispatch('formcreator/getCountry', form);
        store.dispatch('formcreator/getClassification', form);
        store.commit('formcreator/setForm', form);
        store.dispatch('formcreator/getFields', this.i18n.global.locale);
        store.dispatch('getPostcodes', this.country);
        this.$router.push({ name: 'Formcreator' })
      },
    },
  }
</script>

<style scoped>

</style>
