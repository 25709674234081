<template v-slot:filters>
  <div v-if="validationErrors" class="flex justify-center px-3 mt-8 mb-4 sm:mb-0">
    <div class="h-auto w-auto bg-red-200 rounded-lg h-12 px-4 py-3 ">
      <div v-for="(v, k) in validationErrors" :key="k">
        <div v-for="error in v" :key="error">
          <p class="text-gray-600 font-medium text-base" data-cy="validation-error"><font-awesome-icon :icon="['fal','exclamation-triangle']" /> {{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '../store/mainStore';

  // Shows the default laravel validation failed errors (422)
  // Is Included in the main Layout

  export default {
    name: 'ValidationErrors',
    components: {},
    computed: {
      validationErrors() {
        return store.state.main.validationErrors;
      },
    },
  };
</script>

<style scoped>

</style>
