<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <textarea :id="'field' + field.id"
              ref="field"
              v-model="formdata.dynamic_form[field.id]"
              :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
              :data-cy="'textareaField'+field.id"
              :data-gtm-field-label="field.label"
              :data-gtm-field-id="field.id"
              :placeholder="field.label"
              :required="show && !!field.required"
              :state="labelState"
              class="mainform-color mainform-textarea"
              rows="3"
              @blur="logFieldBlur($event.target.value)"
              @focus="editFields"
    />
    <label-state-icon :label-state="labelState" :required="field.required" />
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'TextareaField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
        fieldInDynamicForm: false
      };
    },
    computed: {
      labelState: function () {
        if (this.field.required && !this.fieldInDynamicForm) {
          return false;
        } else if (this.field.required && this.fieldInDynamicForm) {
          return this.formdata.dynamic_form[this.field.id]?.length > 0;
        } else if (this.fieldInDynamicForm) {
          return this.formdata.dynamic_form[this.field.id]?.length > 0 ? true : null;
        } else {
          return null;
        }
      },
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
          if(!this.fieldInDynamicForm && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
            this.fieldInDynamicForm = true;
          }
        }, deep: true,
      },
    },
    mounted() {
      if (this.mode !== 'editContent' && !this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: '' });
      }
    },
    methods: {},
  };
</script>
