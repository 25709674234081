<template>
  <div>
    <p class="text-center headline mt-4">{{ $t('auth.autoLogin.autologinText') }}</p>
  </div>
</template>

<script>
  import store from '../store/mainStore';
  import Bugsnag from '@bugsnag/js';

  export default {
    name: 'Login',
    computed: {
      routeUri: function () {
        if (this.isAuthenticated) {
          // This ist still in use 26.4.24
          if (this.$route.query.action === 'finishLead') {
            store.dispatch('auth/getUser');
            return { name: 'FinishLead', params: { user_id: store.state.main.user.id, lead_uuid: this.$route.query.lead } };
          } else if (this.$route.query.action === 'dashboard') {
            return { name: 'PendingLeads', params: { company_id: this.$route.query.company } };
          } else if (this.$route.query.action === 'shop') {
            return { name: 'CompanyShop', params: { company_id: this.$route.query.company } };
          } else if (this.$route.query.action === 'noMoreMails') {
            return { name: 'PauseAccount', params: { company_id: this.$route.query.company } };
          } else if (this.$route.query.action === 'newLead') {
            return { name: 'NewLeads', params: { company_id: this.$route.query.company, lead_uuid: this.$route.query.lead } };
          } else if (this.$route.query.action === 'makeReview') {
            return '/customers/' + store.state.main.user.id + '/leads/' + this.$route.query.lead + '/companies/' + this.$route.query.company + '/make-review/' + this.$route.query.value;
          } else if (this.$route.query.action === 'showQuoteOverview') {
            return { name: 'QuoteOverview', params: { user_id: store.state.main.user.id } };
          } else if (this.$route.query.action === 'showLeadQuotes') {
            return { name: 'QuoteOverview', params: { user_id: store.state.main.user.id, lead_uuid: this.$route.query.lead } };
          } else if (this.$route.query.action === 'showChat') {
            return { name: 'CustomerChat', params: { user_id: store.state.main.user.id, lead_uuid: this.$route.query.lead, customer_company_id: this.$route.query.company } };
          } else if (this.$route.query.action === 'showQuote') {
            return { name: 'CustomerQuote', params: { user_id: store.state.main.user.id, lead_uuid: this.$route.query.lead, customer_company_id: this.$route.query.company } };

            // This is the solution from 26.4.24
          } else if (this.$route.query.action && this.$route.query.action.length > 0) {
            let params = { user_id: store.state.main.user.id }

            if(this.$route.query.hasOwnProperty('lead')) {
              params.lead_uuid = this.$route.query.lead
            }

            if(this.$route.query.hasOwnProperty('value')) {
              params.value = this.$route.query.value
            }

            if(this.$route.query.hasOwnProperty('company')) {
              if(this.$route.query.action.includes('Customer') || this.$route.query.action.includes('MakeReview')) {
                params.customer_company_id = this.$route.query.company
              } else {
                params.company_id = this.$route.query.company
              }
            }

            return { name: this.$route.query.action, params: params };

          } else if (store.state.main.user && store.state.main.user.employees && store.state.main.user.employees.length) {
            return '/companies/' + store.state.main.user.employees[0].company_id;
          }
          return '/customers/' + store.state.main.user.id;
        } else {
          return '/login';
        }
      },
      isAuthenticated() {
        return store.getters.isAuthenticated;
      },
    },
    async mounted() {
      let user = {};
      console.log('mounted Autologin.vue');
      if (this.$route.redirectedFrom && this.$route.redirectedFrom.query.autologin_token) {
        user = {
          userId: this.$route.redirectedFrom.params.user_id,
          autologinToken: this.$route.redirectedFrom.query.autologin_token,
        };
      }
      try {
        console.log('Try to get the User from auth/autologin with UserData: ' + user)
        let response = await store.dispatch('auth/autologin', user);
        if (store.state.main.user.id) {
          let context = this;

          // If the autologin route is called from the routeGuard
          if (this.$route.query.redirect) {
            setTimeout(function () {
              context.$router.push(context.$route.query.redirect);
              // window.location.href = context.$route.query.redirect;
            }, 10);
          } else {
            setTimeout(function () {
              // If the autologin route is called from the vipany-backend
              context.$router.push(context.routeUri);
            }, 10);
          }
        } else {
          console.log('Autologin.vue redirect to Login: ');
          let context = this;
          setTimeout(function () {
            context.$router.push({
              name: 'Login', query: {
                redirect: context.$route.query.redirect,
              },
            });
          }, 10);
        }
      } catch (e) {
        // Autologin did not work
        Bugsnag.notify(e, function (event) {
          event.severity = 'error';
          event.context = 'Autologin in FinishLead did not work.';
        });

        let context = this;
        setTimeout(function () {
          context.$router.push({
            name: 'Login', query: {
              redirect: context.$route.query.redirect,
            },
          });
        }, 10);
      }
    },
  };
</script>

<style scoped>

</style>
