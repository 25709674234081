<template>
  <layout>
    <template v-slot:header>
      <p class="font-sm sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-title">{{ $t('auth.forgotPassword.forgotPassword') }}</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">{{ $t('auth.forgotPassword.sendNewPassword') }}</p>
    </template>

    <div v-if="!show.success">
      <div class="w-full flex justify-center mt-10" />
      <div class="w-full flex justify-center mt-10">
        <form @submit="onSubmit">
          <div class="w-52 w-full">
            <input id="email"
                   v-model="form.email"
                   class="border border-gray-400 w-full"
                   :placeholder="$t('auth.forgotPassword.email')"
                   required
                   type="email"
                   data-cy="email"
            >
            <div class="flex justify-center mt-8">
              <button type="submit" class="btn btn-lg btn-primary" data-cy="get-password">{{ $t('auth.forgotPassword.getNewPassword') }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <div class="w-full flex justify-center mt-10">
        <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" data-cy="text">{{ $t('auth.forgotPassword.followEmail') }}</p>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import authApi from '../connections/auth';

  export default {
    name: 'ForgotPassword',
    components: { Layout },
    data() {
      return {
        form: {
          email: '',
        },
        show: {
          success: false,
        },
      };
    },
    computed() {
      text;
    },
    mounted() {
      authApi.get('/sanctum/csrf-cookie');
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        // The notification does not show up, because it's handled by a laravel default controller which sends a var called 'message' instead of a 'notification' as we do
        authApi.post('/password/email-forgotten', this.form, { 'progress': true }).then(() => {
          this.show.success = true;
        });
      },
    },
  };
</script>

<style scoped>

</style>
