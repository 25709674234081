const appCompanyData = {
    vipany: {
        name: "Vipany",
        company: "Platreform AG",
        street: "Im Rötler 33",
        zip: "8583",
        location: "Donzhausen",
        phone: "071 553 03 23",
        email: "info@vipany.ch",
        bank: {
            name: "Raiffeisenbank Mittelthurgau",
            iban: "CH51 8080 8005 2271 3693 2",
            swift: "RAIFCH22",
        },
    },
    topofferten: {
        name: "top-offerten",
        company: "top-offerten GmbH",
        street: "Im Rötler 33",
        zip: "8583",
        location: "Donzhausen",
        phone: "044 517 80 00",
        email: "info@top-offerten.ch",
        bank: {
            name: "Postfinance",
            iban: "CH60 0900 0000 6148 4530 3",
            swift: "POFICHBEXXX",
        },
    },
};

export default appCompanyData;