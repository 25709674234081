<template>
  <button class="relative w-14 h-14 flex items-center justify-center text-2xl border border-gray-300 rounded-full shadow-lg hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1 active:bg-gray-900 active:translate-y-0 cursor-pointer mb-6 mr-7" :class="{ 'bg-gray-100': !active, 'bg-gray-800 text-white': active, [textColor]: true }">
    <font-awesome-icon :icon="icon" />
    <span class="absolute text-gray-700 text-xs -bottom-6">{{ label }}</span>
  </button>

</template>

<script>
export default {
  name: 'RoundButton',
  props: {
    icon: Array,
    label: String,
    active: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: 'text-black'
    }
  },
};
</script>

<style scoped>

</style>
