<template>
  <router-view v-if="loaded" />
</template>

<script>
  import store from './store/mainStore';
  import { loadAndSetLocale } from '../plugins/i18n';
  import { dayjsHelper } from '../plugins/dayjsHelper';
  import leadFormApi from './connections/leadForm';
  import companyApi from './connections/company';
  import authApi from './connections/auth';
  import locationApi from './connections/location';
  import searchBackendApi from './connections/seachBackend';

  export default {

    name: 'DataPreparation',
    props: {
      area: String,
    },
    data() {
      return {
        app: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY,
      };
    },
    computed: {
      locale() {
        return store.getters.getLocale;
      },
      vuex() {
        return store.state.company;
      },
      user() {
        return store.state.main.user;
      },
      loaded() {
        if (this.area === 'Public') {
          return true;
        } else if (this.area === 'Customer' && this.$route.params.lead_uuid) {
          if (store.state.customer.currentLead.hasOwnProperty('id')) {
            return true;
          } else {
            return false;
          }
        } else if (this.area === 'Customer') {
          return true;
        } else if (this.area === 'Company' && store.state.company.company.hasOwnProperty('id')) {
          return true;
        } else if (this.area === 'Formcreator') {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      area() {
        console.log('DataPreparation.vue: watch area');
        this.getData();
      },
      user() {
        console.log('DataPreparation.vue: watch user');
        this.changeLocale(store.state.main.user.locale);
      },
      locale: function (newVal, oldVal) {
        console.log('DataPreparation.vue: watch locale old: ' + oldVal + ' to New: ' + newVal + ' computed: ' + this.locale);
        this.reactToLocaleChange(newVal);
      },
    },
    mounted() {
      console.log('DataPreparation.vue: mounted()');
      if (store.state.main.user.hasOwnProperty('id') && store.state.main.user.locale) {
        console.log('ready lang from user: ' + store.state.main.user.locale);
        this.changeLocale(store.state.main.user.locale);
        return;
      }
      // only if there is no user from the cookie
      this.getData();
    },
    methods: {
      changeLocale(locale) {
        console.log('DataPreparation.vue: changeLocale: ' + locale);
        if (locale){
          loadAndSetLocale(locale).then(() => {
            store.dispatch('localeChangedEvent', locale);
          });
          dayjsHelper.changeLanguage(store.state.main.user.locale);
        }
      },
      /**
       * The locale change works this way:
       * 1. In the LocalePicker, the user selects the new locale and then the i18n.loadAndSetLocale() is called.
       * 2. The i18n.loadAndSetLocale() function loads the new locale and sets it in the i18n object, which changes the locale in the whole app.
       * 3. The localeChangedEvent() mutation is called in the store, which sets the new locale in the store.
       * 4. The DataPreparation.vue component watches the locale in the store and calls the reactToLocaleChange() function.
       * 5. The reactToLocaleChange() function sets the new locale in the axios headers and calls the getData() function.
       * 6. The getData() function is called, which fetches the data from the backend. The backend gets the locale from the axios headers and returns the data in the new locale.
       */
      reactToLocaleChange(locale) {
        leadFormApi.defaults.headers.common['Accept-Language'] = locale;
        companyApi.defaults.headers.common['Accept-Language'] = locale;
        authApi.defaults.headers.common['Accept-Language'] = locale;
        locationApi.defaults.headers.common['Accept-Language'] = locale;
        searchBackendApi.defaults.headers.common['Accept-Language'] = locale;
        store.dispatch('localeChangedEvent', locale);
        this.getData();
      },
      async getData() {
        if (store.getters.isGuest && this.$route.name !== 'Autologin' && !this.$route.query.autologin_token) {
          console.log('DataPreparation.vue: isGuest');
          store.dispatch('auth/getUser');
        }

        if (this.$route.name === 'FinishLead') {
          console.log('DataPreparation.vue: FinishLead');
          store.dispatch('customer/getCompanyFinderData', this.$route.params.lead_uuid);
          store.dispatch('getPostcodes', this.country);
        } else if (this.$route.name === 'FinishLead' && !store.state.customer.currentLead.hasOwnProperty('id')) {
          console.log('DataPreparation.vue: FinishLead');
          store.dispatch('getPostcodes', this.country);
          store.dispatch('customer/getCustomerCurrentLead', [this.$route.params.user_id, this.$route.params.lead_uuid]);
        } else if (this.area === 'Customer') {
          console.log('DataPreparation.vue: Customer');
          store.dispatch('customer/getUserReviews', this.$route.params.user_id);
          store.dispatch('customer/getVipanyReviews', this.$route.params.user_id);
          store.dispatch('getPostcodes', this.country);
          store.dispatch('fetchSessionIdIfNeeded', this.$route.params.user_id);
          store.dispatch('customer/getCustomerCompanyLeads', this.$route.params.user_id);
          if (this.user?.postcode_id) {
            store.dispatch('shop/getClosestRetailer', this.user.postcode_id);
          }
        } else if (this.area === 'Company') {
          console.log('DataPreparation.vue: Company');
          store.dispatch('company/getCompany', this.$route.params.company_id);
          store.dispatch('company/getCompanyLeads', this.$route.params.company_id);
        } else if (this.area === 'Public' && this.$route.path === '/shop') {
          console.log('DataPreparation.vue: Public');
          store.dispatch('getPostcodes', this.country);
        }
      },
    },
  };
</script>

<style scoped>

</style>
