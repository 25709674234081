<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" v-html="$t('companyArea.shop.shop')" />
    </template>

    <div class="panel">
      <div class="text-center">
        <div class="font-semibold text-lg mb-3">{{ $t('companyArea.shop.unbeatablePrices') }}</div>
        <div v-html="$t('companyArea.shop.bulkOrdersExplained')" />
      </div>
      <div class="flex flex-col md:flex-row mt-5">
        <div class="w-full md:w-2/3 border rounded p-4 mr-4">
          <div class="font-semibold text-lg mb-3">{{ $t('companyArea.shop.nextDates') }}</div>
          <div class="flex flex-wrap">
            <div v-for="bulkOrder in bulkOrders"
                 class="px-3 py-2 border rounded-md mr-3 mb-3"
                 :class="{ 'bg-gray-300':(bulkOrder === currentBulkOrder), 'bg-gray-100':(bulkOrder !== currentBulkOrder), 'hover:bg-gray-300 cursor-pointer':(!bulkOrderFixed) }"
                 @click="bulkOrderFixed ? '' : currentBulkOrder = bulkOrder"
            >
              {{ this.dayjsHelper.showDateText(bulkOrder.duedate) }}
            </div>
          </div>
          <div v-if="currentBulkOrder.hasOwnProperty('id')" class="text-gray-500">{{ $t('companyArea.shop.companyShop.deliveryTime', { showDateText: this.dayjsHelper.showDateText(currentBulkOrder.duedate) }) }}</div>
          <div v-if="bulkOrderFixed" class="text-gray-500 mt-2">{{ $t('companyArea.shop.companyShop.changeBulkOrderDateLockedInformation') }}</div>
          <div v-if="bulkOrderFixed" class="text-red-500 mt-2 cursor-pointer" @click="emptyCart">{{ $t('companyArea.shop.companyShop.emptyCart') }}</div>
        </div>
        <div class="w-full md:w-1/3 border rounded p-4 mt-4 md:mt-0">
          <cart-overview />
        </div>
      </div>
      <div class="flex flex-col">
        <div v-if="currentBulkOrder.hasOwnProperty('id')" class="flex flex-col md:flex-row mt-4 order-2 md:order-1">
          <product-overview v-for="product in products.filter(obj => !obj.parent_id)"
                            :key="'po'+product.id"
                            :product="product"
                            :current-product="currentProduct"
                            :bulk-order="currentBulkOrder"
                            @current-product="setCurrentProduct"
          />
        </div>

        <product-order v-if="currentProduct.hasOwnProperty('id')"
                       :product="currentProduct"
                       :bulk-order="currentBulkOrder"
                       :related-products="products.filter(item => item.parent_id === currentProduct.id)"
                       :area="area"
        />
      </div>
    </div>
  </layout>
</template>

<script>

  import Layout from '../../Layout.vue';
  import store from '../../store/mainStore';
  import ProductOverview from './ProductOverview.vue';
  import ProductOrder from './ProductOrder.vue';
  import CartOverview from '../../sharedComponents/CartOverview.vue';

  export default {
    name: 'CompanyShop',
    components: { CartOverview, ProductOrder, ProductOverview, Layout },
    data() {
      return {
        currentBulkOrder: {},
        currentProduct: {},
        init: true,
        formdata: {
          company_id: this.$route.params.company_id,
          name: 'boxes',
          content: {
            boxesPerYear: '0-100',
            ordersPerYear: 1,
            onlyInterested: false,
            firstOrder: 'Dez',
            standard: 0,
            books: 0,
            clothes: 0,
            bigBox: 0,
            retailer: false,
          },
        },
        show: {
          survey: false,
        },
      };
    },
    computed: {
      isCompanyArea() {
        return this.$route.meta.area === 'Company';
      },
      area() {
        return this.isCompanyArea ? 'Company' : 'Customer';
      },
      products() {
        return store.state.shop.companyProducts;
      },
      bulkOrders() {
        return store.state.shop.bulkOrders;
      },
      bulkOrderFixed() {
        return store.state.shop.orderProducts.length > 0;
      },
      order() {
        return store.state.shop.order;
      },


    },
    watch: {
      bulkOrders() {
        if (this.init) {
          if (this.order.hasOwnProperty('id') && this.bulkOrders.find(obj => obj.id === this.order.shop_bulk_order_id)) {
            this.currentBulkOrder = this.bulkOrders.find(obj => obj.id === this.order.shop_bulk_order_id);
          } else {
            this.currentBulkOrder = this.bulkOrders[0];
            if(this.order.hasOwnProperty('id')) {
              this.order.shop_bulk_order_id = this.currentBulkOrder.id;
            }
          }
        } else {
          this.init = false;
        }
      },
      order: {
        immediate: true,
        handler() {
          if (this.order.paid) {
            store.dispatch('shop/getCompanyShopProducts', this.$route.params.company_id);
            let order = { uuid: Math.random().toString(36).substring(2, 15), paid: false };
            store.commit('shop/setOrder', order);
            store.commit('shop/setOrderProducts', []);
          }

        }, deep: true,
      },
    },
    created() {
      store.dispatch('shop/getCompanyShopProducts', this.$route.params.company_id);
    },
    mounted() {
      if (!this.order.hasOwnProperty('uuid') || this.order.paid) {
        let order = { uuid: Math.random().toString(36).substring(2, 15), paid: false };
        store.commit('shop/setOrder', order);
        store.dispatch('shop/getOrderFromLocalStorage', this.area);
        // store.commit('shop/setOrderProducts', []);
      }
    },
    methods: {
      setCurrentProduct(item) {
        this.currentProduct = item;
      },
      emptyCart() {
        store.commit('shop/setOrderProducts', []);
      }
    },
  };
</script>

<style scoped>
    ul {
        list-style-type: disc; /* Runde Punkte */
        padding-left: 20px; /* Einzug für die gesamte Liste */
    }
</style>
