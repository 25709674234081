<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="pt-3">
    <label class="form-label">{{ field.label }}</label>
    <input id="price_quality"
           v-model="formdata['price_quality']"
           max="1"
           min="0"
           step="0.1"
           type="range"
           :data-gtm-field-label="field.label"
           :data-gtm-field-id="field.id"
           @blur="logFieldBlur($event.target.value)"
           @change="editFields"
    >
    <div class="form-between">
      <div class="price-quality">{{ $t('form.price') }}</div>
      <div class="price-quality">{{ $t('form.quality') }}</div>
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    name: 'PriceQualityMetaField',
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      optionFields: Array,
      formdata: { required: true },
      mode: String,
      isVisible: false,
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    computed: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
    methods: {},
  };
</script>
