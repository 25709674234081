<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <date-picker :id="'field'+field.id"
                 v-model:date="formdata['date']"
                 :data-cy="'dateStaticField'+field.id"
                 :data-gtm-field-label="field.label"
                 :data-gtm-field-id="field.id"
                 :field-id="field.id"
                 :input-styles="inputStyles"
                 :pick-past="false"
                 :placeholder="field.label"
    />
    <label-state-icon :label-state="labelState" :required="true" />
  </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import DatePicker from '../../snippets/DatePicker.vue';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'DateStaticField',
    components: { LabelStateIcon, DatePicker },
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState() {
        if (this.field.required) {
          if(this.formdata['date'].length > 0) {
            return this.checkDate();
          } else {
            return false;
          }

        } else {
          if(this.formdata['date'].length > 0) {
            return this.checkDate() ? true : null;
          } else {
            return null;
          }
        }
      },
      inputStyles() {
        let string = 'mainform-color mainform-input';
        return this.validation.form && !this.validation[this.field.id].valid ? string + ' vue-datepicker-alert' : string;
      },
      date() {
        return this.formdata['date']
      }
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
        }, deep: true,
      },
      date() {
        this.editFields()
        this.logFieldBlur(this.formdata['date'])
      }
    },
    methods: {
      closed() {
        // this.logFieldBlur(this.date_prov.toISOString().substring(0, 10));
      },
      checkDate() {
        const selectedDateObj = new Date(this.formdata['date']);
        const currentDateObj = new Date();

        if (selectedDateObj < currentDateObj) {
          return false;
        } else {
          return true;
        }
      },
    },

  };
</script>
