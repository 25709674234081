<template>
  <div>
    <p v-if="mode === 'categories'" class="folder" @click="getClassificationTree">
      <font-awesome-icon class="fa-icon link" icon="folder-plus" />
      {{ suggestion.name }}
    </p>
    <p v-else class="suggestion-category title-icon">
      {{ suggestion.name }}
      <font-awesome-icon v-if="mode === 'customCrawling'" :icon="['fas', 'th']" @click.native="backToCategories" />
      <font-awesome-icon v-else-if="mode === 'suggestions' && show.tree" :icon="['fas', 'chevron-up']" @click.native="show.tree = ! show.tree" />
      <font-awesome-icon v-else :icon="['fas', 'chevron-down']" @click.native="show.tree = ! show.tree" />
      <!--            <title-icon @click.native="backToCategories" class="icon-wrapper" style="right: 3px" :icon="['fas', 'th']" v-if="mode === 'customCrawling'"></title-icon>-->
      <!--            <title-icon @click.native="show.tree = ! show.tree" class="icon-wrapper" style="right: 3px" :icon="['fas', 'chevron-up']" v-else-if="mode === 'suggestions' && show.tree"></title-icon>-->
      <!--            <title-icon @click.native="show.tree = ! show.tree" class="icon-wrapper" style="right: 3px" :icon="['fas', 'chevron-down']" v-else></title-icon>-->
    </p>
    <div v-if="show.tree">
      <list-section v-for="item in sectionFiltered"
                    :key="item.id"
                    :custom-crawling="customCrawling"
                    :formdata="formdata"
                    :suggestion="item"
                    :suggestions="suggestions"
      />
    </div>
  </div>
</template>

<script>
  import store from '../../store/mainStore';
  import ListSection from './ListSection.vue';

  export default {
    name: 'ListCategory',
    components: { ListSection },
    props: {
      suggestion: {},
      suggestions: {},
      formdata: {},
      customCrawling: { default: false },

    },
    data() {
      return {
        show: {
          tree: true,
        },
      };
    },
    computed: {
      sectionFiltered() {
        return this.suggestions.filter(obj => obj.parent === this.suggestion.number && obj.is_section === 1);
      },
      mode() {
        if(this.sectionFiltered.length === 0) {
          return 'categories';
        } else if(this.customCrawling) {
          return 'customCrawling';
        } else {
          return 'suggestions';
        }
      },
    },
    methods: {
      backToCategories() {
        store.dispatch('company/getClassificationTree', {});
      },
      getClassificationTree() {
        store.dispatch('company/getClassificationTree', this.suggestion);
      },
    },
  };
</script>

<style scoped>

</style>
