<template>
  <div class="flex flex-col">
    <div v-if="companyLead.lead.quotes.length > 0" class="flex justify-between mt-2">
      <div>
        <div class="text-base font-extrabold overflow-visible cursor-pointer">
          {{
            companyLead.lead.quotes[0].type === 'fixprice' ? 'Fixpreis ' : 'Unverbindlich '
          }}
          <span class="relative backside-hidden overflow-visible">
            <img class="max-w-none absolute w-36 -top-1 -left-1" src="../../../../../assets/images/price-swash.svg">
            <span class="relative">
              <quote-price :quote="companyLead.lead.quotes[0]" />
            </span>
          </span>
        </div>
      </div>
    </div>

    <div v-if="!companyLead.lead.review_mail_sent_at" class="flex">
      <button class="btn btn-md btn-primary mt-3 mr-3" :disabled="confirm[companyLead.id] && confirm[companyLead.id] === 'sent'" @click="confirm[companyLead.id] = 'sendLink'">
        {{ $t('companyArea.leads.leadOverview.cards.sendReviewMail') }}
      </button>
    </div>
    <div class="flex">
      <button v-if="companyLead.lead.commission"
              class="btn btn-lg btn-primary mt-3 mr-3"
              :data-cy="'endprice-' + companyLead.lead_id"
              @click="show.confirmation[companyLead.id] = true"
      >
        {{ $t('companyArea.leads.leadOverview.cards.endprice') }}
      </button>
      <button v-else
              class="btn btn-md btn-secondary mt-3 mr-3"
              :data-cy="'job-done-' + companyLead.lead_id"
              @click="confirmedLeadToArchive"
      >
        {{ $t('companyArea.leads.leadOverview.cards.leadToArchiv') }}
      </button>
    </div>
    <div v-if="companyLead.lead.commission" class="text-xs sm:text-sm mt-3 pr-7 sm:pr-0">
      {{ $t('companyArea.leads.leadOverview.cards.payProvision') }}
    </div>
  </div>

  <confirmation-modal v-if="show.confirmation[companyLead.id]" theme="white" @close="show.confirmation[companyLead.id] = false">
    <confirm-price :company-lead="companyLead" :quote="companyLead.lead.quotes.length > 0 ? companyLead.lead.quotes[0] : {}" @close="show.confirmation[companyLead.id] = false" />
  </confirmation-modal>

  <div v-if="confirm[companyLead.id] && confirm[companyLead.id] === 'sendLink'"
       class="absolute flex justify-center inset-0 rounded pt-10 z-50 bg-opacity-95 bg-green-700"
  >
    <div>
      <div class="text-center text-xl text-white font-extrabold px-2">
        {{ $t('companyArea.leads.leadOverview.cards.sendReviewMail?') }}
      </div>

      <div class="flex justify-center mt-3">
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                data-cy="confirm"
                @click="sendReviewMail(confirm[companyLead.id])"
        >
          <font-awesome-icon :icon="['fal','check']" />
          <span class="absolute -bottom-6 text-white text-xs">{{ $t('general.yes') }}</span>
        </button>
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black hover:text-white mb-4 ml-3"
                data-cy="reject"
                @click="confirm[companyLead.id] = ''"
        >
          <font-awesome-icon :icon="['fal','times']" />
          <span class="absolute -bottom-6 text-white text-xs">{{ $t('general.no') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfirmationModal from '../../../../snippets/ConfirmationModal.vue';
  import ConfirmPrice from '../confirmPrice.vue';
  import QuotePrice from '../../../../sharedComponents/QuotePrice.vue';
  import store from '../../../../store/mainStore';
  import leadFormApi from '../../../../connections/leadForm';

  export default {
    name: 'ConfirmedQuotesCard',
    components: { QuotePrice, ConfirmPrice, ConfirmationModal },
    props: {
      companyLead: Object,
    },
    data() {
      return {
        confirm: [],
        show: {
          confirmation: [],
        },
      };
    },
    methods: {
      confirmedLeadToArchive() {
        store.dispatch('company/confirmedLeadToArchive', this.companyLead.id);
      },
      sendReviewMail(action) {
        if(action === 'sendLink') {
          leadFormApi.get('company-leads/' + this.companyLead.id + '/send-review-mail', { 'progress': true });
        }
        this.confirm[this.companyLead.id] = 'sent';
      },
    },
  };
</script>

<style scoped>

</style>
