<template>
  <div class="flex flex-col md:flex-row bg-white border rounded p-4 mx-2 mt-5">
    <div class="w-full md:w-1/2 pr-4 pb-4">
      <div class="mt-3 font-semibold text-lg pb-3">{{ $t('publicArea.shop.pickUpPoints.title') }}</div>
      {{ $t('publicArea.shop.pickUpPoints.text') }}
    </div>
    <div class="w-full md:w-1/2 rounded overflow-hidden">
      <div ref="mapContainer" class="relative w-full" style="height: 500px;" />
    </div>
  </div>
</template>

<script>
  import L from 'leaflet';
  import markerIcon from 'leaflet/dist/images/marker-icon.png';
  import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
  import markerShadow from 'leaflet/dist/images/marker-shadow.png';
  import store from '../../store/mainStore';

  export default {
    name: 'PickUpPoints',
    data() {
      return {
        mapContainer: null,
        map: null,
        drawMarkers: true,
      };
    },
    computed: {
      retailers() {
        return store.state.shop.companyProfiles;
      },
      postcodes() {
        return store.state.main.postcodes;
      },
    },
    watch: {
      postcodes: {
        handler() {
          if (this.drawMarkers && this.retailers.length > 0 && this.postcodes.length > 0) {
            console.log('hallo')
            this.retailers.forEach(this.drawMarker);
          }
        },
        deep: true,
      },
      retailers: {
        handler() {
          if (this.drawMarkers && this.retailers.length > 0 && this.postcodes.length > 0) {
            this.retailers.forEach(this.drawMarker);
          }
        },
        deep: true,
      },
    },

    mounted() {
      this.map = L.map(this.$refs.mapContainer).setView([46.8, 8.23], 8);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(this.map);
      if (this.drawMarkers && this.retailers.length > 0 && this.postcodes.length > 0) {
        this.retailers.forEach(this.drawMarker);
      }
    },
    methods: {
      drawMarker(retailer) {
        const currentPostcode = this.postcodes.find(obj => obj.id === retailer.postcode_id);
        if (!currentPostcode) return;

        const markerIconDefault = L.icon({
          iconUrl: markerIcon,
          iconRetinaUrl: markerIcon2x,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          shadowUrl: markerShadow,
          shadowSize: [41, 41],
          shadowAnchor: [12, 41],
        });

        const marker = L.marker([currentPostcode.latitude, currentPostcode.longitude], { icon: markerIconDefault }).addTo(this.map);

        marker.bindPopup(`${retailer.address}<br>${retailer.postcode} ${retailer.city}<br><br><b>Telefon:</b> ${retailer.company.phone}<br><b>Email:</b> ${retailer.company.email}`);
        this.drawMarkers = false;
      },
    },
  };
</script>

<style scoped>
    /* Hier kannst du zusätzliche Styling-Regeln hinzufügen */
</style>
