<template>
  <div>
    <confirmation-modal v-if="showForm" theme="white" @close="closeForm">
      <div class="flex justify-center mt-3">
        <div class="flex flex-col w-full justify-center mt-3">
          <p class="text-xl font-semibold mb-8">{{ $t('companyArea.accounting.credit.topupWithPostfinanceTitle') }}</p>
          <p :class="formCharge.showMinAmount === false ? 'hidden text-red-700 text-lg' : 'text-red-700 text-lg'">
            {{ $t('companyArea.accounting.credit.automaticRefillMinimum', { currency: company.currency, amount: formCharge.minAmount }) }}
          </p>
          <postfinance-token-info v-if="postfinanceTokenId" :token="company.postfinance_tokens.find(obj => obj.id === postfinanceTokenId)" />
          <div class="relative mt-4">
            <input v-model="formCharge.amount"
                   type="number"
                   class="pl-12 w-full border border-gray-400"
                   :placeholder="$t('companyArea.accounting.credit.enterAmount')"
                   data-cy="input-amount"
                   @keyup.enter="charge"
            >

            <div class="absolute left-2 top-1.5 text-xxs z-10">
              {{ company.currency }}
            </div>
          </div>
          <p v-if="formCharge.amount > 0" class="text-center mt-2 text-red-600">
            {{ $t('companyArea.accounting.credit.feesIncluded') }} {{
              (formCharge.amount * 1.014).toFixed(2)
            }}
          </p>
          <div class="flex justify-center mt-3">
            <button class="btn btn-lg btn-secondary" data-cy="btn-top-up-add" @click="charge">{{ $t('companyArea.accounting.credit.topUp') }}</button>
          </div>
        </div>
      </div>
    </confirmation-modal>

    <confirmation-modal v-if="transactionSuccessful" @close="closePostfinanceModalAndRemoveQueryString">
      <div class="flex flex-col w-full">
        <p class="text-xl font-semibold place-self-center">{{ $t('companyArea.accounting.credit.topupSuccessful') }}</p>
        <text-button size="lg"
                     class="place-self-center mt-3"
                     :text="$t('general.close')"
                     @click="closePostfinanceModalAndRemoveQueryString"
        />
      </div>
    </confirmation-modal>

    <confirmation-modal v-if="transactionFailed" @close="closePostfinanceModalAndRemoveQueryString">
      <div class="flex flex-col w-full">
        <p class="text-xl font-semibold place-self-center">
          <font-awesome-icon :icon="['fass', 'xmark']" style="color: #b51a00;" />
          {{ $t('companyArea.accounting.credit.topupFailed') }}
        </p>
        <p v-if="errorMessage" class="text-lg font-semibold place-self-center mt-5 mb-2 text-red-600">{{ errorMessage }}</p>
        <div class="flex justify-center">
          <button class="btn btn-lg btn-secondary my-5 w-auto mx-5" data-cy="btn-postfinance-failed-close" @click="closePostfinanceModalAndRemoveQueryString">
            <span>{{ $t('general.close') }}</span>
          </button>
        </div>
      </div>
    </confirmation-modal>
    <spinner v-if="showSpinner" style="z-index: 9999;" />
  </div>
</template>

<script>
  import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
  import TextButton from '../../../snippets/TextButton.vue';
  import store from '../../../store/mainStore';
  import companyApi from '../../../connections/company';
  import notification from '../../../connections/notification';
  import Spinner from '../../../snippets/Spinner.vue';
  import PostfinanceTokenInfo from './PostfinanceTokenInfo.vue';

  export default {
    name: 'PostfinancePayment',
    components: { PostfinanceTokenInfo, Spinner, ConfirmationModal, TextButton },
    props: {
      show: { type: Boolean, default: false },
      postfinanceTokenId: { type: Number, default: null },
      paymentIntentContent: { type: Object, default: null }, // response from server
      twint: { type: Boolean, default: false },
    },
    emits: ['success', 'failed', 'leadTaken'],
    data() {
      return {
        showSpinner: false,
        postfinanceApiUrl: { type: String, default: null },
        transactionSuccessful: false,
        transactionFailed: false,
        errorMessage: '',
        formCharge: {
          twint: false,
          minAmount: 3,
          amount: '',
          currency: store.state.company.company.currency,
          postfinanceTokenId: this.postfinanceTokenId,
          showMinAmount: false,
          returnBaseUri: window.location.pathname,
        },
      };
    },
    computed: {
      mainPostfinancePaymentMethod() {
        if (this.company.postfinance_tokens.length > 0) {
          return this.company.postfinance_tokens.find(obj => obj.main == true);
        } else {
          return null;
        }
      },
      showForm: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit('update:show', value);
          this.formCharge.paymentMethodId = null;
        },
      },
      company() {
        return store.state.company.company;
      },
      postfinanceTokens() {
        return this.company.postfinance_tokens;
      },
    },
    mounted() {
      // Currently we get here after
      // - the company Payment (aufladen)
      // - after the company Payment of a selection Lead with a direct payment
      // - after the User Customer Payment from the Shop
      let postfinanceResult = this.$route.query.postfinance;
      let userId = this.$route.params.user_id;

      if (postfinanceResult) {
        this.showSpinner = false;

        if (this.$route.query.shop_order_id > 0) {
          // This is a User Customer Payment
          if (postfinanceResult === 'successful') {
            this.transactionSuccessful = true;
            companyApi.post('/shop/order/' + this.$route.query.shop_order_id + '/postfinance/successful', {
              'progress': false,
              'notification': false,
            }).then(response => [this.$emit('success')]);
          } else {
            this.transactionFailed = true;
            companyApi.post('/shop/order/' + this.$route.query.shop_order_id + '/postfinance/failed', {
              'progress': false,
              'notification': false,
            }).then(response => [this.$emit('failed')]);
          }
        } else {
          // This is a Company Payment
          if (postfinanceResult === 'successful') {
            this.transactionSuccessful = true;
            companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-postfinance/successful', {
              transactionUuid: this.$route.query.transaction_uuid,
              token: this.$route.query.token,
            }, {
              'progress': false,
              'notification': false,
            }).then(response => this.successfulCompanyPayment(response));
          } else {
            this.transactionFailed = true;
            companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-postfinance/failed', {
              transactionUuid: this.$route.query.transaction_uuid,
              token: this.$route.query.token,
            }, {
              'progress': false,
              'notification': false,
            }).then(response => [store.commit('company/setPostfinanceTokens', response.data.content.postfinance_tokens), this.$emit('failed')]);
          }
        }
      }
    },
    methods: {
      closeForm() {
        this.showForm = false;
        this.showMinAmount = false;
        this.formCharge.showMinAmount = false;
      },
      charge() {
        if (this.formCharge.amount < this.formCharge.minAmount) {
          this.formCharge.showMinAmount = true;
          return;
        }
        this.showForm = false;
        this.showSpinner = true;
        this.formCharge.postfinanceTokenId = this.postfinanceTokenId;
        this.formCharge.twint = this.twint;

        // Call the backend to prepare the Transaction and get the URL to load the dynamic postfinance Script
        companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-postfinance/', this.formCharge, {
          'progress': false,
          'notification': false,
          'axios-retry': { retries: 0 },
        }).then(response => [this.evaluatePaymentIntent(response.data.content, response.data.notification)])
          .catch(error => this.postfinanceErrorCallback(error));
      },
      async evaluatePaymentIntent(content, notification) {
        try {
          if (content && content.url) {
            this.apiUrl = content.url;
            this.showSpinner = true;
            this.loadPostfinanceJs();
          } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('No URL to load the postfinance script');
          }
        } catch (error) {
          this.postfinanceErrorCallback(error);
        }
      },
      startPayment() {
        this.$nextTick(() => {
          // window.LightboxCheckoutHandler is defined in the postfinance script which is loaded in the loadPostfinanceJs method
          if (window.LightboxCheckoutHandler && window.LightboxCheckoutHandler.startPayment) {
            window.LightboxCheckoutHandler.startPayment(null, () => this.postfinanceErrorCallback());
          } else {
            this.postfinanceErrorCallback();
          }
        });
      },
      loadPostfinanceJs() {
        try {
          const script = document.createElement('script');
          // Security Check
          if (this.apiUrl.startsWith('https://checkout.postfinance.ch')) {
            script.src = this.apiUrl;
            document.head.appendChild(script);
            script.onload = () => {
              this.startPayment();
            };
          } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Security Check failed');
          }

          script.onerror = () => {
            throw new Error('Failed to load the postfinance script.');
          };
        } catch (error) {
          this.postfinanceErrorCallback(error);
        }
      },
      postfinanceErrorCallback(error) {
        console.log('postfinanceErrorCallback - error: ', error);
        this.showSpinner = false;
        notification['error'](this.$t('companyArea.accounting.credit.postfinanceLoadError'));
        this.errorMessage = this.$t('companyArea.accounting.credit.postfinanceLoadError');
      },
      closePostfinanceModalAndRemoveQueryString() {
        this.transactionSuccessful = false;
        this.transactionFailed = false;

        let cleanURL = window.location.protocol + '//' + window.location.host + window.location.pathname;

        // Update the URL without reloading the page
        if (cleanURL !== undefined && cleanURL.length > 0 && cleanURL !== window.location.href) {
          window.history.replaceState({}, document.title, cleanURL);
        }
      },
      successfulCompanyPayment(response) {
        if (response.data.notificationType === 'success') {
          store.commit('company/setCredit', response.data.content.creditNew);
          this.$emit('success');
          if (response.data.content.hasOwnProperty('companyLead')) {
            this.leadTaken(response.data.content.companyLead, response.data.content.company.credit);
          }
        }
      },
      leadTaken(companyLead, companyCredit) {
        if (companyLead) {
          store.dispatch('company/updateCompanyLead', companyLead);
        }
        if (companyCredit) {
          store.commit('company/setCredit', companyCredit);
        }
        this.$emit('leadTaken', { companyLead: companyLead, companyCredit: companyCredit });
      },
    },
  };
</script>
