<template>
  <layout v-if="companyLead.hasOwnProperty('id')" :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">{{ $t('customerArea.makeReview.makeFeedback') }}</p>
      <p class="font-extrabold sm:text-2xl text-center mt-1">{{ mobileCompanyName() }}</p>
      <p class="text-xs sm:text-sm text-center">{{ $t('classification.' + lead.form.translation_key) }}</p>
    </template>

    <div v-if="step === 1" class="panel">
      <div class="text-base font-semibold">{{ $t('customerArea.makeReview.priceComplied') }}</div>
      <rating v-model:review="formdata.review_1" class="text-4xl mt-2" :read-only="false" />
      <div class="text-base font-semibold mt-5">{{ $t('customerArea.makeReview.happy') }}</div>
      <div class="text-sm text-gray-500">{{ $t('customerArea.makeReview.workQuality') }}</div>
      <rating v-model:review="formdata.review_3" class="text-4xl mt-2" :read-only="false" />
      <div class="text-base font-semibold mt-5">{{ $t('customerArea.makeReview.goodHands') }}</div>
      <div class="text-sm text-gray-500">{{ $t('customerArea.makeReview.organisationAndAdministration') }}</div>
      <rating v-model:review="formdata.review_2" class="text-4xl mt-2" :read-only="false" />


      <div class="text-base font-semibold mt-8">{{ $t('customerArea.makeReview.personalLines') }}</div>
      <textarea id="textarea"
                v-model="formdata.text"
                :placeholder="$t('customerArea.makeReview.publicFeedback')"
                rows="3"
                class="w-full border border-gray-400 mt-2"
                data-cy="review-input-text"
      />

      <div class="text-base font-semibold mt-8">{{ $t('customerArea.makeReview.jobPrice') }}</div>
      <div class="text-sm text-gray-500">{{ $t('customerArea.makeReview.exactEndprice') }}</div>
      <div class="relative mt-2">
        <input v-model="formdata.price"
               class="pl-12 w-full border border-gray-400"
               type="number"
               :placeholder="$t('customerArea.makeReview.endprice')"
               data-cy="review-input-price"
        >
        <div class="absolute left-3 top-2 text-xxs z-10">
          {{ companyLead.company.currency }}
        </div>
      </div>
      <p class="text-center text-sm text-gray-500 mt-8">{{ $t('customerArea.makeReview.intelligentSearchInfo') }}</p>
      <div v-if="!companyLead.lead.commission" class="flex justify-center mt-3">
        <button class="btn btn-lg btn-primary"
                data-cy="store-review"
                data-gtm="store-review-without-cashback"
                @click="storeCompanyReview(false)"
        >
          {{ $t('customerArea.makeReview.saveReview') }}
        </button>
      </div>
      <div v-else>
        <div class="flex justify-center mt-3">
          <button class="btn btn-lg btn-primary"
                  data-cy="store-review"
                  data-gtm="store-review-with-cashback"
                  @click="storeCompanyReview(true)"
          >
            {{ $t('customerArea.makeReview.getCashback') }}
          </button>
        </div>
        <div v-if="!vipanyReview" class="flex justify-center mt-5">
          <button class="btn btn-lg btn-secondary"
                  data-cy="route-to-vipany-review"
                  data-gtm="store-review-without-cashback"
                  @click="storeCompanyReview(false)"
          >
            {{ $t('customerArea.makeReview.saveWithoutCashback') }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="step === 2" class="panel">
      <div class="flex justify-end pb-2">
        <circle-button :icon="['fal','times']"
                       size="sm"
                       data-cy="close-cashback"
                       @click="$router.push({ name: 'QuoteOverview' })"
        />
      </div>
      <p class="text-base font-semibold">{{ $t('customerArea.makeReview.cashbackInfoTitle') }}</p>
      <p>{{ $t('customerArea.makeReview.cashbackInfoText') }}</p>
      <div class="text-base font-semibold mt-8">IBAN</div>
      <input v-model="iban"
             class="w-full border border-gray-400 mt-2"
             type="text"
             placeholder="IBAN"
             data-cy="review-input-price"
      >
      <div class="text-base font-semibold mt-8 mb-2">{{ $t('customerArea.makeReview.receipt') }}</div>
      <file-input v-model="files"
                  placeholder-button-text="Datei wählen"
                  is-image
                  is-pdf
      />
      <div class="text-base font-semibold mt-8">{{ $t('customerArea.makeReview.priceQuestion') }}</div>
      <div class="relative mt-2">
        <input v-model="formdata.price"
               class="pl-12 w-full border border-gray-400"
               type="number"
               :placeholder="$t('customerArea.makeReview.endprice')"
               data-cy="input-fa-endprice"
        >
        <div class="absolute left-3 top-2 text-xxs z-10">
          {{ companyLead.company.currency }}
        </div>
      </div>
      <!--                    <div class="font-extra-small">Ihre Angabe muss von der Firma verifiziert werden.</div>-->
      <p class="text-center text-sm text-gray-500 mt-8">{{ $t('customerArea.makeReview.intelligentSearchInfo') }}</p>
      <div class="flex justify-center mt-3">
        <button class="btn btn-lg btn-primary" data-cy="store-company-review" @click="uploadReceipt">{{ $t('general.submit') }}</button>
      </div>
      <div v-if="!vipanyReview" class="flex justify-center mt-5">
        <button class="btn btn-lg btn-secondary" data-cy="route-to-vipany-review" @click="routeToVipanyReview">{{ $t('customerArea.makeReview.jumpOverAndMakePlatformReview', { appName: appName }) }}</button>
      </div>
    </div>

    <div v-if="step === 3" class="panel">
      <p class="text-center text-base font-extrabold">{{ $t('customerArea.makeReview.thankYou') }}</p>
      <p v-if="companyLead.cashback_status === null" class="text-center mt-3">{{ $t('customerArea.makeReview.reviewSaved') }}</p>
      <p v-else class="text-center mt-3">{{ $t('customerArea.makeReview.cashbackNeedsConfirm') }}</p>
      <div v-if="!vipanyReview" class="flex justify-center mt-5">
        <button class="btn btn-lg btn-primary" data-cy="route-to-vipany-review" @click="routeToVipanyReview">{{ $t('customerArea.makeReview.makePlatformReview', { appName: appName }) }}</button>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../Layout.vue';
import store from '../store/mainStore';
import { companyMixin } from '../../plugins/mixin';
import Rating from '../snippets/Rating.vue';
import companyApi from '../connections/company';
import FileInput from '../snippets/FileInput.vue';
import notification from '../connections/notification';
import CircleButton from '../snippets/CircleButton.vue';
import { createRouter as $router } from 'vue-router';

export default {
  name: 'MakeReview',
  components: { CircleButton, FileInput, Rating, Layout },
  mixins: [companyMixin],
  data() {
    return {
      formdata: {
        review_1: 5,
        review_2: 5,
        review_3: 5,
        text: '',
        company_id: store.state.customer.currentCompanyLead.company_id,
        lead_id: store.state.customer.currentLead.id,
        user_id: store.state.customer.currentLead.user_id,
        price: '',
        classification_name: store.state.customer.currentLead.form.translation_key,
      },
      iban: '',
      files: [],
      step: store.state.customer.currentCompanyLead.cashback_status === 1 ? 2 : 1,
      form: store.state.customer.currentCompanyLead.lead.form,
    };
  },
  computed: {
    companyLead() {
      return store.state.customer.currentCompanyLead;
    },
    lead() {
      return store.state.customer.currentLead;
    },
    vipanyReview() {
      return store.state.customer.vipanyReviews?.filter(obj => obj.lead_id === this.lead.id)[0];
    },
  },
  beforeCreate() {
    let companyLeads = store.state.customer.companyLeads;
    store.commit('customer/setCurrentCompanyLead', companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid && obj.company_id === parseInt(this.$route.params.customer_company_id)));
  },
  mounted() {
    if(this.$route.params.value) {
      let value = parseInt(this.$route.params.value) / 2
      this.formdata.review_1 = value;
      this.formdata.review_2 = value;
      this.formdata.review_3 = value;
    }
    let review = store.state.customer.userReviews.find(obj => obj.company_id === this.companyLead.company_id && obj.lead_id === this.companyLead.lead_id );
    if(review?.id) {
      this.formdata.review_1 = review.review_1;
      this.formdata.review_2 = review.review_2;
      this.formdata.review_3 = review.review_3;
      this.formdata.text = review.text;
      this.formdata.price = review.price;
    }
  },
  methods: {
    storeCompanyReview(cashback) {
      companyApi.post('company-reviews', this.formdata).
          then(response => response.data.notificationType === 'success'
              ? [store.commit('customer/setCompanyLeads', response.data.content.companyLeads), this.step = this.companyLead.cashback_status > 1 ? 3 : 2]
              : '');
      if(! cashback) {
        this.routeToVipanyReview();
      }
    },
    mobileCompanyName() {
      if(this.companyPublicName(this.companyLead.company).length < 30) {
        return this.companyPublicName(this.companyLead.company);
      } else {
        return this.companyPublicName(this.companyLead.company).slice(0, 30) + '...';
      }
    },
    routeToVipanyReview() {
      if((this.formdata.review_1 + this.formdata.review_2 + this.formdata.review_3) / 3 < 4) {
        this.$router.push({
          name: 'Vipany-Review',
          params: { user_id: this.lead.user_id, lead_uuid: this.lead.uuid },
        });
      } else {
        location.href = store.state.customer.trustedShopsReviewUrl.replace('__EMAIL__', window.btoa(this.lead.email)).replace('__LEAD__', window.btoa(this.lead.uuid));
      }

    },
    uploadReceipt() {
      // temp solution, until https://github.com/arhs/iban.js supports ES modules
      const regex = /CH[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})\s?/iug;
      if(this.iban === '' || this.formdata.price === '') {
        notification.warning(this.$t('general.fieldsMissing'));
      } else if(regex.test(this.iban) === false) {
        notification.warning(this.$t('customerArea.makeReview.warningIban'));
      } else if(this.iban.slice(0, 2).toUpperCase() !== 'CH') {
        notification.warning(this.$t('customerArea.makeReview.warningBankAccount'));
      } else {
        let formData = new FormData();
        formData.append('user_id', store.state.main.user.id);
        formData.append('iban', this.iban);
        formData.append('company_id', this.formdata.company_id);
        formData.append('lead_id', this.formdata.lead_id);
        formData.append('price', this.formdata.price);

        for(var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          // a file should not be heavier than 5mb
          if(file.size > 5242880) {
            notification.warning(this.$t('customerArea.makeReview.warningFileSize'));
            return;
          }
          formData.append('files[' + i + ']', file.file);
        }

        const config = {
          'progress': true,
          headers: { 'content-type': 'multipart/form-data' },
        };
        companyApi.post('/cashback', formData, config).
            then(response => this.step = 3);
      }
    },
  },
};
</script>

<style scoped>

</style>
