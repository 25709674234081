<template>
  <layout v-if="currentLead.hasOwnProperty('id')" :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-xl sm:font-semibold text-center mt-8">{{ $t('customerArea.finishLead.worthIt') }}</p>
      <p class="text-xs sm:text-sm text-center" v-html="$t('customerArea.finishLead.worthItInfo')" />
    </template>

    <div class="flex flex-col sm:flex-row justify-center mt-5">
      <div v-if="extendedForm"
           :class="{ 'cursor-pointer':(currentLead.extended_form_finished_at) }"
           class="flex items-center mx-3 mb-2"
           @click="switchToExtendedForm"
      >
        <info-circle v-if="!currentLead.extended_form_finished_at"
                     size="md"
                     :color="component === 'ExtendedForm' ? 'bg-yellow-400' : 'bg-white'"
                     class="mr-3"
        >
          1
        </info-circle>
        <info-circle v-else
                     size="md"
                     class="mr-3"
                     color="bg-green-500"
        >
          <font-awesome-icon :icon="['fal','check']" />
        </info-circle>
        <span class="text-base font-semibold">{{ $t('customerArea.finishLead.additionalInfo') }}</span>
      </div>
      <div v-if="companyFinder"
           :class="{ 'cursor-pointer':(linkActive) }"
           class="flex items-center mx-3 mb-2"
           @click="linkActive ? component = 'CompanyFinder' : ''"
      >
        <info-circle v-if="!currentLead.company_finder_finished_at"
                     size="md"
                     :color="component === 'CompanyFinder' ? 'bg-yellow-400' : 'bg-white'"
                     class="mr-3"
        >
          2
        </info-circle>
        <info-circle v-else
                     size="md"
                     class="mr-3"
                     color="bg-green-500"
        >
          <font-awesome-icon :icon="['fal','check']" />
        </info-circle>
        <span class="text-base font-semibold">{{ $t('customerArea.finishLead.companySearch') }}</span>
      </div>
      <div v-if="companyFinder || extendedForm"
           class="flex items-center mx-3 mb-2"
           :class="{ 'cursor-pointer':(linkActive) }"
           @click="linkActive ? component = 'FinishLeadTips' : ''"
      >
        <info-circle v-if="!user.email_verified_at || !currentLead.phone"
                     size="md"
                     :color="component === 'FinishLeadTips' ? 'bg-yellow-400' : 'bg-white'"
                     class="mr-3"
        >
          {{
            extendedForm && companyFinder ? 3 : 2
          }}
        </info-circle>
        <info-circle v-else
                     size="md"
                     class="mr-3"
                     color="bg-green-500"
        >
          <font-awesome-icon :icon="['fal','check']" />
        </info-circle>
        <span class="text-base font-semibold">{{ $t('customerArea.finishLead.tipps') }}</span>
      </div>
    </div>

    <div class="grid grid-cols-10">
      <div v-if="component === 'ExtendedForm'" class="col-span-10 lg:col-span-3 2xl:col-span-2 lg:mr-2">
        <div class="panel">
          <p class="text-base font-semibold mb-4">{{ $t('customerArea.finishLead.nextSteps') }}</p>
          <p class="text-sm mb-4" :class="{ 'pulsating-text':(!sendingSchedulesCollected)}" v-html="$t('customerArea.finishLead.nextSteps1', { count: partnerCompaniesCount })" />
          <p class="text-sm mb-4" v-html="$t('customerArea.finishLead.nextSteps2')" />
          <p class="text-sm mb-4" @click="show.desiredQuotes = !show.desiredQuotes" v-html="$t('customerArea.finishLead.nextSteps3', { quotes: currentLead.desired_quotes })" />
          <p class="text-sm mb-4" v-html="$t('customerArea.finishLead.nextSteps4')" />
        </div>
      </div>
      <div class="col-span-10 lg:col-span-6 2xl:col-span-8" :class="component !== 'ExtendedForm' ? '2xl:col-start-2 lg:col-start-3' : ''">
        <component :is="component"
                   :lead="currentLead"
                   :show-desired-quotes="show.desiredQuotes"
                   @success="changeComponent"
        />
      </div>
    </div>
    <confirmation-modal v-if="show.localeSelector" @close="show.localeSelector = false">
      <div>
        <div class="text-md font-semibold">{{ $t('customerArea.finishLead.moreLanguages') }}</div>
        <div>{{ $t('customerArea.finishLead.moreLanguagesText') }}</div>
        <div class="mt-1 flex flex-col">
          <label v-for="locale in locales"
                 :key="locale.locale"
                 class="inline-flex items-center my-1"
          >
            <input v-model="locale.checked"
                   type="checkbox"
                   class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
            >
            <span class="ml-1 mr-3">{{ $t(locale.translation_key) }}</span>
          </label>
        </div>
        <div class="flex justify-center">
          <button class="btn btn-sm btn-secondary mr-3" data-gtm="btn-languages-save" @click="submitCustomerLanguages">{{ $t('general.save') }}</button>
        </div>
      </div>
    </confirmation-modal>
  </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import CircleButton from '../snippets/CircleButton.vue';
  import EditLeadContainer from './EditLead/EditLeadContainer.vue';
  import InfoCircle from '../snippets/InfoCircle.vue';
  import Bugsnag from '@bugsnag/js';
  import CompanyFinder from './FinishLead/CompanyFinder.vue';
  import FinishLeadTips from './FinishLead/FinishLeadTips.vue';
  import ExtendedForm from './FinishLead/ExtendedForm.vue';
  import ConfirmationModal from '../snippets/ConfirmationModal.vue';
  import authApi from '../connections/auth';
  import { LOCALES } from '../../plugins/i18n';
  import { i18n } from '../../main';

  export default {
    name: 'FinishLead',
    components: { ConfirmationModal, ExtendedForm, FinishLeadTips, CompanyFinder, InfoCircle, EditLeadContainer, CircleButton, Layout },
    data() {
      const localesCopy = structuredClone(LOCALES);
      try{
        delete localesCopy[this.i18n.global.locale];
      } catch (error) {
        console.error('Error deleting locale from localesCopy:', error);
      }

      return {
        component: 'ExtendedForm',
        companyFinder: false,
        extendedForm: false,
        // sendingSchedulesCollected: false,
        sendingSchedulesCount: 0,
        locales: localesCopy,
        show: {
          localeSelector: false,
          desiredQuotes: false,
        },
      };
    },
    computed: {
      currentLead() {
        return store.state.customer.currentLead;
      },
      user() {
        return store.state.main.user;
      },
      sendingSchedules() {
        try {
          return JSON.parse(JSON.stringify(store.state.customer.sendingSchedules));
        } catch (error) {
          console.error('Error parsing sendingSchedules JSON:', error);
          return [];
        }
      },
      linkActive() {
        let result = false;
        result = !this.extendedForm ? true : (this.currentLead.extended_form_finished_at ? true : false);
        if (result) {
          result = !this.companyFinder ? true : (this.currentLead.company_finder_finished_at ? true : false);
        }
        return result;
      },
      partnerCompaniesCount() {
        if (this.sendingSchedules !== undefined && this.sendingSchedules.length > 0) {
          return this.sendingSchedules.filter(item => !item.banned).length;
        }

        return '...';
      },
      sendingSchedulesCollected() {
        return this.sendingSchedules.length > 0 && this.sendingSchedules.length === this.sendingSchedulesCount
      },
    },
    watch: {
      sendingSchedulesCollected: function (newVal) {
        if (newVal) {
          if (this.sendingSchedules.length < 30 && this.component === 'ExtendedForm') {
            this.show.localeSelector = true;
          }
        }
      },
      sendingSchedules: function (newVal) {
        this.sendingSchedulesCount = newVal.length;
      },
    },
    // mounted() {
    //   delete this.locales[i18n.global.locale];
    // },
    created() {
      if (this.currentLead.form.use_company_finder) {
        this.companyFinder = true;
      }
      if (this.currentLead.form.fields.filter(obj => obj.extended_form).length > 0) {
        this.extendedForm = true;
      }
      if (this.extendedForm && !this.currentLead.extended_form_finished_at) {
        this.component = 'ExtendedForm';
      } else if (this.companyFinder && !this.currentLead.company_finder_finished_at) {
        this.component = 'CompanyFinder';
      } else {
        this.component = 'FinishLeadTips';
      }

      store.dispatch('customer/getSendingSchedules', this.$route.params.lead_uuid);
    },
    methods: {
      changeComponent(value) {
        window.scrollTo(0, 0);
        if (value === 'ExtendedForm' && this.companyFinder) {
          try {
            // GTM Event, used for Analytics Tracking
            window.dataLayer.push({
              'event': 'extended_form_filled',
              'leadUuid': this.currentLead.uuid,
              'form_id' : this.currentLead.form_id
            });
          } catch (error) {
            Bugsnag.notify(error);
          }

          this.component = 'CompanyFinder';
        } else {
          this.component = 'FinishLeadTips';
        }
      },
      submitCustomerLanguages() {
        let formdata = {
          lead_id: this.currentLead.id,
          additional_languages: [],
        };

        Object.keys(this.locales)
          .filter(localeKey => this.locales[localeKey].checked)
          .forEach(localeKey => {
            formdata.additional_languages.push(this.locales[localeKey].locale);
          });

        // add previously removed current locale
        formdata.additional_languages.push(i18n.global.locale);

        console.log('additional_languges: '+formdata.additional_languages);

        authApi.patch('/users/' + this.currentLead.user_id + '/additional-languages', formdata, { notification: true, progress: false })
          .then(response => [this.show.localeSelector = false]);
      },
      switchToExtendedForm() {
        // if(this.component === 'CompanyFinder' && ! this.currentLead.company_finder_finished_at) {
        //   leadFormApi.post('leads/' + this.$route.params.lead_uuid + '/company-finder/standard').
        //       then(response => [
        //         console.log('CompanyFinder.vue: successful saved ' + this.$route.params.lead_uuid),
        //         store.commit('customer/setCurrentLead', response.data.content),
        //         this.component = 'ExtendedForm']);
        // } else {
        if (this.currentLead.extended_form_finished_at) {
          this.component = 'ExtendedForm';
        }
        // }
      },


    },
  };
</script>

<style scoped>
    @keyframes pulse {
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    }

    .pulsating-text {
        animation: pulse 3s infinite;
    }
</style>
