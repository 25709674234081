<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.contact.contact') }}</p>
    </template>

    <div class="panel">
      <div v-if="!company.register" class="w-full bg-red-600 font-semibold text-center text-white p-1 rounded mb-4">{{ $t('companyArea.settings.contact.enterUid') }}</div>

      <div class="grid grid-cols-2 gap-2">
        <div class="border-b pb-1">
          <span class="text-base font-semibold">{{ $t('companyArea.settings.contact.officialCompanyName') }}</span><br>
          <span class="text-gray-500 text-xs">{{ $t('companyArea.settings.contact.billNameInfo') }}</span>
        </div>
        <div class="border-b text-base font-semibold pb-1"> {{ company.name }}</div>

        <div class="border-b mt-3 pb-1">
          <span class="text-base font-semibold">{{ $t('companyArea.settings.contact.companyLabel') }}</span><br>
          <span class="text-gray-500 text-xs">{{ $t('companyArea.settings.contact.companyLabelInfo') }}</span>
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1"> {{ company.label }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1">
          {{ $t('companyArea.settings.contact.phone') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1"> {{ company.phone }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1">
          {{ $t('companyArea.settings.contact.email') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1"> {{ company.email }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1">
          {{ $t('companyArea.settings.contact.website') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 pb-1"> {{ website.url }}</div>

        <div class="border-b text-base font-semibold mt-3 pb-1" :class="{ 'w-full bg-red-600 font-semibold text-center text-white p-1 rounded': !company.register }">
          {{ $t('companyArea.settings.contact.register') }}
        </div>
        <div class="border-b text-base font-semibold mt-3 flex justify-between pb-1">
          <span>{{ company.register }}</span>
          <circle-button :icon="['fal', 'pen']"
                         size="xs"
                         @click="show.uidEdit = !show.uidEdit"
          />
        </div>
      </div>


      <div class="mt-4">
        <button class="btn btn-sm btn-secondary mt-3 mr-3" @click="show.companyEdit = !show.companyEdit">{{ $t('companyArea.settings.contact.editContact') }}</button>

        <button class="btn btn-sm btn-secondary mt-3" @click="show.password = !show.password">{{ $t('companyArea.settings.contact.changePassword') }}</button>
      </div>

      <confirmation-modal v-if="show.companyEdit" @close="show.companyEdit = false">
        <company-name v-if="user.is_admin" v-model:company="formdata" />

        <input id="label"
               v-model="formdata.label"
               :placeholder="$t('companyArea.settings.contact.companyLabel')"
               required
               type="text"
               class="border border-gray-400 w-full mt-4"
        >
        <p class="text-gray-500 text-xs mb-2">{{ $t('companyArea.settings.contact.companyLabelInfo') }}</p>
        <company-contact v-model:company="formdata" />
        <input id="website"
               v-model="formdata.website"
               class="border border-gray-400 w-full mt-2"
               :placeholder="$t('companyArea.components.contact.companyWebsite.website')"
               required
               type="text"
        >

        <div class="flex justify-center mt-4">
          <button class="btn btn-lg btn-secondary" @click="submit">{{ $t('general.save') }}</button>
        </div>
      </confirmation-modal>

      <confirmation-modal v-if="show.uidEdit" @close="show.uidEdit = false">
        <check-register :company="company" @close="show.uidEdit = false" />
      </confirmation-modal>

      <confirmation-modal v-if="show.password" :content-size="true" @close="show.password = false">
        <div class="flex flex-col lg:flex-row justify-center">
          <input v-model="password.password_old"
                 :placeholder="$t('companyArea.settings.contact.oldPassword')"
                 required
                 type="password"
                 class="border border-gray-400 lg:mr-2"
          >
          <input v-model="password.password_new"
                 :placeholder="$t('companyArea.settings.contact.newPassword')"
                 required
                 type="password"
                 class="border border-gray-400 mt-2 lg:mt-0 lg:mr-2"
          >
          <input v-model="password.password_confirm"
                 :placeholder="$t('companyArea.settings.contact.confirmPassword')"
                 required
                 type="password"
                 class="border border-gray-400 mt-2 lg:mt-0"
          >
        </div>
        <div class="flex justify-center mt-4">
          <button class="btn btn-lg btn-secondary" @click="changePassword">{{ $t('general.save') }}</button>
        </div>
      </confirmation-modal>

      <div class="flex justify-between mt-5">
        <span class="text-base font-semibold">{{ $t('companyArea.settings.contact.locations') }}</span>
        <circle-button v-if="user.is_admin" :icon="['fal', 'plus']" @click="show.addLocation = !show.addLocation" />
      </div>

      <location v-for="(location, index) in locations"
                :key="location.id"
                :location="location"
                :location-index="index"
      />

      <confirmation-modal v-if="show.addLocation" @close="show.addLocation = false">
        <company-city :company="newLocation" :location-index="0" :register="false" />
        <div class="flex justify-center mt-3">
          <button class="btn btn-primary btn-md" @click="submitAddLocation">{{ $t('companyArea.settings.contact.addLocation') }}</button>
        </div>
      </confirmation-modal>
    </div>
  </layout>
</template>

<script>
import store from '../../store/mainStore';
import CompanyName from '../components/contact/CompanyName.vue';
import Location from './snippets/location.vue';
import CompanyCity from '../components/contact/CompanyCity.vue';
import companyApi from '../../connections/company';
import CompanyContact from '../components/contact/CompanyContact.vue';
import notification from '../../connections/notification';
import authApi from '../../connections/auth';
import Layout from '../../Layout.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import CheckRegister from './snippets/CheckRegister.vue';
import CompanyWebsite from '../components/contact/CompanyWebsite.vue';

export default {
  name: 'Contact',
  components: { CompanyWebsite, CheckRegister, ConfirmationModal, CircleButton, Layout, CompanyContact, CompanyCity, Location, CompanyName },
  data() {
    return {
      formdata: {
        companyId: store.state.company.company.id,
        name: store.state.company.company.name,
        phone: store.state.company.company.phone,
        email: store.state.company.company.email,
        register: store.state.company.company.register,
        label: store.state.company.company.label,
        website: store.state.company.website.url,
      },
      password: {
        password_old: '',
        password_new: '',
        password_confirm: '',
      },
      newLocation: {
        locations: [
          {
            website_id: store.state.company.website.id,
            email: store.state.company.company.email,
            address: '',
            postcode: '',
            city: '',
            postcode_id: Number,
            state_code: String,
          }],
      },
      show: {
        addLocation: false,
        companyEdit: false,
        password: false,
        explanation: false,
        uidEdit: false
      },
      user: store.state.main.user,
    };
  },
  computed: {
    locations() {
      return store.state.company.locations;
    },
    company() {
      return store.state.company.company;
    },
    website() {
      return store.state.company.website;
    },
  },
  mounted() {
    if(this.company.register_name_check) {
      this.show.uidEdit = true;
    }
  },
  methods: {
    submit() {
      this.show.companyEdit = false;
      companyApi.patch('/companies/' + this.company.id, this.formdata).then(response => store.commit('company/setCompany', response.data.content.company));
    },
    submitAddLocation() {
      if(this.newLocation.locations[0].email === '' || this.newLocation.locations[0].address === '' || this.newLocation.locations[0].postcode === '' || this.newLocation.locations[0].city === '') {
        notification.warning(this.$t('general.fieldsMissing'));
      } else {
        this.show.addLocation = false;
        companyApi.post('/companies/' + this.company.id + '/locations/', this.newLocation.locations[0]).then(response => store.commit('company/setInitialCompanyData', response.data.content.company));
      }
    },

    changePassword() {
      if(this.password.password_new !== this.password.password_confirm) {
        notification.warning(this.$t('companyArea.settings.contact.password2Wrong'));
      } else if(this.password.password_new.length < 8) {
        notification.warning(this.$t('companyArea.settings.contact.passwordShort'));
      } else {
        authApi.post('/users/' + this.user.id + '/change-password/', this.password);
        this.show.password = false;
      }
    },
  }
};
</script>

<style scoped>

</style>
