<template>
  <div class="flex flex-col items-start border border-gray-200 bg-gray-50 pl-5 pt-3 rounded-lg pb-5 pr-4">
    <span class="flex font-semibold justify-center pb-2">{{ translatedGroupLabel }}</span>
    <button class="grid bg-gray-100 border border-gray-400 rounded h-24 w-24 content-center">
      <slot name="image">
        <img :src="getImageUrl(companyClassificationGroup.classificationGroup.svg_icon)" :alt="translatedGroupLabel">
      </slot>
    </button>

    <div>
      <div class="relative mt-2 rounded-md shadow-sm">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <span class="text-gray-500 sm:text-sm">{{ $t('companyArea.components.budgetClassificationGroup.limitByMonth') }}</span>
        </div>
        <input :id="'budget_by_month.'+companyClassificationGroup.id"
               v-model="companyClassificationGroup.budget_by_month"
               type="text"
               name="budget_by_month"
               class="block w-72 lg:w-full rounded-md border-0 py-1.5 pl-36 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
               :placeholder="$t('companyArea.components.budgetClassificationGroup.noLimitPlaceholder')"
               aria-describedby="budget by month"
               @input="change({classificationGroup: companyClassificationGroup, budgetByMonth: companyClassificationGroup.budget_by_month, budgetByDay: companyClassificationGroup.budget_by_day})"
        >
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <span id="price-currency" class="text-gray-500 sm:text-sm">CHF</span>
        </div>
      </div>
    </div>
    <div>
      <div class="relative mt-2 rounded-md shadow-sm">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <span class="text-gray-500 sm:text-sm">{{ $t('companyArea.components.budgetClassificationGroup.limitByDay') }}</span>
        </div>
        <input :id="'budget_by_day.'+companyClassificationGroup.id"
               v-model="companyClassificationGroup.budget_by_day"
               type="text"
               name="budget_by_day"
               class="block w-72 lg:w-full rounded-md border-0 py-1.5 pl-36 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
               :placeholder="$t('companyArea.components.budgetClassificationGroup.noLimitPlaceholder')"
               aria-describedby="budget by day"
               @input="change({classificationGroup: companyClassificationGroup, budgetByMonth: companyClassificationGroup.budget_by_month, budgetByDay: companyClassificationGroup.budget_by_day})"
        >
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <span id="price-currency" class="text-gray-500 sm:text-sm">CHF</span>
        </div>
      </div>
    </div>

    <!-- Display used budgets -->
    <div class="mt-4 shadow rounded w-72 lg:w-full pr-6 bg-green-50 px-3 py-5 mt-4">
      <span class="flex font-semibold pb-2">{{ $t('companyArea.components.budgetClassificationGroup.usedTitle') }}</span>
      <div v-if="companyClassificationGroup.used_budget_by_month !== null">
        <span>{{ $t('companyArea.components.budgetClassificationGroup.usedThisMonth', {used_budget_by_month: companyClassificationGroup.used_budget_by_month}) }}</span>
      </div>
      <div v-if="companyClassificationGroup.used_budget_by_day !== null">
        <span>{{ $t('companyArea.components.budgetClassificationGroup.usedToday', {used_budget_by_day: companyClassificationGroup.used_budget_by_day}) }}</span>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'BudgetClassificationGroup',
    components: {},
    props: {
      companyClassificationGroup: {},
    },
    emits: ['change'],
    computed: {
      translatedGroupLabel() {
        return this.$t('tableClassificationGroup.' + this.companyClassificationGroup.classificationGroup.translation_key);
      },
    },
    methods: {
      change(classification) {
        this.$emit('change', classification);
      },
      getImageUrl(name) {
        return new URL(`../../../assets/images/icons/${name}`, import.meta.url).href;
      },
    },
  };
</script>
